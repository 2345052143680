<template>
  <div v-if="enableTimezone" class="timezone-mark">
    <div
      v-sa-track="[{type: 'click', event: 'web_time_zone_click', data: getTrackData('iconClick')}]"
      class="timezone-mark-container"
      @click="openTimezoneChangePopup"
    >
      <div class="iconfont icon-timezone" />
      <div class="timezone-name">
        {{ timezone }}
      </div>
    </div>

    <div class="popover-wrapper">
      <div v-if="showAutoTimezoneNotice" class="popover-container">
        <div class="timezone-item website-timezone">
          <div class="title">
            {{ langContext.websiteTimezoneNotice }}
          </div>
          <div class="timezone-name">
            {{ timezone }}
          </div>
        </div>
        <div class="timezone-item device-timezone">
          <div class="title">
            {{ langContext.currentTimezoneNotice }}
          </div>
          <div class="timezone-name">
            {{ deviceTimezone }}
          </div>
        </div>
        <div class="button-wrapper button-group">
          <div
            v-sa-track="[{type: 'click', event: 'web_time_zone_popup_click', data: getTrackData('buttonClick', 1)}]"
            class="button button-grey button-cannel"
            @click="hideAutoTimezoneNotice"
          >
            {{ langContext.autoTimezoneButton.cancelName }}
          </div>
          <div
            v-sa-track="[{type: 'click', event: 'web_time_zone_popup_click', data: getTrackData('buttonClick', 2)}]"
            class="button button-default button-confirm"
            @click="handleChangeTimezone"
          >
            {{ langContext.autoTimezoneButton.confirmName }}
          </div>
        </div>
        <div class="iconfont icon-arrow-top" />
      </div>
      <div v-if="showCurrentTimezoneNotice" class="popover-container">
        <div class="timezone-item device-timezone">
          <div class="title">
            {{ langContext.currentTimezoneNotice }}
          </div>
          <div class="timezone-name">
            {{ timezone }}
          </div>
        </div>
        <div class="button-wrapper">
          <div
            v-sa-track="[{type: 'click', event: 'web_time_zone_popup_click', data: getTrackData('buttonClick', 3)}]"
            class="button button-grey button-cannel"
            @click="hideCurrentTimezoneNotice"
          >
            {{ langContext.currentTimezoneButton.confirmName }}
          </div>
        </div>
        <div class="iconfont icon-arrow-top" />
      </div>
    </div>
    <TimezoneChangePopup ref="TimezoneChangePopup" @confirm="handleTimezoneChangePopupHide" />
  </div>
</template>

<script>
/**
 * 时区标识web组件
 */
import mixin from './mixin'
import TimezoneChangePopup from '../TimezoneChangePopup/web'

export default {
  name: 'TimezoneMark',
  components: {
    TimezoneChangePopup,
  },
  mixins: [mixin],
  methods: {
    /**
     * 点击时区切换
     */
    handleChangeTimezone() {
      this.switchDeviceTimezone()
      this.hideAutoTimezoneNotice()
      this.$ElMessage.success({
        message: this.langContext.refreshNotice,
        duration: 2000,
      })
      setTimeout(() => {
        window.location.reload()
      }, 2000)
    },
  },
}
</script>

<style lang="scss" scoped>
.timezone-mark {
  .timezone-mark-container {
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
      opacity: 0.6;
    }
  }

  .icon-timezone {
    font-size: 14px;
    color: #fff;
  }

  .timezone-name {
    margin-left: 5px;
    font-size: 12px;
    color: #fff;
  }

  .popover-wrapper {
    position: relative;
  }

  .popover-container {
    position: absolute;
    right: 0;
    z-index: 10;
    top: 13px;
    width: 316px;
    padding: 12px;
    background: #fff;
    border-radius: 6px;
    box-shadow: 0 10px 20px 0 rgba(181, 188, 200, 0.2);

    .iconfont {
      position: absolute;
      top: -9px;
      right: 5px;
      color: #fff;
      transform: scaleX(1.5);
    }
  }

  .button-wrapper {
    display: flex;
    margin-top: 16px;
    justify-content: flex-end;

    .button {
      height: 40px;
      font-size: 13px;
      font-weight: 600;
    }

    .button-cannel {
      width: 90px;
      color: var(--txt-color-lv1);
    }

    .button-confirm {
      width: 192px;
    }

    &.button-group {
      justify-content: space-between;
    }
  }

  .timezone-item {
    position: relative;
    padding: 6px 22px;
    height: 45px;

    .title {
      height: 20px;
      line-height: 20px;
      font-size: 14px;
      color: var(--txt-color-lv1);
      font-weight: bold;
    }

    .timezone-name {
      font-size: 12px;
      color: var(--txt-color-lv2);
    }

    &.website-timezone {
      margin-bottom: 5px;

      &::before {
        content: " ";
        display: block;
        position: absolute;
        top: 12px;
        left: 8px;
        width: 6px;
        height: 6px;
        background: #222;
        border-radius: 3px;
      }
    }

    &.device-timezone {
      background: #f8f9fa url('./assets/worldmap.png') center right no-repeat;
      background-size: 85px 46px;
      border-radius: 6px;

      &::before {
        content: " ";
        display: block;
        position: absolute;
        top: 12px;
        left: 8px;
        width: 6px;
        height: 6px;
        background: var(--txt-color-link);
        border-radius: 3px;
      }

      &::after {
        content: " ";
        display: block;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 15px;
        right: 8px;
        background: url('./assets/icon-location.png') no-repeat;
        background-size: 100%;
      }
    }
  }
}
</style>
