// 客户服务页面
export default {
  next: '次',
  submit: '提出',
  back: '戻る',
  feedbackTitle: 'メッセージを送る',
  selectQuestionType: '問題のタイプを選択してください',
  contentDesc: '問題を詳しく説明してください。',
  uploadDesc: '問題を説明するため画像をアップロードしてください（任意） ',
  contentDescValid: '出会った問題を入力してください',
  SubmitFail: '提出失敗しました。後で再試行してください',
  SubmitSuccess: ['提出成功しました。フィードバックを受け取りました', '2営業日以内に、先生からの返信が届きます', '[メッセージ ‐  顧客サービスメッセージ]', 'アプリの通知内容をご確認ください'],
  selectQuestionTypeValid: '問題のタイプを選んでください',
  laterSubmit: '画像がアップロードしました。後で提出してください',
  selectStudentDesc: '学業上の問題を抱えている方',
  selectStudentTipDesc: '質問に答えるために適切な先生を手配いたします',
  feedbackTitleInClass: '質問する',
  SubmitSuccessInClass: ['提出成功しました。フィードバックを受け取りました', '2営業日以内に、先生からの返信が届きます', '[メッセージ - 家庭教師]', 'アプリの通知内容をご確認ください'],
}
