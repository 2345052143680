// 客户服务页面
export default {
  next: 'Next',
  submit: 'Submit',
  back: 'Back',
  feedbackTitle: 'Send a message',
  selectQuestionType: 'Please select a type of problem you are experiencing',
  contentDesc: 'Please describe your problem in detail',
  uploadDesc: 'Upload an image to use in describing your problem (optional) ',
  contentDescValid: 'Please fill in the problems you encountered',
  SubmitFail: 'Submission failed, please try again later',
  SubmitSuccess: ['Submission is successful, we have received your feedback!', 'Within 2 working days, you will receive a reply from the teacher in',
    '[Message - Customer Service Messages]', 'of the App, please pay attention to check the notifications.'],
  selectQuestionTypeValid: 'Please select a type of problem',
  laterSubmit: 'Picture uploaded please submit later',
  selectStudentDesc: 'Who is experiencing academic problems?',
  selectStudentTipDesc: 'We will arrange suitable teachers to answer questions',
  feedbackTitleInClass: 'Ask a Question',
  SubmitSuccessInClass: ['Submission is successful, we have received your feedback!', 'Within 2 working days, you will receive a reply from the teacher in',
    '[Message - Homework Tutor]', 'of the App, please pay attention to check the notifications.'],
}
