/**
 * goods页面配置
 */
export default {
  recordedCourse: '녹화된 강의',
  courseTypeList: [{
    value: 'live',
    label: '라이브 강의',
  },
  {
    value: 'recorded',
    label: '녹화된 강의',
  },
  ],
  // 录播课
  recordingDetail: {
    exceptiontip: '이 강의는 더 이상 등록할 수 없습니다. 더 많은 강의는 Think Academy에서 제공합니다.',
    exceptiontip1: '죄송합니다. 이 강의는 현재 사용할 수 없습니다. Think Academy에서 제공하는 더 많은 강의를 확인하세요.',
    free: '무료',
    courseDetails: '강의 상세',
    courseDetail: '강의 상세',
    overview: '개요',
    syllabus: '강의 계획',
    teacher: '강사',
    validFor: '유효 기간',
    day: '일',
    days: '일',
    viewMore: '모두 보기',
    lesson: '강의',
    lessons: '강의',
    readMore: '더 읽기',
    hr: '시간',
    min: '분',
    courseValidUntil: '만료일: ',
    btnStatus: {
      forSale: '곧 출시',
      buyNow: '지금 구매',
      soldOut: '매진',
      registered: '성공적으로 등록됨',
      subscription: '구매',
    },
    expired: '만료됨',
    expiredTip: '이 강의는 만료되었습니다.',
    permanentTip: '강의는 영구적으로 유효합니다.',
    start: '시작',
  },
  goodsDetail: {
    year: '연간',
    month: '월간',
    discounts: '-20%',
  },
  subscriptionPeriod: {
    2: '일',
    3: '월',
    4: '년',
  },
  subDate: {
    2: '일',
    3: '월',
    4: '년',
  },
  howToAttendClass: {
    title: '수업 참여 방법',
    firstStepLeft: '앱 다운로드',
    firstStepRight: '앱',
    secondStep: '앱에 로그인하면 "수업"에서 강의를 찾을 수 있습니다.',
    thirdStep: '학습을 시작하려는 강의를 찾아 "입장"을 클릭하세요.',
  },
  orderResult: {
    viewMoreCourse: '더 많은 강의 보기',
    viewOrder: '주문 보기',
    viewCourse: '강의 보기',
    cancel: '취소',
    howToAttendClass: '수업 참여 방법',
  },
  recordedVideoTips: {
    0: '재생',
    '0-1': '일시 정지',
    1: '10초 되감기',
    2: '10초 빨리 감기',
    9: '스크린샷',
    11: '전체 화면',
    '11-1': '전체 화면 종료',
  },
  recordedVideo: {
    reload: '새로 고침',
    loadFailed: '로드 실패',
    loading: '로딩 중',
    saveShotScreen: '스크린샷이 저장되었습니다.',
    switchChannel: '채널 전환',
  },
}
