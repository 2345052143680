/**
 * 根据IP地址检测用户网络环境
 * 将环境类型存储于store中
 */
import { overseaApi } from 'config/api'

export default async function ({ req, store, $axios }) {
  if (process.client) return
  try {
    const { headers } = req.ctx
    const forwarded = headers['x-forwarded-for'] || ''
    const forwardedList = forwarded.split(',')
    const realIp = forwardedList.length ? forwardedList[0] : ''
    if (!realIp) {
      return
    }
    const res = await $axios.post(overseaApi.getCityInfoByIp, { ip: realIp }, { rewritePostBody: false })
    const { code, data } = res || {}
    if (code !== 0) {
      return
    }
    const { isMainland, isChina } = data
    store.dispatch('setUserNetworkInfo', {
      isMainland,
      isChina,
    })
  } catch (error) {
    console.log(error)
  }
}
