/**
 * 诊断系统域名映射
 */

export default {
  origin: {
    local: {
      uk: 'http://local-uk.thethinkacademy.com:3012/quiz',
      sg: 'http://local-sg.thethinkacademy.com:3012/quiz',
      bmsg: 'http://local-touch-sg.bettermeedu.com:3012/quiz',
      bmus: 'http://local-touch-us.bettermeedu.com:3012/quiz',
      us: 'http://local-us.thethinkacademy.com:3012/quiz',
      cn: 'http://local-mars.thethinkacademy.com:3012/quiz',
      hk: 'http://local-hk.thethinkacademy.com:3012/quiz',
      tmc: 'http://local.globalmathclub.com:3012/quiz',
      au: 'http://local-au.thethinkacademy.com:3012/quiz',
      my: 'http://local-my.thethinkacademy.com:3012/quiz',
      ca: 'http://local-ca.thethinkacademy.com:3012/quiz',
      tc: 'http://local-chinese.thethinkacademy.com:3012/quiz',
      ai: 'http://local-ai.thethinkacademy.com:3012/quiz',
      tm: 'http://local-tm.think-matrix.com:3012/quiz',
      jp: 'http://local-jp.thethinkacademy.com:3012/quiz',
      kr: 'http://local-kr.thethinkacademy.com:3012/quiz',
      fr: 'http://local-fr.thethinkacademy.com:3012/quiz',
    },
    dev: {
      uk: 'http://beta-uk.thethinkacademy.com/quiz',
      hk: 'http://beta-hk.thethinkacademy.com/quiz',
      bmsg: 'http://beta-sg.bettermeedu.com/quiz',
      bmus: 'http://beta-us.bettermeedu.com/quiz',
      sg: 'http://beta-sg.thethinkacademy.com/quiz',
      us: 'http://beta-us.thethinkacademy.com/quiz',
      cn: 'http://beta-mars.thethinkacademy.com/quiz',
      tmc: 'http://beta.globalmathclub.com/quiz',
      au: 'http://beta-au.thethinkacademy.com/quiz',
      my: 'http://beta-my.thethinkacademy.com/quiz',
      ca: 'http://beta-ca.thethinkacademy.com/quiz',
      tc: 'http://beta-chinese.thethinkacademy.com/quiz',
      ai: 'http://beta-ai.tal.com/quiz',
      tm: 'http://beta-tm.think-matrix.com/quiz',
      jp: 'http://beta-jp.thethinkacademy.com/quiz',
      kr: 'http://beta-kr.thethinkacademy.com/quiz',
      fr: 'http://beta-fr.thethinkacademy.com/quiz',
    },
    beta: {
      uk: 'http://beta-uk.thethinkacademy.com/quiz',
      hk: 'http://beta-hk.thethinkacademy.com/quiz',
      bmsg: 'http://beta-sg.bettermeedu.com/quiz',
      bmus: 'http://beta-us.bettermeedu.com/quiz',
      sg: 'http://beta-sg.thethinkacademy.com/quiz',
      us: 'http://beta-us.thethinkacademy.com/quiz',
      cn: 'http://beta-mars.thethinkacademy.com/quiz',
      tmc: 'http://beta.globalmathclub.com/quiz',
      au: 'http://beta-au.thethinkacademy.com/quiz',
      my: 'http://beta-my.thethinkacademy.com/quiz',
      ca: 'http://beta-ca.thethinkacademy.com/quiz',
      tc: 'http://beta-chinese.thethinkacademy.com/quiz',
      ai: 'http://beta-ai.tal.com/quiz',
      tm: 'http://beta-tm.think-matrix.com/quiz',
      jp: 'http://beta-jp.thethinkacademy.com/quiz',
      kr: 'http://beta-kr.thethinkacademy.com/quiz',
      fr: 'http://beta-fr.thethinkacademy.com/quiz',
    },
    preprod: {
      uk: 'https://pre-prod-uk.thethinkacademy.com/quiz',
      hk: 'https://pre-prod-hk.thethinkacademy.com/quiz',
      bmsg: 'https://pre-prod-sg.bettermeedu.com/quiz',
      bmus: 'https://pre-prod-us.bettermeedu.com/quiz',
      sg: 'https://pre-prod-sg.thethinkacademy.com/quiz',
      us: 'https://pre-prod-us.thethinkacademy.com/quiz',
      cn: 'https://pre-prod-mars.thethinkacademy.com/quiz',
      tmc: 'https://pre-prod.globalmathclub.com/quiz',
      au: 'https://pre-prod-au.thethinkacademy.com/quiz',
      my: 'https://pre-prod-my.thethinkacademy.com/quiz',
      ca: 'https://pre-prod-ca.thethinkacademy.com/quiz',
      tc: 'https://pre-prod-chinese.thethinkacademy.com/quiz',
      ai: 'https://pre-prod-ai.tal.com/quiz',
      tm: 'https://pre-prod-tm.think-matrix.com/quiz',
      jp: 'https://pre-prod-jp.thethinkacademy.com/quiz',
      kr: 'https://pre-prod-kr.thethinkacademy.com/quiz',
      fr: 'https://pre-prod-fr.thethinkacademy.com/quiz',
    },
    prod: {
      uk: 'https://www.thinkacademy.uk/quiz',
      hk: 'https://hk.thethinkacademy.com/quiz',
      bmsg: 'https://www-sg.bettermeedu.com/quiz',
      bmus: 'https://www.bettermeedu.com/quiz',
      sg: 'https://www.thinkacademy.sg/quiz',
      us: 'https://www.thethinkacademy.com/quiz',
      cn: 'https://mars.thethinkacademy.com/quiz',
      tmc: 'https://www.globalmathclub.com/quiz',
      au: 'https://au.thethinkacademy.com/quiz',
      my: 'https://thinkacademymy.com/quiz',
      ca: 'https://www.thinkacademy.ca/quiz',
      tc: 'https://chinese.thethinkacademy.com/quiz',
      ai: 'https://ai.tal.com/quiz',
      tm: 'https://www.think-matrix.com/quiz',
      jp: 'https://jp.thethinkacademy.com/quiz',
      kr: 'https://kr.thethinkacademy.com/quiz',
      fr: 'https://fr.thethinkacademy.com/quiz',
    },
  },
}
