/**
 * 生成随机秒数
 */
export default async function ({ store }) {
  if (process.server) return
  if (store.state.locale !== 'hk') {
    return
  }
  console.log('randomSecond中间件')
  const millisecondCache = window.localStorage.getItem('_official_random_millisecond')
  if (millisecondCache) {
    return
  }
  // 生成0到5000毫秒随机数
  const millisecond = Math.round(Math.random() * 5) * 1000
  window.localStorage.setItem('_official_random_millisecond', millisecond)
}
