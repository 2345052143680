/* eslint-disable max-len */
// 客户服务页面
export default {
  next: 'Suivant',
  submit: 'Soumettre',
  back: 'Retour',
  feedbackTitle: 'Envoyer un message',
  selectQuestionType: 'Veuillez sélectionner le type de problème que vous rencontrez',
  contentDesc: 'Veuillez décrire votre problème en détail',
  uploadDesc: 'Téléchargez une image à utiliser pour décrire votre problème (facultatif) ',
  contentDescValid: 'Veuillez indiquer les problèmes que vous avez rencontrés',
  SubmitFail: 'L\'envoi a échoué, veuillez réessayer plus tard',
  SubmitSuccess: ['La soumission est réussie, nous avons reçu votre retour d\'information!', 'Dans les 2 jours ouvrables, vous recevrez une réponse de l\'enseignant en',
    '[Message - Messages du service client]', 'de l\'application, veillez à vérifier les notifications.'],
  selectQuestionTypeValid: 'Veuillez sélectionner un type de problème',
  laterSubmit: 'Photo téléchargée, à soumettre plus tard',
  selectStudentDesc: 'Qui a des problèmes scolaires?',
  selectStudentTipDesc: 'Nous mettrons à disposition des enseignants compétents pour répondre aux questions',
  feedbackTitleInClass: 'Poser une question',
  SubmitSuccessInClass: ['La soumission est réussie, nous avons reçu vos commentaires!', 'Dans les 2 jours ouvrables, vous recevrez une réponse de l\'enseignant en',
    '[Message - Tuteur de devoirs]', 'de l\'application, veillez à vérifier les notifications.'],
}
