/**
 * 公共数据查询
 */
export default async function ({ store, req,app }) {
  // 客户端环境不执行
  if (process.client) return;
  if (process.env.clientType !== 'web') {
    const appDevice = req.ctx.cookies.get('_app_device')
    const appVersion = req.ctx.cookies.get('_app_version')
    const appName = req.ctx.cookies.get('_app_name')

    // 设置APP设备名称
    store.commit('SET_APP_DEVICE', appDevice)
    // 设置APP版本号
    store.commit('SET_APP_VERSION', appVersion)
    // 设置APP名称
    store.commit('SET_APP_NAME', decodeURI(appName))
  }
  // 请求基础数据
  await store.dispatch('requeryBasicData', app.$axios)
}
