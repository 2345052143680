export default {
  pageTitle: 'Think Academy 앱 다운로드',
  studentApp: '학생 앱',
  studentInfo: '흥미 유발을 위한 몰입형 대화식 온라인 수업',
  parentApp: '부모 앱',
  parentInfo: '자녀의 학습 진행 상황에 대한 실시간 업데이트를 제공하는 편리한 도우미',
  deviceTitle: '기기 요구 사항',
  windowsRequirement: {
    title: 'Windows 요구 사항',
    info1: '운영 체제: Windows 7 이상',
    info2: '최소 구성: CPU 4 코어, 4GB RAM',
    info3: '기기: Chromebook 및 Surface Book은 지원되지 않습니다',
  },
  macRequirement: {
    title: 'Mac 요구 사항',
    info1: '운영 체제: MacOS 10.12 이상',
    info2: '최소 구성: CPU 4 코어, 4GB RAM',
  },
  ipadRequirement: {
    title: 'iPad 요구 사항',
    info1: '운영 체제: iOS 10.0 이상',
    info2: '기기: iPad 5, iPad Air 3, iPad mini 5 및 그 이상의 모델',
  },
  iphoneRequirement: {
    title: 'iPhone 요구 사항',
    info1: '운영 체제: iOS 10.0 이상',
    info2: '기기: iPhone7/Plus 및 그 이상의 모델',
  },
  androidRequirement: {
    title: 'Android 요구 사항',
    info1: '운영 체제: Android 6.0 이상',
    info2: '최소 구성 (태블릿): 2.0 GHz 프로세서 속도, 4GB RAM',
  },
  parentThinkAcademy: 'Think Academy 부모',
  download: '다운로드',
}
