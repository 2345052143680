/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
import { createWay } from 'config/order.js';

export const state = () => ({
  // 订单来源
  orderCreateWay: createWay.NORMAL,
  // 订单状态
  orderStatusFilter: '',
  // 订单商品Id
  skuId: '',
  // 确认订单地址
  createOrderPath: '',
  // 订单参数
  createOrderQuery: {},
  // 订单状态
  statusFilterValue: '',
  // 订单分类
  categoryId: '',
  // 当前选中订单状态索引
  statusFilterIndex: -1,
  // 课纲
  expireDays: 0,
  syllabusList: [],
  // 订阅周期
  periodType: 3,
});

export const mutations = {
  /**
   * 设置确认订单页参数
   */
  SET_ORDER_CREATE_QUERY(state, query) {
    state.createOrderQuery = { ...query }
  },
  /** 设置课纲 */
  SET_SYLLABUS(state, data) {
    state.expireDays = data.expireDays
    state.syllabusList = data.syllabusList
  },
  /**
   * 设置订单商品Id
   * @param {*} state
   * @param {*} skuId
   */
  SET_ORDER_SKU_ID(state, skuId) {
    state.skuId = skuId
  },
  /**
     * 设置确认订单页地址
     */
  SET_ORDER_CREATE_PATH(state, path) {
    state.createOrderPath = path
  },
  /**
     * 设置创建订单的途径(拼团、限时购等)
     * @param {} state
     * @param {string} way
     */
  SET_ORDER_CREATE_WAY(state, way) {
    state.orderCreateWay = createWay[way] || createWay.NORMAL
  },
  /**
   * 设置订单状态筛选器的值
   * @param {state} state
   * @param {status} status
   */
  SET_ORDER_STATUS_FILTER_VALUE(state, status) {
    state.statusFilterValue = status;
  },
  /**
   * 设置订单分类ID
   * @param {*} id Number
   */
  SET_CATEGORY_ID(state, id) {
    state.categoryId = id
  },
  /**
   * 设置订单状态筛选器的索引
   * @param {state} state
   * @param {index} index
   */
  SET_ORDER_STATUS_FILTER_INDEX(state, index) {
    state.statusFilterIndex = index;
  },
  /**
   * 设置订单状态筛选器的值
   * @param {state} state
   * @param {status} status
   */
  SET_ORDER_STATUS_FILTER(state, status) {
    state.orderStatusFilter = status;
  },
  /**
  * 设置订阅周期类型
  * @param {*} state
  * @param {*} type
  */
  SET_SUB_PERIOD_TYPE(state, type) {
    state.periodType = type
  },
};

export const actions = {
  updateCreateOrderInfo({ state, commit }, config) {
    const { way } = config
    commit('SET_ORDER_CREATE_WAY', way)
    const { skuId } = config
    commit('SET_ORDER_SKU_ID', skuId)
    commit('SET_ORDER_CREATE_PATH', `/order/create/${skuId}`)
    let query = { orderOrigin: state.orderCreateWay }
    switch (state.orderCreateWay) {
      case 1:
        // 正常购买
        break
      case 2:
        // 待缴费
        query = Object.assign(query, { fromPage: 'unpaid-courses-list' })
        break
      case 3:
        // 拼团-开团
        query = Object.assign(query, { activityId: config.activityId, activityType: config.activityType })
        break
      case 4:
        // 拼团-参团
        query = Object.assign(query, { groupId: config.groupId, activityId: config.activityId, activityType: config.activityType })
        break
      default: break
    }
    commit('SET_ORDER_CREATE_QUERY', query)
  },
  updateCategoryId({ commit }, categoryId) {
    commit('SET_CATEGORY_ID', categoryId)
  },
}
