/**
 * 设置购课场景，用于埋点
 */
import { createWay } from 'config/order.js';

export default ({ app, query, store }) => {
  app.router.afterEach((to, from) => {
    let scene = ''
    if (to.name === 'order-list' || to.name === 'order-detail-orderId') {
      // 订单类
      scene = 'my_orders';
    }
    if (to.name === 'courses-detail-coursesId' || to.name === 'order-create-skuId') {
      const fromPage = query.from || to.query.fromPage || from.name || '';
      // scene = fromPage === 'unpaid-courses-list' ? 'unpaid_courses' : 'regular';
      switch (fromPage) {
        case 'unpaid-courses-list': {
          // 待缴费
          scene = 'unpaid_courses';
          break;
        }
        case 'free-trial-class': {
          // 免费试听课
          scene = 'free_trial_class'
          break;
        }
        case 'level_testing': {
          // 诊断
          scene = 'level_testing'
          break
        }
        case 'courses-detail-coursesId': {
          if (store.state.order.orderCreateWay === createWay.ACTIVITY_GROUP_NEW) {
            // 开团
            scene = 'regular_open_group_buy'
          } else if (store.state.order.orderCreateWay === createWay.ACTIVITY_GROUP_JOIN) {
            // 参团
            scene = 'regular_join_group_buy'
          }
          scene = 'regular'
          break
        }
        case 'activity-detail-coursesId':
          // 参团详情页
          scene = 'join_group_buy_page'
          break
        case 'invite_activity': {
          // 老带新
          scene = 'referral'
          break
        }
        case 'h5_activity': {
          // H5编辑器
          scene = 'h5_activity'
          break
        }
        default: {
          // 班级详情页
          scene = 'regular';
          break;
        }
      }
    }
    if (scene) window.sessionStorage.setItem('scene', scene)
  })
}
