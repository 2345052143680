/**
 * 课程筛选
 */
export default {
  more: '더 보기',
  retract: '축소',
  reset: '초기화',
  define: '정의',
  all: '전체',
  resultsNum: '필터링된 결과 {total}개',
}
