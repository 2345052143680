<template>
  <div v-if="showFooter" class="footer">
    <div class="wrapper font-medium">
      <div class="module-item brand">
        <div class="logo" :class="`logo-${$store.state.locale}`">
          <img v-lazy-load v-lazy-load :data-src="logoUrl" :alt="$t('seo.common.logoDescription')" :title="$t('common.websiteName')" height="40" width="auto" loading="lazy">
        </div>
        <div v-if="$t('common.footerOtherInfo').length > 0" class="right">
          <div v-for="(otherInfo, index) in $t('common.footerOtherInfo')" :key="index" class="wasc-auth">
            <a v-if="otherInfo.link" :href="otherInfo.link" :aria-label="otherInfo.name" target="_blank">
              <img class="wasc-icon" :height="otherInfo.iconHeight" v-lazy-load :data-src="otherInfo.icon" alt="" loading="lazy">
            </a>
            <img v-else class="wasc-icon" :height="otherInfo.iconHeight" v-lazy-load :data-src="otherInfo.icon" alt="" loading="lazy">
            <p class="wasc-name" v-html="otherInfo.name" />
          </div>
        </div>
      </div>
      <div class="module-item links">
        <template v-if="showDetail">
          <div class="main-container">
            <div class="links-group-item about-us">
              <div class="title">
                {{ aboutUs.title }}
              </div>
              <template v-for="(item, index) in aboutUs.links">
                <a v-if="item.target" :key="index" class="hover-transition" rel="noopener" :href="item.url" :target="item.target">{{ item.name }}</a>
                <nuxt-link v-else :key="`nuxt-link-${index}`" class="hover-transition" :to="item.url" :target="item.target" rel="noopener">
                  {{ item.name }}
                </nuxt-link>
              </template>
            </div>
            <!-- 联系方式模块 -->
            <template v-if="Object.keys(contactUsConfig).length">
              <!-- 邮箱&电话号 -->
              <template v-if="contactUsConfig.displayType == 'contact'">
                <div class="links-group-item contact">
                  <div class="title">
                    {{ contactUsConfig.title }}
                  </div>
                  <div v-if="contactList.length && locale !== 'tm'" class="contact-list">
                    <div v-for="(item, index) in contactList" :key="index" class="contact-item">
                      <div class="item-title" v-html="item.title" />
                      <div class="item-content" v-html="item.content" />
                      <div v-if="item.footer" v-html="item.footer" />
                    </div>
                  </div>
                  <div v-else class="item-group">
                    <a v-if="showContactEmail && contactEmail" class="alink-with-icon hover-transition" rel="noopener" :href="`mailto:${contactEmail}`">
                      <i class="iconfont icon-mail2" /><span>{{ contactEmail }}</span>
                    </a>
                    <a v-if="contactPhone && locale !== 'tm'" class="alink-with-icon hover-transition" rel="noopener" :href="`tel:${contactPhone}`">
                      <i class="iconfont icon-phone2" /><span>{{ contactPhoneString || contactPhone }}</span>
                    </a>
                  </div>
                </div>
              </template>
              <!-- 地址联系方式 -->
              <template v-else-if="contactUsConfig.displayType == 'address'">
                <div class="links-group-item contact address font-medium">
                  <div class="title">
                    {{ contactUsConfig.title }}
                  </div>
                  <div class="item-group">
                    <div v-for="(address, index) in contactUsConfig.data" :key="index" class="address-item">
                      <div class="title">
                        {{ address.title }}
                      </div>
                      <div class="desc phone">
                        <i class="iconfont icon-telephone-solid" /><span>{{ address.phone }}</span>
                      </div>
                      <div class="desc open-time">
                        <i class="iconfont icon-take-class-time-solid" /><span>{{ address.openTime }}</span>
                      </div>
                      <div class="desc address-desc">
                        <i class="iconfont icon-location-solid" /><span>{{ address.address }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <!-- 电话&工作时间 -->
              <template v-if="contactUsConfig.displayType == 'workTime'">
                <div class="links-group-item contact">
                  <div class="title">
                    {{ contactUsConfig.title }}
                  </div>
                  <div class="item-group">
                    <div v-if="contactPhone" class="contact-with-icon">
                      <i class="iconfont icon-phone2" /><span>{{ contactPhone }}</span>
                    </div>
                    <div v-if="workingTime" class="contact-with-icon">
                      <i class="iconfont icon-take-class-time-solid" /><span>{{ workingTime }}</span>
                    </div>
                  </div>
                </div>
              </template>
            </template>
            <div v-if="followsLinks.length > 0" class="links-group-item follow">
              <div class="title">
                {{ follows.title }}
              </div>
              <div class="item-group">
                <template v-for="(follow, index) in followsLinks">
                  <a v-if="follow.linkType == 'link'" :key="index" class="alink-with-icon hover-transition" rel="noopener" :href="follow.link" :target="follow.target">
                    <i class="iconfont" :class="[follow.icon]" /><span class="alink-text">{{ follow.name }}</span>
                  </a>
                  <div v-else-if="follow.linkType == 'qrcode'" :key="`follow-v-else-${index}`" class="alink-with-icon has-qrcode">
                    <i class="iconfont" :class="[follow.icon]" /><span class="alink-text">{{ follow.name }}</span>
                    <!-- 两个二维码特殊处理 -->
                    <div v-if="follow.linkWechat" class="qrcode-wrapper hover-transition" :class="{'is-fr': follow.linkWechat}">
                      <div>
                        <img v-lazy-load :data-src="follow.link" :alt="$t('common.websiteName') + ' ' + follow.name" class="qrcode-img" width="150" loading="lazy">
                        <div v-if="follow.tipText" class="tip-text">
                          {{ follow.tipText }}
                        </div>
                      </div>
                      <div class="line" />
                      <div v-if="follow.linkWechat">
                        <img v-lazy-load :data-src="follow.linkWechat" :alt="$t('common.websiteName') + ' ' + follow.name" class="qrcode-img" width="150" loading="lazy">
                        <div v-if="follow.tipTextWechat" class="tip-text">
                          {{ follow.tipTextWechat }}
                        </div>
                      </div>
                    </div>
                    <div v-else class="qrcode-wrapper hover-transition">
                      <img v-lazy-load :data-src="follow.link" :alt="$t('common.websiteName') + ' ' + follow.name" class="qrcode-img" width="150" loading="lazy">
                      <div v-if="follow.tipText" class="tip-text">
                        {{ follow.tipText }}
                      </div>
                    </div>
                  </div>
                  <!-- </div> -->
                </template>
              </div>
            </div>
            <!-- 友情链接 -->
            <div v-if="otherSchools.length > 0" class="links-group-item other-school">
              <div class="title">
                {{ otherSchool.title }}
              </div>
              <div class="item-group">
                <a v-for="(item, index) in otherSchools" :key="index" class="hover-transition" rel="noopener" :href="item.url" :target="item.target">{{ item.name[locale] }}</a>
              </div>
            </div>
          </div>
        </template>
        <!-- 协议链接 -->
        <div class="policy-links">
          <template v-for="(item, index) in policyLinks">
            <a v-if="item.target" :key="index" class="copyright-alink hover-transition" :href="item.url" :target="item.target">{{ item.name }}</a>
            <nuxt-link v-else :key="`policy-link-else-${index}`" class="copyright-alink hover-transition" :to="item.url">
              {{ item.name }}
            </nuxt-link>
          </template>
        </div>
      </div>
      <div class="module-item company-info">
        <div v-for="(companyInfoItem, companyInfoIndex) in companyInfo" :key="`companyInfoIndex-${companyInfoIndex}`" class="company-info-item">
          <template v-if="!companyInfoItem.isLink">
            <p>{{ companyInfoItem.text }}</p>
          </template>
          <template v-else>
            <a class="host-record-alink hover-transition" :href="companyInfoItem.link" target="_blank" rel="noopener">{{ companyInfoItem.text }}</a>
          </template>
        </div>
      </div>
    </div>
    <!-- 安全协议 -->
    <!-- <div v-if="locale == 'hk'" class="safety">
      <div class="safety-info-item">
        <span>本網站受reCAPTCHA保護，並適用Google</span>
        <a class="host-record-alink hover-transition" href="https://policies.google.com/privacy">隱私政策</a> <span>和</span>
        <a class="host-record-alink hover-transition" href="https://policies.google.com/terms">服務條款</a>
      </div>
    </div>
    <div v-else class="safety">
      <div class="safety-info-item">
        <span>This site is protected by reCAPTCHA and the Google</span>
        <a class="host-record-alink hover-transition" href="https://policies.google.com/privacy">Privacy Policy</a> <span>and</span>
        <a class="host-record-alink hover-transition" href="https://policies.google.com/terms">Terms of Service</a> <span>apply.</span>
      </div>
    </div> -->
  </div>
</template>
<script>
import mixin from './mixin.js';

export default {
  mixins: [mixin],
}
</script>
<style lang="scss" scoped>
.footer {
  font-size: 14px;
  line-height: 18px;
  color: var(--txt-color-lv1);
  background: #f5f5f5;

  .module-item {
    &:not(:last-child) {
      border-bottom: 1px solid #fff;
    }
  }

  i,
  span {
    vertical-align: middle;
  }

  a {
    display: block;
    margin: 20px 0;
    color: inherit;

    &:last-child {
      margin: 20px 0  0;
    }
  }

  a:hover {
    color: #3370ff;
  }

  .alink-with-icon {
    margin: 20px 0;

    &:last-child {
      margin: 20px 0  0;
    }

    .iconfont {
      margin-right: 6px;
      font-size: 20px;
      line-height: 20px;
      text-align: center;
      color: inherit;
    }

    &:hover {
      color: #3370ff;

      .iconfont.icon-ins2 {
        background: linear-gradient(90deg, #ff543e 0%, #c837ab 100%);
        background-clip: text;
        color: transparent;
      }

      .iconfont.icon-ins2 + span.alink-text {
        background: linear-gradient(90deg, #ff543e 0%, #c837ab 100%);
        background-clip: text;
        color: transparent;
      }

      .iconfont.icon-wechat2 {
        color: #02ca8a;
      }

      .iconfont.icon-wechat2 + span.alink-text {
        color: #02ca8a;
      }

      .iconfont.icon-line2 {
        color: #04c300;
      }

      .iconfont.icon-line2 + span.alink-text {
        color: #04c300;
      }

      .iconfont.icon-youtube2 {
        color: #ff503f;
      }

      .iconfont.icon-youtube2 + span.alink-text {
        color: #ff503f;
      }
    }
  }

  .contact-with-icon {
    margin: 20px 0;

    &:last-child {
      margin: 20px 0  0;
    }

    .iconfont {
      margin-right: 6px;
      font-size: 20px;
      line-height: 20px;
      text-align: center;
      color: inherit;
    }
  }

  .module-item.brand {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 20px 0;

    .right {
      display: flex;
      margin-bottom: 6px;

      .wasc-auth {
        display: flex;
        align-items: center;
        margin-left: 30px;

        a {
          margin: 0;
        }

        .wasc-icon {
          display: block;
          margin-right: 6px;
        }

        .wasc-name {
          font-size: 14px;
          line-height: 16px;
          color: var(--txt-color-lv1);
        }
      }
    }
  }

  .module-item.links {
    padding: 30px 0;

    .main-container {
      display: flex;
      justify-content: space-between;
    }

    .links-group-item {
      .title {
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
      }

      .has-qrcode {
        position: relative;
        cursor: pointer;

        .qrcode-wrapper {
          position: absolute;
          left: -190px;
          top: -70px;
          padding: 12px;
          width: 180px;
          background-color: #fff;
          border-radius: 4px;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          text-align: center;
          visibility: hidden;
          opacity: 0;

          .qrcode-img {
            display: block;
            margin: 0 auto;
            width: 150px;
            height: 150px;
            background: #f1f1f1;
          }
        }

        .is-fr {
          display: flex;
          width: auto;
          left: -345px;

          .line {
            margin-top: 26px;
            width: 1px;
            border: 1px solid #f1f1f1;
            height: 100px;
          }
        }

        .tip-text {
          margin-top: 11px;
          font-size: 12px;
          line-height: 14px;
          color: #999;
        }

        &:hover {
          .qrcode-wrapper {
            visibility: visible;
            opacity: 1;
          }
        }
      }
    }
  }

  .module-item.links .links-group-item.contact {
    .contact-list {
      margin-top: 20px;

      .contact-item {
        color: rgba(34, 34, 34, 0.4);

        .item-title {
          margin-bottom: 4px;
        }

        .item-content {
          /deep/ a:hover {
            color: #3370ff;
          }
        }

        &:not(:last-child) {
          margin: 20px 0;
        }
      }
    }
  }

  .module-item.links .links-group-item.contact.address {
    .item-group {
      display: flex;
    }

    .address-item {
      flex-shrink: 0;
      margin-right: 20px;
      width: 280px;

      &:last-child {
        margin-right: 0;
      }

      .title {
        margin: 20px 0  12px;
        font-size: 14px;
        line-height: 20px;
        color: var(--txt-color-lv1);
      }

      .desc {
        display: flex;
        align-items: center;
        margin-bottom: 12px;
        color: var(--txt-color-lv2);

        &:last-child {
          margin-bottom: 0;
        }

        &.address-desc {
          align-items: flex-start;
        }

        &.open-time {
          max-width: 230px;
          align-items: flex-start;
        }

        span {
          font-size: 12px;
          line-height: 17px;
        }
      }

      .iconfont {
        margin-right: 5px;
        font-size: 18px;
        line-height: 18px;
        color: inherit;
      }
    }
  }

  .module-item.links .policy-links {
    display: flex;
    text-decoration: underline;
    margin-top: 20px;

    .copyright-alink {
      margin: 0 30px 0 0;
    }
  }

  .module-item.company-info {
    display: flex;
    justify-content: space-between;
    padding: 32px 0;
    font-size: 14px;
    line-height: 16px;
    color: rgba(34, 34, 34, 0.4);

    .company-info-item:only-child {
      margin: 0 0 0 auto;
    }

    a.host-record-alink {
      margin: 0;
      color: rgba(34, 34, 34, 0.4);
    }

    a.host-record-alink:hover {
      color: #3370ff;
    }
  }

  .safety {
    .safety-info-item {
      display: flex;
      justify-content: center;
      padding: 9px 0;
      font-size: 14px;
      line-height: 16px;
      color: rgba(34, 34, 34, 0.4);
      background-color: #eee;
      border-top: 1px solid #f5f5f5;
    }

    .safety-info-item:only-child {
      margin: 0 0 0 auto;
    }

    a.host-record-alink {
      margin: 0;
      padding: 0 5px;
      color: rgba(34, 34, 34, 0.4);
      text-decoration: underline;
    }
  }
}
</style>
