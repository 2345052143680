/* eslint-disable max-len */
// 绑定弹框配置
export default {
  phone: {
    title: '請完善您的聯繫郵箱',
    subTitle: '填寫準確的手機號，方便您能及時收到老師對於孩子的課堂迴響',
    changeTitle: '请输入想要更换的注册手机号',
    linkTitle: '請輸入想要綁定的注册手機號',
  },
  email: {
    title: '請完善您的聯繫郵箱',
    subTitle: '請填寫準確的郵箱，是為了您能及時收到老師發送的免費學習資源以及更多學習諮詢',
    changeTitle: '请输入想要更换的注册邮箱',
    linkTitle: '請輸入想要綁定的注册郵箱',
  },
  submitBtn: '確認',
  tip: '如果你有任何問題，請聯繫我們：',
  bindSuccess: '編輯成功',
};
