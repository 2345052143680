export default {
  ImproveUserInfo: {
    title: '중요한 연락처 정보 추가',
    WeChat: 'Wechat',
    WhatsApp: 'WhatsApp',
    Line: 'Line',
    save: '저장',
    cancel: '취소',
    editWeChat: 'WeChat를 입력해주세요',
    editWhatsApp: 'WhatsApp를 입력해주세요',
    editLine: 'Line를 입력해주세요',
    contactTip1: 'Wechat / WhatsApp / Line 교사가 학부모와 학생에 대해 소통하기 위해 사용됩니다',
    contactTip2: '학습.',
    contactTip3: '연락처 정보를 정직하게 기입해주세요. 연락처 정보가 누락되면 교사가 학생들의 학습 상황을',
    contactTip4: '즉시 학부모와 동기화하는 데 영향을 미칩니다',
    minChoose: '저장하기 전에 최소한 하나의 연락처 정보를 기입해주세요',
  },
  recordingCard: {
    recordingCourse: '녹화된 강의',
    viewOrder: '주문 보기',
    howToAttendClass: '수업 참여 방법',
    cancel: '취소',
    viewCourse: '강의 보기',
    viewMoreCourse: '더 많은 강의 보기',
  },
  // 支付失败结果页提示文案
  toBePaidResult: {
    title: '중요한 결제 안내',
    content: '차감이 이루어졌으나 주문이 "결제 완료" 상태가 아닌 경우, 은행의 차감 통지를 아직 받지 못한 것일 수 있습니다. 나중에 확인해주세요. 궁금한 사항이 있으시면 다음으로 연락해주세요:',
    confirm: '알겠습니다!',
  },
  courseDetail: {
    rescheduled: '일정 변경됨',
  },
  guidePopup: {
    // 7诊断预约 9已诊断 11预约诊断报告咨询 13完成诊断报告咨询 27长期班待支付
    7: ' to view appointment records.',
    9: ' to view diagnostic reports.',
    11: ' to view appointment records.',
    13: ' to view recommended courses.',
    27: ' to view recommended courses.',
  },
  scoreQuery: {
    legalCheck: '계속하시기 전에 저희 <a class="link-text" href="/terms-of-use" target="_blank">이용 약관</a> 및 <a class="link-text" href="/privacy-policy" target="_blank">개인정보 처리방침</a>을 읽고 동의했음을 확인해 주시기 바랍니다.',
    searchError: '구두점, 공백 또는 기타 특수 문자가 없는 내용을 입력하십시오.',
    notFoundTip: '일치하는 기록을 찾을 수 없습니다. 입력이 정확한지 확인해 주세요.',
    queryResult: '결과 조회',
    share: '다운로드와 공유',
    saveImage: '이미지 저장',
    cancel: '취소',
  },
}
