/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
/**
 * 当前分校配置数据
 */

import { oneApi } from 'config/api';

export const state = () => ({
  // 待完善联系方式种类
  contactsPreferred: [],
  // 分校名
  schoolName: '',
  // 分校英文简写
  schoolSimplifyName: '',
});

export const mutations = {
  /**
  * 分校基础信息
  * @param {*} state
  * @param {*} data
  */
  SET_BASIC_INFO(state, data) {
    Object.keys(state).forEach((key) => {
      if (data[key]) {
        state[key] = data[key]
      }
    })
  },
}

export const actions = {
  async requerySchoolData({ commit }, { axios, schoolCode }) {
    const params = {
      v: '',
    }
    const res = await axios.post(oneApi.requerySchoolConfig, params, { rewritePostBody: true })

    if (res.code !== 0) return
    const resData = res.data || {};

    if (resData.list.length === 0) return
    const schoolData = resData.list.find((item) => item.schoolCode === schoolCode) || {}

    commit('SET_BASIC_INFO', schoolData)
  },
}
