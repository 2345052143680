/**
 * web vitals指标上报
 */

import {
  onFCP,
  onCLS,
  onFID,
  onLCP,
  onTTFB,
} from 'web-vitals/attribution'

export default function ({ app, $sensors, route }) {
  if (process.server) return

  const sendVitals = ({
    name,
    value,
    delta,
    rating,
  }) => {
    $sensors.track('website_vitals', {
      vitals_name: name,
      vitals_value: value,
      vitals_delta: delta,
      vitals_rating: rating,
      page_path: route.path,
    })
  }

  app.router.onReady(() => {
    onFCP(sendVitals)
    onCLS(sendVitals)
    onFID(sendVitals)
    onLCP(sendVitals)
    onTTFB(sendVitals)
  })
}
