export default {
  props: {
    isHide: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      textarea: '',
      maxLen: 500,
    }
  },
  computed: {
    hasInput() {
      return this.textarea.length
    },
    maxTextLen() {
      return this.textarea.length === this.maxLen
    },
  },
  methods: {
    handlemessageSend() {
      if (this.textarea === '') return
      this.handleSpecialLabel()
      this.$emit('send', { content: this.textarea })
      this.textarea = ''
    },
    handleKeydown(event) {
      if (event.keyCode === 13 && !event.shiftKey) {
        event.preventDefault()
      }
    },
    handleKeyup(event) {
      if (event.keyCode === 13 && !event.shiftKey) this.handlemessageSend()
    },
    handleSpecialLabel() {
      this.textarea = this.textarea.replace(/[\<]/g, '&lt;').replace(/[\>]/g, '&gt;')
    },
  },
}
