import { oneApi } from 'config/api';

const uniqueId = require('lodash/uniqueId')

const ReplyTypeEnum = {
  CLASSCARD: 'isCardReply', // 课程卡片
  GUIDER: 'isGuideReply', // 教导主任
  TEXT: 'isContentReply', // 普通文本
  BAD: 'isBadRequest', // 参数错误
}

const apiUrl = process.env.runtime === 'prod' || process.env.runtime === 'preprod' ? 'https://one.thethinkacademy.com/webchat/streamReply' : 'https://beta-one.thethinkacademy.com/webchat/streamReply'

export default {
  data() {
    return {
      isChatOpen: false, // 是否打开
      isInteracting: false, // 是否正在交互中
      isGetClassing: false, // 是否正在获取课程中
      messageList: [], // 消息列表
      welcomeMsg: '', // 欢迎语
      webchatSid: '', // 会话ID
      failedMsgEn: 'Sorry, there is a service problem, I cannot answer the question, please try again later.',
      failedMsgZh: '抱歉，我的服务出现异常问题，本次提问无法为您回答，请您稍后再尝试重新提问。',
      failedClassMsgEn: "Sorry, I can't find a suitable course. Is there anything else I can help you with?",
      failedClassMsgZh: '抱歉，未能找到符合条件的课程。请问我还有什么可以帮您的吗？',
      replyType: '',
    }
  },
  computed: {
    noWeb() {
      return process.env.clientType !== 'web'
    },
  },
  methods: {
    openChat(data) {
      if (this.noWeb) {
        this.stopDefault()
      }
      // 交互中
      if (this.isInteracting || this.webchatSid) {
        this.isChatOpen = true
        return
      }
      const { webchatSid = '', welcome = 'Welcome to Think Academy, you can ask me anything in English or Chinese.', messageList = [] } = data
      this.webchatSid = webchatSid
      this.welcomeMsg = welcome
      this.messageList = this.handleMsgList(messageList)
      this.isChatOpen = true
    },
    async handleSend(message) {
      this.isInteracting = true
      const { content } = message
      const lastMessega = this.handleTextData({ content, status: 'waiting' })
      this.messageList = [...this.messageList, lastMessega]
      this.queryChatContent(content, lastMessega.contentId)
    },
    // 将内容发送chatgpt
    async queryChatContent(content, contentId) {
      const params = {
        webchatSid: this.webchatSid,
        content,
      }
      try {
        const res = await this.$axios.post(oneApi.queryChatContent, params, { rewritePostBody: true, rewriteTimeout: true, rewriteTimer: 10000 })
        if (res.code !== 0) {
          this.handleSendFailed(contentId)
          this.isInteracting = false
          return
        }
        const resData = res.data || {}
        this.handleTheData(contentId, 'success')
        this.handleSendSuccess({ ...resData, ...params })
      } catch (error) {
        this.handleSendFailed(contentId)
        this.isInteracting = false
      }
    },
    //
    handleReplyStreamFail(contentId) {
      this.handleReplyFailed(contentId)
      this.isInteracting = false
    },
    // 获取回复内容
    // async queryChatReply(data, contentId) {
    //   const { messageId, webchatSid } = data
    //   const params = {
    //     webchatSid,
    //     messageId,
    //   }
    //   try {
    //     const res = await this.$axios.post(oneApi.getChatReply, params, { rewritePostBody: true, rewriteTimeout: true, rewriteTimer: 60000 })
    //     this.isInteracting = false
    //     if (res.code !== 0) {
    //       this.handleReplyFailed(contentId)
    //       return
    //     }
    //     const resData = res.data || {}
    //     this.handleReplySuccess(resData, contentId)
    //   } catch (error) {
    //     this.handleReplyFailed(contentId)
    //     this.isInteracting = false
    //   }
    // },
    // 获取回复内容流
    async queryChatReplyStream(reqData, contentId) {
      const { messageId, webchatSid } = reqData
      const body = JSON.stringify({
        data: {
          messageId,
          webchatSid,
        },
      })
      // 正在交互或非首次打开
      try {
        const response = await fetch(apiUrl, {
          method: 'POST',
          body,
          headers: {
            accept: 'text/event-stream',
            'Content-Type': 'application/json',
          },
        })
        if (!response.ok) {
          const error = await response.json()
          this.handleReplyStreamFail(contentId)
          console.error('response.ok', error.error)
        }
        const data = response.body
        const reader = data.getReader()
        const decoder = new TextDecoder('utf-8')
        let done = false
        let isfirst = true
        let newContentID
        let dealChar1
        let dealChar2
        let repID1
        let repID2
        while (!done) {
          /* eslint-disable-next-line */
          const { value, done: readerDone } = await reader.read()
          if (value) {
            const char = decoder.decode(value, { stream: true })
            const eventIndex = char.indexOf('event:isGuideReply');

            if (eventIndex !== -1) {
              // If data contains both formats, split and process separately
              const firstPart = char.slice(0, eventIndex);
              const secondPart = char.slice(eventIndex);

              dealChar1 = this.streamSplit(firstPart);
              dealChar2 = this.streamSplit(secondPart);
            } else {
              // If data contains only one format, follow the existing logic
              dealChar1 = this.streamSplit(char);
            }
            const dealChar = this.streamSplit(char)
            if (dealChar.charType === 3) {
              this.handleReplyStreamFail(contentId)
              return
            }
            if (dealChar1) {
              repID1 = this.streamReplySuccess(dealChar1, isfirst, newContentID);
            }
            if (dealChar2) {
              repID2 = this.streamReplySuccess(dealChar2, isfirst, newContentID);
            }
            if (isfirst) {
              this.delMessageByID(contentId)
              isfirst = false
              newContentID = repID1 || repID2
            }
          }
          done = readerDone
        }
        this.streamReplyToLInk()
        this.$nextTick(() => {
          this.$refs.messageList?.scrollDown()
        })
        if (!this.isGetClassing) this.isInteracting = false
      } catch (error) {
        this.handleReplyStreamFail(contentId)
      }
    },
    // 处理流，生成数据
    streamSplit(char) {
      const chunks = char.split('\n\n')
      const contArr = []
      const author = 2 // 回答
      let charType = 0 // 0 普通，1 教导主任，2 课程卡片  3 接口报错
      let type = 0 // 0 文本 1 图片

      chunks.forEach((chunk) => {
        if (chunk === '') return
        // 命中特殊规则
        // 教导主任
        if (chunk.includes(ReplyTypeEnum.GUIDER) || chunk.includes('event: otherinfo')) {
          const { specialStr, specialType } = this.streamSpecialStr(chunk)
          if (specialStr) {
            charType = 1
            type = specialType
            contArr.push({
              content: specialStr,
              messageType: author,
              contentType: specialType,
            })
          }
          return
        }
        // 课程卡片
        if (chunk.includes(ReplyTypeEnum.CLASSCARD) || chunk.includes('skuID')) {
          const { specialStr, specialType } = this.streamSpecialStr(chunk, 'skuID:', 'skuIDEnd', ReplyTypeEnum.CLASSCARD)
          if (specialStr) {
            charType = 2
            type = specialType
            contArr.push({
              content: specialStr,
              messageType: author,
              contentType: specialType,
            })
          }
          return
        }
        // 参数错误
        if (chunk.includes(ReplyTypeEnum.BAD)) {
          charType = 3
          return
        }
        const tmpStr = chunk.replace('data: ', '')
        if (tmpStr !== '') contArr.push(tmpStr)
      })
      return {
        charType,
        contArr,
        author,
        type,
      }
    },
    // 消息回复成功
    // eslint-disable-next-line
    async streamReplySuccess(data, isfirst = false, contentId) {
      const {
        charType = 0, contArr = [], author, type,
      } = data
      // 教导主任
      if (charType === 1) {
        const replyMessageList = this.handleMsgList(contArr, 'reply')
        this.messageList = [...this.messageList, ...replyMessageList]
        this.$nextTick(() => {
          this.$refs.messageList?.scrollDown()
        })
        return
      }
      // 课程卡片
      if (charType === 2) {
        this.isGetClassing = true
        try {
          const res = await this.$axios.post(oneApi.queryCourseDetail, { skuId: contArr[1].content }, { rewritePostBody: true });
          if (res.code !== 0) {
            this.queryCourseFail()
          } else {
            contArr[1].content = res.data || {}
            contArr[0] = { content: contArr[0], messageType: 2, contentType: 0 }
            const replyMessageList = this.handleMsgList(contArr, 'reply')
            this.messageList = [...this.messageList, ...replyMessageList]
            this.$nextTick(() => {
              this.$refs.messageList?.scrollDown()
            })
          }
        } catch (error) {
          this.queryCourseFail()
          console.log('zz error', error)
        }
        this.isGetClassing = false
        this.isInteracting = false
        return
      }
      if (isfirst) {
        const content = contArr.join('')
        const replyMessage = this.handleTextData({ content, author, type })
        this.messageList = [...this.messageList, replyMessage]
        // eslint-disable-next-line
        return replyMessage.contentId
      }
      // 防止回复数据过快未取到值
      let idx = this.messageList.findIndex((item) => item.contentId === contentId)
      idx = idx === -1 ? this.messageList.length - 1 : idx
      this.messageList[idx].content = `${this.messageList[idx].content}${this.handleTextSign(contArr.join(''))}`
      this.$nextTick(() => {
        this.$refs.messageList?.scrollDown()
      })
    },
    queryCourseFail() {
      const failedMsg = this.getReplyStr(ReplyTypeEnum.CLASSCARD)
      const lastMessega = this.handleTextData({ content: failedMsg, author: 2, status: 'success' })
      this.messageList = [...this.messageList, lastMessega]
    },
    // 处理内容成超链接
    streamReplyToLInk() {
      const idx = this.messageList.findIndex((item) => item.type === 0 && item.content?.includes('http') && !item.content?.includes('<a'))
      if (idx === -1) return
      const { content, type } = this.messageList[idx]
      if (type === 1) return
      this.messageList[idx].content = this.handleTextLink(content, 2, type)
    },
    // 获取教导主任、班级卡片
    streamSpecialStr(str, startIdent = '"content":', endIdent = ',"contentType"', origin = ReplyTypeEnum.GUIDER) {
      const startIdx = str.indexOf(startIdent)
      const endIdx = str.indexOf(endIdent)
      const typeIdx = str.lastIndexOf(endIdent)
      let realStartIdx = startIdx + startIdent.length + 1
      let realEndIdx = endIdx - 1
      let specialType = str.substr(typeIdx + endIdent.length + 1, 1) * 1
      if (origin === ReplyTypeEnum.CLASSCARD) {
        realStartIdx -= 1
        realEndIdx += 1
        specialType = 2
      }
      return {
        specialStr: str.slice(realStartIdx, realEndIdx),
        specialType,
      }
    },
    // 删除指定内容
    delMessageByID(contentId) {
      const idx = this.messageList.findIndex((item) => item.contentId === contentId)
      if (idx !== -1) this.messageList.splice(idx, 1)
    },
    // 处理数组内容
    handleMsgList(list, from = 'open') {
      const msgList = []
      if (from === 'open') {
        const welcomeData = this.handleTextData({ content: this.welcomeMsg, author: 2 })
        msgList.push(welcomeData)
      }
      if (list.length === 0) return msgList
      list.forEach((item) => {
        const temp = this.handleTextData({ content: item.content, author: item.messageType || 2, type: item.contentType })
        msgList.push(temp)
      })
      return msgList
    },
    /**
     * 生成内容格式
     * content: 内容
     * author: 1用户  2 机器人
     * status: 消息传输状态 success, failed, waiting
     * type: 消息内容  0表示信息，1表示图片链接
     * contentId: 唯一ID 标识
     *
     */
    handleTextData({
      content = '', author = 1, status = 'success', type = 0,
    }) {
      const contentId = uniqueId('content_')
      if (!content) {
        return ({
          content, author, type, status, contentId,
        })
      }
      // 处理script标签 和 换行符
      const resStr = this.handleTextSign(content)
      // 处理 a 标签
      // resStr = this.handleTextLink(resStr, author, type)
      return {
        content: resStr, author, type, status, contentId,
      }
    },
    // 处理 script 标签 和 换行符
    handleTextSign(str) {
      if (typeof str !== 'string') return str
      const rowRegexp = str.includes('\\n') ? /\\n/g : /\n/g
      return str.replace(/[\<]/g, '&lt;').replace(/[\>]/g, '&gt;').replace(rowRegexp, '<br />')
    },
    // 处理链接
    handleTextLink(str, author, type) {
      if (type === 1) return str
      if (typeof str !== 'string') return str
      const regexp = /(http:\/\/|https:\/\/)([\w=?.\/&-]+)/g
      const aColor = author === 1 ? '#fff' : '#ffaa0a'
      return str.replace(regexp, ($url) => `<a style='color: ${aColor}' href='${$url.endsWith('.') ? $url.slice(0, -1) : $url}' target='_blank'>${$url}</a>`)
    },
    // 处理指定元素
    handleTheData(contentId = '', status = 'failed') {
      const idx = this.messageList.findIndex((item) => item.contentId === contentId)
      const msgLen = this.messageList.length ? this.messageList.length - 1 : 0
      if (idx === -1 || msgLen === 0) return
      // 是否 最新一个元素
      this.messageList[idx].status = status
      if (idx !== msgLen && status === 'success') {
        const curEle = this.messageList.splice(idx, 1)
        this.messageList = [...this.messageList, ...curEle]
      }
    },
    // 处理消息发送失败
    handleSendFailed(contentId) {
      this.handleTheData(contentId)
    },
    // 处理消息回复失败
    handleReplyFailed(contentId) {
      this.handleTheData(contentId, 'success')
    },
    // 消息发送成功
    async handleSendSuccess(data) {
      const failedMsg = this.getReplyStr()
      const lastMessega = this.handleTextData({ content: failedMsg, author: 2, status: 'waiting' })
      this.messageList = [...this.messageList, lastMessega]
      // this.queryChatReply(data, lastMessega.contentId)
      this.queryChatReplyStream(data, lastMessega.contentId)
    },
    // 消息回复成功
    // handleReplySuccess(data, contentId) {
    //   const idx = this.messageList.findIndex((item) => item.contentId === contentId)
    //   if (idx !== -1) this.messageList.splice(idx, 1)
    //   const { messageList = [] } = data
    //   const replyMessageList = this.handleMsgList(messageList, 'reply')
    //   this.messageList = [...this.messageList, ...replyMessageList]
    // },
    // 重新发送消息
    hanfleRetrySend(contentId) {
      if (this.isInteracting) return
      this.isInteracting = true
      this.handleTheData(contentId, 'waiting')
      const lastItem = this.messageList.find((item) => item.contentId === contentId)
      const content = lastItem?.content
      this.queryChatContent(content, contentId)
    },
    // 获取回复失败文案
    getReplyStr(origin = '') {
      const lastLen = this.messageList.length ? this.messageList.length - 1 : 0
      const { content = '' } = this.messageList[lastLen]
      const isZh = /[\u4e00-\u9fa5]/.test(content)
      if (isZh) {
        return origin === ReplyTypeEnum.CLASSCARD ? this.failedClassMsgZh : this.failedMsgZh
      }
      return origin === ReplyTypeEnum.CLASSCARD ? this.failedClassMsgEn : this.failedMsgEn
    },
  },
}
