/**
 * goods页面配置
 */
export default {
  goodsDetail: {
    productDetails: '商品介紹',
    productOverview: '商品詳情',
    buyNow: '立即購買',
    onlyStock: '僅剩{0}個庫存',
    stock: '庫存',
    quantity: '數量',
    btnStatus: {
      forSale: '待售中',
      buyNow: '立即購買',
      soldOut: '已售罄',
    },
    retryBtnText: '查看更多課程',
    exceptiontip: '此商品已不再售卖，请查看Think Academy提供的其他服务',
    outOfStockError: '手慢一步，商品已售罄',
    purchasedCourse: '您已報名過此課程，暫不支持重複購買',
    exceeds: '輸入的商品數目超出庫存數（{0}），請重新輸入',
    readMore: '展開',
    readLess: '收起',
  },

}
