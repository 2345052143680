/**
 * 时区切换窗口
 */
export default {
  // 标题
  title: '時間帯を選ぶ',
  // 当前时区描述
  describe: '現在の時間帯は',
  // 无搜索结果提示
  noResultNotice: '結果なし',
  // 切换定位刷新页面提示
  refreshNotice: '時間帯を変更する',
  // 搜索输入框提示文本
  searchInputPlaceholder: '時間帯を検索',
}
