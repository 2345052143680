export default {
  refund: {
    payment: '',
    refundTuition: 'Remboursable ',
    refundMaterial: 'Matériel d\'apprentissage remboursable',
    refundMaterialTitle: 'Matériel d\'apprentissage remboursable',
    refundMaterialTips: 'Seul le matériel pédagogique qui n\'a pas été expédié peut faire l\'objet d\'un remboursement. Si vous avez des questions, veuillez nous contacter à l\'adresse suivante: {x}',
    attachedGood: 'Pièces jointes',
  },
  preclass: 'Préparation avant le cours',
  preclassTips1: 'Veuillez télécharger ',
  preclassTips2: ' de comprendre la préparation avant la classe',
  safetyTips: 'Nos services de paiement sont fournis par des institutions de paiement internationales certifiées et sécurisées.',
  activation: 'activation',
  toBeActivation: 'To be activated',
  shoppingCart: {
    myCart: "Mon panier d'achat",
    totalItems: 'Total {x} articles',
    deleteItem: 'Supprimer l\'article',
    cancelText: 'Annuler',
    confirmDel: 'Confirmer la suppression',
    deleteText: 'Supprimer',
    couponsInvalid: 'Coupon invalide',
    payMethod: 'Ce mode de paiement est',
    autoDeduction: 'déduction automatique',
    cartDetails: 'Détails du panier',
    addToCart: 'ajouter au panier',
    noData: 'Aucune donnée disponible',
    confirmDelete: 'Êtes-vous sûr de vouloir supprimer cet article de votre panier ?',
    productName: 'Nom du produit',
    amtPayable: 'Montant à payer',
    discountAmt: 'Montant de la réduction',
    amtPaid: 'Montant effectivement payé',
    offerDetails: "Détails de l'offre",
    changeOffer: 'Modifier la réduction',
    additional: 'Ajout',
    cart: 'Panier',
    admissionNotice: "Votre enfant doit passer un test d'admission avant de pouvoir s'inscrire à ce cours, avec seulement {x} tentatives possibles",
    cannotCreateOrder: 'Impossible de créer une commande pour le produit actuel',
    opFrequent: 'Opération trop fréquente',
    qtyModifiedSuccess: 'Modification de la quantité réussie',
    deleteSuccess: 'Suppression réussie',
    valExceed: 'La valeur ne peut pas dépasser {x}',
    valLess: 'La valeur ne peut pas être inférieure à {x}',
    addedSuccess: 'Ajout réussi',
    promoNotice: "Les cours actuels ne proposent des promotions qu'à certains utilisateurs, veuillez vérifier le prix final au moment de la validation après avoir changé d'élèves",
    addedToCartSuccess: 'Ajout au panier réussi',
    combinedDiscount: 'Remise combinée',
    mailingNotice: 'Le cours (produit) que vous avez acheté comprend des articles à envoyer par courrier, veuillez confirmer votre adresse :',
    refundNotice: "Le cours pour lequel vous demandez un remboursement fait partie d'un package groupé.Le remboursement désactivera la fonction de paiement automatique pour les autres cours du package.Veuillez confirmer si vous souhaitez continuer ?",
  },
}
