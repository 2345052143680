/**
 * 时区切换窗口
 */
export default {
  // 标题
  title: 'Choisir le fuseau horaire',
  // 当前时区描述
  describe: 'Votre fuseau horaire actuel est ',
  // 无搜索结果提示
  noResultNotice: 'Aucun résultat',
  // 切换定位刷新页面提示
  refreshNotice: 'Changement de fuseau horaire pour vous',
  // 搜索输入框提示文本
  searchInputPlaceholder: 'Rechercher un fuseau horaire',
}
