// 关于学而思文案配置
export default {
  banner: {
    title: '分校地址',
    desc: '對學而思課程有興趣？歡迎到訪學而思太子、銅鑼灣教學中心或致電我們了解詳情。',
  },

  branchSchool: [
    {
      title: '學而思太子協成行教學點',
      imgLength: 4,
      location: 'taizi',
      address: '分校地址: 九龍彌敦道794-802號 協成行太子中心 G/F-2/F（港鐵太子站A出口，步行兩分鐘）如有任何諮詢，請至G/F地下大堂詢問。課室位於一樓及二樓，可由惠康旁玻璃門入，上樓梯前往課室上堂。',
      businessHours: '營業時間: 星期二至日  10:00-19:00',
      phone: '查詢電話: +852 3556 3900',
      btnText: '查看地址',
      link: 'https://www.google.com/maps/place/%E5%AD%B8%E8%80%8C%E6%80%9D%E9%A6%99%E6%B8%AF/@22.32628,114.168157,15z/data=!4m5!3m4!1s0x0:0xf6cda8601d1a7ed4!8m2!3d22.3262804!4d114.1681571?hl=zh-TW',
    }, {
      title: '學而思銅鑼灣廣場教學點',
      imgLength: 3,
      location: 'tongluowan',
      address: '分校地址: 香港銅鑼灣軒尼詩道489號銅鑼灣廣場一期5/F',
      businessHours: '營業時間: 星期三至日  10:00-19:00',
      phone: '查詢電話: +852 3611 3089',
      btnText: '查看地址',
      link: 'https://www.google.com/maps?ll=22.280265,114.18281&z=15&t=m&hl=zh-TW&gl=US&mapclient=embed&cid=10159891916356803806',
    },
  ],

  complaints: {
    title: '意见及投诉',
    desc: '如果你對我們有任何意見，可以通過以下方式聯絡我們：',
    phone: '投訴電話：+852 3556 3916',
    email: '投訴電郵：complaints@tal.com',
  },
};
