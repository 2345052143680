/**
 * 页面公共配置
 */

export default {
  // 通用导航
  navigation: {
    /**
     * 返回导航链接配置
     * @param {Vue上下文} context
     */
    getNavigationLinks(context) {
      return context.$t('common.navigationLinks');
    },
    /**
     * 返回用户下拉菜单链接配置
     * @param {Vue上下文} context
     */
    getUserNavigationLinks(context) {
      return context.$t('common.userNavigationLinks')
    },
  },

  // 通用尾部
  // Touch端合并进来的
  footer: {
    /**
     * 返回关于我们链接配置
     * @param {Vue上下文} context
     */
    getAboutLinks(context) {
      const links = [{
        name: 'About Think Academy',
        url: '/',
        target: '_self',
        show: true,
      },
      {
        name: 'About TAL',
        url: 'http://en.100tal.com',
        target: '_blank',
        show: context.$store.state.locale !== 'sg',
      }, {
        name: 'Job Opportunities',
        url: context.$t('common.jobOpportunities'),
        target: '_self',
        show: true,
      }]
      return links;
    },

    /**
     * 返回版权相关链接配置
     * @param {Vue上下文} context
     */
    getCopyrightLinks(context) {
      const links = [{
        name: 'Terms and Conditions',
        url: '/terms-and-conditions',
        show: context.$store.state.locale === 'uk',
      }, {
        name: 'Safeguarding Policy',
        url: '/safeguarding-policy',
        show: context.$store.state.locale === 'uk',
      }, {
        name: context.$t('termsOfUse.title'),
        url: '/terms-of-use',
        show: true,
        target: context.$store.state.locale === 'us' ? '_blank' : '',
      }, {
        name: context.$t('privacyPolicy.title'),
        url: '/privacy-policy',
        show: true,
        target: context.$store.state.locale === 'us' ? '_blank' : '',
      }, {
        name: 'Investor Relations',
        url: 'https://ir.100tal.com',
        target: '_blank',
        show: context.$store.state.locale !== 'sg',
      }]
      return links;
    },
  },

  // Cookies声明
  cookiesStatement: {
    description: 'We use cookies to provide you with better services. By clicking any link on this page, your consent has been given.',
  },

  // cookies policy
  cookiesAccept: {
    title: 'Our Use of Cookies',
    description: `<p>We use Cookies to personalise your user experience on our website.</p>
    <p>For more information please see our <a href="/cookies-policy" target="_blank">Cookie Policy</a></p>`,
  },
}
