/* eslint-disable max-len */
/**
 * careers页配置
 */
export default {
  // 视频播放配置
  videoPlayConfig: {
    title: `More than a Job...
    it's an adventure in innovation`,
    subTitle: 'Making Education better with Love and Technology',
    videoSrc: 'https://download-pa-s3.thethinkacademy.com/static-pa/static/node_global_website_online/sg/common/career-sg-video.mp4',
    posterUrl: 'https://download-pa-s3.thethinkacademy.com/static-pa/static/node_global_website_online/sg/common/career-sg-video-cover.jpg',
  },

  // 公司介绍
  companyCultureIntro: {
    content: `<p>At Think Academy, we are redefining online education. With the rapid development of big data, artificial intelligence, the Internet, and other information technologies, we are shaping the future of online education and promoting education progress. To get there, you’ll have the opportunity to work with a collective of optimists and actionists to tackle this challenging objective.</p>
    <p style="margin-top: 40px;">We believe in the philosophy of listening to the voice of our customers and partners. Commit to delivering extraordinary products and customer services. Join us and let's build the world's most advanced online education engine.</p>`,
    introList: [
      {
        img: '/images/careers/company-culture-icon-1',
        title: 'Make Your Own Rules',
        description: 'Dare to step outside the box and build up creative problem-solving solutions.',
      },
      {
        img: '/images/careers/company-culture-icon-2',
        title: 'Constantly Surpassing Yourself',
        description: 'We enjoy working on challenge tasks together as the hardest challenges are often the most rewarding.',
      },
      {
        img: '/images/careers/company-culture-icon-3',
        title: 'Influence and Impact More',
        description: 'At Think Academy, we\'re trying to bring fair and high quality education to every student - not just grow our business.',
      },
    ],
  },

  // 工作环境
  workingEnviroment: {
    title: `Working With
    Think Academy`,
    listData: [
      {
        title: 'Open and Empowerment Enviroment',
        content: 'We’re dedicated to ensuring all employees have the information they need to do their jobs well. Our weekly All Hands meetings, regular 1:1s, and community chat are just a few of the programs we run to bring and share key aspects of the business across the entire organization.',
      },
      {
        title: 'Define Your Career Path',
        content: 'It\'s our goal to create a workplace that everyone can be their authentic self. We respect everyone\'s career expectations and put more effort into developing diverse career paths to help each employee succeed in their career goals.',
      },
      {
        title: 'Rewarding Workplace',
        content: 'We\'re serious about sharing in Think Academy\'s successes. Everyone\'s hardworking will be paid off. Our competitive benefits package includes company bonuses, performance bonuses, allowances, premium medical coverage for employees. Two Promotion and Salary adjustment window per year, service rewards, and other reward programs.',
      },
    ],
  },

  // 价值观
  ourValuesConfig: {
    title: 'Our Values',
    listData: [
      {
        title: 'Customer Commitment',
      },
      {
        title: 'Quality beyond the Quantity',
      },
      {
        title: 'Be Open and Integrity',
      },
      {
        title: 'Be Passionate and Determined',
      },
      {
        title: 'Embrace and Drive Change',
      },
    ],
  },

  // 部门介绍
  departmentsIntroConfig: {
    title: 'Departments',
    listData: [
      {
        title: 'Busniess Development',
        content: 'We are the brains of the team and the engine of business development! We turn good ideas into reality, drive the branch to reach performance targets and make ourselves the backbone of the team.',
      },
      {
        title: 'Curriculum Development',
        content: 'Our team is the core of the SG branch. Without high quality curriculum content, it is impossible to develop children\'s interest in math or help them improve their learning situation.',
      },
      {
        title: 'Teacher Development and quality Management',
        content: 'Think Academy takes teaching quality as the priority for all teachers. We transform novices into professional teachers with vivid expressions and clear logic flow.',
      },
      {
        title: 'Student Service',
        content: 'We keep the Engin running! The Student Services team\'s mission is to coordinate resources and system management to ensure every student has the assistance necessary to succeed in online education.',
      },
      {
        title: 'Corporate Functional',
        content: 'Composed of functional departments that are responsible for specific functions within the company.',
      },
    ],
  },

  // 员工故事
  memberStoryConfig: {
    title: 'Explore our member\'s story',
    listData: [
      {
        name: 'Ng WeiJie',
        role: `Future Leader Program Trainee
        (Curriculum Development)`,
        story: 'Working in the curriculum team alongside fellow teachers who are passionate about education has been a fruitful experience! Being able to discuss our variety of solving methodologies and even combining some of them to achieve better results, the strive to enhance and enrich our students\' knowledge and learning journey is never-ending as we seek to improve ourselves on a daily basis as well.',
      },
      {
        name: 'Trisha Wong',
        role: 'Guiding Teacher',
        story: 'Grateful to be a part of Think Academy who has empowered and nurtured me as an individual, entrusting me with responsibilities since Day 1 whilst providing immense mentoring and support. As the “front line” of ThinkAcademy, our team consists of a bunch of energetic Master Teachers and Guiding Teachers who meet and interact with the many faces of our users!',
      },
      {
        name: 'Jason Ang',
        role: `Future Leader Program Trainee
        (Curriculum Development)`,
        story: 'As I have always enjoyed completing Math questions, the Curriculum team certainly fitted my passion.  Curriculum teachers tirelessly look through many years of questions and select the content that would most accurately suit the current school syllabus. However, the fun does not just stop there! We also spend our rest days enjoying our hobbies! With a caring leader and a bonded team, it even makes working hard enjoyable!',
      },
      {
        name: 'Anthea Lim',
        role: `Lower Primary Team Leader 
        (Business Development Team)`,
        story: 'Being part of the business development team has been a challenging yet rewarding experience. The Business Development team have allowed me to lead various projects and work alongside members from different departments. It is extremely heartwarming to work with a team of enthusiastic educators where we work towards our common goal of enhancing our students knowledge and providing them with the best from all the programmes that we have designed.',
      },
    ],
  },
}
