/**
 * 各分校地址
 */
export default {
  url: [{
    locale: 'bmus',
    url: 'https://www.bettermeedu.com',
    target: '_blank',
    name: {
      bmus: 'Better Me US',
      bmsg: 'Better Me US',
    },
  }, {
    locale: 'bmsg',
    url: 'https://sg.bettermeedu.com',
    target: '_blank',
    name: {
      bmus: 'Better Me Singapore',
      bmsg: 'Better Me Singapore',
    },
  }],
}
