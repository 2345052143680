/**
 * 公共配置
 */
/* eslint-disable global-require */
export default {
  logo: {
    // 彩色常规LOGO
    normal: require('assets/images/common/logo.png'),
    // 白色常规LOGO
    normalWhite: require('assets/images/common/logo-white.png'),
    // 彩色LOGO图标
    simple: require('assets/images/common/icon-logo.svg'),
    // 白色LOGO图标
    simpleWhite: require('assets/images/common/icon-logo-white.svg'),
    // 加载中图标
    loading: require('assets/images/common/logo-loading.png'),
  },
  // 日期格式化方式
  dateFormatLine: 'DD-MM-YYYY',
  // 重试
  retry: 'Réessayer',
  // 尾部是否显示邮箱
  showContactEmail: true,
  // 联系电话显示字符
  contactPhoneString: '',
  parent: 'Parent',
  stage3Null: 'Appointments are temporarily unavailable. We recommend that you start an online evaluation directly.',
}
