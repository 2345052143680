export const PLATFORM_TYPE = {
  ONLINE: '1', // Dual Online
  DUAL: '2', // Dual Offline
  OFFLINE: '3', // In-Person
  BIG_ONLINE: '4', // Online
  THINK_LIVE: '5', // Trial Class
  ZOOM: '6',
  CLASS_IN: '7',
};

export function isOnline(platformType) {
  return platformType === '1' || platformType === 'ONLINE'
}

export function isDual(platformType) {
  return platformType === '2' || platformType === 'DUAL'
}

export function isOffline(platformType) {
  return platformType === '3' || platformType === 'OFFLINE'
}

export function isBigOnline(platformType) {
  return platformType === '4' || platformType === 'BIG_ONLINE'
}

export function isThinkLive(platformType) {
  return platformType === '5' || platformType === 'THINK_LIVE'
}

export function isZoom(platformType) {
  return platformType === '6' || platformType === 'ZOOM'
}

export function isClassIn(platformType) {
  return platformType === '7' || platformType === 'CLASS_IN'
}
