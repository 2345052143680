<template>
  <div class="complete-student-info-dialog-template">
    <CommonDialog :show.sync="show" :dialog-title="$t('login.switchStudent.completeStudentProfile')">
      <template v-slot:body>
        <div class="complete-stu-info-dialog-body">
          <StudentInfoForm :student-data="studentData" :tip-text-top-of-btn="tipTextTopOfBtn" :submit-btn-text="submitBtnText" @save="saveHandler" />
        </div>
      </template>
    </CommonDialog>
  </div>
</template>

<script>
import bus from 'common/bus';
import { oneApi } from 'config/api';
import CommonDialog from 'components/common/CommonDialog/index.vue';
import StudentInfoForm from 'components/myAccount/StudentInfoForm/index.vue';

export default {
  components: { CommonDialog, StudentInfoForm },
  props: {
    triggerArgs: {
      default: () => ({}),
      type: Object,
    },
  },
  data() {
    const studentId = this.$route.query.studentId || '';
    return {
      studentId,
      isLoading: false,
      show: false,
      tipTextTopOfBtn: this.$t('login.switchStudent.continueSignUpTip'),
      submitBtnText: this.$t('login.switchStudent.continueSignUp'),
      studentInfo: {
        nickName: '',
        firstName: '',
        lastName: '',
        gradeId: '',
        email: '',
      },
    }
  },
  computed: {
    studentData() {
      const stuInfo = this.studentInfo;
      return stuInfo;
    },
  },
  methods: {
    saveHandler(newStudentData) {
      this.completeStuInfoClick()
      this.saveStudentInfo(newStudentData)
    },
    async saveStudentInfo(formData) {
      this.isSaving = true;
      const data = {
        nickName: formData.nickName.trim(),
        firstName: formData.firstName.trim(),
        lastName: formData.lastName.trim(),
        gradeId: formData.gradeId,
      }
      const res = await this.$axios.post(oneApi.modifyUserInfo, data, { rewritePostBody: true, uid: this.studentId || (this.studentInfo && this.studentInfo.uid) || '' });

      if (Number(res.code) !== 0) {
        this.$ElMessage.error(res.msg);
        this.isSaving = false;
        return;
      }

      this.$ElMessage.success({ message: this.$t('featureComponents.page.tips.success'), customClass: 'el-message-toast' });
      this.$store.commit('ucenter/SET_USER', formData)

      // 更新用户信息
      this.$store.dispatch('ucenter/queryUserBasicInfo', { $axios: this.$axios, userData: { fillInAttention: false } });

      this.isSaving = false;
      this.saveSuccessHandler();
    },
    async saveSuccessHandler() {
      console.log('PC邮箱7')
      // 关闭弹窗
      this.show = false;

      // 成功后跳转路径
      if (this.triggerArgs) {
        if (this.triggerArgs.triggerSrc === 'REGISTRATION_COURSE') {
          // 触发后续购课流程
          bus.$emit('courses.detail.registrationCourses', false)
        }
        if (this.triggerArgs.triggerSrc === 'UNPAID_COURSE') {
          const skuId = this.triggerArgs.skuId || '';
          const res = await this.$axios.post(oneApi.toBePaidCheck, { skuId }, { rewritePostBody: true, uid: this.studentId || (this.studentInfo && this.studentInfo.uid) || '' })
          if (Number(res.code) !== 0) {
            this.$ElMessage.error(res.msg);
            return;
          }
          const resData = res.data.helper || {};
          if (resData.status === 1) {
            this.$store.dispatch('bindDialog/setDialogData', {
              visible: true,
              type: 'email',
              execute: true,
              guide: this.newEmailOrPhone,
            })
            return
          }
          if (resData.status === 2) {
            this.$store.dispatch('bindDialog/setDialogData', {
              visible: true,
              type: 'phone',
              execute: true,
              guide: this.newEmailOrPhone,
            })
            return
          }
          // 需要完善个人信息
          if (resData.status === 3) {
            bus.$emit('complete-stu-info', { triggerSrc: 'UNPAID_COURSE' });
            return
          }
          // 续费课程，跳转至创建订单页面
          if (skuId) this.$router.push(`/order/create/${skuId}?fromPage=unpaid-courses-list${this.triggerArgs.studentId ? `&studentId=${this.triggerArgs.studentId}` : ''}`);
        }
      }
    },
    async newEmailOrPhone(obj) {
      let data = {}
      let res;
      // let data2 = null

      // if (obj.phone) {
      //   data2 = {
      //     type: 0,
      //     contactInfo: obj.phone,
      //     countryCallingCode: obj.phoneAreaCode.value,
      //   }
      // } else {
      //   data2 = {
      //     type: 2,
      //     contactInfo: obj,
      //   }
      // }
      console.log('校验手机号27');
      // const res2 = await this.$axios.post(oneApi.contactVerify, data2, { rewritePostBody: true })

      // if (Number(res2.code) !== 0) {
      //   this.$ElMessage.error(res2.msg)
      //   return;
      // }

      if (obj.phone) {
        data = {
          attentionPhone: obj.phone,
          attentionCountryCode: obj.phoneAreaCode.value,
        }
        res = await this.$axios.post(oneApi.bindEmail2, data, { rewritePostBody: true, uid: this.studentId })
      } else {
        data = {
          attentionEmail: obj,
        }
        res = await this.$axios.post(oneApi.bindEmail2, data, { rewritePostBody: true, uid: this.studentId })
      }
      // 触发后续购课流程
      if (Number(res.code) !== 0) {
        this.$ElMessage.error(res.msg)
        return;
      }
      this.$store.dispatch('ucenter/queryUserBasicInfo', { $axios: this.$axios, userData: { fillInAttention: false } }).then(() => {
        this.saveSuccessHandler()
      });
      this.$store.dispatch('bindDialog/close')
    },
    async getStudentInfo() {
      this.isLoading = true;
      const res = await this.$axios.post(oneApi.queryUserBasicInfo, {}, { rewritePostBody: true });

      if (Number(res.code) !== 0) {
        this.$ElMessage.error(res.msg);
        this.isLoading = false;
        return;
      }
      this.isLoading = false;
      this.$store.commit('ucenter/SET_USER', res.data)
    },
    openDialog(params) {
      this.studentInfo = Object.assign(this.studentInfo, (params && params.studentInfo) || {});
      this.show = true;
      this.completeStuInfoShow()
      // this.getStudentInfo();
    },
    closeDialog() {
      this.show = false;
    },
    // 神策埋点-完善个人信息弹窗曝光
    completeStuInfoShow() {
      this.Sensors.track('student_profile_pv')
    },
    completeStuInfoClick() {
      this.Sensors.track('student_profile_click')
    },
  },
}
</script>

<style lang="scss" scoped>
.complete-stu-info-dialog-body {
  padding: 14px 16px 20px;
  width: 512px;
}
</style>
