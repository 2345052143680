// 客户服务页面
export default {
  next: '下一步',
  submit: '提交',
  back: '返回',
  feedbackTitle: '留言幫助',
  selectQuestionType: '請選擇一個您遇到的問題類型',
  contentDesc: '請詳細描述您遇到的問題',
  uploadDesc: '上傳圖片用於描述您的問題（可選）',
  contentDescValid: '請填寫您遇到的問題',
  SubmitFail: '提交失敗，請稍後再試',
  SubmitSuccess: ['提交成功，我們已收到您的問題反饋！', '在2個工作日內，您將在App的', '【消息 - 客服消息】', '中收到老師的回復，請註意查看App的通知。'],
  selectQuestionTypeValid: '请选择一个您遇到的问题类型',
  laterSubmit: '圖片上傳中請稍後提交',
  selectStudentDesc: '請選擇遇到學習問題的孩子',
  selectStudentTipDesc: '我們將安排合適的老師解答問題',
  feedbackTitleInClass: '提問',
  SubmitSuccessInClass: ['提交成功，我們已收到您的問題反饋！', '在2個工作日內，您將在App的', '【消息 - 作業輔導】', '中收到老師的回復，請註意查看App的通知。'],
}
