<template>
  <div
    ref="scrollList"
    class="chat-message-list"
  >
    <template v-for="(message, idx) in messages">
      <Message
        :key="idx"
        :message="message"
        @retry="handleRetry"
      />
    </template>
  </div>
</template>

<script>
import mixin from './mixin.js';
import Message from './Message/web.vue'

export default {
  components: {
    Message,
  },
  mixins: [mixin],
}
</script>

<style scoped>
.chat-message-list {
  height: calc(100% - 120px);
  overflow-y: auto;
  padding: 30px 20px;
  border-radius: 0 0 20px 20px;
}
</style>
