/**
 * 公共配置
 */
/* eslint-disable global-require */
export default {
  // 日期格式化方式
  dateFormatLine: 'YYYY-MM-DD',
  // 重试
  retry: '重試',
  // 尾部是否显示邮箱
  showContactEmail: true,
  // 联系电话显示字符
  contactPhoneString: '',
  parent: '家長',
  stage3Null: '当前暂时无法进行预约，建议您直接进行在线诊断。',
}
