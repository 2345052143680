/**
 * 课程筛选
 */
export default {
  more: '更多',
  retract: '收起',
  reset: '重置',
  define: '確定',
  all: '全部',
  resultsNum: '為您篩選{total}個結果',
}
