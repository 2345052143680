// 课程详情页面配置
export default {
  exceptiontip: 'This course is no longer open for registration. View more courses provided by Think Academy.',
  retryBtnText: 'View more courses',
  categoryName: 'Courses',
  teacher: 'Teacher',
  suitable: 'Suitable for',
  syllabus: 'Syllabus and Schedule',
  startedTag: 'Started',
  courseScheduleBtn: ['Back', 'View all {0} lessons'],
  courseDetails: 'Course Details',
  classTransfer: 'Class Transfer',
  // 右侧报名卡片
  enrollTip: 'We\'ve reserved a spot for you!',
  discountPrice: '',
  free: 'Free',
  taxiInclude: 'GST included',
  taxiExcluded: 'GST excluded',
  couponTipTitle: 'You have {discount} voucher(s), sign up now to apply your discount!',
  childList: 'You have coupons on ',
  childList2: ' sign up now to apply your discount!',
  btnStatus: {
    unavailable: 'Unavailable',
    courseEnded: 'Course Ended',
    forSale: 'For Sale',
    full: 'Full',
    enrolNow: 'Enroll',
    enrol: 'Enroll',
    autoPayInProcess: 'Auto pay in process',
  },
  autoPayInProcessTip: 'Your account will be automatically debited within 24 hours. We will inform you via text message once the payment is competed.',
  // 弹窗
  highlights: 'Course Highlights',
  successDialog: {
    title: 'Added to Wish List',
    desc: 'View Wish List details in your Student Portal',
    btnText: 'I got it',
  },
  assessmentNotice: {
    tips: 'Please have your child attempt the placement test. Your child will then be recommended to the suitable course.',
    btnText: 'Take the test',
  },
  signUpCheckInfoDialog: {
    case1: {
      title: 'Please take the Admission Test',
      content: 'Your child must pass the Admission Test before signing up to this course. {0} attempt{1} remaining',
      mainBtnText: 'Start test',
    },
    case2: {
      unableSignUp: 'Unable to sign up',
      ok: 'OK',
      msg: '',
    },
    case3: {
      title: 'Reminder',
      mainBtnText: 'Confirm',
    },
    case1014: {
      title: 'Auto pay in process',
      content: 'Your account will be automatically debited within 24 hours. We will inform you via text message once the payment is competed.',
      mainBtnText: 'I got it',
    },
    case604: {
      title: 'Unable to sign up',
      content: 'Sorry, this course is only available for new users, Come and browse more courses!',
      mainBtnText: 'View courses',
      subBtnText: 'Cancel',
    },
  },
  redeemCodeEntrance: ['If you have a Think Academy registration qualification code, please', 'redeem here'],
  redeemQualificationCode: {
    title: 'Redeem Qualification Code',
    placeholder: 'Please enter your code',
    btnText: 'Apply',
    continue: 'Continue to registration',
    check: 'Check available classes',
    errorMsg: 'Please enter your code.',
    levelDegreeDesc: [
      'You have redeemed the qualification code, this code is for {0} {1} classes registration',
      'This admission test result is for {0} {1} classes registration',
    ],
    tips: ['Instructions', 'If you have a registration qualification code, please redeem the code here to continue registration', 'If you have any questions, please contact Think Academy customer service at'],
    fail: 'Code is invalid. If you have any questions, please contact your teacher or Think Academy customer service at {0}',
  },
  evaluationStatusTxt: {
    case1: 'Take the Admission Test to find out which course is best suited for your child',
    case2: 'Your child must take the Admission Test before signing up for this course',
    case3: 'Your child passed the Admission Test. You can sign up now',
    case4: 'Your child\'s Admission Test results suggest that this course is too difficult',
  },
  classFollow: {
    title: 'We will notify you!',
    content: 'We\'ve added this class to your wish list and will email you when there is a new place available!',
    mainBtn: 'View wish list',
    subBtn: 'I got it',
  },
  classFollowCancel: {
    title: 'Cancel availability alerts',
    content: 'One it’s cancelled, you will not be notified when there is a new place available.',
    mainBtn: 'Confirm',
    subBtn: 'Cancel',
    successTip: 'Cancellation succeeded',
  },
};
