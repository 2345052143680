export default {
  props: {
    message: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isChater() {
      return this.message.author === 2
    },
    isImg() {
      return this.message.author === 2
    },
  },
  methods: {
    handleRetry(contentId) {
      this.$emit('retry', contentId)
    },
  },
}
