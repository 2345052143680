/**
 * 课程筛选
 */
export default {
  more: 'More',
  retract: 'Retract',
  reset: 'Reset',
  define: 'Define',
  all: 'ALL',
  resultsNum: 'Filter {total} results for you',
}
