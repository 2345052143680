export default {
  allOrders: '모든 주문',
  detail: {
    preClass: '수업 전 준비',
    preClassInfo: '어린이들이 수업에 참석하는 방법과 수업 전 준비 사항을 이해하세요.',
    prepare: {
      preClass: '수업 전 준비',
      parents: '부모님을 위한',
      students: '학생을 위한',
      tobetterInfo: '자녀의 학습 상황을 더 잘 이해하고, 후속 과정 일정, 강사의 학습 공지 및 수업 갱신을 따르려면 부모 앱을 다운로드하세요.',
      benefitsInfo: '부모 앱 다운로드의 장점',
      calendar: '캘린더',
      calendarInfo: '자녀의 모든 수업 일정을 확인할 수 있어 효율적인 시간 계획을 도와줍니다.',
      preClassInfo: '수업 전에 사전에 수업 자료를 검토하여 수업 내용과 강사의 교수 계획을 미리 볼 수 있습니다.',
      learningFeedback: '학습 피드백',
      learningInfo: '수업 후에는 수업 보고서와 강사 평가에 접근하여 자녀의 수업 성적을 이해할 수 있습니다. 또한 자녀의 숙제 완료 상태와 강사의 수정 사항을 신속하게 확인할 수 있습니다.',
      learningService: '학습 서비스 알림',
      learningServiceInfo: '자녀의 학습 업데이트 및 중요한 서비스 알림을 즉시 받을 수 있습니다.',
      quickOnline: '빠른 온라인 수업 선택',
      quickOnlineInfo: '전문 상담사가 수업를 추천하여 자녀의 교육에 완벽한 맞춤을 제공합니다.',
    },
    attend: {
      howtoAttend: '수업 참석 방법',
      howtoAttendInfo: '이 수업는 학습 센터에서 직접 수업을 진행합니다. 더 나은 조직을 위해 수업 시작 15분 전에 학생들이 교실에 도착하도록 요청합니다.',
      location: '학습 센터 위치',
      classroom: '교실',
      address: '주소',
      contactNumber: '연락처',
    },
    attendOnline: {
      howtoAttend: '수업 참석 방법',
      attendInfo: '자녀에게 더 나은 수업 경험을 제공하기 위해 아래 단계를 따라주세요.',
      stepOne: '1단계',
      stepOneInfo: '“Think Academy Student” 다운로드',
      stepOneDetail: '자녀의 수업 경험을 보장하기 위해 PC, iPad 또는 Android 태블릿을 사용하여 학생 앱인 Think Academy Student를 다운로드하세요.',
      stepTwo: '2단계',
      stepTwoInfo: '“Think Academy Student” 열고 교실에 입장',
      stepTwoDetailOnline: 'Think Academy 계정을 사용하여 학생 앱에 로그인하고 올바른 자녀를 선택했는지 확인하세요. 수업 시작 15분 전에 학생들은 교실에 입장할 수 있습니다.',
      stepTwoDetailRecord: 'Think Academy 계정을 사용하여 학생 앱에 로그인하고 올바른 자녀를 선택했는지 확인하세요. "수업"에서 "녹화된 수업"로 전환한 다음 "입장"을 클릭하여 학습을 시작하세요.',
    },
    viewVideo: '동영상 보기',
  },
  studentAppName: 'Think Academy Student',
  parentAppName: 'Think Academy Parent',
  all: 'All',
}
