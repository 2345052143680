/**
 * 美分网页标题处理
 * 因备案问题，国内访问美分站点时网页标题拼接 - Beijing Century TAL Education Technology Co., Ltd
 */

export default async function ({ store, app }) {
  // 非美分不处理
  if (store.state.locale !== 'us') return

  // 客户端环境处理逻辑
  if (process.client) {
    /* eslint-disable */
    app.head.titleTemplate = store.state.titleTemplate
  }

  // 服务端环境处理逻辑
  if (process.server) {
    const { userNetworkInfo } = store.state
    const { isMainland } = userNetworkInfo
    // 中国大陆地区
    if (isMainland === true) {
      const titleTemplate = '%s - Beijing Century TAL Education Technology Co., Ltd'
      store.dispatch('setTitleTemplate', titleTemplate)
      /* eslint-disable */
      app.head.titleTemplate = titleTemplate
    }
  }
}
