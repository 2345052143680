import moment from 'moment-timezone'
import Cookies from 'js-cookie'
import config from 'config/index'

const {
  // 手工设置时区cookie名称
  timezoneCookie,
  // 自动定位时区提示气泡关闭标记名
  autoTimezoneNoticeFlagName,
  // 当前时区提示气泡关闭标记名
  currentTimezoneNoticeFlagName,
} = config

/**
 * 获取设备时区
 * 手工未设置时区，并且设备时区与分校时区不一致，则提示自动定位时区是否切换气泡，关闭后下次不再提示
 * 手工已设置时区，并且设备时区与分校时区不一致，则提示当前时区提示气泡，关闭后下次不再提示
 * 手工未设置时区气泡优先级高于手工已设置时区气泡
 * 手工设置时区cookie: _official_timezone
 * 自动定位时区是否切换气泡隐藏localStorage: _official_auto_timezone_notice
 * 当前时区提示气泡隐藏localStorage: _official_current_timezone_notice
 */
export default {
  props: {},
  data() {
    return {
      // 是否启用时区切换
      enableTimezone: this.$store.state.enableTimezone,
      // 翻译文案上下文
      langContext: this.$t('featureComponents.TimezoneMark'),
      // 设备时区
      deviceTimezone: moment.tz.guess(),
      // 本地时区缓存
      timezoneCache: '',
      // 自动定位时区提示气泡关闭标记
      autoTimezoneNoticeFlag: '0',
      // 当前时区提示气泡关闭标记
      currentTimezoneNoticeFlag: '0',
      // 是否显示自动定位时区提示气泡关闭标记
      showAutoTimezoneNotice: false,
      // 是否显示当前时区提示气泡关闭标记
      showCurrentTimezoneNotice: false,
    }
  },
  computed: {
    // 当前分校时区
    timezone() {
      return this.$store.state.timezone
    },
  },
  mounted() {
    this.getTimezoneCache()
    this.getLocalStorageCache()
    this.init()
  },
  methods: {
    /**
     * 初始化
     */
    init() {
      // 如果设备时区与当前时区一致，不进行任何提示
      if (this.deviceTimezone === this.timezone) {
        return
      }
      // 本地未设置时区提示
      if (!this.timezoneCache && this.autoTimezoneNoticeFlag === '0') {
        this.showAutoTimezoneNotice = true
      }
      // 本地已设置时区提示
      if (this.timezoneCache && this.currentTimezoneNoticeFlag === '0') {
        this.showCurrentTimezoneNotice = true
      }
    },

    /**
     * 获取时区缓存
     */
    getTimezoneCache() {
      this.timezoneCache = Cookies.get(timezoneCookie) || ''
    },

    /**
     * 设置时区缓存
     * @param {String} timezone 时区
     */
    setTimezoneCache(timezone) {
      if (!timezone) return
      Cookies.set(timezoneCookie, timezone, { expires: 365 })
    },

    /**
     * 获取本地离线缓存标记
     */
    getLocalStorageCache() {
      this.autoTimezoneNoticeFlag = window.localStorage.getItem(autoTimezoneNoticeFlagName) || '0'
      this.currentTimezoneNoticeFlag = window.localStorage.getItem(currentTimezoneNoticeFlagName) || '0'
    },

    /**
     * 切换设备时区
     */
    switchDeviceTimezone() {
      this.setTimezoneCache(this.deviceTimezone)
      window.localStorage.setItem(autoTimezoneNoticeFlagName, '1')
    },

    /**
     * 隐藏自动定位时区提示气泡关闭标记
     */
    hideAutoTimezoneNotice() {
      window.localStorage.setItem(autoTimezoneNoticeFlagName, '1')
      this.showAutoTimezoneNotice = false
    },

    /**
     * 隐藏当前时区提示气泡关闭标记
     */
    hideCurrentTimezoneNotice() {
      window.localStorage.setItem(currentTimezoneNoticeFlagName, '1')
      this.showCurrentTimezoneNotice = false
    },

    /**
     * 打开时区切换弹层
     */
    openTimezoneChangePopup() {
      this.$refs.TimezoneChangePopup.open()
    },

    /**
     * 处理时区提示层关闭
     */
    handleTimezoneChangePopupHide() {
      this.showAutoTimezoneNotice = false
      this.showCurrentTimezoneNotice = false
    },

    /**
     * 获取埋点数据
     * @param {String} eventType 事件类型 iconClick / buttonClick
     * @param {Number} buttonType 按钮类型 1、点击“暂不切换” 2、点击“切换到设备时区” 3、点击“知道了”
     */
    getTrackData(eventType, buttonType) {
      const data = {
        website_time_zone: this.timezone,
        device_time_zone: this.deviceTimezone,
      }
      if (eventType === 'buttonClick') {
        data.click_button = buttonType
      }
      return data
    },
  },
}
