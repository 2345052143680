export default {
  register: {
    formPlaceholder: 'チェックアウトの前に登録情報を入力してください',
    formTitle: '登録情報',
    submitName: '提出',
    requiredError: '入力が必要である。',
    selectPlaceholder: '選んでください',
    inputPlaceholder: '入力してください',
  },
  priceInfo: {
    deduction: '48時間後の自動引き落とし',
  },
  orderCreate: {
    subscription: '購入',
  },
}
