/**
 * 导航时区标识
 */
export default {
  // 自动定位时区按钮名称
  autoTimezoneButton: {
    cancelName: '지금은 아닙니다',
    confirmName: '귀하의 시간대로 전환',
  },
  // 网站时区提示
  websiteTimezoneNotice: '웹사이트 표시 시간대:',
  // 当前时区提示
  currentTimezoneNotice: '귀하의 시간대:',
  // 当前时区按钮名称
  currentTimezoneButton: {
    confirmName: '알겠습니다',
  },
  // 切换定位刷新页面提示
  refreshNotice: '귀하의 시간대로 전환 중입니다',
}
