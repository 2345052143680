import { oneApi } from 'config/api';
import { elementOfArray } from 'common/utils';

export default {
  data() {
    const studentId = this.$route.query.studentId || '';
    return {
      isLoadingCoupons: false,
      isLoadingGenerate: false,
      products: [],
      // 有效优惠券列表
      couponList: [],
      // 可用优惠券数量
      canUseCount: 0,
      // 最佳优惠
      recommendCoupons: [],
      // 当前选中优惠券
      selectedCoupons: [],
      // 历史已用优惠券
      historyUseCoupons: [],
      // 券后价
      priceAfterCoupon: 0,
      remainStagePrice: 0,
      remainStagePriceAfterHistoryCoupons: 0,
      studentId,
      couponsStuList: [],
    }
  },
  computed: {
    countryList() {
      return this.$store.state.courses.countryList;
    },
    historyUseCouponDiscount() {
      return this.historyUseCoupons.reduce((acc, currentValue) => acc + currentValue.discount, 0)
    },
    recommendDiscountPrice() {
      return this.recommendCoupons.reduce((acc, currentValue) => acc + currentValue.discount, this.historyUseCouponDiscount)
    },
  },
  methods: {
    /**
     * 获取当前用户优惠券
     */
    async queryCoupons(courseDetail) {
      this.isLoadingCoupons = true;
      this.setGoodsData(courseDetail)
      const data = {
        products: this.products,
      };

      if (this.historyUseCoupons.length > 0) {
        data.historyUseCouponIds = this.historyUseCoupons.map((coupon) => coupon.id)
        data.remainStagePriceAfterDiscount = this.remainStagePriceAfterHistoryCoupons
      }

      console.log('优惠券1')
      const res = await this.$axios.post(oneApi.queryAvalibaleCoupons, data, { rewritePostBody: true, uid: this.studentId });

      if (Number(res.code) !== 0) {
        this.isLoadingCoupons = false;
        if (process.env.clientType === 'web') {
          this.$ElMessage({
            type: 'error',
            message: res.msg,
          });
        } else {
          this.$Toast(res.msg);
        }
        return;
      }

      const resData = res.data || {};

      this.canUseCount = resData.canUseCount;
      this.recommendCoupons = resData.useCouponInfo || [];
      this.selectedCoupons = resData.useCouponInfo || [];
      this.couponList = resData.effectCoupons || [];
      const getTaxParams = await this.getTaxParams(courseDetail)
      await this.getTax(getTaxParams, courseDetail.showPriceIsInclude === 0)

      this.isLoadingCoupons = false;
    },
    // 请求分期
    async getGenerate(courseDetail) {
      console.log('起点2')
      this.isLoadingGenerate = true;
      const data = this.getGenerateParams(courseDetail)
      const res = await this.$axios.post(oneApi.getGenerate, data, { rewritePostBody: true, uid: this.studentId });
      if (Number(res.code) !== 0 || !res.data) {
        this.isLoadingGenerate = false;
        return
      }

      const resData = res.data || {}
      // 非首次分期用户使用优惠券的情况
      if (resData.useCouponInfo) this.historyUseCoupons = [...resData.useCouponInfo];
      this.goodsDataAppendParams(resData.remainNoPayCount, courseDetail.spec.lessonCount)
      this.setRemainStagePrice(resData.stageList || [])

      this.isLoadingGenerate = false;
    },
    async getTax(data, showPriceIsInclude) {
      const res = await this.$axios.post(oneApi.queryCategoryTax, data, { rewritePostBody: true, uid: this.studentId });
      if (Number(res.code) !== 0 || !res.data) {
        return
      }
      const resData = res.data || {}
      let fee = 0
      let tax = 0
      const priceList = resData.list || []
      priceList.forEach((item) => {
        fee += item.fee
        tax += item.tax
      })
      if (showPriceIsInclude) {
        this.priceAfterCoupon = fee + tax
      } else {
        this.priceAfterCoupon = fee
      }
    },
    // 设置商品
    setGoodsData(courseDetail) {
      const subPlatformType = courseDetail.spec && 'subPlatformType' in courseDetail.spec
        ? courseDetail.spec.subPlatformType
        : null
      const courseSpec = courseDetail.spec || {}
      this.products = [
        {
          skuInfo: {
            clazzId: courseDetail.spec.clazzId,
            skuId: courseDetail.id,
            goodsCategoryId: courseDetail.clazzCategory,
            courseType: courseDetail.spec.courseType,
            year: courseDetail.spec.year,
            subjectId: courseDetail.spec.subjectId,
            platformType: courseDetail.spec.platformType,
            levelDegreeId: courseDetail.spec.levelDegreeId,
            gradeIds: courseDetail.spec.gradeList.map((gradeItem) => gradeItem.id) || [],
            term: courseDetail.spec.term,
            courseId: courseDetail.spec.courseId,
            subPlatformType,
            isCompetition: courseSpec.isCompetition || 0,
          },
          price: courseDetail.singleSellPrice,
          sellPrice: courseDetail.packagePrice,
          amount: 1,
        },
      ]
      if (courseDetail.attachedItems && courseDetail.attachedItems.length > 0) {
        courseDetail.attachedItems.forEach((item) => {
          if (item.required || item.selected) {
            this.products.push({
              skuInfo: {
                clazzId: courseDetail.spec.clazzId,
                skuId: item.skuId,
                goodsCategoryId: item.category,
                courseType: courseDetail.spec.courseType,
                year: courseDetail.spec.year,
                subjectId: courseDetail.spec.subjectId,
                platformType: courseDetail.spec.platformType,
                levelDegreeId: courseDetail.spec.levelDegreeId,
                gradeIds: courseDetail.spec.gradeList.map((gradeItem) => gradeItem.id) || [],
                term: courseDetail.spec.term,
                courseId: courseDetail.spec.courseId,
                subPlatformType,
                isCompetition: courseSpec.isCompetition || 0,
              },
              price: item.price,
              sellPrice: courseDetail.sellPrice,
              amount: 1,
            });
          }
        })
      }
    },
    // 分期续费商品信息增加剩余未购课次数参数
    goodsDataAppendParams(remainNoPayCount, totalLessonNum) {
      this.products.forEach((productItem) => {
        if (productItem.skuInfo.skuId === this.goodId) {
          // eslint-disable-next-line no-param-reassign
          productItem.skuInfo.remainLessonNumForContinueStage = remainNoPayCount || remainNoPayCount
          // eslint-disable-next-line no-param-reassign
          productItem.skuInfo.totalLessonNumForContinueStage = totalLessonNum
        }
      })
    },
    // 分期参数
    getGenerateParams(courseDetail) {
      const data = {}
      data.skuId = courseDetail.id
      return data
    },
    // 剩余期次待支付金额
    setRemainStagePrice(stageList) {
      this.remainStagePrice = 0;
      this.remainStagePriceAfterHistoryCoupons = 0;
      stageList.forEach((stageItem) => {
        if (stageItem.continuePay) {
          this.remainStagePrice += Number(stageItem.sellPrice)
          this.remainStagePriceAfterHistoryCoupons += Number(stageItem.sellPrice) - Number(stageItem.couponDiscount)
        }
      })
    },
    // 算税参数
    async getTaxParams(courseDetail) {
      const list = this.setPriceParams({ categoryId: courseDetail.clazzCategory, id: courseDetail.id, price: courseDetail.singleSellPrice }, courseDetail.attachedItems || [])
      if (this.countryList.length === 0) await this.$store.dispatch('courses/queryCountryList', { $axios: this.$axios })
      // 默认国家id = 根据所在分校国家
      let countryId = this.countryList[0].id;
      const defaultCountry = elementOfArray('twoLetterCode', this.$store.state.locale.toUpperCase(), this.countryList);
      if (defaultCountry) countryId = defaultCountry.id;

      const newCategorys = []
      list.forEach((item0) => {
        let itemPrice = 0
        let finalPrice = item0.sellPrice
        if (finalPrice > 0 && !item0.gift) {
          const discountList = this.setDiscountList()
          discountList.forEach((item) => {
            if (item0.skuId === item.id) {
              itemPrice -= item.discount
              finalPrice = item0.sellPrice + itemPrice > 0 ? item0.sellPrice + itemPrice : 0
            }
          })
        }
        newCategorys.push({ categoryId: item0.categoryId, skuId: item0.skuId, sellPrice: finalPrice })
      })
      const data = {
        platformType: courseDetail.spec.platformType,
        countryId,
        categorys: newCategorys,
      }
      return data
    },
    setPriceParams(sellPrice, attachedItems) {
      const priceParams = [];
      priceParams.push({
        categoryId: sellPrice.categoryId,
        skuId: sellPrice.id,
        sellPrice: sellPrice.price,
      });
      attachedItems.forEach((item) => {
        if (item.required || item.selected) {
          priceParams.push({
            categoryId: item.category,
            skuId: item.skuId,
            sellPrice: item.price,
          });
        }
      })

      return priceParams
    },
    setDiscountList() {
      let discountList = [];
      const totalDiscountList = [].concat(this.historyUseCoupons, this.recommendCoupons)
      totalDiscountList.forEach((item) => {
        const products = []
        item.products.forEach(((item0) => {
          products.push({
            id: item0.skuId,
            discount: item0.discount,
          });
        }))
        discountList.push({
          id: item.id,
          discount: item.discount,
        });
        discountList = discountList.concat(products)
      })

      return discountList
    },
    async getConStudentList(courseDetail) {
      this.setGoodsData(courseDetail)
      const data = {
        products: this.products,
      }
      const res = await this.$axios.post(oneApi.studentList, data, { rewritePostBody: true })
      if (Number(res.code) !== 0 || !res.data) {
        return
      }
      this.couponsStuList = res.data.studentList
    },
  },
}
