import { v4 as uuidv4, validate as uuidValidate, version as uuidVersion } from 'uuid';
import { getRootDomain } from 'common/utils.js'

function uuidValidateV4(uuid) {
  return uuidValidate(uuid) && uuidVersion(uuid) === 4;
}

export default ({ req, store }) => {
  // 客户端不执行
  if (process.client) return

  const officialUuid = req.ctx.cookies.get('_official_uuid') || ''
  let deviceId = ''

  if (officialUuid && uuidValidateV4(officialUuid)) {
    // 已存在设备Id，更新cookies有效时间
    deviceId = officialUuid
  } else {
    deviceId = uuidv4();
  }

  const { host } = req.headers

  let domain = host.replace(/\:\S+$/, '')

  domain = getRootDomain(domain)

  // 设置store中的数据
  store.commit('SET_DEVICE_ID', deviceId)

  // 更新cookies
  req.ctx.cookies.set('_official_uuid', deviceId, { domain, maxAge: 365 * 24 * 60 * 60 * 1000 });
}
