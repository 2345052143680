/**
 * 时区切换窗口
 */
export default {
  // 标题
  title: '시간대 선택',
  // 当前时区描述
  describe: '현재 시간대는 ',
  // 无搜索结果提示
  noResultNotice: '결과 없음',
  // 切换定位刷新页面提示
  refreshNotice: '시간대 변경 중입니다',
  // 搜索输入框提示文本
  searchInputPlaceholder: '시간대 검색',
}
