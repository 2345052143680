<template>
  <div :id="localeClass" class="platform__pc">
    <Header />
    <template v-if="$nuxt.isOffline">
      <div class="network-offline-container">
        <Exception tip-text="Loading failed, please check your connection." placeholder-class-name="system-error-img" :show-retry-btn="true" @retry="reload" />
      </div>
    </template>
    <template v-else>
      <!-- 使用个人中心layout的所有页面均认为需要登录 -->
      <template v-if="isLogin">
        <div class="personal-center-body-wrapper">
          <div class="wrapper flex-wrapper">
            <SideNavigationBar class="side-nav-bar-container" />
            <div class="page-template-container">
              <nuxt />
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <DefaultGuideLogin />
      </template>
    </template>
    <no-ssr>
      <OnlineService v-if="$store.state.locale === 'sg' || $store.state.locale === 'uk'" />
    </no-ssr>
    <CookiesAccept v-if="$store.state.locale === 'uk'" />

    <no-ssr>
      <!-- 登录及绑定邮箱弹窗 -->
      <LoginDialog :show.sync="showLoginDialog" :success-url="loginSuccessUrl" :trigger-src="loginTriggerSrc" :login-trigger-source="loginTriggerSource" :is-group="isGroup" />
      <BindEmailDialog :show.sync="showBindEmailDialog" :success-url="bindEmailSuccessUrl" :trigger-src="bindEmailTriggerSrc" />
      <CompleteStuInfoDialog ref="completeStuInfoDialog" :trigger-args="completeStuInfoTriggerArgs" />
      <UnpaidDialog ref="unpaidDialog" />
      <BindDialog />
      <AppointmentDiagnosis :show.sync="showDiagnosis" :diagnosisInfo="diagnosisInfo"></AppointmentDiagnosis>
    </no-ssr>
  </div>
</template>

<script>
import bus from 'common/bus';
import headMixin from 'mixins/layout/head';
import Header from '@/components/global/FeatureComponents/Header/web';
import SideNavigationBar from 'components/common/SideNavigationBar';
import OnlineService from 'components/common/OnlineService/index.vue'
import LoginDialog from 'components/login/LoginDialog/index.vue';
import BindEmailDialog from 'components/login/BindEmailDialog/index.vue';
import CompleteStuInfoDialog from 'components/login/CompleteStuInfoDialog/index.vue';
import DefaultGuideLogin from 'components/common/DefaultGuideLogin/index.vue';
import Exception from 'components/common/Exception/index.vue';
import UnpaidDialog from 'components/common/UnpaidDialog/index.vue';
import CookiesAccept from 'components/common/CookiesAccept/index.vue';
import BindDialog from 'components/common/bindDialog/index.vue';
import AppointmentDiagnosis from 'components/appointmentDiagnosis/index.vue';

export default {
  components: {
    Header, SideNavigationBar, OnlineService, LoginDialog, BindEmailDialog, CompleteStuInfoDialog, DefaultGuideLogin, Exception, UnpaidDialog, CookiesAccept, BindDialog, AppointmentDiagnosis
  },
  mixins: [headMixin],
  data() {
    return {
      // 登录弹窗
      showLoginDialog: false,
      // 绑定邮箱弹窗
      showBindEmailDialog: false,
      // 完善学员信息弹窗
      showCompleteStuInfoDialog: false,
      loginSuccessUrl: '',
      loginTriggerSource: '',
      bindEmailSuccessUrl: '',
      loginTriggerSrc: '',
      bindEmailTriggerSrc: '',
      completeStuInfoTriggerArgs: null,
      isGroup: false,
      showDiagnosis: false,
      diagnosisInfo: {},
    }
  },
  computed: {
    /**
     * 当前语言环境样式名
     * 可通过此样式名叠加样式实现不同语言样式
     */
    localeClass() {
      return `locale__${this.$store.state.locale}`;
    },
    /**
     * 登录态
     */
    isLogin() {
      return this.$store.state.ucenter.isLogin;
    },
  },
  watch: {
    isLogin(newVal) {
      if (!newVal) {
        this.login()
      }
    },
  },
  mounted() {
    // 页面刷新强制跳转到页面顶部
    document.documentElement.scrollTop = 0;

    if (!this.isLogin) this.login()
    // 登录弹窗
    bus.$on('login', (argsObj) => {
      if (argsObj && argsObj.triggerSrc) {
        this.loginTriggerSrc = argsObj.triggerSrc;
      }
      if (argsObj && argsObj.triggerSource) {
        this.loginTriggerSource = argsObj.triggerSource
      }
      if (argsObj && argsObj.successUrl) {
        this.loginSuccessUrl = argsObj.successUrl;
      }
      if (argsObj && argsObj.isGroup) {
        this.isGroup = argsObj.isGroup;
      }
      this.login();
    });

    // 预约诊断弹窗
    bus.$on('toShowDiagnosis', (argsObj) => {
      console.log('进3')
      this.toShowDiagnosis(argsObj);
    });

    this.$nextTick(() => {
      // 完善学员信息弹窗
      bus.$on('complete-stu-info', (argsObj) => {
        if (argsObj) this.completeStuInfoTriggerArgs = argsObj;
        this.completeStuInfo(argsObj);
      });
      // 续报提醒弹窗
      bus.$on('openUnpaidDialog', () => {
        this.openUnpaidDialog();
      });
    })
  },
  destroyed() {
    // 页面销毁的时候取消监听
    bus.$off('login')
    bus.$off('toShowDiagnosis')
    bus.$off('complete-stu-info')
    bus.$off('openUnpaidDialog')
  },
  methods: {
    login() {
      this.showLoginDialog = true;
    },
    toShowDiagnosis(argsObj) {
      this.diagnosisInfo = argsObj || {}
      this.showDiagnosis = true
    },
    bindEmail() {
      this.showBindEmailDialog = true;
    },
    completeStuInfo(argsObj) {
      if (this.$refs.completeStuInfoDialog) this.$refs.completeStuInfoDialog.openDialog(argsObj);
    },
    reload() {
      if (this.$nuxt.isOffline) return;
      window.location.reload();
    },
    openUnpaidDialog() {
      if (this.$refs.unpaidDialog) this.$refs.unpaidDialog.openDialog();
    },
  },
  head() {
    return {
      ...headMixin.head.call(this),
      htmlAttrs: {
        id: `style__${this.$store.state.betterme}`,
      },
      link: [{
        hid: 'icon',
        rel: 'icon',
        type: 'image/x-icon',
        href: this.$store.state.betterme === 'bettermeedu' ? 'https://download-pa-s3.thethinkacademy.com/static-pa/project/game/assets/favicon.ico' : '/favicon.ico',
      }],
    }
  },
}
</script>

<style lang="scss" scoped>
.personal-center-body-wrapper {
  height: 100%;
  padding: 40px 0 79px;
  background: #fff;

  .side-nav-bar-container {
    margin-right: 24px;
  }

  .page-template-container {
    position: relative;
    width: 878px;
    min-height: 100%;
    background: #fafafa;
    border-radius: 8px;
    overflow: hidden;
  }
}
</style>
