const middleware = {}

middleware['adaptive.touch'] = require('../../../src/client/middleware/adaptive.touch.js')
middleware['adaptive.touch'] = middleware['adaptive.touch'].default || middleware['adaptive.touch']

middleware['adaptive.web'] = require('../../../src/client/middleware/adaptive.web.js')
middleware['adaptive.web'] = middleware['adaptive.web'].default || middleware['adaptive.web']

middleware['alipayGrayUser'] = require('../../../src/client/middleware/alipayGrayUser.js')
middleware['alipayGrayUser'] = middleware['alipayGrayUser'].default || middleware['alipayGrayUser']

middleware['app'] = require('../../../src/client/middleware/app.js')
middleware['app'] = middleware['app'].default || middleware['app']

middleware['apph5'] = require('../../../src/client/middleware/apph5.js')
middleware['apph5'] = middleware['apph5'].default || middleware['apph5']

middleware['basicData'] = require('../../../src/client/middleware/basicData.js')
middleware['basicData'] = middleware['basicData'].default || middleware['basicData']

middleware['careers'] = require('../../../src/client/middleware/careers.js')
middleware['careers'] = middleware['careers'].default || middleware['careers']

middleware['checkUserNetwork'] = require('../../../src/client/middleware/checkUserNetwork.js')
middleware['checkUserNetwork'] = middleware['checkUserNetwork'].default || middleware['checkUserNetwork']

middleware['deviceId'] = require('../../../src/client/middleware/deviceId.js')
middleware['deviceId'] = middleware['deviceId'].default || middleware['deviceId']

middleware['facebook'] = require('../../../src/client/middleware/facebook.js')
middleware['facebook'] = middleware['facebook'].default || middleware['facebook']

middleware['gtm'] = require('../../../src/client/middleware/gtm.js')
middleware['gtm'] = middleware['gtm'].default || middleware['gtm']

middleware['interceptor'] = require('../../../src/client/middleware/interceptor.js')
middleware['interceptor'] = middleware['interceptor'].default || middleware['interceptor']

middleware['onlineService'] = require('../../../src/client/middleware/onlineService.js')
middleware['onlineService'] = middleware['onlineService'].default || middleware['onlineService']

middleware['overseasNetworkLoad'] = require('../../../src/client/middleware/overseasNetworkLoad.js')
middleware['overseasNetworkLoad'] = middleware['overseasNetworkLoad'].default || middleware['overseasNetworkLoad']

middleware['pageData'] = require('../../../src/client/middleware/pageData.js')
middleware['pageData'] = middleware['pageData'].default || middleware['pageData']

middleware['queryCustomPage'] = require('../../../src/client/middleware/queryCustomPage.js')
middleware['queryCustomPage'] = middleware['queryCustomPage'].default || middleware['queryCustomPage']

middleware['redirect'] = require('../../../src/client/middleware/redirect.js')
middleware['redirect'] = middleware['redirect'].default || middleware['redirect']

middleware['schoolConfig'] = require('../../../src/client/middleware/schoolConfig.js')
middleware['schoolConfig'] = middleware['schoolConfig'].default || middleware['schoolConfig']

middleware['timezone'] = require('../../../src/client/middleware/timezone.js')
middleware['timezone'] = middleware['timezone'].default || middleware['timezone']

middleware['titleTemplateChange'] = require('../../../src/client/middleware/titleTemplateChange.js')
middleware['titleTemplateChange'] = middleware['titleTemplateChange'].default || middleware['titleTemplateChange']

middleware['ucenter'] = require('../../../src/client/middleware/ucenter.js')
middleware['ucenter'] = middleware['ucenter'].default || middleware['ucenter']

middleware['websiteData'] = require('../../../src/client/middleware/websiteData.js')
middleware['websiteData'] = middleware['websiteData'].default || middleware['websiteData']

export default middleware
