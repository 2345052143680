export default {
  register: {
    formPlaceholder: 'Veuillez compléter les informations d\'inscription avant de passer à la caisse',
    formTitle: 'Informations d\'inscription',
    submitName: 'Soumettre',
    requiredError: 'Le champ est obligatoire.',
    selectPlaceholder: 'Veuillez sélectionner',
    inputPlaceholder: 'Veuillez saisir',
  },
  priceInfo: {
    deduction: 'Déduction automatique après 48 heures',
  },
  orderCreate: {
    subscription: 'Achat',
  },
}
