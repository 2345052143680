/**
 * 班级卡片通用能力
 */
import get from 'lodash/get'
import { isOffline, isBigOnline } from 'common/platform.js'
import { classRightTagTextMap, classRightTagClassMap, classRightStampClassMap } from 'common/map.js';

export default {
  computed: {
    cardUnavailable() {
      return this.remainStore === 0 || [15, 203, 204, 205].indexOf(this.tagType) >= 0
    },
    // 上课时间描述
    classTimeDesc() {
      return get(this.cardData, 'spec.timeDesc', '') || get(this.cardData, 'timeDesc', '')
    },
    // 剩余库存
    remainStore() {
      const store = this.cardData.store || 0;
      const soldStore = this.cardData.sellStore || 0;
      const remainStore = Number(store) - Number(soldStore)
      return remainStore > 0 ? remainStore : 0
    },
    // 右上角标识文案
    cardRightTagText() {
      const type = this.tagType
      return classRightTagTextMap(this, type, this.remainStore)
    },
    // 右上角标识类
    cardRightTagClass() {
      const type = this.tagType
      return classRightTagClassMap(type)
    },
    // 右上角标识戳类
    cardStampClass() {
      const type = this.tagType
      return classRightStampClassMap(type)
    },
    // 学科标签
    subjectTag() {
      return get(this.cardData, 'spec.subject', '') || get(this.cardData, 'tag', '')
    },
    // 授课方式标签
    platformTag() {
      const { platformType, subPlatformType } = this.getPlatform
      if (isOffline(platformType)) {
        return this.$t('page.classCard.inPerson')
      }
      if (isBigOnline(platformType)) {
        if (['hk', 'us'].includes(this.locale) && subPlatformType !== undefined) {
          return `${subPlatformType === 0 ? this.$t('page.classCard.bigOnline') : this.$t('page.classCard.smallOnline')}`
        }
        return 'Online'
      }
      return 'Other'
    },
  },
}
