import { getOtherSchools } from 'common/utils.js';

export default {
  data() {
    const { locale, localeConfig } = this.$store.state
    return {
      locale,
      otherSchool: this.$t('common.otherSchool'),
      otherSchools: getOtherSchools(locale),
      policyLinks: this.$t('common.policyLinks') || [],
      aboutUs: this.$t('common.aboutUs'),
      follows: this.$t('common.follows'),
      contactUsConfig: this.$t('common.contactUs'),
      companyInfo: this.$t('common.companyInfo'),
      contactPhone: this.$t('common.contactPhone'),
      showContactEmail: this.$t('common').showContactEmail,
      contactEmail: this.$t('common.contactEmail'),
      contactPhoneString: this.$t('common.contactPhoneString'),
      workingTime: this.$t('common.workingTime'),
      // 联系信息列表
      // 显示标题+内容，无图标显示
      contactList: this.$t('common.contactList') || [],
      showDetail: localeConfig.showFooterDetail,
      showFooter: localeConfig.hasFooter,
    }
  },
  computed: {
    logoUrl() {
      return this.$store.state.website.config.logo || this.$t('common.logo.normal')
    },
    followsLinks() {
      if (!this.follows.links || this.follows.links.length === 0) return []
      if (this.locale !== 'tm') return this.follows.links
      return this.follows.links.filter((item) => item.isTm)
    },
  },
}
