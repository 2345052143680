export default {
  // 教师禁用展示文案
  teacherDisableText: '此教師信息敬請期待，快去查看更多傑出的教師吧！',
  exceptionText: '更多教師信息，敬請期待',
  teacher: '我們的老師',
  intro: {
    title: '星級教師',
    describe: '學而思非常注重教師團的培養，特設教學部幫助老師提升授課能力，提高學童心理發展專業性。 課堂沿用啟發式教學，讓小朋友愛上主動學習。學而思課堂通過趣味教材和生動講解，再結合科技和遊戲互動元素，多方面激發小朋友學習興趣，並通過對小朋友自信心和成就感的培養，將學習興趣轉化成自主學習動力。',
  },
  detailTabs: [{ title: '教師簡介', name: 'Profile' }, { title: '教授課程', name: 'Courses' }],
  achievement: '教學成果',
  longTerm: '長期班',
  shortTerm: '短期班',
}
