/**
 * 设置渠道来源参数
 * 购课下单时会取sessionStorage下的来源参数
 */

export default ({ req, route }) => {
  if (process.client) {
    const source = route.query.source || ''
    const utmSource = route.query.utm_source || ''
    const utmMedium = route.query.utm_medium || ''
    const utmCampaign = route.query.utm_campaign || ''
    if (source) {
      window.sessionStorage.setItem('source', source)
    }
    if (utmSource) {
      window.sessionStorage.setItem('utm_source', utmSource)
    }
    if (utmMedium) {
      window.sessionStorage.setItem('utm_medium', utmMedium)
    }
    if (utmCampaign) {
      window.sessionStorage.setItem('utm_campaign', utmCampaign)
    }
  }

  // 渠道点击ID存储cookie, 利于后期服务端渲染时根据渠道点击ID判断是否加载渠道SDK
  if (process.server) {
    const fbclid = route.query.fbclid || '' // facebook渠道点击ID
    const gclid = route.query.gclid || '' // google渠道点击ID
    const ttclid = route.query.ttclid || '' // tiktok渠道点击ID
    if (fbclid) {
      req.ctx.cookies.set('_official_fbclid', fbclid, { httpOnly: false, overwrite: true })
    }
    if (gclid) {
      req.ctx.cookies.set('_official_gclid', gclid, { httpOnly: false, overwrite: true })
    }
    if (ttclid) {
      req.ctx.cookies.set('_official_ttclid', ttclid, { httpOnly: false, overwrite: true })
    }
  }
}
