/**
 * 调转规则页面配置
 */
export default {
  title: '調課轉班規則',
  content: `
    <p>如果您報名課程後，發現上課時間、難度、老師、上課方式不滿足您的預期，您可以考慮申請調課、轉班，為孩子選擇更適合的課程就讀。</p>
    <p>具體規則如下：</p>

    <ol>
      <li>僅支持年份、學期、學科、剩余課次數相同的課程之間，進行調課、轉班；</li>
      <li>僅支持相同標準價的課程之間進行調課轉班（標準價指的是Think Academy對各課程的標準價，與您實際支付的價格、享受的活動優惠等無關）。調課、轉班不會帶來您的訂單信息改變，我們也暫不支持差價退費、補價進行調課轉班操作。</li>
      <li>您最多可以為購課課次數的一半課次進行調課操作，每個班級最多申請2次轉班，請謹慎操作；</li>
      <li>您可以申請調課到當前就讀難度及以下難度的班級臨時聽一講，也可以轉班到當前就讀難度及以下難度的班級；</li>
      <li>無論長期班還是短期班，我們都不支持跨年級調課轉班；</li>
      <li>部分活動課程、講次處於直播開始前30分鐘-直播結束後30分鐘的課程，暫不支持調課、轉班，敬請理解；</li>
      <li>調課、轉班後，原課程已上完的課次，仍可在學習中心查看回放，請放心操作；</li>
      <li>因不同的班級可能存在不同的分期計劃，您在轉班後，當前班級的分期計劃可能發生變更。</li>
    </ol>
  `,
}
