export default {
  title: '중요한 연락처 정보 추가',
  Email: '이메일 주소',
  Phone: '전화번호',
  email: '이메일 주소',
  phone: '전화번호',
  WeChat: '위챗',
  WhatsApp: 'WhatsApp',
  Line: '라인',
  save: '저장',
  cancel: '취소',
  editWeChat: 'WeChat를 입력해주세요',
  editWhatsApp: 'WhatsApp를 입력해주세요',
  editLine: 'Line를 입력해주세요',
  editPhone: '전화번호 편집',
  editEmail: '이메일 편집',
  contactTip1: '위챗 / WhatsApp / 라인은 교사가 학생에 대해 부모님과 소통하기 위해 사용됩니다',
  contactTip2: '학습.',
  contactTip3: '연락처 정보를 정직하게 기입해주세요. 연락처 정보가 누락되면 교사가 학생의 학습 상황을 부모님과',
  contactTip4: '시간에 맞춰 동기화하는 데 영향을 미칩니다',
  minChoose: '저장하기 전에 최소한 하나의 연락처 정보를 기입해주세요',
  headline: '중요한 연락처 정보 추가',
  describe: '연락치 정보를 정확하게 기업해주세요. 연락지 정보가 누락되면 교사가 학생의 학습 상황을 부모님에게 알려주기 어려우니 주의하시기 바랍니다.',
  invalidPhone: '유효한 휴대폰 번호를 입력해주세요',
  invalidEmail: '유효한 이메일을 입력해주세요',
  invalidWechat: '유효한 위챗을 입력해주세요',
  invalidWhatsApp: '유효한 WhatsApp을 입력해주세요',
  saveSuccess: '저장되었습니다',
  saveFail: '최소한 하나의 연락처를 입력해주세요',
  contactTip5: '휴대폰 번호와 이메일 주소는 자녀의 학습과 관련된 중요한 알림을 보내는 데 사용되므로 정확하게 입력해 주세요.',
};
