export default {
  allOrders: ' すべての注文',
  detail: {
    preClass: '授業前の準備',
    preClassInfo: 'お子様がどのように授業を受けているのか、授業前に何を準備する必要があるのかを理解する。',
    prepare: {
      preClass: '授業前の準備',
      parents: '親のため',
      students: '学生のため',
      tobetterInfo: 'お子さまの学習状況、フォローアップコースのスケジュール、チューターからの学習通知、コースの更新などをより把握するために、保護者向けアプリをダウンロードしてください',
      benefitsInfo: '保護者向けアプリをダウンロードするメリット',
      calendar: 'カレンダー',
      calendarInfo: '時間を効率的に計画するため、全てのお子様のクラス時間割を見ることができます',
      preClassInfo: '授業の前に、事前に教材を見ることで、コースの内容や先生の指導計画を予習することができます',
      learningFeedback: '学習フィードバック',
      learningInfo: '授業後は、授業後のレポートや先生からの評価を見るでお子様の学習状況を把握できます。また、宿題の提出状況や先生の添削もすぐ確認できます',
      learningService: '学習サービスの通知',
      learningServiceInfo: 'お子様の学習状況や重要なサービスに関するお知らせをすぐに受け取ることができます。',
      quickOnline: 'クイック・オンライン・コース選択',
      quickOnlineInfo: '専任のアドバイザーが、お子様の教育に最適なコースをご紹介します',
    },
    attend: {
      howtoAttend: '参加方法',
      howtoAttendInfo: 'このコースは、ラーニングセンターで直接授業を行います。授業開始15分前には教室にお越しください',
      location: 'ラーニングセンターの位置',
      classroom: '教室',
      address: 'アドレス',
      contactNumber: '連絡の電話番号',
    },
    attendOnline: {
      howtoAttend: '参加方法',
      attendInfo: 'お子様により良いクラス体験をさせるために、以下の手順に従ってください。',
      stepOne: 'ステップ1',
      stepOneInfo: 'Download “Think Academy Student”',
      stepOneDetail: "お子様の授業体験が確保できるよう<span class='orange-txt'> PCやiPad、Androidタブレット</span>を利用して学生アプリのThink Academy Studentをダウンロードしてください",
      stepTwo: 'ステップ2',
      stepTwoInfo: '“Think Academy Student”アプリを開いて教室に入る',
      stepTwoDetailOnline: 'Think Academyのアカウントで生徒用APPにログインし、正しいお子様を選択しているかご確認ください。クラス開始15分前から入室可能です。',
      stepTwoDetailRecord: 'シンク・アカデミーのアカウントを使用して生徒用アプリにログインし、正しいお子様を選択していることを確認してください。 「コース」で「録画コース」に切り替え、「入る」をクリックして学習を開始してください。',
    },
    viewVideo: 'ビデオを見る',
  },
  studentAppName: 'Think Academy Student',
  parentAppName: 'Think Academy Parent',
  all: 'すべて',
}
