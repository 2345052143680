/* eslint-disable max-len */
/**
 * Acceptable use policy 页配置
 */

export default {
  title: 'Acceptable Use Policy',
  content: `<p>This acceptable use policy sets out the content standards that apply when you upload content to our Site, make contact with other users on our Site, link to our Site, or interact with our Site in any other way. When using the Site, you must comply with this Acceptable Use Policy.</p>
  <p><u>Prohibited uses</u></p>
  <p>You may use our Site only for lawful purposes.  You may not use our Site:</p>
  <ul>
    <li>In any way that breaches any applicable local, national or international law or regulation.</li>
    <li>In any way that is unlawful or fraudulent or has any unlawful or fraudulent purpose or effect.</li>
    <li>For the purpose of harming or attempting to harm minors in any way.</li>
    <li>To bully, insult, intimidate or humiliate any person.</li>
    <li>To send, knowingly receive, upload, download, use or re-use any material which does not comply with our content standards.</li>
    <li>To transmit, or procure the sending of, any unsolicited or unauthorised advertising or promotional material or any other form of similar solicitation (spam).</li>
    <li>To knowingly transmit any data, send or upload any material that contains viruses, Trojan horses, worms, time-bombs, keystroke loggers, spyware, adware or any other harmful programs or similar computer code designed to adversely affect the operation of any computer software or hardware.</li>
  </ul>
  <p>You also agree:</p>
  <ul>
    <li>Not to reproduce, duplicate, copy or re-sell any part of our Site in contravention of the provisions of our <a href="/terms-of-use">terms of use</a>.</li>
    <li>
      Not to access without authority, interfere with, damage or disrupt:
      <ul>
        <li>any part of our Site;</li>
        <li>any equipment or network on which our Site is stored; </li>
        <li>any software used in the provision of our Site; or </li>
        <li>any equipment or network or software owned or used by any third party.</li>
      </ul>
    </li>
  </ul>
  <p><u>Interactive services</u></p>
  <p>We may from time to time provide interactive services on our Site, including, without limitation:</p>
  <ul>
    <li>Chat rooms.</li>
    <li>Bulletin boards.</li>
    <li>Comments.</li>
    <li>Question and answering.</li>
  </ul>
  <p>("<b>Interactive Services</b>").</p>
  <p>Where we do provide any Interactive Services, we will provide clear information to you about the kind of service offered, if it is moderated and what form of moderation is used (including whether it is human or technical).</p>
  <p>However, we are under no obligation to oversee, monitor or moderate any Interactive Services we provide on our Site, and we expressly exclude our liability for any loss or damage arising from the use of any Interactive Services by a user in contravention of our content standards, whether the service is moderated or not.</p>
  <p><u>Content standards</u></p>
  <p>These content standards apply to any material which you contribute to our Site ("<b>User Content</b>"), and to any Interactive Services associated with it.</p>
  <p>User Content must:</p>
  <ul>
    <li>Be accurate (where it states facts).</li>
    <li>Be genuinely held (where it states opinions).</li>
    <li>Comply with the law applicable in Singapore.</li>
  </ul>
  <p>User Content must not:</p>
  <ul>
    <li>Be defamatory of any person.</li>
    <li>Be obscene, offensive, hateful or inflammatory.</li>
    <li>Bully, insult, intimidate or humiliate.</li>
    <li>Promote sexually explicit material.</li>
    <li>Include child sexual abuse material.</li>
    <li>Promote violence.</li>
    <li>Promote discrimination based on race, sex, religion, nationality, disability, sexual orientation or age.</li>
    <li>Infringe any copyright, database right, trade mark or other proprietary rights, or rights of privacy or publicity, of any other person.</li>
    <li>Be false, misleading or likely to deceive any person.</li>
    <li>Breach any legal duty owed to a third party, such as a contractual duty or a duty of confidence.</li>
    <li>Promote any illegal activity or violate any law or regulation.</li>
    <li>Be in contempt of court.</li>
    <li>Be threatening, abuse or invade another's privacy, or cause annoyance, inconvenience or needless anxiety.</li>
    <li>Be likely to harass, upset, embarrass, alarm or annoy any other person.</li>
    <li>Impersonate any person, or misrepresent your identity or affiliation with any person.</li>
    <li>Advocate, promote, incite any party to commit, or assist any unlawful or criminal act such as (by way of example only) copyright infringement or computer misuse.</li>
    <li>Contain a statement which you know or believe, or have reasonable grounds for believing, that members of the public to whom the statement is, or is to be, published are likely to understand as a direct or indirect encouragement or other inducement to the commission, preparation or instigation of acts of terrorism.</li>
    <li>Contain any advertising or any form of commercial solicitation or activity or promote any services or web links to other sites.</li>
  </ul>
  <p><u>Breach of this policy</u></p>
  <p>When we consider that a breach of this Acceptable Use Policy has occurred, we may take such action as we deem appropriate. </p>
  <p>Failure to comply with this Acceptable Use Policy constitutes a material breach of the <a href="/terms-of-use">terms of use</a> upon which you are permitted to use our Site, and may result in our taking all or any of the following actions:</p>
  <ul>
    <li>Immediate, temporary or permanent withdrawal of your right to use our Site.</li>
    <li>Immediate, temporary or permanent removal of any User Content uploaded by you to our Site.</li>
    <li>Issue of a warning to you.</li>
    <li>Legal proceedings against you for reimbursement of all costs on an indemnity basis (including, but not limited to, reasonable administrative and legal costs) resulting from the breach.</li>
    <li>Further legal action against you.</li>
    <li>Disclosure of such information to law enforcement authorities as we reasonably feel is necessary or as required by law.</li>
  </ul>
  <p>We exclude our liability for all action we may take in response to breaches of this Acceptable Use Policy. The actions we may take are not limited to those described above, and we may take any other action we reasonably deem appropriate.</p>
  <p>The terms of this Acceptable Use Policy, their subject matter and their formation (and any non-contractual disputes or claims) are governed by Singapore law. </p>
  <p>You and we both agree to the exclusive jurisdiction of the courts of Singapore.</p>`,
}
