/**
 * 导航时区标识
 */
export default {
  // 自动定位时区按钮名称
  autoTimezoneButton: {
    cancelName: '今ではない',
    confirmName: '今の時間帯に変更する',
  },
  // 网站时区提示
  websiteTimezoneNotice: 'ウェブサイトに表示されるタイムゾーン',
  // 当前时区提示
  currentTimezoneNotice: 'あなたの時間帯',
  // 当前时区按钮名称
  currentTimezoneButton: {
    confirmName: 'OK',
  },
  // 切换定位刷新页面提示
  refreshNotice: '今の時間帯に変更する',
}
