/**
 * 渠道归因插件
 */
export default function ({ route, app, store }) {
  const { query } = route

  const sourceType = query.source_type || window.sessionStorage.getItem('source_type') || ''
  const sourceId = query.source_id || window.sessionStorage.getItem('source_id') || ''

  // 渠道参数解析
  if (sourceType && sourceId) {
    store.commit('attribution/SET_SOURCE', {
      type: sourceType || 0,
      id: sourceId || 0,
    })
  }

  // 通用活动参数解析
  const eventType = query.event_type || window.sessionStorage.getItem('event_type') || ''
  const eventId = query.event_id || window.sessionStorage.getItem('event_id') || ''

  // 优先取通用活动参数解析
  if (eventType || eventId) {
    store.commit('attribution/SET_EVENT', {
      type: eventType || 0,
      id: eventId || 0,
    })
  }

  app.router.afterEach((to, from) => {
    // 官网内活动参数解析
    const activityType = to.query.activityType || from.query.activityType || ''
    const activityId = to.query.activityId || from.query.activityType || ''

    if (activityType || activityId) {
      store.commit('attribution/SET_EVENT', {
        type: `31${activityType}`,
        id: activityId,
      })
    }
  })
}
