/* eslint-disable max-len */
/**
 * privacy-policy 页面配置
 */

export default {
  title: '私隱政策',
  content: `
  <p>學而思教育科技（香港）有限公司（下稱 "學而思" 或 "我們" ）遵守香港法例第 486章《個人資料（私隱）條例》，會對所取得的個人資料加以保護及保密，尊重和保障閣下私隱權利。</p>
  <p> 一、閣下請詳閱本私隱政策聲明，以確保閣下充份了解學而思將如何處理閣下在使用本網站及相關服務時提供的個人資料。如果不同意本隱私政策任何內容，閣下應立即停止使用學而思產品或服務。當閣下使用學而思提供的任何一項服務時，即表示您已同意我們按照本隱私政策來合法使用和保護您的個人私隱信息。學而思可無需事先通知閣下而修訂、更改或補充本政策聲明。 </p>
  <p>二、學而思會在以下主要項目及情況收集閣下的個人資料：報讀課程及學生服務、當閣下參與我們主辦的特別活動、使用我們的網上服務，例如提交查詢等。需要提供的個人資料包括但不限於：學生姓名及身份證號碼、家長姓名、電話號碼、電郵地址、聯絡地址等。個人資料的收集及利用將限於我們管理業務所需的範圍內，包括服務與設施的提供，以及與選定之業務夥伴交換資料作教育服務及課程宣傳用途。</p>
  <p>三、閣下到訪本網站時，網站會作記錄，以分析網站的訪客人數和一般使用狀況。有關統計分析結果的擁有權及所有權為學而思所有，學而思亦保留權利公布、使用及出版有關的結果。其中部分資料將透過 "Cookies" 方式收集。Cookies是載有小量資料的檔案，自動儲存於訪客本身電腦所安裝的互聯網瀏覽器，可供本網站日後檢索。如閣下想禁用Cookies或被通知Cookies的使用，可更改瀏覽器的設定。</p>
  <p>四、閣下確認並且授權，學而思保留並儲存閣下提供的所有資料將儲存於本公司一個或多個資料庫之中。學而思將按照嚴格的保安和保密標準對這些資料庫內的所有資料維持全面控制和保護，不會透露給第三方，但以下人士或機構除外：</p>
  <p></p>
  <ol>
    <li>學而思按適用的法律、法庭指令或監管條例或規則的要求，需要向之透露資料的任何人士。如教育局、社會福利署、衛生署/醫院/診所、其他有關之政府部門或監管機構。（通常在此情況下，本校將不能通知學生或監護人或在徵求其同意後才向上述人士披露有關資料）</li>
    <li>任何對學而思有保密責任的人，包括對學而思有保密資料承諾的與我們同一集團的公司（如學生或監護人同意收取推廣資料，則包括與我們聯合推廣或宣傳服務或產品的合作夥伴）。</li>
    <li>涉及提供閣下所須服務或資訊的其他學而思關聯公司及好未來教育集團。</li>
  </ol>
  <p>閣下所提供的資料可能會被轉移給位於香港以外的地方。</p>
  <p>五、學而思不會在未得到閣下事先同意下，為市場推廣目的而向第三方透露閣下的個人資料。</p>
  <p>六、若閣下不希望收到學而思所發送有關教育服務及課程之直銷宣傳推廣資料，或不希望我們披露、轉移或使用閣下的個人資料作上述直銷用途，請以書面郵寄至我們，地址是彌敦道794-802號協成行太子中心2樓。</p>
  <p>七、本私隱政策聲明所載的任何條款均不限制任何人根據香港個人資料（私隱）條例而享有的權利。 閣下須知道閣下根據個人資料（私隱）條例而享有的權利，包括：</p>
  <ol>
    <li>查詢學而思有否持有閣下任何個人資料；</li>
    <li>查閱學而思所持有的閣下的資料；</li>
    <li>要求改正閣下的資料；</li>
    <li>查明學而思在持有的資料及資料類型方面不時實行的政策及慣例；</li>
    <li>禁止學而思查閱或使用閣下的資料，並要求獲知學而思就任何上述要求所採取的行動。 學而思保留權利就遵循上述的要求而收取合理的費用，然而若查閱是為改正資料，該手續費將可獲豁免。</li>
  </ol>
  <p>九、儘管互聯網在本質上並非安全可靠的通訊環境，但用戶可運用適當的技術加強網絡安全性。為嚴格保密從我們網站用戶獲取的所有個人資料，除使用防火牆和其他精密技術性設備外，我們還提供及設有其他嚴格的保安措施，以保護我們的系統、資料和數據，避免任何意外、惡意破壞或損毀。</p>
  <p>十、本校有既定的指引及員工培訓，規定只有有工作需要的員工才會獲授權存取學生個人資料，所有獲授權人士亦需採取適當的保安措施保障學生個人資料不受未獲准許或意外的查閱、處理、刪除或其他使用所影響。</p>
  <p>十一、任何個人資料不會被存有超逾其收集目的之所需期限。因應不同種類的個人資料之所需保存期限，本校已有適當程序以棄置過時的資料紀錄。</p>
  <p>十二、閣下如對本聲明有任何查詢，歡迎以書面與我們聯絡：</p>
  <p class="center">學而思教育科技（香港）有限公司  資料保護部</p>
  <p class="center">彌敦道794-802號協成行太子中心2樓</p>
 `,
}
