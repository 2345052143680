export default {
  allOrders: '所有订单',
  detail: {
    preClass: '课前准备',
    preClassInfo: '快来了解孩子如何上课，课前需要准备什么吧',
    prepare: {
      preClass: '课前准备',
      parents: '家长',
      students: '学生',
      tobetterInfo: '为了能更好的了解孩子学习情况、后续课程安排、老师的学习通知、课程的续报，请您下载家长端APP',
      benefitsInfo: '下载家长端的好处',
      calendar: '日历课表',
      calendarInfo: '您可以了解孩子的所有课次安排，合理安排时间',
      preClassInfo: '在课前您可以通过查看课件提前了解本节课课程内容以及老师的上课安排',
      learningFeedback: '学情反馈',
      learningInfo: '在课后您可以查看课后报告及老师评价，了解孩子课堂学习表现；并且可以及时了解孩子作业完成情况及老师批改情况',
      learningService: '学习服务通知',
      learningServiceInfo: '您可以第一时间收到孩子的学习动态和重要的服务通知',
      quickOnline: '在线快捷选课',
      quickOnlineInfo: '我们有专属顾问为您推荐体系化课程，您一定可以找到适合您孩子的课程',
    },
    attend: {
      howtoAttend: '如何上课？',
      howtoAttendInfo: '此课程我们将在线下进行授课，为了老师更好的安排学生，请孩子在开课前15分钟到达教室',
      location: '教学点',
      classroom: '教室',
      address: '地址',
      contactNumber: '联系电话',
    },
    attendOnline: {
      howtoAttend: '如何上课？',
      attendInfo: '为了让孩子有一个更好的上课体验，请您按以下步骤操作',
      stepOne: '第一步',
      stepOneInfo: '下载 “Think Academy Student',
      stepOneDetail: '为了保证孩子的上课体验，请您用PC、iPad 或者 Android平板来下载学生端：Think Academy Student',
      stepTwo: '第二步',
      stepTwoInfo: '打开 “Think Academy Student” 并进入课堂',
      stepTwoDetailOnline: '请用您的Think Academy账号登录学生端APP，确认您选择了正确的孩子。孩子可以在课次开始前15分钟，进入课堂',
      stepTwoDetailRecord: '请用您的Think Academy账号登录学生端APP，确认您选择了正确的孩子。在学习中心课程类型中，切换至“录播课”，点击“上课”开始学习',
    },
    viewVideo: '观看视频',
  },
  studentAppName: 'Think Academy Student',
  parentAppName: 'Think Academy Parent',
  all: 'All',
}
