export default {
  'order-list': true,
  'order-detail-orderId': true,
  'order-refund-orderId': true,
  'order-create-skuId': true,
  'order-success-orderId': true,
  'order-result-orderId': true,
  'my-courses-list': true,
  'my-courses-detail': true,
  'personal-information': true,
}
