export default {
  // 教师禁用展示文案
  teacherDisableText: "Teacher's information is not found. Go find more of our outstanding teachers!",
  exceptionText: 'More excellent teachers are on the way. Please stay tuned.',
  intro: {
    title: 'Meet Our Math Master Teachers',
    describe: 'Our growing team of exceptional Master Teachers have been hand-picked via a rigorous selection process and includes graduates of the world’s top universities, national and international Math Olympiad award winners, former GEP students, PSLE top scorers and former MOE teachers.',
  },
  detailTabs: [{ title: 'Profile', name: 'Profile' }, { title: 'Courses', name: 'Courses' }],
  achievement: 'Achievements',
  longTerm: 'Long Term Course',
  shortTerm: 'Short Term Course',

}
