/**
 * 导航时区标识
 */
export default {
  // 自动定位时区按钮名称
  autoTimezoneButton: {
    cancelName: '暫不切換',
    confirmName: '切換為你的時區',
  },
  // 网站时区提示
  websiteTimezoneNotice: '網站展示的時區:',
  // 当前时区提示
  currentTimezoneNotice: '你的時區:',
  // 当前时区按钮名称
  currentTimezoneButton: {
    confirmName: '知道了',
  },
  // 切换定位刷新页面提示
  refreshNotice: '正在為您切換時區',
}
