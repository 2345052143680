/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
import { oneApi } from 'config/api'

export const state = () => ({
  // 续报换班开始时间
  renewalStartDate: '',
  // 续报换班结束时间
  renewalEndDate: '',
});

export const mutations = {
  /**
   * 设置续报换班时间配置
   * @param {state} state
   * @param {config} config
   */
  SET_UNPAID_TRANSFER_DATE(state, config) {
    state.renewalStartDate = config.startDate;
    state.renewalEndDate = config.endDate;
  },
};

export const actions = {
  async queryUnpaidConfigInfo({ commit }, $axios) {
    const res = await $axios.post(oneApi.getUnpaidInfo, {}, { rewritePostBody: true });
    // 业务异常阻断
    if (Number(res.code) !== 0) {
      return
    }
    const resData = res.data || {};
    const startDate = resData.startDate || ''
    const endDate = resData.endDate || ''

    commit('SET_UNPAID_TRANSFER_DATE', { startDate, endDate });
  },
}
