<template>
  <div class="card" :class="{recommended: isRecommend}">
    <div class="part content">
      <span />
      <p class="level font-semibold">
        {{ cardData.levelDegreeName }}
      </p>
      <div>
        <span>Difficulty</span>
        <Rate :rate-level="cardData.levelDegreeOrder" :max="cardData.levelDegreeOrder" on-color="#FFCF1B" />
      </div>
    </div>
    <div v-if="cardData.levelDegreeDesc" class="part bottom">
      {{ cardData.levelDegreeDesc }}
    </div>
  </div>
</template>

<script>
import Rate from 'components/common/Rate'

export default {
  components: { Rate },
  props: {
    isRecommend: {
      default: false,
      type: Boolean,
    },
    cardData: {
      default: () => ({}),
      type: Object,
    },
  },
}
</script>
<style lang="scss" scoped>
.card {
  max-width: 311px;
  margin: 10px auto;

  .part {
    background: #f8f9fa;
    border-radius: 10px;
    margin-bottom: 1px;
    position: relative;

    &.recommended {
      >span {
        position: absolute;
        top: -8px;
        right: -14px;
        padding: 2px 8px 9px 14px;
        font-size: 12px;
        width: 127px;
        height: 32px;
        text-align: center;
        font-weight: 600;
        background-image: url("~assets/images/test/recommend.png");
        background-size: cover;
        color: #fff;
        z-index: 2;
      }
    }

    &.content {
      padding: 16px;

      .level {
        font-size: 24px;
        color: var(--txt-color-lv1);
        line-height: 29px;
        margin-bottom: 10px;
      }

      div {
        display: flex;

        span {
          background: rgba(222, 226, 231, 0.5);
          border-radius: 4px;
          padding: 2px 4px;
          font-weight: 500;
          line-height: 14px;
          color: var(--txt-color-lv1);
          font-size: 12px;
          margin-right: 10px;
        }
      }
    }

    &.bottom {
      padding: 10px 16px;
      font-size: 13px;
      font-weight: 500;
      color: var(--txt-color-lv2);
      line-height: 15px;
    }
  }

  &.recommended {
    .part {
      background: var(--txt-color-link3);

      &.content {
        >span {
          position: absolute;
          top: 0;
          right: -14px;
          padding: 2px 8px 9px 14px;
          font-size: 12px;
          width: 127px;
          height: 32px;
          text-align: center;
          font-weight: 600;
          background-image: url("~assets/images/test/recommend.png");
          background-size: cover;
          color: #fff;
          z-index: 2;
        }
        .level { color: var(--txt-color-link); }

        div {
          span {
            background: rgba(255, 133, 10, 0.1);
            color: var(--txt-color-link);
          }
        }
      }

      &.bottom {
        color: var(--txt-color-link);
        background: var(--txt-color-link3);
      }
    }
  }
}
</style>
