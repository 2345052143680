/**
 * goods页面配置
 */
export default {
  recordedCourse: 'Recorded Course',
  courseTypeList: [{
    value: 'live',
    label: 'Live Courses',
  },
  {
    value: 'recorded',
    label: 'Recorded Courses',
  },
  ],
  // 录播课
  recordingDetail: {
    exceptiontip: 'This course is no longer open for registration. View more courses provided by Think Academy',
    exceptiontip1: 'Sorry, this lesson is not available now. View more courses provided by Think Academy',
    free: 'Free',
    courseDetails: 'Course Details',
    courseDetail: 'Course Detail',
    overview: 'Overview',
    syllabus: 'Syllabus',
    teacher: 'Teacher',
    validFor: 'Valid for',
    day: 'day',
    days: 'days',
    viewMore: 'View all',
    lesson: 'lesson',
    lessons: 'lessons',
    readMore: 'Read more',
    hr: 'hr',
    min: 'min',
    courseValidUntil: 'Expiration date: ',
    btnStatus: {
      forSale: 'Coming Soon',
      buyNow: 'Buy Now',
      soldOut: 'Sold Out',
      registered: 'Successfully Signed Up',
      subscription: 'Purchase',
    },
    expired: 'Expired',
    expiredTip: 'This course is expired',
    permanentTip: 'Course valid permanently',
    start: 'Start',
  },
  goodsDetail: {
    year: 'Yearly',
    month: 'Monthly',
    discounts: '-20%',
  },
  subscriptionPeriod: {
    2: 'day',
    3: 'month',
    4: 'year',
  },
  subDate: {
    2: 'Day',
    3: 'Month',
    4: 'Year',
  },
  howToAttendClass: {
    title: 'How to attend class',
    firstStepLeft: 'Download',
    firstStepRight: 'App',
    secondStep: 'Log in the App,you can find the course in "Class"',
    thirdStep: 'Find the lesson you want to study, click "Enter" to start learning',
  },
  orderResult: {
    viewMoreCourse: 'View more courses',
    viewOrder: 'View Order',
    viewCourse: 'View Course',
    cancel: 'Cancel',
    howToAttendClass: 'How to Attend Class',
  },
  recordedVideoTips: {
    0: 'Play',
    '0-1': 'Pause',
    1: 'Rewind 10s',
    2: 'Fast forward 10s',
    9: 'Screenshot',
    11: 'Full screen',
    '11-1': 'Exit full screen',
  },
  recordedVideo: {
    reload: 'Reload',
    loadFailed: 'Failed to load',
    loading: 'Loading',
    saveShotScreen: 'The screenshot is saved',
    switchChannel: 'Switch channel',
  },
}
