/**
 * 在线客服中间件
 */

export default function ({ app, store }) {
  // 客户端环境不执行
  if (process.client) return

  const { locale } = store.state

  if (locale === 'hk') {
    app.head.script.push({
      innerHTML: 'window.$crisp=[];window.CRISP_WEBSITE_ID="28425d7c-2914-469a-8040-e14f2b8f7bd9";(function(){d=document;s=d.createElement("script");s.src="https://client.crisp.chat/l.js";s.async=1;d.getElementsByTagName("head")[0].appendChild(s);})();',
      async: true,
    })
  }
}
