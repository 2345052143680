<template>
  <div class="student-item-card-wrapper" :class="{'select-student-card': canSelected}">
    <div class="student-main-container">
      <div class="stu-avatar-container">
        <img v-lazy-load :data-src="studentInfo.avatar" alt="avatar">
      </div>
      <div class="font-medium stu-info-container">
        <div class="stu-name text-overflow-ellipsis">
          <slot name="stu-name">
            {{ easternNameOrderEnabled ? `${studentInfo.lastName || ''}${studentInfo.firstName || ''}` || studentInfo.nickName :
              (studentInfo.firstName || '')+(studentInfo.lastName || '') ? `${studentInfo.firstName || ''} ${studentInfo.lastName || ''}`: studentInfo.nickName }}
          </slot>
        </div>
        <div class="stu-no">
          <span>No.</span><span>{{ studentInfo.card | formatCardNo }}</span>
        </div>
      </div>
    </div>
    <div v-show="isEditStudents" class="btn-cancel" @click="cancel" />
    <div v-show="!canSelected && !isEditStudents" class="font-semibold button button-small button-default btn-change-stu" @click="switchStudent">
      {{ $t('page.personalInfo.switch') }}
    </div>
    <div v-show="canSelected && !isEditStudents" class="btn-select-student">
      <i class="iconfont icon-success" />
    </div>
    <ConfirmDialog
      :show.sync="studentConfig.show"
      :title="studentConfirmConfig.title"
      :sub-btn-text="studentConfirmConfig.subBtnText"
      :main-btn-text="studentConfirmConfig.mainBtnText"
      @subBtnClick="confirmCancel"
      @mainBtnClick="confirm"
    >
      <template v-slot:content>
        <div class="confirm-content font-medium">
          {{ studentConfirmConfig.content }}
          <div v-show="studentConfig.step === 2 && studentConfig.extra" class="confirm-content-desc">
            <p v-if="studentConfig.extra && studentConfig.extra.phone">
              <span class="font-medium">{{ studentConfirmConfig.phone }}</span>
              <span class="font-medium">{{ studentConfig.extra.phone }}</span>
            </p>
            <p v-if="studentConfig.extra && studentConfig.extra.email">
              <span class="font-medium">{{ studentConfirmConfig.email }}</span>
              <span class="font-medium">{{ studentConfig.extra.email }}</span>
            </p>
          </div>
        </div>
      </template>
    </ConfirmDialog>
  </div>
</template>

<script>
import { oneApi } from 'config/api';
import ConfirmDialog from 'components/common/ConfirmDialog/index.vue';

export default {
  components: {
    ConfirmDialog,
  },
  props: {
    studentInfo: {
      default: () => ({}),
      type: Object,
    },
    canSelected: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      saving: false,
      studentConfig: {
        show: false,
        step: null,
        extra: null,
      },
    }
  },
  computed: {
    isEditStudents() {
      return this.$parent.isEditStudents;
    },
    studentConfirmConfig() {
      return this.$t(`page.personalInfo.studentCancel.${this.studentConfig.step}`)
    },
    easternNameOrderEnabled() {
      const { easternNameOrderEnabled } = JSON.parse(window.localStorage.getItem('clientOptions')) || {}
      return easternNameOrderEnabled || false
    },
  },
  methods: {
    async cancel() {
      this.Sensors.track('my_family_associate_delete', {
        delete_id: this.studentInfo.uid,
      })

      const res = await this.$axios.post(oneApi.subChangeCheck, {
        changeType: 1,
        studentId: this.studentInfo.uid,
      }, { rewritePostBody: true });
      // const res = {
      //   code: 0,
      //   data: {
      //     continue: true,
      //     helper: { status: 1 },
      //   },
      // }

      if (+res.code !== 0) {
        this.$ElMessage.error(res.msg);
        return;
      }

      const { data } = res
      const { status, extra = { phone: '', email: '' } } = data.helper

      if (data.continue) {
        if (status === 0) {
          this.studentConfig.step = 3
        } else if (status === 1) {
          this.studentConfig.step = 2
          this.studentConfig.extra = extra
        }
      } else if (status === 2) {
        this.studentConfig.step = 1
      } else if (status === 3) {
        // 删除的是主账户，无法解绑
        this.studentConfig.step = 4
      }

      if (this.studentConfig.step) {
        this.studentConfig.show = true
      }
    },
    confirmCancel() {
      this.studentConfig.show = false
      this.btnTrack()
    },
    async confirm() {
      if (this.saving) {
        return
      }

      if (this.studentConfig.step === 1 || this.studentConfig.step === 4) {
        this.studentConfig.show = false
      } else {
        const res = await this.$axios.post(oneApi.batchUnbind, { targetUid: [this.studentInfo.uid] }, { rewritePostBody: true });
        this.saving = false

        if (+res.code !== 0) {
          this.$ElMessage.error(res.msg);
          return
        }

        this.$emit('cancel-student', this.studentInfo.uid)
      }

      this.btnTrack(false)
    },
    switchStudent() {
      this.$emit('switchStudent', this.studentInfo);
    },
    btnTrack(isCancel = true) {
      this.Sensors.track('my_family_delete_confirm_pop', {
        pop_type: ['无法解绑', '可解绑', '可解绑且注销'][this.studentConfig.step - 1],
        pop_button: isCancel ? 'cancel' : 'confirm',
      })
    },
  },

}
</script>

<style lang="scss" scoped>
.student-item-card-wrapper {
  position: relative;
  margin: 10px 0;
  width: 380px;
  height: 72px;
  background: #fff;
  border-radius: 10px;

  .student-main-container {
    display: flex;
    padding: 16px;

    .stu-avatar-container {
      margin-right: 10px;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      border: 2px solid #f4f6fa;

      img {
        width: 38px;
        height: 38px;
        border-radius: 50%;
      }
    }

    .stu-info-container {
      .stu-name {
        margin: 4px 0 6px;
        font-size: 14px;
        line-height: 14px;
        letter-spacing: 0.4px;
        color: var(--txt-color-lv1);
        max-width: 285px;
      }

      .stu-no {
        font-size: 12px;
        line-height: 12px;
        letter-spacing: 0.46px;
        color: var(--txt-color-lv2);
      }
    }
  }

  .btn-change-stu {
    position: absolute;
    top: 31px;
    right: 16px;
    font-size: 12px;
    padding: 7px 12px;
    line-height: 14px;
  }

  .btn-select-student {
    position: absolute;
    top: 31px;
    right: 16px;
    width: 20px;
    height: 20px;
    background: var(--txt-color-link3);
    border-radius: 50%;

    .iconfont.icon-success {
      display: none;
    }
  }

  .btn-cancel {
    position: absolute;
    right: 16px;
    top: 27px;
    width: 32px;
    height: 32px;
    background-image: url("~assets/images/my-account/btn-cancel.png");
    background-size: cover;
    background-repeat: no-repeat;
    cursor: pointer;
  }

  &.select-student-card {
    cursor: pointer;

    &.selected {
      .btn-select-student {
        background: var(--txt-color-link);

        .iconfont.icon-success {
          display: block;
          margin: 2px auto;
          width: 16px;
          height: 16px;
          font-size: 16px;
          color: #fff;
        }
      }
    }
  }

  .confirm-content {
    margin-top: 10px;
    font-size: 16px;
    font-weight: 500;
    color: var(--txt-color-lv2);
    line-height: 19px;
  }

  .confirm-content-desc {
    margin: 10px 17px 0;
    padding: 10px 16px;
    border-radius: 10px;
    background: rgba(244, 246, 250, 0.5);

    p > span:first-child {
      margin-right: 10px;
      height: 16px;
      font-size: 14px;
      font-weight: 500;
      color: var(--txt-color-lv2);
      line-height: 16px;
    }

    p > span:last-child {
      height: 16px;
      font-size: 14px;
      font-weight: 500;
      color: var(--txt-color-lv1);
      line-height: 16px;
    }

    p:first-child {
      margin-bottom: 10px;
    }

    p:only-child {
      margin-bottom: 0;
    }
  }
}

.student-item-card-wrapper:nth-child(2n+1) {
  margin-right: 40px;
}
</style>
