/* eslint-disable max-len */
/**
 * terms-and-conditions页面配置
 */
export default {
  title: '條款及細則',
  content: `
    <p>（2019年3月版本）</p>
    <p>本網站為學而思教育科技（香港）有限公司（下稱 "學而思" 或 "我們"）擁有及運作，此條款及細則在你使用本網站時適用。</p>
    <p>使用本網站前，請先細閱此條款及細則。使用或連結至本網站或其任何手機版本，即代表你已閱覧、明白並同意受此條款及細則約束。此條款及細則為你與本公司之間具法律約束力的協議。 </p>
    <p>學而思有權全權自行決定於任何時候更改、增加或刪減條款及細則的部分。如我們有此所為，將在本頁張貼有關就條款及細則所作的更改，並會在本頁頂標示條款最近修改日期。我們可能向註冊用戶及訂閱用戶發出電郵以就有關更改作出通知。不論你有否收到我們就有關更改發出的通知電郵，如你在任何有關更改後繼續使用本網站，將被視作接受新條款及細則。如你並不同意依從此或任何未來的條款及細則，請勿使用或登入（或繼續使用或登入）本網站。 </p>
    <h3>內容所有權</h3>
    <p>本網站內或透過其提供之材料（除另有註明）為學而思及其特許人版權所有，其所有權利皆予以保留。</p>
    <p>用戶可瀏覽、列印及下載材料以謹供個人、非商業及課堂使用，唯使用時不可移除或修改任何商標、版權或其他所有權聲明。課堂使用指合資格老師就於合資格教育機構註冊的學生課堂上的非商業使用。 </p>
    <p>本網站使用任何商標並不代表該等商標之擁有人與本網站有任何關聯或對本網站之認可。 </p>
    <h3>免責聲明</h3>
    <p>學而思提供包括但並不限於教育及其他相關資訊（下稱 "資訊" ），我們承諾力求網站內容的準確性及完整性，但不會對內容有錯誤或遺漏承擔責任，亦不就此承擔任何損失及賠償責任。為方便使用者查閱由其他人士／團體或通過其他人士／團體提供的資訊，學而思網站可能含有學而思以外的第三方營運之外界網站的超連結。提供該等外界網站的超連結僅供您參考，但並不意味著學而思認可該等外界網站的內容。對任何因透過學而思網站瀏覽或接觸該等外界網站的內容而引致或所涉及的損失或損害（包括但不限於相應而生的損失或損害），學而思概不承擔任何法律責任（不論是民事侵權行為責任或合約責任或其他）。 </p>
    <p>任何資料不論是否經使用學而思提供的服務下載或取得，均由閣下自負風險並自行考量作出。因下載任何資料而導致閣下的電腦系統任何損壞或資料損壞或流失，純屬閣下的責任。當你使用資訊，你已被視為無條件接受上述所載的免責條款，而學而思擁有絕對酌情權及可在無須預先通知你的情況下就上述條款作出不時的修改及／或修訂。 </p>
    <p>雖然我們合理地盡量嘗試從本網站摒除病毒或其他具感染或擾亂特性的編碼，但無法確保全部摒除，亦不會就病毒及其他電腦編碼／軟件承擔法律責任。建議你在本網站下載資料前應先實行恰當保護措施。 </p>
  `,
}
