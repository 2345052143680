/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
import { createWay } from 'config/order.js';

export const state = () => ({
  eventType: 0,
  eventId: 0,
  sourceType: 0,
  sourceId: 0,
});

export const mutations = {
  SET_EVENT(state, event) {
    state.eventType = event.type
    state.eventId = event.id

    if (process.client) {
      window.sessionStorage.setItem('event_type', state.eventType);
      window.sessionStorage.setItem('event_id', state.eventId);
    }
  },
  SET_SOURCE(state, source) {
    state.sourceType = source.type
    state.sourceId = source.id

    if (process.client) {
      window.sessionStorage.setItem('source_type', state.sourceType);
      window.sessionStorage.setItem('source_id', state.sourceId);
    }
  },
}

export const actions = {
  updateEvent({ commit }, event) {
    const { orderCreateWay } = event
    const { activityType } = event
    const { activityId } = event

    switch (orderCreateWay) {
      // 拼团-开团&参团
      case createWay.ACTIVITY_GROUP_NEW:
      case createWay.ACTIVITY_GROUP_JOIN:
        commit('SET_EVENT', {
          type: `31${activityType}`,
          id: activityId,
        })
        break;
      default:
        break
    }
  },
}
