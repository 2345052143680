import { baseCommon } from '@thinkacademy/website-base'
import { getClientType } from 'common/utils'

const {
  // 各分校对应的默认手机区号
  getDefaultPhoneAreaCode: defaultPhoneAreaCode,
  // 各分校默认国家两字母缩写
  getDefaultCountryShortCode: defaultCountryShortCode,
} = baseCommon

// 班级卡片热度状态
const classHotTagType = (store, sellStore, remainSellTime) => {
  const fullShiftRate = 1 - ((store - 0) - (sellStore - 0)) / store
  if (remainSellTime > 1000) {
    // to be sold
    return 11
  }
  if (fullShiftRate >= 0 && fullShiftRate <= 0.25) {
    // new
    return 12
  }
  if (fullShiftRate > 0.25 && fullShiftRate <= 0.5) {
    // popular
    return 13
  }
  if (store - sellStore <= 0) {
    // full
    return 15
  }
  // 剩余x left
  return 14
}

// 学习中心-课程卡片状态映射
const myCoursesStatusMap = (type) => {
  const map = new Map([
    [1, 201],
    [2, 202],
    [3, 203],
    [4, 204],
    [5, 205],
  ])
  return map.get(type)
}

// 班级卡片标签文案映射
const classRightTagTextMap = (context, type, remainStore) => {
  const tagMap = new Map([
    // 通用热度标签类型
    [11, context.$t('page.common.toBeSold')],
    [12, context.$t('featureComponents.page.coursesCardTag.new')],
    [13, context.$t('featureComponents.page.coursesCardTag.popular')],
    [14, context.$t('page.common.storeLeft', [remainStore])],
    // 满班
    [15, context.$t('featureComponents.page.coursesCardTag.full')],
    // 我的课程
    [201, context.$t('featureComponents.page.coursesCardTag.current')],
    [202, context.$t('featureComponents.page.coursesCardTag.current')],
    [203, context.$t('featureComponents.page.coursesCardTag.completed')],
    [204, context.$t('featureComponents.page.coursesCardTag.refunded')],
    [205, context.$t('featureComponents.page.coursesCardTag.playBack')],
    // 待缴费清单标签类型
  ])

  if (!tagMap.has(type)) return ''

  return tagMap.get(type)
}

// 班级卡片标签颜色映射
const classRightTagClassMap = (type) => {
  switch (type) {
    // 通用热度标签类型
    case 11:
      return 'green';
    case 13:
      return 'yellow';
    case 12:
      return 'orange';
    case 14:
      return 'blue';
    case 15:
      return 'pink';
      // 我的课程
    case 201:
    case 202:
      return 'orange';
    case 203:
    case 204:
    case 205:
      return 'grey';

    // 待缴费清单标签类型
    default:
      return ''
  }
}

// 班级卡片标识戳样式类名映射
const classRightStampClassMap = (type) => {
  switch (type) {
    // 待缴费清单标签类型
    default:
      return ''
  }
}

// 资源位标识
const resourceLocationMap = new Map([
  ['home', `${getClientType()}-official_home_screen-1`],
  ['shop-home', `${getClientType()}-shop_home_screen`],
  ['shop-list', `${getClientType()}-shop_list_screen`],
  ['competition-mall', `${getClientType()}-competition_shop_screen-1`],
  ['student-protal', `${getClientType()}-profile_adv`],
  ['side-bar', `${getClientType()}-sidebar_adv`],
  ['examination-home', `${getClientType()}-examination_home_screen`],
])

// 资源位标识
const resourceLocationSaMap = new Map([
  ['home', '官网-首屏'],
  ['shop-home', '商城-首屏'],
  ['shop-list', '商城-筛选'],
  ['competition-mall', 'Web-competition_shop_screen-1'],
  ['student-protal', '个人中心-老带新'],
  ['side-bar', '侧边栏'],
  ['examination-home', '模拟考试站'],
])

const getSceneName = (scene) => {
  const sceneNameMap = {
    // 订单类
    my_orders: '订单详情页',
    // 待缴费
    unpaid_courses: '待缴费',
    // 免费试听课
    free_trial_class: '免费试听课',
    // 诊断
    level_testing: '诊断H5',
    // 开团
    regular_open_group_buy: '班级详情页开团',
    // 参团
    regular_join_group_buy: '班级详情页参团',
    // 参团详情页
    join_group_buy_page: '拼团详情页',
    // 老带新
    referral: '老带新落地页',
    // H5编辑器
    h5_activity: 'H5编辑器活动页',
    // 班级详情页
    regular: '班级详情页',
  }
  return sceneNameMap[scene] || ''
}

// 商城频道来源参数映射
const getMallChannelByLocation = (location) => {
  if (!location) return ''
  const channelLocationMap = new Map([
    ['courses-mall', 1],
    ['competition-mall', 4],
    ['resources', 5],
  ])
  return channelLocationMap.get(location)
}

export {
  defaultPhoneAreaCode,
  defaultCountryShortCode,
  classHotTagType,
  myCoursesStatusMap,
  classRightTagTextMap,
  classRightTagClassMap,
  classRightStampClassMap,
  resourceLocationMap,
  resourceLocationSaMap,
  getSceneName,
  getMallChannelByLocation,
}
