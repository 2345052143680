import { baseConfig } from '@thinkacademy/website-base'

const {
  domainsLocaleMap,
  countryCodeMap,
  schoolCodeMap,
  fallbackLocale,
  validLocales,
} = baseConfig

/**
 * 通用配置
 */
export default {
  // 国家域名语言环境映射关系
  domainsLocaleMap,
  // 国家编码映射关系
  countryCodeMap,
  // 分校码映射关系
  schoolCodeMap,
  // 预设语言环境
  fallbackLocale,
  // 有效的语言环境编码
  validLocales,
  // 官网渠道编码
  channelCode: 'website',
  // 手工设置时区cookie名称
  timezoneCookie: '_official_timezone',
  // 自动定位时区提示气泡关闭标记名
  autoTimezoneNoticeFlagName: '_official_auto_timezone_notice',
  // 当前时区提示气泡关闭标记名
  currentTimezoneNoticeFlagName: '_official_current_timezone_notice',
}
