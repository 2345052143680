export default {
  coupon: {
    selectCoupons: 'クーポンを選ぶ',
    tips: 'おすすめ <span class="orange-text">{x} coupon(s)</span> が選択され, <span class="orange-text">{y}</span>が保存されます',
    couponTips: '<span class="orange-text">{x} coupon(s)</span>が選択され,  <span class="orange-text">{y}</span>が保存されます',
    noSelectedTips: '<span class="orange-text">{x} Coupon(s)</span> が使用可能',
    changeCard: '支払いカードを引き換え',
    automaticPay: '自動支払い',
    authorized: '自動的',
    unauthorized: '非自動的',
  },
}
