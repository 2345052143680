/**
 * 时区切换窗口
 */
export default {
  // 标题
  title: '選擇時區',
  // 当前时区描述
  describe: '您當前的時區是',
  // 无搜索结果提示
  noResultNotice: '搜索結果為空',
  // 切换定位刷新页面提示
  refreshNotice: '正在為您切換時區',
  // 搜索输入框提示文本
  searchInputPlaceholder: '搜索時區',
}
