<template>
  <div class="login-account-box">
    <div class="label-line">
      <div class="icon">
        <i class="iconfont iconicon_email_normal" />
      </div>
      <div class="text">
        {{ $t('login.login.loginForm.email.label') }}
      </div>
    </div>
    <el-input
      v-model="inputValue"
      class="login-input account-input new-login-font"
      :placeholder="$t('login.login.loginForm.email.placeholder')"
      :clearable="true"
      :disabled="disabled"
      @blur="inputBlurHandler"
      @focus="inputFocusHandler"
    />
  </div>
</template>
<script>
import { filterBlank } from 'common/utils.js';

export default {
  props: {
    disabled: {
      type: Boolean,
      default: () => false,
    },
    value: {
      type: [String, Number],
      default: '',
    },
    lastModified: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      inputValue: '',
    }
  },
  watch: {
    value(val) {
      this.inputValue = val
    },
    inputValue(val) {
      this.$emit('input', val)
    },
  },
  mounted() {
    this.init();
    if (this.disabled) {
      this.inputValue = this.value
    }
  },
  methods: {
    init() {
      if (Object.keys(this.lastModified).length && this.lastModified.type === 'email') {
        this.inputValue = this.lastModified.accountName
      }
    },
    inputBlurHandler() {
      this.value = filterBlank(this.value);
    },
    inputFocusHandler() {
      this.$emit('input-focus')
    },
  },
}
</script>
