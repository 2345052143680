<template>
  <div>
    <el-dialog
      v-if="show"
      :visible.sync="show"
      :top="top"
      :show-close="false"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      :width="dialogWidth"
      :custom-class="`common-dialog ${customClass}`"
      :append-to-body="appendToBody"
    >
      <div class="common-dialog-template">
        <div v-if="showHeader" class="dialog-header">
          <div
            v-if="showLeftIcon"
            class="icon-container left-icon"
            @click="backHandler"
          >
            <i class="iconfont icon-arrow-left" />
          </div>
          <div class="title">
            {{ dialogTitle }}
          </div>
          <!-- todo close 替换 -->
          <div
            v-if="showCloseIcon"
            class="icon-container right-icon"
            @click="closeHander"
          >
            <i class="iconfont icon-close-new" />
          </div>
        </div>
        <div class="dialog-body" :style="{background: bodyBg}">
          <slot name="body" />
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    showHeader: {
      default: true,
      type: Boolean,
    },
    dialogTitle: {
      default: '',
      type: String,
    },
    dialogWidth: {
      default: '512px',
      type: String,
    },
    show: {
      default: false,
      type: Boolean,
    },
    showCloseIcon: {
      default: true,
      type: Boolean,
    },
    showLeftIcon: {
      default: false,
      type: Boolean,
    },
    bodyBg: {
      default: '',
      type: String,
    },
    top: {
      default: '15vh',
      type: String,
    },
    appendToBody: {
      default: false,
      type: Boolean,
    },
    customClass: {
      default: '',
      type: String,
    },
  },
  data() {
    return {

    }
  },
  created() {
  },
  methods: {
    backHandler() {
      this.$emit('left-icon-handler')
    },
    closeHander() {
      // 通知父级更新弹出层显示状态
      this.$emit('update:show', false);
    },
  },

}
</script>

<style lang="scss">
.el-dialog.common-dialog {
  background: transparent;
  box-shadow: none;
  // position: absolute;
  // margin-top: 0 !important;
  // width: auto !important;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);

  .el-dialog__header { display: none; }

  .el-dialog__body {
    word-break: break-word;
    position: relative;
    padding: 0 !important;

    .dialog-body {
      width: 100%;
      background: #fafafa;
      border-radius: 0 0 8px 8px;
      padding: 20px 0;
    }

    .common-dialog-template .dialog-header {
      display: flex;
      justify-content: center;
      padding: 0 20px;
      height: 60px;
      background: rgba(255, 255, 255, 1);
      border-radius: 8px 8px 0 0;

      .title {
        font-size: 20px;
        font-weight: 500;
        color: rgba(69, 85, 113, 1);
        line-height: 60px;
      }

      .icon-container {
        position: absolute;
        top: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background: #f4f6fa;
        cursor: pointer;

        &.left-icon {
          left: 20px;
        }

        &.right-icon {
          right: 20px;
        }

        .iconfont {
          font-size: 20px;
          color: #a1abb8;
        }
      }
    }
  }
}

.register-popup {
  .common-dialog-template {
    .dialog-header .title {
      color: var(--txt-color-lv1) !important;
    }

    .dialog-body {
      background: #fff !important;
    }
  }
}
</style>
