import { oneApi } from 'config/api'
import Cookies from 'js-cookie'

export default {
  async trackEvent(context, eventArr) {
    const that = context
    const eventData = []
    for (let i = 0; i < eventArr.length; i += 1) {
      const eventParams = eventArr[i].params && eventArr[i].params instanceof Object && Object.keys(eventArr[i].params).length > 0 ? JSON.stringify(eventArr[i].params) : null
      eventData.push({
        event: eventArr[i].event,
        params: eventParams,
        userId: that.$store.state.ucenter.user.uid || '',
        devId: that.$store.state.deviceId,
        activityId: eventArr[i].activityId || null,
        projectId: eventArr[i].projectId || null,
        dataType: eventArr[i].type,
      })
    }
    const data = eventData
    await that.$axios.post(oneApi.dataUpload, data, { rewritePostBody: true })
  },

  /**
   * 投放渠道回传上报
   * @param {Object} options
   * options.gtagOptions Google上报配置
   * options.fbqOptions Facebook上报配置
   */
  launchReport(options) {
    try {
      // eslint-disable-next-line
      const gtag = window.gtag
      // eslint-disable-next-line
      const fbq = window.fbq
      // eslint-disable-next-line
      const ttq = window.ttq
      const gclid = Cookies.get('_official_gclid') || ''
      const fbclid = Cookies.get('_official_fbclid') || ''
      const ttclid = Cookies.get('_official_ttclid') || ''
      const { gtagOptions = {}, fbqOptions = {}, ttqOptions = {} } = options
      if (gtag && gclid && gtagOptions.name) {
        gtag('event', gtagOptions.name, gtagOptions.params || {})
      }
      if (fbq && fbclid && fbqOptions.name) {
        fbq('track', fbqOptions.name, fbqOptions.params || {})
      }
      if (ttq && ttclid && ttqOptions.name) {
        ttq.track(ttqOptions.name, ttqOptions.params || {})
      }
    } catch (error) {
      console.log(error)
    }
  },
}
