export default {
  register: {
    formPlaceholder: '下單前請完成報名信息的填寫',
    formTitle: '報名信息',
    submitName: '提交',
    requiredError: '請輸入此必填項',
    selectPlaceholder: '請選擇',
    inputPlaceholder: '請填寫',
  },
  priceInfo: {
    deduction: 'Automatic deduction after 48 hours',
  },
  orderCreate: {
    subscription: 'Purchase',
  },
}
