<template>
  <div v-drag class="chat-window" :class="isChatOpen ? 'opend' : 'closed'">
    <Header @close="closeChat" />
    <MessageList ref="messageList" :messages="messageList" @retry="hanfleRetrySend" />
    <UserInput ref="userBottom" :is-hide="isInteracting" @send="handleSend" />
  </div>
</template>

<script>
import mixin from './mixin.js';
import Header from './Header/web.vue'
import MessageList from './MessageList/web.vue'
import UserInput from './UserInput/web.vue'

export default {
  components: {
    Header,
    MessageList,
    UserInput,
  },
  mixins: [mixin],
  methods: {
    closeChat() {
      this.isChatOpen = false
    },
  },
}
</script>

<style lang="scss" scoped>
.chat-window {
  width: 400px;
  height: calc(100% - 160px);
  min-height: 300px;
  max-height: 704px;
  position: fixed;
  right: 15px;
  bottom: 70px;
  box-sizing: border-box;
  box-shadow: 0 7px 40px 2px rgba(148, 149, 150, 0.1);
  background: #fdfdfd;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  animation: fadeIn;
  animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
}

.chat-window.closed {
  opacity: 0;
  display: none;
}

@keyframes fadeIn {
  0% {
    display: none;
    opacity: 0;
  }

  100% {
    display: flex;
    opacity: 1;
  }
}
</style>
