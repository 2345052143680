/**
 * goods页面配置
 */
export default {
  recordedCourse: '錄播課',
  courseTypeList: [{
    value: 'live',
    label: '直播課',
  },
  {
    value: 'recorded',
    label: '錄播課',
  },
  ],
  // 录播课
  recordingDetail: {
    exceptiontip: '此課程已不再放開報名，請查看Think Academy提供的更多的課程',
    exceptiontip1: '此課程已不支持觀看，請查看學而思提供的其他課程',
    free: '免費',
    courseDetail: '錄播課介紹',
    courseDetails: '錄播課介紹',
    overview: '錄播課詳情',
    syllabus: '錄播課講次',
    teacher: '教師',
    validFor: '課程有效期',
    day: '天',
    days: '天',
    viewMore: '查看全部',
    lesson: '課程',
    lessons: '課程',
    readMore: '展開',
    hr: '小時',
    min: '分鐘',
    courseValidUntil: '此課程有效期至：',
    btnStatus: {
      forSale: '待售中',
      buyNow: '立即購買',
      soldOut: '已售罄',
      registered: '已報名',
      subscription: '添加訂閱',
    },
    expired: '過期',
    expiredTip: '此課程已失效',
    permanentTip: '此課程永久有效',
    start: '去上課',
  },
  goodsDetail: {
    year: 'Yearly',
    month: 'Monthly',
    discounts: '-20%',
  },
  subscriptionPeriod: {
    2: 'day',
    3: 'month',
    4: 'year',
  },
  subDate: {
    2: 'Day',
    3: 'Month',
    4: 'Year',
  },
  howToAttendClass: {
    title: '如何上課',
    firstStepLeft: '下載',
    firstStepRight: '進行上課',
    secondStep: '登陸後，點擊下方導航欄中的「上課」可找到已購買的課程',
    thirdStep: '找到想學的課次，點擊「進入」就可以開始學習啦',
  },
  orderResult: {
    viewMoreCourse: '查看更多課程',
    viewOrder: '查看訂單',
    viewCourse: '查看課程',
    cancel: '取消',
    howToAttendClass: '如何上課',
  },
  recordedVideoTips: {
    0: '播放',
    '0-1': '暫停',
    1: '快進10秒',
    2: '快退10秒',
    9: '截圖',
    11: '全屏',
    '11-1': '取消全屏',
  },
  recordedVideo: {
    reload: '重新加載',
    loadFailed: '加載失敗',
    loading: '加載中',
    saveShotScreen: '截图已保存',
    switchChannel: '切換線路',
  },
}
