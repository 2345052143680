/**
 * 课程筛选
 */
export default {
  more: 'ほか',
  retract: '撤回',
  reset: 'リセット',
  define: '確定する',
  all: 'すべて',
  resultsNum: 'フィルター{total}の結果',
}
