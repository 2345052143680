/* eslint-disable */
export const state = () => ({
  // 订单状态
  orderStatusFilter: '',
});

export const mutations = {
  /**
   * 设置订单状态筛选器的值
   * @param {state} state 
   * @param {status} status 
   */
  SET_ORDER_STATUS_FILTER (state, status) {
    state.orderStatusFilter = status;
  }
};