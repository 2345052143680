/* eslint-disable max-len */
/**
 * free assessment 页配置
 */

export default {
  banner: {
    title: `FREE 1-TO-1
    ASSESSMENT`,
    description: 'Get an in-depth assessment by our Master Teachers on your child’s Math knowledge, thinking abilities and skills. Identify learning gaps and receive a customised study plan to achieve excellence in School Math, Math Olympiads or GEP Math.',
    buttonName: 'Book Free Evaluation Now',
    buttonWidth: '240px',
    className: 'banner-sg',
  },
  yourWillGet: {
    title: 'What You’ll Get',
    introList: [
      {
        img: '/images/free-assessment/you-will-get-icon-1',
        title: '',
        description: 'An Online Math Assessment for your child to complete',
      },
      {
        img: '/images/free-assessment/you-will-get-icon-2',
        title: '',
        description: '40-min 1-to-1 Live Online Lesson by our Master Teacher',
      },
      {
        img: '/images/free-assessment/you-will-get-icon-3',
        title: '',
        description: '20-min Live Online Parent-Teacher discussion on your child’s strengths, and learning gaps',
      },
      {
        img: '/images/free-assessment/you-will-get-icon-4',
        title: '',
        description: 'Customised practical learning and study tips, strategies and extra practice questions',
      },
    ],
  },
  parentsComment: {
    title: 'What Our Parents Say',
    listData: [
      {
        name: 'Mrs Sim',
        comment: 'Very detailed – without school examinations, this helped me know exactly how my daughter is fairing for Math.',
      },
      {
        name: 'Elisa',
        comment: 'Comprehensive evaluation by Master Teacher Bel! Thank you for the great tips to support my child’s Math Olympiad DSA dreams.',
      },
      {
        name: 'Mabel',
        comment: 'Good understanding of my child and his learning gaps for Math Olympiad, especially because his school doesn’t provide Math Olympiad training.',
      },
    ],
  },
  apuGuide: {
    btnText: 'Book Now',
    apuLogo: 'apu-logo-sg',
  },
  bookDrawer: {
    title: `Please select your child’s
    2021 level`,
    listData: [
      // {
      //   department: 'Preschool',
      //   icon: 'icon-preschool-department',
      //   grades: [
      //     {
      //       value: 32,
      //       grade: 'Kindergarten 2',
      //       url: 'https://calendly.com/1v1-assessment/k2-2021',
      //     },
      //   ],
      // },
      {
        department: 'Primary',
        icon: 'icon-primary-department',
        grades: [
          {
            value: 33,
            grade: 'Primary 1',
            url: 'https://calendly.com/1v1-assessment/p1-2021',
          },
          {
            value: 34,
            grade: 'Primary 2',
            url: 'https://calendly.com/1v1-assessment/p2-2021',
          },
          {
            value: 35,
            grade: 'Primary 3',
            url: 'https://calendly.com/1v1-assessment/p3-2021',
          },
          {
            value: 36,
            grade: 'Primary 4',
            url: 'https://calendly.com/1v1-assessment/p4-2021',
          },
          {
            value: 37,
            grade: 'Primary 5',
            url: 'https://calendly.com/1v1-assessment/p5-2021',
          },
        ],
      },
    ],
  },
}
