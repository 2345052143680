<template>
  <div class="default-guide-login-wrapper">
    <div class="guide-login-text-container">
      Please log in.
    </div>
  </div>
</template>

<script>
import bus from 'common/bus.js';

export default {
  mounted() {
    this.init();
  },
  methods: {
    init() {
      if (!this.$store.state.ucenter.isLogin) {
        bus.$emit('login', {
          triggerSource: 'personal-page',
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.default-guide-login-wrapper {
  min-height: calc(100vh - 80px);
  text-align: center;

  .guide-login-text-container {
    padding-top: 20px;
    font-size: 18px;
    color: #666;
  }
}
</style>
