/* eslint-disable max-len */
/**
 * pricing-policy 页面配置
 */

export default {
  title: '收退款政策',
  content: `
  <h3>1. 報名及繳費規則：</h3>
  <ul>
    <li>春暑秋常規課程為恆常班，其他課程為短期班。特別班次以校區通知為準。</li>
    <li>插班安排:春季班第一堂後入學的，都視為插班安排。實際插班時段請參閱本校通告。</li>
    <li>按照教育局註冊學校條例，本校課程將以每月預繳形式向家長收取學費，先收費後上課。</li>
    <li>支付方式：於Think Academy HK官方網站 或Think Academy APP以信用卡、銀聯、支付寶方式繳費。</li>
    <li>教材費：僅適用於恆常班學生，每套書籍連同教材定額收取港幣200元書籍費，將在第一期學費繳交時一併收取。已繳付的費用，不得轉讓他人或其他課程。</li>
    <li>註1：春季班共三套書籍教材，暑期班共一套書籍教材，秋季班共二套書籍教材。</li>
  </ul>
  <h3>2. 上堂規則：</h3>
  <ul>
    <li>恆常班：可試聽、可旁聽，每位學生只允許一位家長旁聽。</li>
    <li>短期班：可旁聽，每位學生只允許一位家長旁聽。</li>
  </ul>
  <h3>3. 調課及轉班安排：</h3>
  <ul>
    <li>調課轉班原則：接受同年份、同學期、同年級、同科目、同班次、總金額相同、總課次相同、剩餘課次相同的班次之間互相辦理。</li>
    <li>調課轉班次數：春季班允許調課9次、暑期班4次、秋季班6次；轉班在有名額的情況下則不限次數。</li>
    <li>辦理渠道：Think Academy HK官方購課網站 或Think Academy APP</li>
  </ul>
  <h3>4. 退款政策：</h3>
  <ul>
    <li>退款計算方法：</li>
  </ul>
  <p>I.恆常班第二堂上課前退款，全額退還；第二堂上課後退款，則按剩餘課次退還。</p>
  <p>II.短期班及其他特殊班次之退費以本校開班的說明為準。</p>
  <ul>
    <li>退款說明：</li>
  </ul>
  <p>I.學生需要在下一堂課上課前退款，已過上課時間將不予退款。</p>
  <p>II.學生自身原因缺席將不予退款。</p>
  <p>III.學生如未領取書本及教材，書籍費可全額退回。</p>
  <p>IV.學生凡領取書本及教材後，書籍費將不予退回。</p>
  <p>V.凡書本及教材在本校寄出後，書籍費將不予退回。(僅適用於學而思在線課學生)</p>
  <p>VI.實際書本寄出時間以分校通知為準，學而思教育科技（香港）有限公司將保留最終解釋權。</p>
  <p>註2：該學期已結課後將不予退款。</p>
  <p>VII.處理退款時，本校不會取去學費收據的正本。學生或家長收到退款時，需要簽收確認。</p>
  <ul>
    <li>退款方式：</li>
  </ul>
  <p>退款時，剩餘款項會退還到對應的支付賬號或銀行戶口。(約7-12工作天到賬)</p>
 `,
}
