<template>
  <div :id="localeClass" class="platform__pc" :class="{'only-sg-free-trial-classes-page':$store.state.locale==='sg'&&this.$route.path==='/free-trial-class'}">
    <Header />
    <template v-if="$nuxt.isOffline">
      <div class="network-offline-container">
        <Exception tip-text="Loading failed, please check your connection." placeholder-class-name="system-error-img" :show-retry-btn="true" @retry="reload" />
      </div>
    </template>
    <template v-else>
      <template v-if="!isNeedLogin || isLogin">
        <nuxt />
      </template>
      <template v-else>
        <DefaultGuideLogin />
      </template>
      <ToolsGroup />
    </template>

    <Footer />
    <CookiesAccept v-if="$store.state.locale === 'uk'" />
    <CookiesStatement v-else-if="$store.state.locale != 'hk'" />
    <OnlineService v-if="$store.state.locale === 'sg' || $store.state.locale === 'uk'" />

    <no-ssr>
      <!-- 登录及绑定邮箱弹窗 -->
      <LoginDialog :show.sync="showLoginDialog" :success-url="loginSuccessUrl" :trigger-src="loginTriggerSrc" :login-trigger-source="loginTriggerSource" :is-group="isGroup" />
      <AppointmentDiagnosis :show.sync="showDiagnosis" :diagnosis-info="diagnosisInfo" />
      <BindEmailDialog :show.sync="showBindEmailDialog" :success-url="bindEmailSuccessUrl" :trigger-src="bindEmailTriggerSrc" />
      <CompleteStuInfoDialog ref="completeStuInfoDialog" :trigger-args="completeStuInfoTriggerArgs" />
      <UnpaidDialog ref="unpaidDialog" />
      <BindDialog />
    </no-ssr>
  </div>
</template>

<script>
import bus from 'common/bus';
import headMixin from 'mixins/layout/head';
import authPathNames from 'config/authPathNames';
import Header from '@/components/global/FeatureComponents/Header/web';
import Footer from '@/components/global/FeatureComponents/Footer/web'
import CookiesStatement from 'components/common/CookiesStatement/index.vue';
import CookiesAccept from 'components/common/CookiesAccept/index.vue';
import ToolsGroup from 'components/common/ToolsGroup/index.vue';
import OnlineService from 'components/common/OnlineService/index.vue'
import LoginDialog from 'components/login/LoginDialog/index.vue';
import BindEmailDialog from 'components/login/BindEmailDialog/index.vue';
import CompleteStuInfoDialog from 'components/login/CompleteStuInfoDialog/index.vue';
import DefaultGuideLogin from 'components/common/DefaultGuideLogin/index.vue';
import Exception from 'components/common/Exception/index.vue';
import UnpaidDialog from 'components/common/UnpaidDialog/index.vue';
import BindDialog from 'components/common/bindDialog/index.vue';
import AppointmentDiagnosis from 'components/appointmentDiagnosis/index.vue';

export default {
  components: {
    Header,
    Footer,
    CookiesStatement,
    CookiesAccept,
    ToolsGroup,
    OnlineService,
    LoginDialog,
    AppointmentDiagnosis,
    BindEmailDialog,
    CompleteStuInfoDialog,
    DefaultGuideLogin,
    Exception,
    UnpaidDialog,
    BindDialog,
  },
  mixins: [headMixin],
  data() {
    const isNeedLogin = authPathNames[this.$route.name];
    return {
      isNeedLogin,
      // 登录弹窗
      showLoginDialog: false,
      // 绑定邮箱弹窗
      showBindEmailDialog: false,
      // 完善学员信息弹窗
      showCompleteStuInfoDialog: false,
      loginSuccessUrl: '',
      bindEmailSuccessUrl: '',
      loginTriggerSrc: '',
      loginTriggerSource: '',
      bindEmailTriggerSrc: '',
      completeStuInfoTriggerArgs: null,
      isGroup: false,
      showDiagnosis: false,
      diagnosisInfo: {},
    }
  },
  computed: {
    /**
     * 当前语言环境样式名
     * 可通过此样式名叠加样式实现不同语言样式
     */
    localeClass() {
      return `locale__${this.$store.state.locale}`;
    },
    /**
     * 登录态
     */
    isLogin() {
      return this.$store.state.ucenter.isLogin;
    },
  },
  mounted() {
    // 页面刷新强制跳转到页面顶部
    this.redirect()

    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;

    if (!this.isLogin && this.isNeedLogin) this.login()

    // 登录弹窗
    bus.$on('login', (argsObj) => {
      if (argsObj && argsObj.triggerSrc) {
        this.loginTriggerSrc = argsObj.triggerSrc;
      }
      if (argsObj && argsObj.triggerSource) {
        this.loginTriggerSource = argsObj.triggerSource
      }
      if (argsObj && argsObj.successUrl) {
        this.loginSuccessUrl = argsObj.successUrl;
      }
      if (argsObj && argsObj.isGroup) {
        this.isGroup = argsObj.isGroup;
      }
      this.login();
    });
    // 预约诊断弹窗
    bus.$on('toShowDiagnosis', (argsObj) => {
      this.toShowDiagnosis(argsObj);
    });

    this.$nextTick(() => {
      // 完善学员信息弹窗
      bus.$on('complete-stu-info', (argsObj) => {
        if (argsObj) this.completeStuInfoTriggerArgs = argsObj;
        this.completeStuInfo(argsObj);
      });
      // 续报提醒弹窗
      bus.$on('openUnpaidDialog', () => {
        this.openUnpaidDialog();
      });
    })
  },
  destroyed() {
    // 页面销毁的时候取消监听
    bus.$off('login')
    bus.$off('toShowDiagnosis')
    bus.$off('complete-stu-info')
    bus.$off('openUnpaidDialog')
  },
  methods: {
    login() {
      this.showLoginDialog = true;
    },
    toShowDiagnosis(argsObj) {
      console.log('进1')
      this.diagnosisInfo = argsObj || {}
      this.showDiagnosis = true
    },
    bindEmail() {
      this.showBindEmailDialog = true;
    },
    completeStuInfo(argsObj) {
      if (this.$refs.completeStuInfoDialog) this.$refs.completeStuInfoDialog.openDialog(argsObj);
    },
    reload() {
      if (this.$nuxt.isOffline) return;
      window.location.reload();
    },
    openUnpaidDialog() {
      if (this.$refs.unpaidDialog) this.$refs.unpaidDialog.openDialog();
    },
    // 美分重定向
    redirect() {
      const { fullPath } = this.$route;
      const link = 'https://youthmath.org/'
      if (fullPath.includes('/world-think-cup')) {
        window.open(link, '_blank')
      } else if (fullPath.includes('/math-reward-challenge')) {
        window.location.href = link
      }
    },
  },
  head() {
    return {
      ...headMixin.head.call(this),
      htmlAttrs: {
        id: `style__${this.$store.state.betterme}`,
      },
      link: [{
        hid: 'icon',
        rel: 'icon',
        type: 'image/x-icon',
        href: this.$store.state.betterme === 'bettermeedu' ? 'https://download-pa-s3.thethinkacademy.com/static-pa/project/game/assets/favicon.ico' : '/favicon.ico',
      }],
    }
  },
}
</script>
<style lang="scss" scoped>
.only-sg-free-trial-classes-page {
  min-width: 1440px;
}
</style>
