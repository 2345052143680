<template>
  <div>
    <div v-if="isSend" class="verify-headline">
      <h3 class="login-headline font-semibold">
        {{ $t("login.login.loginForm.phone.verifyMainTitle") }}
      </h3>
      <div v-if="locale !== 'jp'" class="login-subtitle">
        {{ $t("login.login.loginForm.phone.verifySubTitle") }}
      </div>
      <div class="login-tip" :class="{ 'login-exchange': locale === 'jp' }">
        +{{ formData.phoneAreaCode.value }} {{ formData.phone }}
      </div>
      <div v-if="locale === 'jp'" class="login-exchange-end">
        {{ $t("login.login.loginForm.phone.verifySubTitle") }}
      </div>
    </div>
    <div v-else-if="isTop === 2" class="verify-headline">
      <h3 class="login-headline font-semibold">
        {{ $t('login.login.CreateANewAccount') }}
      </h3>
    </div>
    <!-- 手机号表单 -->
    <el-form
      ref="loginForm"
      class="login-new-form font-medium"
      :model="formData"
      :rules="loginRule"
    >
      <el-form-item v-show="!isSend" prop="phone">
        <PhoneInput
          v-model="formData.phone"
          :last-modified="lastModified"
          @areaChange="areaChange"
          @input-focus="reset('phone')"
        />
      </el-form-item>
      <el-form-item v-show="isSend" prop="captcha">
        <CodeInput
          ref="phoneCode"
          v-model="formData.captcha"
          :scene="scene"
          type="phone"
          :is-top="isTop"
          :phone="formData.phone"
          :area-code="areaCode"
          :cookie-name="cookieName"
          :before-send="codeBeforeSend"
          :send-success="codeSendSuccess"
          :send-error="codeSendError"
          @input-focus="reset('captcha')"
        />
      </el-form-item>
    </el-form>
    <!-- 可以切换 -->
    <div v-if="hasRepeat" class="has-repeat">
      {{ $t('login.login.ThisPhoneAlready') }}
      <span @click="toSignUp(3)">{{ $t('login.login.SignInNow') }}</span>
    </div>
    <div v-if="hasRepeat2" class="has-repeat">
      {{ $t('login.login.ItSeemsHaven') }}
      <span @click="toSignUp(2)">{{ $t('login.login.SignUpNow') }}</span>
    </div>
    <div
      v-if="canSwitchType"
      style="padding-top: 6px"
    >
      <div v-show="!isSend" class="update-button-container">
        <el-button
          type="primary"
          class="button login-button new-login-font"
          :loading="sendLoading"
          :disabled="!isPhoneContinue"
          @click="goSendCode('phoneCode')"
        >
          <span class="normal">
            {{ $t("login.login.loginForm.phone.continueBtn") }}
          </span>
        </el-button>
        <div v-if="isTop === 3" style="margin-top: 16px" />
        <div v-else class="update-divider">
          <div class="update-dividet-text">
            {{ $t("login.login.loginForm.changeTipText") }}
          </div>
        </div>
        <el-button
          class="
            button button-outline
            switch-button
            square-button
            new-login-font
          "
          :disabled="sendLoading"
          @click="switchType('email')"
        >
          <i class="iconfont iconicon_email_normal" />
          {{ $t("login.login.loginForm.phone.switchBtn") }}
        </el-button>
      </div>
      <div v-show="isSend" class="update-button-container">
        <el-button
          type="primary"
          class="button login-button new-login-font"
          :loading="isLoging"
          :disabled="!isLoginSubmitAvalible"
          @click="loginSubmit"
        >
          <span class="normal">
            {{ $t("login.login.loginForm.submitBtnText") }}
          </span>
        </el-button>
      </div>
      <div v-if="isTop === 3 && !isSend" class="sign-up-n">
        {{ $t('login.login.DontHaveAccoun') }} <span @click="toSignUp(1)">{{ $t('login.login.SignUpNow') }}</span>
      </div>
    </div>
    <!-- 不可以切换 -->
    <div v-else style="padding-top: 6px" :class="{ isTop3: isTop === 3 }">
      <div v-show="!isSend" class="update-button-container">
        <div
          :class="['arrow-button', !isPhoneContinue ? 'arrow-disabled' : '']"
          @click="goSendCode('phoneCode')"
        >
          <div class="arrow-img" />
        </div>
      </div>
      <div v-show="isSend" class="update-button-container paddingTop8">
        <el-button
          type="primary"
          class="button login-button new-login-font"
          :loading="isLoging"
          :disabled="!isLoginSubmitAvalible"
          @click="loginSubmit"
        >
          <span class="normal">
            {{ $t("bindForm.submitBtn") }}
          </span>
        </el-button>
      </div>
      <div class="tip font-medium">
        {{ $t("bindForm.tip") + contact }}
      </div>
      <div v-if="isTop === 3 && !isSend" class="sign-up-n">
        {{ $t('login.login.DontHaveAccoun') }} <span @click="toSignUp(1)">{{ $t('login.login.SignUpNow') }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import PhoneInput from 'components/login/newLoginForm/newPhoneInput.vue';
import CodeInput from 'components/login/newLoginForm/newCodeInput.vue';
import { oneApi } from '~/config/api';

export default {
  components: {
    PhoneInput,
    CodeInput,
  },
  props: {
    isLoging: {
      // 登陆按钮加载中
      type: Boolean,
      default: false,
    },
    canSwitchType: {
      type: Boolean,
      default: true,
    },
    cookieName: {
      type: String,
      default: 'login-phone-count',
    },
    scene: {
      type: Number,
      default: 1,
    },
    lastModified: {
      type: Object,
      default: () => ({}),
    },
    isTop: {
      type: Number,
      default: 2,
    },
  },
  data() {
    return {
      isChecking: false, // 检测中
      contact:
        this.$t('common.contactPhone') !== ''
          ? this.$t('common.contactPhone')
          : this.$t('common.contactEmail'),
      isSend: false, // 是否切换到code页面
      sendLoading: false, // 按钮加载中状态
      formData: {
        phone: '',
        captcha: '',
        phoneAreaCode: {
          value: '',
        },
      },
      loginRule: {
        phone: [
          {
            required: true,
            message: this.$t('login.login.loginForm.phone.rules.required'),
            trigger: 'blur',
          },
        ],
        captcha: [
          {
            required: true,
            message: this.$t('login.login.loginForm.captcha.rules.required'),
            trigger: 'blur',
          },
          {
            pattern: /^\d{6}$/,
            message: this.$t('login.login.loginForm.captcha.rules.format'),
            trigger: 'blur',
          },
        ],
      },
      phoneCodeList: this.$store.state.phoneCodeList,
      hasRepeat: false,
      hasRepeat2: false,
    };
  },
  computed: {
    isLoginSubmitAvalible() {
      return /^\d{6}$/.test(this.formData.captcha);
    },
    isPhoneContinue() {
      if (this.formData.phoneAreaCode.reg) {
        return this.formData.phoneAreaCode.reg.test(this.formData.phone);
      }
      return this.formData.phone !== '';
    },
    areaCode() {
      return this.formData.phoneAreaCode.value;
    },
    locale() {
      return this.$store.state.locale;
    },
  },
  watch: {
    lastModified() {
      if (
        Object.keys(this.lastModified).length
        && this.lastModified.type === 'phone'
      ) {
        // eslint-disable-next-line prefer-destructuring
        const a = this.phoneCodeList.filter(
          (item) => item.value === this.lastModified.countryCallingCode,
        )[0];
        this.$nextTick(() => {
          this.formData.phoneAreaCode = a;
        });
      }
    },
  },
  methods: {
    init() {
      if (
        Object.keys(this.lastModified).length
        && this.lastModified.type === 'phone'
      ) {
        // eslint-disable-next-line prefer-destructuring
        const a = this.phoneCodeList.filter(
          (item) => item.value === this.lastModified.countryCallingCode,
        )[0];
        this.$nextTick(() => {
          this.formData.phoneAreaCode = a;
        });
      }
      this.$refs.phoneCode.init();
      this.formData.captcha = '';
      this.isSend = false;
    },
    /**
     * 触发登录按钮
     */
    loginSubmit() {
      this.$refs.loginForm.validate((isValid) => {
        if (isValid) {
          this.$emit('submit', this.formData);
        }
      });
    },
    // 切换表单
    switchType() {
      this.formData.phone = '';
      this.$refs.loginForm.clearValidate();
      this.$emit('switch');
    },
    // 触发发送验证码
    goSendCode(codInputName) {
      if (this.canSwitchType) {
        // 登陆弹窗触发该方法，加入埋点
        this.$emit('clickContinue');
      }
      if (this.sendLoading || !this.isPhoneContinue) {
        return undefined;
      }
      this.sendLoading = true;
      // 加一个已注册的验证
      if (this.isTop === 2) {
        this.hasRepeat = false;

        this.$refs.loginForm.validateField('phone', async (errMsg) => {
          if (errMsg !== '') {
            return;
          }
          const data = {
            type: 0,
            countryCallingCode: this.formData.phoneAreaCode.value,
            contactInfo: this.formData.phone,
          };
          console.log('校验手机号30');
          const res = await this.$axios.post(oneApi.contactVerify, data, {
            rewritePostBody: true,
          });
          this.sendLoading = false
          if (Number(res.code) == 100101) {
            this.hasRepeat = true;
            return;
          } if (Number(res.code) !== 0) {
            this.$ElMessage({
              type: 'error',
              message: res.msg,
            });
            return;
          }
          return this.$refs[codInputName].sendCodeHanlder();
        });
      } else if (this.isTop === 3) {
        this.hasRepeat2 = false;

        this.$refs.loginForm.validateField('phone', async (errMsg) => {
          if (errMsg !== '') {
            return;
          }
          const data = {
            type: 0,
            countryCallingCode: this.formData.phoneAreaCode.value,
            contactInfo: this.formData.phone,
          };
          const res = await this.$axios.post(oneApi.contactVerify, data, {
            rewritePostBody: true,
          });
          this.sendLoading = false
          if (Number(res.code) === 0) {
            this.hasRepeat2 = true;
            return;
          } if (Number(res.code) !== 100101) {
            this.$ElMessage({
              type: 'error',
              message: res.msg,
            });
            return;
          }
          return this.$refs[codInputName].sendCodeHanlder();
        });
      }
    },
    // 手机区号change
    areaChange(obj) {
      if (!obj) return;
      if (typeof obj === 'object') {
        this.formData.phoneAreaCode = obj;
      } else {
        const a = this.phoneCodeList.filter(
          (item) => item.value === this.lastModified.countryCallingCode,
        )[0];
        this.$nextTick(() => {
          this.formData.phoneAreaCode = a;
        });
      }

      this.setPhonePattern();
      if (this.formData.phone === '') return;
      this.$refs.loginForm.validateField('phone');
    },
    /**
     * 设置手机号校验正则
     */
    setPhonePattern() {
      const { reg } = this.formData.phoneAreaCode;
      this.loginRule.phone[1] = {
        pattern: reg,
        message: this.$t('login.login.loginForm.phone.rules.format'),
        trigger: 'blur',
      };
    },
    // 验证码发送前
    codeBeforeSend(callback) {
      this.$refs.loginForm.validateField('phone', async (errMsg) => {
        if (errMsg !== '') {
          return;
        }
        this.sendLoading = true;
        // 绑定联系方式时需要验证联系方式是否重复
        if (this.scene === 3 && !this.isChecking) {
          const data = {
            type: 0,
            countryCallingCode: this.formData.phoneAreaCode.value,
            contactInfo: this.formData.phone,
          };
          this.isChecking = true;
          const res = await this.$axios.post(oneApi.contactVerify, data, {
            rewritePostBody: true,
          });
          this.isChecking = false;
          if (Number(res.code) !== 0) {
            this.$ElMessage({
              type: 'error',
              message: res.msg,
            });
            this.sendLoading = false;
            return;
          }
        }
        callback();
      });
    },
    // 发送成功
    codeSendSuccess() {
      this.sendLoading = false;
      this.isSend = true;
      let valueCode = '';
      // 修改dialog文案使用
      valueCode = `+${this.formData.phoneAreaCode.value} ${this.formData.phone}`;
      this.$emit('titleChange', {
        isSend: this.isSend,
        valueCode,
      });
    },
    codeSendError() {
      this.sendLoading = false;
    },
    reset(prop) {
      if (this.$refs.loginForm && this.$refs.loginForm.clearValidate) {
        this.$refs.loginForm.clearValidate(prop);
      }
    },
    toSignUp(num) {
      this.hasRepeat = false
      this.hasRepeat2 = false
      this.$emit('toSignIn', num);
    },
  },
};
</script>
<style lang="scss" scoped>
.arrow-button {
  width: 56px;
  height: 56px;
  background: var(--txt-color-link);
  border-radius: 28px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 16px;
  cursor: pointer;

  &:hover {
    background: var(--txt-color-link2);
  }

  .arrow-img {
    background: url("~assets/images/common/next-arrow.png") no-repeat;
    background-size: 100%;
    width: 24px;
    height: 24px;
  }
}

.arrow-disabled {
  opacity: 0.2;
}

.paddingTop8 {
  padding-top: 8px;
}

.tip {
  font-size: 12px;
  color: var(--txt-color-lv2);
  line-height: 14px;
  padding-bottom: 20px;
  text-align: center;
}
.has-repeat {
  font-size: 12px;
  font-family: Montserrat-Medium, Montserrat;
  font-weight: 500;
  color: #d44e4e;
  line-height: 15px;
  margin-top: -12px;
  margin-bottom: -3px;
  span {
    color: var(--txt-color-link);
    text-decoration: underline;
    cursor: pointer;
    &:hover {
      color: var(--txt-color-link2);
    }
  }
}
.sign-up-n {
  font-size: 12px;
  font-family: Montserrat-Medium, Montserrat;
  font-weight: 500;
  color: #7a7a7a;
  line-height: 18px;
  margin-top: 20px;
  cursor: pointer;
  position: absolute;
  bottom: 20px;
  left: 24px;
  span {
    color: var(--txt-color-link);
    text-decoration: underline;
    &:hover {
      color: var(--txt-color-link2);
    }
  }
}
</style>
