/* eslint-disable max-len */
/**
 * terms-of-use 页面配置
 */

export default {
  title: '⽤⼾協議',
  content: `<p>本協定是由使⽤者（以下簡稱“你”）與學⽽思（以 下簡稱“本平臺”）之間就學⽽思所提供的產品和服務等 相關事宜（包括本平臺正在提供和將來可能向你提供的 本平臺的產品和本平臺的服務，以下統稱為“產品和服 務”）所訂⽴的協定。請你務必在點擊同意本協議之 前，審慎閱讀、充分理解各條款內容，特別是免除或者 限制責任的條款、法律適⽤和爭議解決條款。免除或者 限制責任的條款將以粗體標識，你應重點閱讀。在你點擊“同意”按鈕後，即表示你已充分閱讀、理解且接受本 協定的全部內容，並與本平臺達成⼀致，本協定即構成 對雙⽅有約束⼒的法律檔。 本平臺有權在必要時修改本協議條款，並以站內公 示的⽅式通知到你。你可以隨時在本平臺查閱修改後的 最新版本協議。如你不同意修改後的協議，你有權終⽌ 對本平臺的授權。本協議更新後，如果你繼續使⽤相應 權益，即視為你已接受修改後的協議。</p>
  <p>第1條本協議條款的確認和接納</p>
  <p>1.1 本協議條款是處理雙⽅權利義務的契約，始終有效，法律另有強制性規定或雙⽅另有特別約定的，依其規定；</p>
  <p>1.2 使⽤者同意本協議，即視為使⽤者確認⾃⼰具有享受 本平臺服務、下單購物等相應的權利能⼒和⾏為能⼒，能夠獨⽴承擔法律責任；</p>
  <p>1.3 學⽽思保留在中華⼈⺠共和國⼤陸地區法施⾏之法律 允許的範圍內獨⾃決定拒絕服務、關閉使⽤者帳⼾、清 除或編輯內容或取消訂單的權利。1.4本協議所列明的條款，並不能完全涵蓋所有的權利和 義務。本平臺公佈其他聲明、規則、隱私權政策為本協定的補充協定如《使⽤者資訊保護及隱私政策》，與本 協議不可分割且具有同等法律效⼒。</p>
  <p>第2條本平臺服務內容</p>
  <p>2.1 課程信息</p>
  <p>本平臺上的課程價格等資訊隨時都有可能發⽣變動，本 平臺不作特別通知。網站上課程數量龐⼤，由於眾所周 知的互聯網技術因素等客觀原因存在，本平臺網⾴顯示 的資訊可能會有⼀定的滯後性或差錯，對此情形你知悉 並理解。</p>
  <p>2.2 課程信息</p>
  <p>在你下訂單時，請你仔細確認所購課程的名稱、價格、 聯繫位址、電話、收貨⼈等資訊。收貨⼈與⽤⼾本⼈不 ⼀致的，收貨⼈的⾏為和意思表示視為⽤⼾的⾏為和意
  思表示，⽤⼾應對收貨⼈的⾏為及意思表示的法律後果 承擔連帶責任。</p>
  <p>2.3 課程退費</p>
  <p>2.3.1 你需在下次課開課之前辦理退費，已過課時或已結 課不予退費；</p>
  <p>2.3.2 由於你⾃身原因缺課不予退費；</p>
  <p>2.3.3 線下課程退費需由你或監護⼈親⾃到服務中⼼前臺 辦理，同事需出示有效證件（身份證、護照），學員電 ⼦聽課證。</p>
  <p>2.4結束服務結束使⽤者服務後，使⽤者使⽤網路服務的權利⾺上終 ⽌。從那時起，⽤⼾沒有權利，本網站也沒有義務傳送 任何未處理的資訊或未完成的服務給使⽤者或協⼒廠
  商。</p>
  <p>第3條⽤⼾註冊資格</p>
  <p>你確認，在你開始註冊程式使⽤本平臺服務前，你 應當具備中華⼈⺠共和國法律規定的與你的⾏為相適應 的⺠事⾏為能⼒。若你不具備前述與你⾏為相適應的⺠ 事⾏為能⼒，則你及你的監護⼈應依照法律規定承擔因 此⽽導致的⼀切後果。此外，你還需確保你不是任何國 家、地區或國際組織實施的貿易限制、經濟制裁或其他 法律法規限制的物件，也未直接或間接為前述對象提供 資⾦、商品或服務，否則你應當停⽌使⽤本平臺服務， 同時你理解違反前述要求可能會造成你無法正常註冊及 使⽤本平臺服務。</p>
  <p>第4條使⽤者資訊</p>
  <p>4.1 ⽤⼾應⾃⾏誠信向本平臺提供註冊資料，使⽤者同意 其提供的資料真實、準確、完整、合法有效，使⽤者註 冊資料如有變動的，應及時更新其註冊資料。如果使⽤ 者提供的註冊資料不合法、不真實、不準確、不詳盡 的，⽤⼾需承擔因此引起的相應責任及後果，並且學⽽ 思保留終⽌⽤⼾使⽤學⽽思各項服務的權利；</p>
  <p>4.2 ⽤⼾在本平臺進⾏流覽、購買課程等活動時，涉及 ⽤⼾真實姓名/名稱、通信地址、聯繫電話、電⼦郵箱 等隱私資訊的，本平臺將予以嚴格保密，除⾮得到⽤⼾ 的授權或法律另有規定，本平臺不會向外界披露使⽤者 隱私資訊；</p>
  <p>4.3 ⽤⼾註冊成功後，將產⽣⽤⼾名和密碼等帳⼾資 訊，你可以根據本平臺規定改變你的密碼。⽤⼾應謹慎 合理的保存、使⽤其⽤⼾名和密碼。⽤⼾若發現任何⾮法使⽤⽤⼾帳號或存在安全性漏洞的情況，請⽴即通知 本平臺；⽤⼾名或昵稱中不能含有任何侮辱、誹謗、淫穢或暴⼒等侵害他⼈合法權益或違反公序良俗的詞語。 如你違反前述規定，本平臺有權隨時限制或拒絕你使⽤ 該帳號，甚⾄註銷該帳號。</p>
  <p>4.4 使⽤者務必確保⽤⼾名及密碼的安全性。如果丟 失，造成的損失將由本⼈承擔全部後果。使⽤者對利⽤該學號及密碼所進⾏的⼀切活動負全部責任；因此所衍 ⽣的任何損失或損害，學⽽思不承擔任何責任；</p>
  <p>4.5 ⽤⼾不得將帳號借予他⼈或多⼈使⽤。如果發現或 者有正當的理由懷疑多⼈共⽤⼀個帳號的現象，本平臺保留結束或終⽌該帳號的權利，同時⽤⼾應承擔由此產⽣的全部責任，並與實際使⽤⼈承擔連帶責任；</p>
  <p>4.6 ⽤⼾同意授權本平臺擁有通過郵件、短信電話等形式，向在本平臺註冊、購物使⽤者、收貨⼈發送訂單資 訊、學習資訊、促銷活動等告知資訊的權利</p>
  <p>4.7 ⽤⼾可以在任何時候，通過平臺提供的服務或平臺 公佈的其他途徑，更新或修改⽤⼾註冊時所提供的資料。</p>
  <p>第5條⽤⼾的權利</p>
  <p>5.1 使⽤者可以根據本協定以及平臺更新和公佈的其他規 則使⽤平臺提供的產品和服務。</p>
  <p>5.2 使⽤者有權在使⽤平臺提供的產品和服務期間監督平臺及平臺的⼯作⼈員是否按照平臺所公佈的標準向使⽤者提供產品和服務，也可以隨時向平臺提出與平臺的 產品和服務有關的意⾒和建議。</p>
  <p>5.3 如果使⽤者不同意本協議條款，或對平臺後來更新的條款有異議，或對平臺所提供的產品和服務不滿意，使⽤者可以選擇停⽌使⽤平臺的產品和服務。如果使⽤者選擇停⽌使⽤平臺的產品和服務，則平臺即不再對⽤⼾承擔任何責任和義務，同時⽤⼾有保管已註冊帳號密
  碼不外泄的義務。</p>
  <p>第6條⽤⼾承擔的責任</p>
  <p>6.1 ⽤⼾同意按照平臺不時發佈、變更和修改的本協定條款及其他規則接受並使⽤平臺的產品和服務，使⽤者不得通過不正當的⼿段或其他不公平的⼿段使⽤平臺的產品和服務或參與平臺活動。</p>
  <p>6.2 不得⼲擾平臺正常地提供產品和服務，包括但不限於：</p>
  <p>6.2.1 攻擊、侵⼊平臺的伺服器或使伺服器超載；</p>
  <p>6.2.2 破解、修改平臺提供的⽤⼾端程式；</p>
  <p>6.2.3 攻擊、侵⼊平臺的伺服器端程式；</p>
  <p>6.2.4 製作、發佈、使⽤、傳播任何形式的妨礙平臺正常運⾏的輔助⼯具或程式；</p>
  <p>6.2.5 不得在公開場合或互聯網環境中公開程式的漏洞和錯誤（Bug）；</p>
  <p>6.2.6 不合理地⼲擾或阻礙他⼈使⽤平臺所提供的產品和服務。</p>
  <p>6.3 你使⽤本服務時，請勿隨意透露⾃⼰的各類財產帳⼾、銀⾏卡、信⽤卡、社交號碼及對應密碼等重要資料，否則由此帶來的任何損失由你⾃⾏承擔。你應對你帳⼾項下的所有⾏為結果（包括但不限於線上簽署各類協定、發佈資訊、購買商品及服務及披露資訊等）負
  責。</p>
  <p>6.4 ⽤⼾需對⾃⼰帳號中的所有活動和事件負責。使⽤者須遵守有關互聯網資訊發佈的有關法律、法規及通常適⽤的互聯網⼀般道德和禮儀的規範，使⽤者將⾃⾏承擔使⽤者所發佈的資訊內容的責任。特別的，⽤⼾不得發佈或實施任何違法違規或任何危害電腦網路安全的⾏
  為，包括但不限於：</p>
  <p>6.4.1 反對憲法所確定的基本原則的；</p>
  <p>6.4.2 危害國家安全，洩露國家秘密，顛覆國家政權，破壞國家統⼀的；</p>
  <p>6.4.3 損害國家榮譽和利益的；</p>
  <p>6.4.4 煽動⺠族仇恨、⺠族歧視，破壞⺠族團結的；</p>
  <p>6.4.5 破壞國家宗教政策，宣揚邪教和封建迷信的；</p>
  <p>6.4.6 散佈謠⾔，擾亂社會秩序，破壞社會穩定的；</p>
  <p>6.4.7 散佈淫穢、⾊情、賭博、暴⼒、兇殺、恐怖或者教
  唆犯罪的；</p>
  <p>6.4.8 侮辱或者誹謗他⼈，侵害他⼈合法權益的；</p>
  <p>6.4.9 含有法律、⾏政法規禁⽌的其他內容的；</p>
  <p>6.4.10 侵害他⼈智慧財產權、商業機密權、肖像權、隱私等合法權利的內容；</p>
  <p>6.4.11 違反⼈⽂道德、⾵俗習慣的；</p>
  <p>6.4.12 企圖探查、掃描、測試雲市場或網路的弱點或其它實施破壞網路安全的⾏為；</p>
  <p>6.4.13 企圖⼲涉、破壞雲市場或網站的正常運⾏，或傳播惡意程式或病毒以及其他破壞⼲擾正常網路資訊服務；<p>6.4.14利⽤BUG（⼜叫“漏洞”或者“缺陷”）來獲得不正當的利益或者利⽤互聯網或其他⽅式將BUG公之於 眾；</p>
  <p>6.4.15 其他違法違規的⾏為。</p>
  <p>6.5 你可以根據需要⾃⾏決定購買、使⽤本平臺中任意的產品。你在購買、使⽤具體產品之前，請審慎瞭解具體產品的功能、要求、收費及退費規則等詳細內容，如果你對具體產品有異議的，則請勿以任何⽅式購買或使⽤。</p>
  <p>6.6 本平臺中的產品為獨⽴開發或依法獲得相關權利⼈授權，通過本平臺向⽤⼾進⾏宣傳、推廣和銷售，受中華⼈⺠共和國著作權法及國際版權條約和其他智慧財產權法及條約的保護。你應尊重服務商的智慧財產權，未經授權不得實施下列任何⾏為：</p>
  <p>6.6.1 對產品進⾏反向⼯程、反向彙編、反向編譯等；</p>
  <p>6.6.2 刪除產品及其他副本上所有關於商標、著作權等權利資訊及內容；</p>
  <p>6.6.3 複製、修改、連結、轉載、彙編、傳播，建⽴鏡像網站、擅⾃借助產品發展與之有關的衍⽣產品、作品等；</p>
  <p>6.6.4 實施其他侵害服務商合法權益的⾏為；</p>
  <p>6.7 使⽤者使⽤本平臺服務需要⾃備上網設備：包括並不限於電腦或者其他上網終端、數據機及其他必備的上網裝置；⽤⼾需⾃⾏⽀付上網費⽤：包括並不限於網路接⼊費、上網設備租⽤費、⼿機流量費等；</p>
  <p>6.8 必須遵守中華⼈⺠共和國的法律、法規、規章、條例，以及其他具有法律效⼒的規範不使⽤網路服務做⾮法⽤途；
  <p>6.9 不⼲擾或混亂網路服務；</p>
  <p>6.10 使⽤者使⽤本平臺的服務時不得侵犯學⽽思所有著作權、版權；</p>
  <p>6.11 不得在本平臺內發佈違法資訊，使⽤者對其發佈的內容單獨承擔法律責任；</p>
  <p>6.12 本平臺保有刪除站內各類不符合法律政策或不真實的資訊內容⽽無須通知使⽤者的權利；</p>
  <p>6.13 若⽤⼾未遵守以上規定的，本平臺有權作出獨⽴判斷並採取暫停或關閉⽤⼾帳號等措施。⽤⼾須對⾃⼰在網上的⾔論和⾏為承擔法律責任。</p>
  <p>6.14 使⽤者同意本平臺因教學需要變更上課時間及授課⽼師。</p>
  <p>第7條免責聲明</p>
  <p>7.1 本平臺不保證（包括但不限於）：</p>
  <p>7.1.1 本平臺不受⼲擾，及時、安全、可靠或不出現錯誤；</p>
  <p>7.1.2 使⽤者經由本服務取得的任何產品、服務或其他材料符合使⽤者的期望；</p>
  <p>7.1.3 使⽤者使⽤經由本服務下載的或取得的任何資料，其⾵險⾃⾏負擔；</p>
  <p>7.1.4 由於地震、颱⾵、洪⽔、⽕災、戰爭、政府禁令以及其他不能預⾒並且對其發⽣和後果不能防⽌或避免的不可抗⼒或互聯網上的駭客攻擊事件，致使影響本服務條款的履⾏，本平臺不承擔責任。</p>
  <p>7.2你理解並同意：在使⽤本服務的過程中，可能會遇到不可抗⼒、技術⾵險等因素，使本服務發⽣中斷，對於下述原因導致的合同履⾏障礙、履⾏瑕疵、履⾏延後或履⾏內容變更、資料丟失等情形，平臺在法律允許的最⼤範圍內免責：</p>
  <p>7.2.1 因⾃然災害、罷⼯、暴亂、戰爭、政府⾏為、司法⾏政命令等不可抗⼒因素。</p>
  <p>7.2.2 你或平臺的電腦軟體、系統、硬體出現故障或其他原因導致你無法使⽤平臺。</p>
  <p>7.2.3 因電⼒供應故障、通訊網路故障（包括但不限於電⼦通訊傳達失敗或延時、⽤於電⼦通訊的電腦程式對電⼦通訊的攔截或操縱）等公共服務因素或你⾃身因素（包括但不限於你操作不當、通過⾮平臺授權的⽅式使⽤本服務）或第三⼈因素（包括但不限於受到電腦病
  毒、⽊⾺或其他惡意程式、駭客攻擊的破壞、顧客的錯誤下單等錯誤操作）。</p>
  <p>7.2.4 在平臺已盡善意管理的情況下，因常規或緊急的設備與系統維護、設備與系統故障、缺陷、網路資訊與資料安全、技術⾵險等因素。</p>
  <p>7.2.5 其他平臺無法控制或合理預⾒的情形。</p>
  <p>7.3 如果平臺發現了因系統故障或其他原因導致的處理錯誤，無論有利於平臺還是有利於⽤⼾，平臺都有權在根據本協定規定通知使⽤者後糾正該錯誤、回轉/回檔相關交易或資料。使⽤者理解並同意，使⽤者因前述處理錯誤⽽多付或少付的款項均不計利息，平臺不承擔因
  前述處理錯誤⽽導致的任何損失或責任（包括使⽤者可能因前述錯誤導致的利息、匯率等損失），但因平臺惡意⽽導致的處理錯誤除外。</p>
  <p>7.4 平臺僅對因平臺原因給⽤⼾造成的直接、實際損失依法承擔相應的賠償責任，不對任何間接損失、懲罰性賠償承擔責任。</p>
  <p>7.5 平臺對使⽤者同意本協定內容後所享有的權益擁有調整及最終解釋權。</p>
  <p>第8條商業秘密</p>
  <p>本條所述“商業秘密”包括但不限於：</p>
  <p>8.1 與本平臺的產品或服務有關的任何/所有協定、往來傳真或郵件；</p>
  <p>8.2 與本平臺的產品或服務有關的客⼾資料、產品、商業計畫、⾏銷資訊、投資資訊、財務狀況、圖紙、技術決竅、電腦程式、研究及其他資料；</p>
  <p>8.3 屬於協⼒廠商但披露⽅承擔保密義務的資訊；</p>
  <p>8.4 你與我們均瞭解並同意商業秘密的保密期限⾃知曉之⽇起⾄商業秘密進⼊公知領域⽌。</p>
  <p>第9條版權聲明</p>
  <p>9.1 學⽽思及其關聯公司是本平臺的智慧財產權權利⼈。本平臺的⼀切著作權、商標權、專利權、商業秘密等智慧財產權，以及與本平臺相關的所有資訊內容（包括但不限於⽂字、圖⽚、⾳訊、視頻、圖表、介⾯設計、版⾯框架、有關資料或電⼦⽂檔等）均受中華⼈⺠共和國法律法規和相應的國際條約保護，xx享有上述智慧財產權，但相關權利⼈依照法律規定應享有的權利除外。</p>
  <p>9.2 未經平臺書⾯或相關權利⼈書⾯同意，你不得⾃⾏或許可任何協⼒廠商實施、利⽤、轉讓上述智慧財產權。</p>
  <p>第10條服務條款的修改</p>
  <p>本平臺有權在必要時修改本協定服務條款以及各單項服務的相關條款。使⽤者在享受單項服務時，應當及時查閱瞭解修改的內容，並⾃覺遵守本協定服務條款以及該單項服務的相關條款。</p>
  <p>第11條⾵險承擔</p>
  <p>使⽤者同意使⽤平臺提供的服務是出於使⽤者的個⼈意願，並願⾃負任何⾵險，包括但不限於⽤⼾因執⾏平臺所提供的服務程式或資料⽽導致使⽤者或使⽤者所使⽤的電腦系統受到損害或發⽣任何資料的流失等。</p>
  <p>第12條損害賠償</p>
  <p>使⽤者若違反本協議或相關法令，導致平臺的⺟公司、⼦公司、附屬公司、關係企業、受雇⼈、代理⼈及其他⼀切相關履⾏輔助⼈員因此受到損害或⽀出⼀切衍⽣費⽤（包括但不限於⽀付上述⼈⼠須就⽤⼾的違反⾏為所進⾏的⼀切辯護或索償訴訟及相關和解之法律費⽤），⽤⼾應負擔損害賠償及費⽤承擔責任。</p>
  <p>第13條公司擁有終⽌權</p>
  <p>13.1 本平臺有權以線上公告、站內信等任何⽅式，通知終⽌運營本平臺提供服務⽽無需承擔任何責任。</p>
  <p>13.2 使⽤者應確實遵守本協議及有關法律命令的規定。平臺對於使⽤者是否違反本協議擁有最終決定權。若平臺認定使⽤者違反本協議或任何法令，平臺有權在無需向使⽤者進⾏事先通知的情況之下，⽴即暫停及終⽌或刪除⽤⼾帳號與使⽤者帳號中的所有相關資料、檔案及
  任何記錄，以及取消、停⽌、限制⽤⼾的使⽤資格。如有系統故障、Bug、程式出錯等等問題，平臺有權把平臺的資料還原到⼀定⽇期，以維護平臺的正常運⾏。⽤⼾不得因此要求補償或賠償。</p>
  <p>13.3 為了向使⽤者提供產品和服務，平臺可能會需要定期或不定期地對有關的伺服器或伺服器端程式或平臺所提供的產品和服務的相關官⽅網站進⾏維護，該維護可能造成服務的中斷，並且該中斷可能沒有事先的通知對於由此引起的中斷平臺不承擔任何責任。</p>
  <p>13.4 ⽤⼾已經瞭解並同意對於因平臺、平臺的合作⽅或電信網路系統軟硬體設備的故障、失靈或⼈為操作的疏失⽽全部或部分中斷、暫時無法使⽤、遲延並造成平臺提供產品和服務的停⽌或中斷的，平臺不承擔任何責任；對於因他⼈侵⼊平臺的網路或系統，篡改、刪改或
  偽造、變造網站和平臺資料或資料⽽造成平臺的產品和服務的停⽌或中斷，平臺也不承擔任何責任。</p>
  <p>13.5 本平臺始終在不斷變更和改進服務。本平臺可能會增加或刪除功能，也可能暫停或徹底停⽌某項服務。⽤⼾同意本平臺有權⾏使上述權利且不需對⽤⼾或協⼒廠商承擔任何責任。</p>
  <p>第14條損害賠償</p>
  <p>使⽤者若違反本協議或相關法令，導致平臺的⺟公司、⼦公司、附屬公司、關係企業、受雇⼈、代理⼈及其他 ⼀切相關履⾏輔助⼈員因此受到損害或⽀出⼀切衍⽣費⽤（包括但不限於⽀付上述⼈⼠須就⽤⼾的違反⾏為所進⾏的⼀切辯護或索償訴訟及相關和解之法律費⽤），
  ⽤⼾應負擔損害賠償及費⽤承擔責任。</p>
  <p>第15條法律適⽤、管轄和其他</p>
  <p>15.1 本協議的⽣效、履⾏、解釋及爭議的解決均適⽤中華⼈⺠共和國法律。你因使⽤本平臺服務所產⽣及與本平臺服務有關的爭議，由本平臺與你協商解決。協商不成時，任何⼀⽅均可向被告所在地有管轄權的⼈⺠法院提起訴訟。</p>
  <p>15.2 本協議所有條款的標題僅為閱讀⽅便，不作為本協定涵義解釋或限制的依據。15.3本協議任⼀條款被視為廢⽌、無效或不可執⾏，該條應視為可分的且並不影響本協議其餘條款的有效性及可執⾏性。</p>
  <p>15.4 本協議可能存在包括中⽂、英⽂在內的多種語⾔的版本，如果存在中⽂版本與其他語⾔的版本相衝突的地⽅，以中⽂版本為准。</p>
  `,
}
