/**
 * 用户基础数据查询
 */
export default async function ({
  store, app, req,
}) {
  // 客户端环境不执行
  if (process.client) return;
  if (req.ctx.cookies.get('_official_token')) {
    await store.dispatch('ucenter/queryUserBasicInfo', { $axios: app.$axios, userData: { fillInAttention: false } });
  } else {
    store.commit('ucenter/SET_IS_LOGIN', false);
  }
}
