/**
 * goods页面配置
 */
export default {
  goodsDetail: {
    productDetails: 'Product Details',
    productOverview: 'Product Overview',
    buyNow: 'Buy Now',
    stock: 'Stock',
    quantity: 'Quantity',
    onlyStock: 'Only {0} left in stock',
    btnStatus: {
      forSale: 'Coming Soon',
      buyNow: 'Buy Now',
      soldOut: 'Sold Out',
    },
    retryBtnText: 'View Our Courses',
    exceptiontip: 'This product is no longer available. View more services provided by Think Academy.',
    outOfStockError: 'This product is currently out of stock.',
    purchasedCourse: "You've purchased this course.",
    exceeds: 'The number of items you ordered exceeds the number of items in stock ({0}), please re-enter.',
    readMore: 'Read more',
    readLess: 'Read less',
  },
}
