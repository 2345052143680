/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
/**
 * 频道
 */

export const state = () => ({
  selectedCompetitionChannelId: '',
  selectedResourcesChannelId: '',
})

export const mutations = {
  SET_SELECTED_COMPETITION_CHANNEL_ID(state, channelId) {
    state.selectedCompetitionChannelId = channelId
  },
  SET_SELECTED_RESOURCES_CHANNEL_ID(state, channelId) {
    state.selectedResourcesChannelId = channelId
  },

}
