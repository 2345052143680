export default {
  pageTitle: '下載Think Academy客户端',
  studentApp: '學生端',
  studentInfo: '沉浸式互動線上課堂，激發學習興趣',
  parentApp: '家長端',
  parentInfo: '全面瞭解孩子學習進度，實时獲得孩子學情迴響',
  deviceTitle: '設備要求',
  windowsRequirement: {
    title: 'Windows要求',
    info1: '操作系統： Windows 7及以上版本',
    info2: '最低配置：CPU 4 核，4GB RAM',
    info3: '設備：不支持使用設備Chromebook和Surface Book',
  },
  macRequirement: {
    title: 'Mac要求',
    info1: '操作系統：MacOS 10.12及以上',
    info2: '最低配置：CPU 4 核，4GB RAM',
  },
  ipadRequirement: {
    title: 'iPad要求',
    info1: '操作系統：iOS 10.0及以上版本',
    info2: '設備：iPad 5、iPad Air 3、iPad mini 5和所有更新型號',
  },
  iphoneRequirement: {
    title: 'iPhone要求',
    info1: '操作系統：iOS 10.0及以上版本',
    info2: '設備：iPhone7/Plus及所有更新型號',
  },
  androidRequirement: {
    title: 'Android要求',
    info1: '操作系統：Android 6.0及以上',
    info2: '最低配置： (Tablet)2.0 GHz處理器速度，4GB RAM',
  },
  parentThinkAcademy: 'Think&nbsp;Academy家長端',
  download: '下載',
}
