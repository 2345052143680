<template>
  <el-input v-model="inputValue" class="login_input" :placeholder="$t('login.login.loginForm.phone.placeholder')" :clearable="true" @blur="inputBlurHandler">
    <el-select slot="prepend" v-model="phoneAreaCode" :popper-append-to-body="false" popper-class="phone-area-code-dropdown normal-grace-dropdown text-left phone-box"
               class="login-form-phone-code-select1 normal-grace-dropdown font-medium" value-key="countryCode"
    >
      <el-option v-for="code in phoneCodeList" :key="code.countryCode" class="phone-area-code-option" :label="`+${code.value}`" :value="code">
        <div>
          <span class="area-code">+{{ code.value }}</span>
          <span class="area-name">{{ code.countryName }}</span>
        </div>
      </el-option>
    </el-select>
  </el-input>
</template>
<script>
import { filterBlank } from 'common/utils.js';

export default {
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
  },
  data() {
    const { locale } = this.$store.state;
    return {
      locale,
      inputValue: '',
      phoneAreaCode: {},
      phoneCodeList: this.$store.state.phoneCodeList,
    }
  },
  watch: {
    value(val) {
      this.inputValue = val
    },
    inputValue(val) {
      this.$emit('input', val)
    },
    phoneAreaCode: {
      handler(val) {
        this.$emit('areaChange', val)
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      if (!this.phoneCodeList.length) {
        await this.$store.dispatch('requeryBasicData', this.$axios)
        this.phoneCodeList = [...this.$store.state.phoneCodeList]
      }
      // 初始化手机区号对象
      const initPhoneAreaCode = this.$store.state.defaultPhoneCode || this.phoneCodeList[0]
      this.phoneAreaCode = initPhoneAreaCode;
    },
    inputBlurHandler() {
      this.value = filterBlank(this.value);
    },
  },
}
</script>
<style lang="scss" scoped>
  .phone-area-code-dropdown {
    height: 160px;

    .el-scrollbar {
      height: inherit;
    }

    .phone-area-code-option {
      width: 300px;

      .area-code {
        display: inline-block;
        margin-right: 10px;
        width: 60px;
      }
    }
  }

  .login-form-phone-code-select1 {
    width: 76px;
    color: var(--txt-color-lv1);

    /deep/ .el-input__suffix-inner {
      float: right;
    }

    /deep/.el-input__suffix {
      right: 15px;
    }
  }
</style>
