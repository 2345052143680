/**
 * 课程筛选
 */
export default {
  more: 'Plus',
  retract: 'Rétracter',
  reset: 'Réinitialiser',
  define: 'Définir',
  all: 'TOUS',
  resultsNum: 'Filtre {total} résultats pour vous',
}
