/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
import { oneApi } from 'config/api';

export const state = () => ({
  // 订单状态
  cartCount: 0,
});
export const mutations = {
  SET_CART_COUNT(state, count) {
    state.cartCount = count || 0
  },
}
// this.$store.dispatch('cart/getCartCount', { $axios: this.$axios, studentId: this.userInfo.uid })
export const actions = {
  async getCartCount({ commit }, { $axios, studentId }) {
    const params = { studentId };
    try {
      console.log(params);
      if (!studentId) {
        return
      }
      const response = await $axios.post(oneApi.getCartAmount, params, { rewritePostBody: true });
      const { code, data } = response;
      if (code === 0) {
        commit('SET_CART_COUNT', data.total);
      }
    } catch (error) {
      console.log('error:', error);
    }
  },
};
