/* eslint-disable max-len */
/**
 * 待缴费清单相关 文案配置
 */

export default {
  unpaidCoursesRules: {
    title: 'Unpaid Course Guide',
    rules: `
      <p>1. What is unpaid course?</p>
      <p class="dotted-para" style="margin-left: 10px; margin-bottom: 10px;">Unpaid courses are pre-selected for you to hold a spot in class. You can pay for the class to finish registeration. If there is time conflict, you can follow the instructions below for switching a class.</p>

      <p>2. How to switch a class?</p>
      <p style="margin-left: 10px;"> a) Under unpaid courses, click "switch" in the pre-selected class to select another class of your choice.</p>
      <p style="margin-left: 10px;"> b) After switching to a new class, your spot in the previous class will be removed.</p>
      <p style="margin-left: 10px; margin-bottom: 10px;"> c) You can go to "Unpaid Courses" to pay and finish registration.</p>

      <p>3. Note:</p>
      {deadlineForSwitch}
      <p class="dotted-para" style="margin-left: 10px; margin-bottom: 10px;">There are restrictions to the class can be switched. If the class you want is not available for switching, Please contact us.</p>
    `,
  },
  autopay: {
    deductionPlanServiceNotice: 'If you want to cancel the automatic debit service, please contact our customer service: <a href="https://wa.me/message/YW6VVHRXUAELJ1?src=qr" target="_blank">WhatsApp</a>',
    deductionPlanButtonName: 'Deduction plan',
    deductionPlanDescribe: 'Please note that the card has sufficient funds to avoid the seat being removed due to failure to pay on time.',
    settingsButton: {
      buttonName: 'Autopay Settings',
      status: {
        open: 'Open',
        close: 'Close',
      },
      onlyAutoStatus: {
        open: 'In order to prevent you from forgetting to renew, we hava started the automatic deduction service for you.',
        close: 'State: Unauthorized',
      },
    },
    settingsDetail: {
      nextPaymentTime: 'Next payment time',
      paymentMethod: 'payment method',
      automaticFeeOrder: 'Automatic fee order',
      paymentTime: 'Payment time',
      cancelButtonName: 'Cancel automatic pay',
      authorizeButtonName: 'Authorize automatic pay',
      paid: 'Paid',
    },
    contactNumber: '【400 006 3390】',
  },
  autopayNotice: {
    success: 'Automatic deduction succeeded',
    fail: 'Automatic deduction authorization failed',
    cancelNotice: 'Automatic cancel succeeded',
    buttonName: 'I got it',
  },
}
