<template>
  <div>
    <el-dialog
      v-if="show"
      :visible.sync="show"
      :show-close="false"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      :append-to-body="appendToBody"
      :width="width"
      custom-class="normal-dialog common-confirm-dialog"
    >
      <div class="common-confirm-dialog-template">
        <div v-if="showHeader" class="dialog-header">
          <div v-if="showClose" class="close-icon-container" @click="closeHandler">
            <i class="iconfont icon-close" />
          </div>
        </div>
        <div class="dialog-body">
          <div v-if="img" :class="`img ${img}`" />
          <div v-if="title" class="title font-semibold" :class="{'custom-title': customTitle}">
            {{ title }}
          </div>
          <div class="dialog-content-container">
            <slot name="content">
              <div v-if="content" class="content font-medium" v-html="content" />
              <slot name="content-bottom" />
            </slot>
          </div>
        </div>
        <div v-if="showMainBtn || showSubBtn" class="dialog-footer" :class="{'custom-footer': customFooter}">
          <el-button v-if="subBtnText || showSubBtn" class="button button-normal button-default" @click="subBtnClickHandler">
            {{ subBtnText }}
          </el-button>
          <el-button v-if="mainBtnText || showMainBtn" class="button button-normal" @click="mainBtnClickHandler">
            {{ mainBtnText }}
          </el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      default: false,
      type: Boolean,
    },
    width: {
      default: '440px',
      type: String,
    },
    img: {
      default: '',
      type: String,
    },
    title: {
      default: '',
      type: String,
    },
    content: {
      default: '',
      type: String,
    },
    mainBtnText: {
      default: '',
      type: String,
    },
    showHeader: {
      default: true,
      type: Boolean,
    },
    showMainBtn: {
      default: true,
      type: Boolean,
    },
    subBtnText: {
      default: '',
      type: String,
    },
    showSubBtn: {
      default: false,
      type: Boolean,
    },
    showClose: {
      default: true,
      type: Boolean,
    },
    appendToBody: {
      default: false,
      type: Boolean,
    },
    // 自定义标题样式
    customTitle: {
      default: false,
      type: Boolean,
    },
    customFooter: {
      default: false,
      type: Boolean,
    },
  },
  methods: {
    mainBtnClickHandler() {
      this.$emit('mainBtnClick');
      this.closeHandler();
    },
    subBtnClickHandler() {
      this.$emit('subBtnClick');
      this.closeHandler();
    },
    closeHandler() {
      this.$emit('update:show', false);
    },
  },
}
</script>

<style lang="scss" scoped>
.common-confirm-dialog {
  .common-confirm-dialog-template {
    padding: 20px 0 24px;
    word-break: normal;
  }

  .dialog-header {
    position: relative;
    height: 44px;

    .close-icon-container {
      position: absolute;
      right: -8px;
      padding: 4px 0;
      width: 32px;
      height: 32px;
      text-align: center;
      background: #f3f6fa;
      border-radius: 50%;
      z-index: 999;
      cursor: pointer;

      .iconfont {
        color: var(--txt-color-lv2);
        font-size: 14px;
        font-weight: bold;
        line-height: 24px;
      }

      &:hover .iconfont {
        color: var(--txt-color-lv1);
        background: #f6f6f6;
      }
    }
  }

  .dialog-body {
    .img {
      background-size: 100% 100%;
      margin: 0 auto;
      width: 225px;
      height: 225px;

      &.box {
        background: url('~assets/images/error/unpaid_box.png') no-repeat;
        background-size: 100% 100%;
        margin: -53px auto 10px;
        width: 112px;
        height: 112px;
      }
    }

    .title {
      margin: 0 auto;
      width: 360px;
      text-align: center;
      font-size: 20px;
      line-height: 24px;
      color: var(--txt-color-lv1);

      &:nth-child(1) {
        margin-top: 24px;
      }
    }

    .title.custom-title {
      margin-top: 0;
    }

    .dialog-content-container {
      .content {
        margin: 10px auto;
        width: 360px;
        text-align: center;
        font-size: 16px;
        line-height: 19px;
        color: var(--txt-color-lv2);
      }
    }
  }

  .dialog-footer {
    display: flex;
    justify-content: center;
    margin: 44px auto 24px;
    min-height: 10px;

    .button {
      width: 100%;
      margin-right: 20px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .custom-footer {
    margin: 32px 0 0 0;
  }
}
</style>
