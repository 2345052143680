/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
/**
 * 站点配置数据
 */
export const state = () => ({
  // 是否主站点
  isMain: true,
  // 导航数据
  header: {
    // 配置化导航栏
    navigationLinks: [],
  },
  // 尾部配置
  footer: {},
  // 站点公共配置
  config: {
    // 导航类型
    // single: 单导航
    // double: 双导航
    headerType: 'single',
    // 彩色LOGO
    logo: '',
    // 白色LOGO
    whiteLogo: '',
  },
  // 页面配置
  page: {
    // 是否是默认配置 0 / 1
    isDefault: '',
    // 页面标题
    title: '',
    // 页面关键字
    keywords: '',
    // 页面描述
    description: '',
  },
})

export const mutations = {
  /**
   * 是否主站点
   * @param {*} state
   * @param {*} isMain
   */
  SET_IS_MAIN(state, isMain) {
    state.isMain = isMain
  },

  /**
   * 设置导航数据
   * @param {*} state
   * @param {*} data
   */
  SET_HEADER_DATA(state, data) {
    const { navigationLinks = [] } = data
    state.header.navigationLinks = navigationLinks
  },

  /**
   * 设置站点配置数据
   * @param {*} state
   * @param {*} config
   */
  SET_CONFIG_DATA(state, config) {
    const { headerType = 'single', logo = '', whiteLogo = '' } = config
    state.config.headerType = headerType
    state.config.logo = logo
    state.config.whiteLogo = whiteLogo
  },

  /**
   * 设置页面配置数据
   * @param {*} state
   * @param {*} data
   */
  SET_PAGE_DATA(state, data) {
    const {
      isDefault = '', title = '', keywords = '', description = '',
    } = data
    state.page.isDefault = isDefault
    state.page.title = title
    state.page.keywords = keywords
    state.page.description = description
  },
}

export const actions = {
  updateIsMain({ commit }, isMain) {
    commit('SET_IS_MAIN', isMain)
  },

  updateConfigData({ commit }, config) {
    commit('SET_CONFIG_DATA', config)
  },

  updateHeaderData({ commit }, data) {
    commit('SET_HEADER_DATA', data)
  },

  updatePageData({ commit }, data) {
    commit('SET_PAGE_DATA', data)
  },
}
