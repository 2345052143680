/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
/**
 * 用户数据
 *
 */
import { oneApi } from 'config/api'

const initUser = {
  uid: '',
  card: '',
  firstName: '',
  lastName: '',
  gradeId: '',
  gradeName: '',
  nickName: '',
  status: '',
  avatar: '',
  email: '',
  phone: '',
  countryCallingCode: '',
  linkedAccount: [],
}

export const state = () => ({
  // 登录相关数据
  // 登录手机号
  phone: '',
  /**
   * 已选手机区号
   * @key {countryName}
   * @key {countryCode}
   * @key {value}
   * @key {reg}
   */
  selectedPhoneAreaCode: {},
  // 当前用户登录状态
  isLogin: false,
  // ---------------------------
  // 用户数据
  user: {
    uid: '',
    card: '',
    firstName: '',
    lastName: '',
    gradeId: '',
    gradeName: '',
    nickName: '',
    status: '',
    avatar: '',
    email: '',
    phone: '',
    countryCallingCode: '',
    linkedAccount: [],
    // 新增家长昵称及头像
    parentNickname: '',
    parentAvatar: '',
  },
  masterUserData: {},
  unpaidList: {
    total: 0,
  },
  showUnpaidBox: false,
  isComleteInfo: false,
  associatedAccounts: 0,
  isAlipayHKGrayUser: false,
  studentsList: [],
  clientOptions: {},
});

export const mutations = {
  /**
   * 设置用户登录状态
   * @param {state}} state
   * @param {loginStatus} loginStatus
   */
  SET_IS_LOGIN(state, loginStatus) {
    state.isLogin = loginStatus;
    state.masterUserData = {}
  },
  /**
   * 设置Phone
   * @param {state} state
   * @param {phone} phone
   */
  SET_PHONE(state, phone) {
    state.phone = phone;
  },

  SET_USER_PHONE(state, { phone, code }) {
    state.user.phone = phone;
    state.user.countryCallingCode = code;
  },
  /**
   * 设置所选手机区号对象
   */
  SET_SELECTED_PHONE_AREA_CODE(state, codeObj) {
    state.selectedPhoneAreaCode = { ...codeObj };
  },
  /**
   *
   * @param {更新用户邮箱} state
   * @param {*} userData
   */
  SET_USER_EMAIL(state, email) {
    state.user.email = email;
  },
  /**
   * 更新当前登录家长用户头像
   * @param {state}} state
   * @param {avatar} parentAvatar
   */
  SET_USER_AVATAR(state, parentAvatar) {
    state.user.parentAvatar = parentAvatar;
  },
  /**
   * 更新当前登录用户数据
   * @param {state}} state
   * @param {userData} userData
   */
  SET_USER(state, userData) {
    // 登录接口和获取用户信息接口关于用户id返回的字段不一致
    if ('id' in userData) { userData.uid = userData.id }
    if ('cardNo' in userData) { userData.card = userData.cardNo }
    state.user.phone = ''
    state.user.email = ''
    state.user = Object.assign(state.user, userData);
    state.isComleteInfo = !(!state.user.nickName || !state.user.firstName || !state.user.lastName || !state.user.gradeId);
  },
  /**
   * 更新主用户数据
   * @param {state}} state
   * @param {masterUserData} masterUserData
   */
  SET_MASTER_USER(state, masterUserData) {
    state.masterUserData = Object.assign(state.masterUserData, masterUserData);
  },
  RESET_USER_INFO(state) {
    state.user = { ...initUser }
  },
  /**
   * 获取待支付列表
   * @param {state}} state
   * @param {unpaidList}} unpaidList
   */
  SET_UNPAID_LIST(state, data) {
    state.unpaidList = data;
  },
  SET_SHOW_UNPAID_BOX(state, show) {
    state.showUnpaidBox = show;
  },
  /**
   * 设置现家庭账号学员数量
   * @param {state} state
   * @param {total} total
   */
  SET_ASSOCIATED_ACCOUNTS(state, total) {
    state.associatedAccounts = total;
  },
  /**
   * 设置支付宝HK灰度用户
   * @param {state} state
   * @param {isAlipayHKGrayUser} isAlipayHKGrayUser
   */
  SET_ALIPAY_HK_GRAY_USER(state, isAlipayHKGrayUser) {
    state.isAlipayHKGrayUser = isAlipayHKGrayUser
  },
  /**
   * 学生列表
   * @param {*} state
   * @param {*} list
   */
  SET_STUDENTSLIST(state, studentsList) {
    state.studentsList = studentsList
  },
  /**
   * client options
   * @param {*} options
   */
  SET_CLIENT_OPTIONS(state, options) {
    state.clientOptions = options
  },
};

export const actions = {
  async queryUserBasicInfo({ commit, dispatch }, { $axios, userData }) {
    const data = { ...userData }
    const res = await $axios.post(oneApi.queryUserBasicInfo, data, { rewritePostBody: true })
    if (Number(res.code) !== 0) {
      commit('SET_IS_LOGIN', false);
      return
    }
    const resData = res.data || {};
    commit('SET_IS_LOGIN', true);
    commit('SET_USER', resData);
    dispatch('getAlipayGrayUser', { $axios });
    dispatch('getFamilyStudentsList', { $axios });
    dispatch('getClientOptions', { $axios });
  },
  async getFamilyStudentsList({ commit }, { $axios }) {
    const res = await $axios.post(oneApi.getFamilyStudentsList, {}, { rewritePostBody: true })
    if (Number(res.code) !== 0) {
      this.$ElMessage.error(res.msg);
      return
    }
    const resData = res.data || {}
    const list = resData.associatedAccount.concat(resData.currentAccount)
    // 按照uid升序排列
    list.sort((one, next) => one.uid - next.uid)
    commit('SET_STUDENTSLIST', list)
    if (process.server) return
    window.localStorage.removeItem('studentsList')
    window.localStorage.setItem('studentsList', JSON.stringify(list))
  },
  async getClientOptions({ commit }, { $axios }) {
    const res = await $axios.post(oneApi.getClientOptions, {}, { rewritePostBody: true });
    if (Number(res.code) !== 0) {
      this.$ElMessage.error(res.msg);
      return
    }
    commit('SET_CLIENT_OPTIONS', res.data)
    if (process.server) return
    window.localStorage.removeItem('clientOptions')
    window.localStorage.setItem('clientOptions', JSON.stringify(res.data))
  },
  resetUserInfo({ commit }) {
    commit('RESET_USER_INFO')
  },
  async queryUnpaid({ commit }, { $axios }) {
    const res = await $axios.post(oneApi.unpaidRemind, {}, { rewritePostBody: true })
    if (Number(res.code) !== 0) {
      return
    }
    const resData = res.data || {};
    commit('SET_UNPAID_LIST', resData);
  },
  async getAlipayGrayUser({ commit }, { $axios }) {
    const res = await $axios.post(oneApi.getAlipayHKGrayList, {}, { rewritePostBody: true })
    console.log(res, 'res');
    if (Number(res.code) !== 0) {
      return
    }
    const resData = res.data || {};
    const isGray = resData.gray
    commit('SET_ALIPAY_HK_GRAY_USER', isGray)
  },
}
