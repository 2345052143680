/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
export const state = () => ({
  visible: false,
  type: 'phone',
  guide: null,
  execute: false,
});

export const mutations = {
  /**
    * 设置弹框显示
    * @param {State} state
    * @param {平台} visible
    * @param {跳过验证后的方法} guide
    * @param {验证阶段} execute
    */
  SET_VISIBLE(state, visible) {
    state.visible = visible;
  },
  SET_TYPE(state, type) {
    state.type = type;
  },
  SET_GUIDE(state, guide) {
    state.guide = guide;
  },
  SET_EXECUTE(state, execute) {
    state.execute = execute;
  },
}
export const actions = {
  setDialogData({ commit }, obj) {
    commit('SET_VISIBLE', obj.visible);
    commit('SET_TYPE', obj.type);
    commit('SET_GUIDE', obj.guide || null);
    commit('SET_EXECUTE', obj.execute || false);
  },
  close({ commit }) {
    commit('SET_VISIBLE', false);
    commit('SET_GUIDE', null);
    commit('SET_EXECUTE', false);
  },
}
