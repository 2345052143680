<template>
  <el-dialog
    v-if="show"
    :visible.sync="show"
    :show-close="false"
    :close-on-click-modal="false"
    :destroy-on-close="true"
    :append-to-body="true"
    width="480px"
    custom-class="normal-dialog"
  >
    <template slot="title">
      <div class="dialog-header">
        <div class="header-container">
          <div class="title">
            {{ langContext.title }}
          </div>
          <div class="describe">
            {{ langContext.describe }}<span class="current-timezone">{{ timezone }}</span>
          </div>
        </div>
        <div class="operate-dialog-icon close-icon" @click="handleClose">
          <i class="iconfont icon-close-new" />
        </div>
      </div>
    </template>
    <div class="timezone-wrapper">
      <div class="timezone-filter">
        <div class="iconfont icon-search" />
        <input v-model="filterValue" class="timezone-input" :placeholder="langContext.searchInputPlaceholder">
        <div v-if="filterValue" class="icon-close-wrapper" @click="clearFilterValue">
          <i class="iconfont icon-close-new" />
        </div>
      </div>
      <div class="timezone-result-wrapper">
        <template v-if="loading">
          <Loading :show="true" />
        </template>
        <template v-else>
          <div v-if="showEmpty" class="empty-wrapper">
            <div class="notice">
              {{ langContext.noResultNotice }}
            </div>
          </div>
          <div v-else id="timezone-popup-result" class="timezone-result">
            <div id="timezone-popup-result-wrapper" class="result-wrapper">
              <template v-for="(item, index) in timezoneList">
                <div v-if="filterTimezone(item)" :key="index" class="timezone-item" :class="{'active': item === timezone}" @click="handleChangeTimezone(item)">
                  <div class="timezone-name" v-html="handleHighlight(item)" />
                  <div v-if="item === timezone" class="icon-checked">
                    <i class="iconfont icon-success" />
                  </div>
                </div>
              </template>
            </div>
          </div>
        </template>
      </div>
    </div>
  </el-dialog>
</template>

<script>
/**
 * 时区切换窗口web组件
 */
import mixin from './mixin'
import Loading from 'components/common/Loading'

export default {
  name: 'TimezoneChangePopup',
  components: {
    Loading,
  },
  mixins: [mixin],
  methods: {
    /**
     * 点击时区切换
     * @param {String} timezone 时区
     */
    handleChangeTimezone(timezone) {
      if (timezone === this.timezone) return
      this.setTimezone(timezone)
      this.handleClose()
      this.trackReport()
      this.$ElMessage.success({
        message: this.langContext.refreshNotice,
        duration: 2000,
      })
      this.$emit('confirm')
      setTimeout(() => {
        window.location.reload()
      }, 2000)
    },
  },
}
</script>

<style lang="scss" scoped>
.dialog-header {
  position: relative;

  .operate-dialog-icon {
    position: absolute;
    top: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    color: #a1abb8;
    background: #f4f6fa;
    cursor: pointer;

    &.close-icon {
      right: 20px;
    }
  }

  .header-container {
    padding: 30px 60px 15px 60px;

    .title {
      margin-bottom: 3px;
      color: var(--txt-color-lv1);
      font-size: 20px;
      font-weight: 600;
    }

    .describe {
      height: 32px;
      color: var(--txt-color-lv2);
      font-size: 14px;
      line-height: 16px;
    }

    .current-timezone {
      color: #3370ff;
    }

    &::after {
      content: " ";
      display: block;
      position: absolute;
      top: 30px;
      left: 15px;
      width: 44px;
      height: 44px;
      background: url('./assets/logo.png') no-repeat;
      background-size: 100%;
    }
  }
}

.timezone-wrapper {
  padding-bottom: 20px;
}

.timezone-filter {
  position: relative;
  margin-bottom: 20px;

  .icon-close-wrapper {
    position: absolute;
    top: 13px;
    right: 13px;
    width: 20px;
    height: 20px;
    background: #fff;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    .icon-close-new {
      font-size: 14px;
      color: var(--txt-color-lv1);
    }
  }

  .icon-search {
    position: absolute;
    left: 20px;
    top: 14px;
    font-size: 16px;
    color: var(--txt-color-lv2);
  }
}

.timezone-input {
  display: block;
  width: 100%;
  height: 46px;
  background: #f8f9fa;
  font-size: 16px;
  padding: 0 44px 0 44px;
  border-radius: 23px;
  border: 1px solid #f8f9fa;
  color: var(--txt-color-lv1);

  &:focus {
    border: 1px solid var(--txt-color-link);
  }

  &:focus-visible {
    outline: 0;
  }

  &::placeholder {
    color: #a2aabb;
  }
}

.timezone-result-wrapper {
  height: 354px;
}

.empty-wrapper {
  overflow: hidden;

  &::before {
    content: " ";
    display: block;
    width: 160px;
    height: 160px;
    margin: 80px auto 20px auto;
    background: url('./assets/no-search-result.png') no-repeat;
    background-size: 100%;
  }

  .notice {
    font-size: 12px;
    color: var(--txt-color-lv2);
    text-align: center;
  }
}

.timezone-result {
  max-height: 354px;
  background: #f8f9fa;
  border-radius: 10px;
  padding: 0 10px 0 18px;
  overflow: auto;

  .result-wrapper {
    margin-right: 8px;
  }

  .timezone-name {
    width: 360px;
    font-size: 16px;
    color: var(--txt-color-lv1);
    font-weight: bold;

    span {
      color: var(--txt-color-link);
    }
  }

  .timezone-item {
    display: flex;
    padding: 20px 0;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #fff;
    cursor: pointer;

    &:last-child {
      border-bottom: none;
    }

    &.active,
    &:hover {
      .timezone-name {
        color: var(--txt-color-link);
      }
    }
  }

  .icon-checked {
    color: var(--txt-color-link);
    font-size: 20px;
  }
}
</style>
