export default {
  register: {
    formPlaceholder: '결제 전에 등록 정보를 입력해주세요',
    formTitle: '등록 정보',
    submitName: '제출',
    requiredError: '입력이 필요합니다.',
    selectPlaceholder: '선택해주세요',
    inputPlaceholder: '입력해주세요',
  },
  priceInfo: {
    deduction: '48시간 후 자동 차감',
  },
  orderCreate: {
    subscription: '구매',
  },
}
