export default {
  coupon: {
    selectCoupons: '쿠폰 선택',
    tips: '추천 <span class="orange-text">{x}개 쿠폰</span> 선택, <span class="orange-text">{y}</span>',
    couponTips: '<span class="orange-text">{x}개 쿠폰</span> 선택, <span class="orange-text">{y}</span>',
    noSelectedTips: '<span class="orange-text">{x}개 쿠폰</span> 사용 가능',
    changeCard: '결제 카드 변경',
    automaticPay: '자동 결제',
    authorized: '승인됨',
    unauthorized: '승인되지 않음',
  },
}
