/**
 * 站点数据查询
 */

import { oneApi } from 'config/api'
import { clearUrlEndSlash } from 'common/utils'

export default async function ({
  store,
  req,
  app,
  route,
}) {
  if (store.state.website.isMain && process.client && route.name !== 'all') {
    return
  }

  const host = process.server ? req.ctx.hostname : window.location.hostname
  const path = clearUrlEndSlash(route.path)
  const params = {
    host,
    path,
  }
  const res = await app.$axios.post(oneApi.querySiteData, params, { rewritePostBody: true, sendToken: false })
  const { code, data = {} } = res || {}
  console.log(data, 'data111111');
  if (code !== 0) {
    return
  }
  const { isMain = true, siteModuleList = [], configs = {} } = data
  siteModuleList.forEach((item) => {
    let moduleContent = null
    try {
      moduleContent = JSON.parse(item.content)
    } catch (err) {
      console.log(err)
    }
    if (moduleContent) {
      const { componentName, componentData = {} } = moduleContent
      switch (componentName) {
        case 'Header':
          store.dispatch('website/updateHeaderData', componentData)
          break;
        default: break;
      }
    }
  })
  store.dispatch('website/updateIsMain', isMain)
  store.dispatch('website/updateConfigData', configs)
}
