export default {
  title: '完善重要聯繫方式',
  Email: '郵箱地址',
  Phone: '手機號',
  email: '郵箱地址',
  phone: '手機號',
  WeChat: '微信',
  WhatsApp: 'WhatsApp',
  Line: 'Line',
  save: '保存',
  cancel: '取消',
  editWeChat: '編輯 微信',
  editWhatsApp: '編輯 WhatsApp',
  editLine: '編輯 Line',
  editPhone: '編輯 手機號',
  editEmail: '編輯 郵箱地址',
  contactTip1: '微信/WhatsApp/Line将用于教师与家长沟通学生的学习情况。',
  contactTip2: '',
  contactTip3: '請如實填寫聯繫方式，如聯繫方式缺失，會影響老師向家長及時同步學生的學習情况',
  contactTip4: '',
  minChoose: '至少填寫一個聯繫方式才可保存',
  headline: '改善重要聯繫方式',
  describe: '請如實填寫聯繫信息，如果聯繫信息缺失，將影響老師及時嚮家長同步學生的學習情況',
  invalidPhone: '請輸入有效的手機號',
  invalidEmail: '請輸入有效的郵箱地址',
  invalidWechat: '請輸入有效的微信',
  invalidWhatsApp: '請輸入有效的whatsapp',
  saveSuccess: '提交成功',
  saveFail: '請至少填寫一個聯繫方式',
  contactTip5: '電話和郵箱會被用來發送孩子學習相關的重要通知，請您準確填寫',
};
