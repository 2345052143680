const moment = require('moment-timezone');
/**
 * 格式化日期
 * @param {日期} dateStr
 * @param {日期时间格式化字符串} formatStr
 */
/* eslint-disable-next-line */
const resourceDateFormate = (timeStamp, zone, formatStr = '') => {
  return moment(timeStamp).format(formatStr);
}

/**
 * 货币价格转换
 * @param {price} price
 * @param {minorUnit} minorUnit
 * @param {是否保留两位小数} isKeepTwoDecimal
 */

const priceUnitConvert = (price, minorUnit, isKeepTwoDecimal = '0') => {
  const newprice = Number(price);
  let result = newprice / minorUnit;
  if (Number(isKeepTwoDecimal) === 1) {
    const resultStr = result.toString();
    const resultDecimal = resultStr.split('.');
    if (resultDecimal.length < 2) {
      // 整数
      result += '.00';
    } else if (resultDecimal.length === 2 && resultDecimal[1].length === 1) {
      // 一位小数
      result += '0';
    }
  }
  return result;
}

/**
 * 手机号脱敏
 * @param {phone} phone
 */
const encryptPhone = (initPhone, defaultValue = '') => {
  const phone = initPhone
  if (!phone || phone === '' || phone === defaultValue) {
    return defaultValue
  }
  if (phone.includes('*')) return phone;
  // 手机号长度
  const phoneLen = phone.length;

  // 加密长度
  const encryptLen = Math.floor(phoneLen / 2);

  const startIndex = Math.floor((phoneLen - encryptLen) / 2);
  const endIndex = startIndex + encryptLen;

  const startStr = phone.substring(0, startIndex);
  const endStr = phone.substring(endIndex, phoneLen);

  const encryptStr = phone.substring(startIndex, endIndex).replace(/\d|\D/g, '*');

  return startStr + encryptStr + endStr;
}

const encryptEmail = (email, defaultValue = '') => {
  if (!email || email === '' || email === defaultValue) {
    return defaultValue
  }
  if (email.includes('*')) return email;
  const [head, end] = email.split('@')
  return `${encryptPhone(head)}@${end}`;
}

/**
 * 订单状态文本
 * @param {orderStatus} orderStatus
 */
const orderStatusText = (orderStatus) => {
  let orderStatusTxt = '';
  switch (orderStatus) {
    case 100:
      orderStatusTxt = 'To be paid';
      break;
    case 200:
      orderStatusTxt = 'Paid';
      break;
    case 300:
      orderStatusTxt = 'Cancelled';
      break;
    case 301:
      orderStatusTxt = 'Cancelled';
      break;
    case 400:
      orderStatusTxt = 'Refunded';
      break;
    case 500:
      orderStatusTxt = 'Refund in Progress';
      break;
    default:
      orderStatusTxt = '';
  }
  return orderStatusTxt;
}

/**
 * 美分显示英文月份日期
 * @param {时间戳} time
 */
const transMonthDay = (time) => {
  const monthAndDay = moment(time).format('LL').split(',')[0]
  const transRes = `${monthAndDay.split(' ')[0].slice(0, 3)}.${monthAndDay.split(' ')[1]}`
  return transRes
}

/**
 * 格式化学员卡编号
 * @param {学员卡编号}} val
 */
const formatCardNo = (val) => {
  if (!val) return ''
  return val.replace(/(.{4})/g, '$1 ')
}

// 获取对应国家时间格式
const getFormatTime = (timeStamp, locale) => {
  if (locale === 'hk' || locale === 'kr' || locale === 'jp') {
    // const week = this.weekdays[moment(timeStamp).format('d')]
    const month = locale === 'hk' ? moment(timeStamp).format('M月D日') : moment(timeStamp).format('MMM Do');
    const date3 = `${month} ${moment(timeStamp).format('dddd')} ${moment(timeStamp).format('HH:mm')}`
    return date3
  }
  const clientOptions = JSON.parse(window.localStorage.getItem('clientOptions')) || {}
  const date1 = `${moment(timeStamp).format('MMM D, ddd')} ${moment(timeStamp).format('HH:mm')}` // USA, FR
  const date2 = `${moment(timeStamp).format('HH:mm')} ${moment(timeStamp).format('ddd D MMM')}` // UK

  return clientOptions.dateFormat === 'UK' ? date2 : date1
}

export default {
  resourceDateFormate,
  priceUnitConvert,
  encryptPhone,
  orderStatusText,
  formatCardNo,
  encryptEmail,
  transMonthDay,
  getFormatTime,
}
