// 资源位曝光
const adShow = (context, adName, adId, extraArgs) => {
  let data = {
    // 运营位名称
    ad_name: adName,
    ad_id: adId,
  }
  if (extraArgs && Object.keys(extraArgs).length > 0) {
    data = Object.assign(data, extraArgs)
  }
  context.Sensors.track('ad_show', data)
}

// 资源位点击
const adClick = (context, adName, adId, extraArgs) => {
  let data = {
    // 运营位名称
    ad_name: adName,
    ad_id: adId,
  }
  if (extraArgs && Object.keys(extraArgs).length > 0) {
    data = Object.assign(data, extraArgs)
  }
  context.Sensors.track('ad_click', data)
}

export {
  adShow,
  adClick,
}
