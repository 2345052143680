const descriptionWins = `<p>EdTech-Powered Live Online Learning Platform </p>
<ul> 
<li>Get access to interactive virtual classrooms using larger screens
<li>Experience peer learning environment by meeting your classmates</li>

<li>Answer questions and enjoy learning activities</li>
<li>Win rewards for even more fun</li>
</ul>
`;

const deviceRequireWins = [
  {
    label: 'Operating System',
    desc: 'Windows 7 and above',
  },
  {
    label: 'Minimum configurations',
    desc: 'CPU 4 Cores, 4GB RAM',
  },
  {
    label: 'Device',
    desc: '<span style="border-bottom: 1px dashed;">Chromebook and Surface Book</span><span> are NOT SUPPORTED</span>',
  },
]

const descriptionMac = `<p>EdTech-Powered Live Online Learning Platform </p>
<ul> 
<li>Get access to interactive virtual classrooms using larger screens</li>

<li>Experience peer learning environment by meeting your classmates</li>

<li>Answer questions and enjoy learning activities</li>
<li>Win rewards for even more fun</li>
</ul>
`;

const deviceRequireMac = [
  {
    label: 'Operating System',
    desc: 'MacOS 10.12 and above',
  },
  {
    label: 'Minimum configurations',
    desc: 'CPU 4 Cores, 4GB RAM',
  },
]

const descriptionPaid = `<p>EdTech-Powered Live Online Learning Platform </p>
<ul> 
<li>Get access to interactive virtual classrooms using larger screens</li>

<li>Experience peer learning environment by meeting your classmates</li>

<li>Answer questions and enjoy learning activities</li>
<li>Win rewards for even more fun</li>
</ul>
`;
const deviceRequirePaid = [
  {
    label: 'Operating System',
    desc: 'iOS 10.0 and above',
  },
  {
    label: 'Device',
    desc: 'iPad 5, iPad Air 3, iPad mini 5, and all newer models',
  },
]

const descriptionIOS = `<p>EdTech-Powered Live Online Learning Platform </p>
<ul> 
<li>Get access to interactive virtual classrooms</li>

<li>Answer questions and enjoy learning activities</li>

<li>Win rewards for even more fun</li></ul>
`;

const deviceRequireIOS = [
  {
    label: 'Operating System',
    desc: 'iOS 10.0 and above',
  },
  {
    label: 'Device',
    desc: 'iPhone7/Plus and all newer models',
  },
]

const descriptionAndroid = `<p>EdTech-Powered Live Online Learning Platform </p>
<ul> 
<li>Get access to interactive virtual classrooms</li>

<li>Answer questions and enjoy learning activities</li>

<li>Win rewards for even more fun</li></ul>
`;

const deviceRequireAndroid = [
  {
    label: 'Operating System',
    desc: 'Android 6.0 and above',
  },
  {
    label: 'Minimum configurations (Tablet)',
    desc: '2.0 GHz processor speed, 4GB RAM',
  },
]

export default {
  title: 'Think Academy Student',
  version: 'Version',
  download: 'Download',
  scan: 'Scan the QR code',
  or: 'or',
  keys: {
    mac: 'MAC_PC_STUDENT',
    windows: 'WIN_PC_STUDENT',
    ipad: 'IOS_HD_STUDENT',
    iphone: 'IOS_PHONE_STUDENT',
    android: 'ANDROID_PHONE_STUDENT',
  },
  paramsKeys: {
    mac: 'Mac',
    windows: 'Windows',
    ipad: 'iPad',
    iphone: 'IOS',
    android: 'Android',
  },
  ableTabs: [
    {
      title: 'Windows',
      name: 'windows',
      requirement: 'Windows 7 and above.',
      download_btn: true,
      description: `${descriptionWins}`,
      deviceRequire: {
        title: 'Compatibility & Minimum Computer Configurations',
        list: deviceRequireWins,
      },
    },
    {
      title: 'Mac',
      name: 'mac',
      requirement: 'MacOS 10.12 and above.',
      download_btn: true,
      description: `${descriptionMac}`,
      deviceRequire: {
        title: 'Compatibility & Minimum Computer Configurations',
        list: deviceRequireMac,
      },
    },
    {
      title: 'iPad',
      name: 'ipad',
      requirement: 'Only supports iOS 10.0 and above.',
      download_btn: true,
      description: `${descriptionPaid}`,
      deviceRequire: {
        title: '',
        list: deviceRequirePaid,
      },
    },
    {
      title: 'Android Tablet',
      name: 'android',
      requirement: 'Only supports Android 6.0 and above.',
      description: `${descriptionAndroid}`,
      deviceRequire: {
        title: '',
        list: deviceRequireAndroid,
      },
    },

  ],
  parentTabs: [
    {
      title: 'iPhone',
      name: 'iphoneParent',
      requirement: 'Only supports iOS 10.0 and above. ',
      description: `${descriptionIOS}`,
      deviceRequire: {
        title: '',
        list: deviceRequireIOS,
      },
    },
    {
      title: 'Android',
      name: 'androidParent',
      requirement: 'Only supports Android 6.0 and above.',
      description: `${descriptionAndroid}`,
      deviceRequire: {
        title: '',
        list: deviceRequireAndroid,
      },
    },
  ],
}
