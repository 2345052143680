/**
 * 导航时区标识
 */
export default {
  // 自动定位时区按钮名称
  autoTimezoneButton: {
    cancelName: 'Not Now',
    confirmName: 'Switch to your time zone',
  },
  // 网站时区提示
  websiteTimezoneNotice: 'Website Displayed Time Zone:',
  // 当前时区提示
  currentTimezoneNotice: 'Your Time Zone:',
  // 当前时区按钮名称
  currentTimezoneButton: {
    confirmName: 'Got it',
  },
  // 切换定位刷新页面提示
  refreshNotice: 'Switching time zone for you',
}
