/**
 * goods页面配置
 */
export default {
  recordedCourse: '録画コース',
  courseTypeList: [{
    value: 'live',
    label: 'ライブコース',
  },
  {
    value: 'recorded',
    label: '録画コース',
  },
  ],
  // 录播课
  recordingDetail: {
    exceptiontip: 'このコースの受付は終了しました。Think Academyが提供する他のコースを見る',
    exceptiontip1: ' 申し訳ありませんが、このレッスンは現在受講できません。Think Academyが提供する他のコースを見る',
    free: '無料',
    courseDetails: 'コース詳細',
    courseDetail: 'コース詳細',
    overview: '概要',
    syllabus: '講義概要',
    teacher: '先生',
    validFor: '有効期限',
    day: '日付',
    days: '日付',
    viewMore: 'すべて見る',
    lesson: 'レッスン',
    lessons: 'レッスン',
    readMore: 'もっと読む',
    hr: 'hr',
    min: 'min',
    courseValidUntil: '有効期限',
    btnStatus: {
      forSale: '近日公開',
      buyNow: '今すぐ購入',
      soldOut: '完売',
      registered: '登録に成功',
      subscription: '購入',
    },
    expired: '期限切れ',
    expiredTip: 'このコースは期限切れです',
    permanentTip: 'コースは永久に有効',
    start: '開始',
  },
  goodsDetail: {
    year: '毎年',
    month: '毎月',
    discounts: '-20%',
  },
  subscriptionPeriod: {
    2: '日',
    3: '月',
    4: '年',
  },
  subDate: {
    2: '日',
    3: '月',
    4: '年',
  },
  howToAttendClass: {
    title: 'クラスの参加方法',
    firstStepLeft: 'ダウンロード',
    firstStepRight: 'アプリ',
    secondStep: 'アプリにログインし、"クラス "からコースを見つけることができます',
    thirdStep: '学習したいレッスンを見つけ、"入る "をクリックして学習を開始する。',
  },
  orderResult: {
    viewMoreCourse: 'コスをもっと見る',
    viewOrder: '注文を見る',
    viewCourse: 'コースを見る',
    cancel: 'キャンセル',
    howToAttendClass: 'クラスの参加方法',
  },
  recordedVideoTips: {
    0: 'プレー',
    '0-1': 'ポーズ',
    1: '巻き戻し10秒',
    2: '早送り10秒',
    9: 'スクリーンショット',
    11: '全画面',
    '11-1': '全画面を終了する',
  },
  recordedVideo: {
    reload: '再ロード',
    loadFailed: 'ロードに失敗しました',
    loading: 'ロード中',
    saveShotScreen: 'スクリーンショットが保存されました',
    switchChannel: 'チャンネルを引き換え',
  },
}
