import moment from 'moment-timezone'
import Cookies from 'js-cookie'
import config from 'config/index'
import { oneApi } from 'config/api'

export default {
  props: {},
  data() {
    return {
      // 翻译文案上下文
      langContext: this.$t('featureComponents.TimezoneChangePopup'),
      show: false,
      loading: false,
      filterValue: '',
      deviceTimezone: moment.tz.guess(),
      timezoneList: [],
    }
  },
  computed: {
    // tirm后的筛选值
    filterValueForTrim() {
      return this.filterValue.trim()
    },

    // 当前分校时区
    timezone() {
      return this.$store.state.timezone
    },

    /**
     * 是否显示空结果状态
     */
    showEmpty() {
      if (!this.filterValueForTrim) return false
      const filterTimezoneList = this.timezoneList.filter((item) => new RegExp(this.filterValueForTrim.toLowerCase()).test(item.toLowerCase()))
      return !filterTimezoneList.length
    },
  },
  mounted() {},
  methods: {
    open() {
      this.show = true
      this.reset()
      this.queryTimezoneList(() => {
        this.$nextTick(() => {
          this.autoPosition()
        })
      })
    },

    handleClose() {
      this.show = false
    },

    reset() {
      this.loading = false
      this.filterValue = ''
    },

    /**
     * 设置时区
     * @param {String} timezone 时区
     */
    setTimezone(timezone) {
      Cookies.set(config.timezoneCookie, timezone, { expires: 365 })
      this.$store.dispatch('updateTimezone', timezone)
    },

    /**
     * 处理时区高亮
     * @param {String} value 时区
     * @returns 处理时区高亮结果
     */
    handleHighlight(value) {
      if (!this.filterValueForTrim) {
        return value
      }
      return value.replace(new RegExp(this.filterValueForTrim, 'i'), '<span style="color: #ffaa0a;">$&</span>')
    },

    /**
     * 过滤时区
     * @param {String} timezone 时区
     */
    filterTimezone(timezone) {
      if (!this.filterValueForTrim) return true
      return new RegExp(this.filterValueForTrim.toLowerCase()).test(timezone.toLowerCase())
    },

    /**
     * 清除过滤输入框
     */
    clearFilterValue() {
      this.filterValue = ''
    },

    /**
     * 查询国际时区列表
     */
    async queryTimezoneList(callback) {
      if (this.timezoneList.length) {
        callback()
        return
      }
      this.loading = true
      const res = await this.$axios.post(oneApi.queryTimezoneList, {}, { rewritePostBody: true })
      this.loading = false
      const { code, data = [] } = res
      if (code !== 0) {
        return
      }
      this.timezoneList = data
      callback()
    },

    /**
     * 上报埋点数据
     */
    trackReport() {
      this.Sensors.track('web_time_zone_change', {
        website_time_zone: this.timezone,
        device_time_zone: this.deviceTimezone,
        // 时区来源 1、原时区list 2、搜索结果
        time_zone_source: this.filterValue ? 2 : 1,
      })
    },

    /**
     * 自动定位到时区位置
     */
    autoPosition() {
      let timezoneIndex = 0
      this.timezoneList.forEach((item, index) => {
        if (item === this.timezone) {
          timezoneIndex = index
        }
      })
      if (timezoneIndex <= 2) {
        return
      }
      try {
        const itemHeight = document.getElementById('timezone-popup-result-wrapper').offsetHeight / this.timezoneList.length
        const scrollTopHeight = itemHeight * (timezoneIndex - 2)
        document.getElementById('timezone-popup-result').scrollTop = scrollTopHeight
      } catch (error) {
        console.log(error)
      }
    },
  },
}
