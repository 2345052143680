export default {
  setting: {
    personalInformation: '帳戶信息',
    personalInformationInfo: '完善帳戶信息，以便老師可以更好的提供服務',
    myFamily: '家庭帳號',
    myFamilyInfo: '管理我的家庭組成員',
    myAddress: '我的地址',
    myAddressInfo: '管理和編輯我的地址',
    security: '帳號安全',
    securityInfo: '管理我的登入手機號和登入郵箱',
    myAccount: '我的帳號',
  },
  payment: {
    tips: '為避免您忘記繳費導致退班，我們將為您開啟自動扣款服務（僅支持Stripe銀行卡）',
  },
}
