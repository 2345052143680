// 客户服务页面
export default {
  next: '다음',
  submit: '제출',
  back: '뒤로',
  feedbackTitle: '메시지 보내기',
  selectQuestionType: '문제 유형을 선택하세요',
  contentDesc: '문제를 자세히 설명해주세요',
  uploadDesc: '문제 설명에 사용할 이미지를 업로드하세요 (선택 사항)',
  contentDescValid: '문제를 입력해주세요',
  SubmitFail: '제출 실패, 나중에 다시 시도해주세요',
  SubmitSuccess: ['제출이 완료되었습니다. 여러분의 피드백을 받았습니다!', '영업일 기준 2일 이내에 다음과 같은 방법으로 선생님으로부터 답장을 받게 됩니다.',
    '[메시지 - 고객 서비스 메시지]', '의 알림을 확인하여 주의를 기울이십시오.'],
  selectQuestionTypeValid: '문제 유형을 선택해주세요',
  laterSubmit: '이미지 업로드 후 나중에 제출해주세요',
  selectStudentDesc: '어떤 학생이 학업 문제를 겪고 있나요?',
  selectStudentTipDesc: '적절한 선생님들이 질문에 답변해드릴 수 있도록 도와드리겠습니다',
  feedbackTitleInClass: '질문하기',
  SubmitSuccessInClass: ['제출이 완료되었습니다. 여러분의 피드백을 받았습니다!', '영업일 기준 2일 이내에 다음과 같은 방법으로 선생님으로부터 답장을 받게 됩니다',
    '[메시지 - 숙제 튜터]', '의 알림을 확인하여 주의를 기울이십시오.'],
}
