<template>
  <el-input v-model="inputValue" class="login_input" :placeholder="$t('login.login.loginForm.email.placeholder')" :clearable="true" @blur="inputBlurHandler" />
</template>
<script>
import { filterBlank } from 'common/utils.js';

export default {
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
  },
  data() {
    return {
      inputValue: '',
    }
  },
  watch: {
    value(val) {
      this.inputValue = val
    },
    inputValue(val) {
      this.$emit('input', val)
    },
  },
  methods: {
    inputBlurHandler() {
      this.value = filterBlank(this.value);
    },
  },
}
</script>
