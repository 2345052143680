/* eslint-disable */
/**
 * 头部导航store数据
 */

export const state = () => ({
  // 年级
  selectedGrade: ''
});

export const mutations = {
  /**
   * 设置头部导航透明状态
   * @param {State} state 
   * @param {年级} grade 
   */
  CHANGE_SELECT_GRADE(state, grade) {
    state.selectedGrade = grade;
  }
}