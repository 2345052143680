/**
 * Careers页面中间件
 */

export default function ({ app, store }) {
  // 客户端环境不执行
  if (process.client) return;

  // 美国官网插入greenhouse js，当前暂时写死地址
  // 英、新国家如果也有此需求，则js地址需要配置化
  if (store.state.locale === 'us') {
    app.head.script.push({
      src: 'https://boards.greenhouse.io/embed/job_board/js?for=thinkacademyus',
      defer: true,
    });
  } else if (store.state.locale === 'my') {
    app.head.script.push({
      src: 'https://boards.greenhouse.io/embed/job_board/js?for=thinkacademymy',
      defer: true,
    });
  } else if (store.state.locale === 'ca') {
    app.head.script.push({
      src: 'https://boards.greenhouse.io/embed/job_board/js?for=thinkacademycan',
      defer: true,
    });
  } else if (store.state.locale === 'au') {
    app.head.script.push({
      src: 'https://boards.greenhouse.io/embed/job_board/js?for=thinkacademyaus',
      defer: true,
    });
  } else if (store.state.locale === 'hk') {
    app.head.script.push({
      src: 'https://boards.greenhouse.io/embed/job_board/js?for=thinkacademyhk',
      defer: true,
    });
  } else if (store.state.locale === 'uk') {
    app.head.script.push({
      src: 'https://boards.greenhouse.io/embed/job_board/js?for=thinkacademyuk',
      defer: true,
    });
  } else if (store.state.locale === 'sg') {
    app.head.script.push({
      src: 'https://boards.greenhouse.io/embed/job_board/js?for=thinkacademysg',
      defer: true,
    });
  } else if (store.state.locale === 'bmsg') {
    app.head.script.push({
      src: 'https://boards.greenhouse.io/embed/job_board/js?for=bettermesg',
      defer: true,
    });
  } else if (store.state.locale === 'bmus') {
    app.head.script.push({
      src: 'https://boards.greenhouse.io/embed/job_board/js?for=bettermeus',
      defer: true,
    });
  } else if (store.state.locale === 'jp') {
    app.head.script.push({
      src: 'https://boards.greenhouse.io/embed/job_board/js?for=thinkacademyjapan',
      defer: true,
    });
  } else if (store.state.locale === 'kr') {
    app.head.script.push({
      src: 'https://boards.greenhouse.io/embed/job_board/js?for=thinkacademykorea',
      defer: true,
    });
  } else if (store.state.locale === 'fr') {
    app.head.script.push({
      src: 'https://boards.greenhouse.io/embed/job_board/js?for=thinkacademyfrance',
      defer: true,
    });
  }
}
