/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
/**
 * 自定义页面数据
 */

export const state = () => ({
  // 服务端最小首屏模块数量, 首屏渲染加速使用
  minFirstScreenModule: 2,
  // 页面模块列表
  pageModuleList: [],
  type: 0
})

export const mutations = {
  SET_PAGE_MODULE_PAGE(state, pageModuleList) {
    state.pageModuleList = pageModuleList.pageModuleList || []
    state.type = pageModuleList.type || 0
  },
}

export const actions = {
  updatePageData({ commit }, data) {
    commit('SET_PAGE_MODULE_PAGE', data || {})
  },
}
