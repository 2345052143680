const descriptionWins = `<p>EdTech 驅動的實時在線學習平台 </p>
<ul> 
<li>使用更大的屏幕訪問互動式虛擬教室</li>
<li>通過與同學見面來體驗同伴學習環境</li>

<li>回答問題並享受學習活動</li>
<li>有趣地贏取更多的獎勵</li>
</ul>
`;

const deviceRequireWins = [
  {
    label: '操作系統',
    desc: 'Windows 7及以上版本',
  },
  {
    label: '最低配置',
    desc: 'CPU 4 核，4GB RAM',
  },
  {
    label: '設備',
    desc: '<span> 不支持使用設備</span><span style="border-bottom: 1px dashed;">Chromebook和Surface Book</span>',
  },
]

const descriptionMac = `<p>EdTech 驅動的實時在線學習平台 </p>
<ul> 
<li>使用更大的屏幕訪問互動式虛擬教室</li>

<li>通過與同學見面來體驗同伴學習環境</li>

<li>回答問題並享受學習活動</li>
<li>有趣地贏取更多的獎勵</li>
</ul>
`;

const deviceRequireMac = [
  {
    label: '操作系統',
    desc: 'MacOS 10.12及以上',
  },
  {
    label: '最低配置',
    desc: 'CPU 4 核，4GB RAM',
  },
]

const descriptionPaid = `<p>EdTech 驅動的實時在線學習平台 </p>
<ul> 
<li>使用更大的屏幕訪問互動式虛擬教室</li>

<li>通過與同學見面來體驗同伴學習環境</li>

<li>回答問題並享受學習活動</li>
<li>有趣地贏取更多的獎勵</li>
</ul>
`;
const deviceRequirePaid = [
  {
    label: '操作系統',
    desc: 'iOS 10.0及以上版本',
  },
  {
    label: '設備',
    desc: 'iPad 5、iPad Air 3、iPad mini 5和所有更新型號',
  },
]

const descriptionIOS = `<p>EdTech 驅動的實時在線學習平台 </p>
<ul> 
<li>訪問互動式虛擬教室</li>

<li>回答問題並享受學習活動</li>

<li>有趣地贏取更多的獎勵</li></ul>
`;

const deviceRequireIOS = [
  {
    label: '操作系統',
    desc: 'iOS 10.0及以上版本',
  },
  {
    label: '設備',
    desc: 'iPhone7/Plus及所有更新型號',
  },
]

const descriptionAndroid = `<p>EdTech 驅動的實時在線學習平台 </p>
<ul> 
<li>訪問互動式虛擬教室</li>

<li>回答問題並享受學習活動</li>

<li>有趣地贏取更多的獎勵</li></ul>
`;

const deviceRequireAndroid = [
  {
    label: '操作系統',
    desc: 'Android 6.0及以上',
  },
  {
    label: '最低配置 (Tablet)',
    desc: '2.0 GHz處理器速度，4GB RAM',
  },
]

export default {
  title: 'Think Academy 課堂',
  download: '下載',
  version: '版本',
  scan: '掃描二維碼',
  or: '或',
  keys: {
    mac: 'MAC_PC_STUDENT',
    windows: 'WIN_PC_STUDENT',
    ipad: 'IOS_HD_STUDENT',
    iphone: 'IOS_PHONE_STUDENT',
    android: 'ANDROID_PHONE_STUDENT',
  },
  paramsKeys: {
    mac: 'Mac',
    windows: 'Windows',
    ipad: 'iPad',
    iphone: 'IOS',
    android: 'Android',
  },
  ableTabs: [
    {
      title: 'Windows',
      name: 'windows',
      requirement: 'Windows 7 and above.',
      download_btn: true,
      description: `${descriptionWins}`,
      deviceRequire: {
        title: '兼容性和最低電腦配置',
        list: deviceRequireWins,
      },
    },
    {
      title: 'Mac',
      name: 'mac',
      requirement: 'MacOS 10.12及以上.',
      download_btn: true,
      description: `${descriptionMac}`,
      deviceRequire: {
        title: '兼容性和最低電腦配置',
        list: deviceRequireMac,
      },
    },
    {
      title: 'iPad',
      name: 'ipad',
      requirement: 'iOS 10.0及以上版本',
      download_btn: true,
      description: `${descriptionPaid}`,
      deviceRequire: {
        title: '',
        list: deviceRequirePaid,
      },
    },
    {
      title: 'Android Tablet',
      name: 'android',
      requirement: 'Android 6.0及以上.',
      description: `${descriptionAndroid}`,
      deviceRequire: {
        title: '',
        list: deviceRequireAndroid,
      },
    },

  ],
  parentTabs: [
    {
      title: 'iPhone',
      name: 'iphoneParent',
      requirement: 'Only supports iOS 10.0 and above. ',
      description: `${descriptionIOS}`,
      deviceRequire: {
        title: '',
        list: deviceRequireIOS,
      },
    },
    {
      title: 'Android',
      name: 'androidParent',
      requirement: 'Only supports Android 6.0 and above.',
      description: `${descriptionAndroid}`,
      deviceRequire: {
        title: '',
        list: deviceRequireAndroid,
      },
    },
  ],
}
