<template>
  <div class="common-rate">
    <i v-for="index of max" :key="index" class="iconfont" :class="[icon, index <= rateLevel ? 'on' : 'off',index <= rateLevel ? 'onColor' : 'voidColor']"
       :style="{'font-size': iconSize}"
    />
  </div>
</template>
<script>
export default {
  props: {
    rateLevel: {
      type: Number,
      default: 0,
    },
    icon: {
      type: String,
      default: 'icon-star',
    },
    onColor: {
      type: String,
      default: '#FF850A',
    },
    voidColor: {
      type: String,
      default: '#FFBB77',
    },
    max: {
      type: Number,
      default: 5,
    },
    iconSize: {
      type: String,
      default: '18px',
    },

  },
  data() {
    return {
    }
  },
}
</script>
<style lang="scss" scoped>
.common-rate {
  i {
    font-size: 18px;
    vertical-align: middle;
    transition: all 0.2s linear;

    &.on {
      color: var(--txt-color-link) !important;
    }

    &.off {
      color: rgba(255, 170, 10, 0.3);
    }

    &.onColor {
      color: #ff850a;
    }

    &.voidColor {
      color: #fb7;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}
</style>
