/**
 * 编辑地址相关文案
 */export default {
  showPostcode: false,
  addressForm: {
    name: {
      label: '姓名',
      placeholder: '姓名',
    },
    phone: {
      label: '電話',
      placeholder: '電話',
    },
    postcode: {
      label: '郵政編碼',
      placeholder: '郵政編碼',
    },
    address1: {
      label: '地址1',
      placeholder: '地址1',
    },
    address2: {
      label: '地址2（選填）',
      placeholder: '地址2（選填）',
    },
    city: {
      label: '城鎮/城市',
      placeholder: '城鎮/城市',
    },
    state: {
      label: '州',
      placeholder: '州',
    },
    country: {
      label: '國家',
      placeholder: '國家',
    },
  },
  saveText: '保存',
  billingAddress: '通訊地址',
  deliveryAddress: '郵寄地址',
  billingAddressTip: '請添加通訊地址',
  materialsTip: '添加新地址以接收學習資料',
  editText: '編輯地址',
  noAddressTip: '請提供您的聯絡地址',
  default: '默認地址',
  edit: '編輯',
  delete: '删除',
  setAsDefault: '設定默認地址',
  viewMore: '查看更多地址',
  viewLess: '收起',
  addANewAddress: '添加新地址',
  defaultAddress: '默認地址',
  deleteConfirmTip: '您確定要删除這個地址麼？',
  confirm: '確認',
  cancel: '取消',
  noAddressList: '您還沒有添加過地址',
  changeAddress: '更改地址',
  curAddress: '當前地址',
  chooseANewAddress: '選擇新地址',
  selectOtherAddress: '選擇其他地址',
}
