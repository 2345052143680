/* eslint-disable global-require */
// 关于学而思文案配置
export default {
  nameIntro: {
    title: '學而思名字的由來',
    desc: [
      '孔子說“學而不思則罔，思而不學則殆”，告誡人們要將學習和思考結合起來，才能學到切實有用的知識和經驗。',
      '學習，不僅是要向書本學習，更要向一切人學習，向一切事物學習；思考，不應只滿足於第一次思考，更要定期反思自己，博學而篤志。',
    ],
  },

  logoIntro: {
    title: '學而思問號的含義',
    desc: [
      '如果用一個符號代表生命的不息和生活的向前，那麼一定是“？”',
      '代表“疑問”的問號將“學習”與“思考”相結合，強調“學起於思，思源於疑”的哲學精神，代表了學而思激發孩子的主動性和創造性，讓孩子在人生的道路上不斷學習與思考，在疑惑中探索，在探索中提升，從而獲得受益一生的能力。',
      '問號是由五塊不同形狀、大小、顏色的方塊組成，寓意著多彩的學習內容、豐富的教學手段、多元的評價體系、陽光的生活態度以及循序漸進的學習過程，更象徵著孩子們在好的學習環境下將會獲得的五彩斑斕的絢麗人生。',
    ],
  },

  educationConcept: {
    title: '學而思教育理念',
    listData: [
      {
        tag: '激發動力',
        title: '激發動力，讓小朋友愛學',
        desc: '學生能否主動學習，取決於是否有足夠的學習興趣，學而思沿用啟發式教學，讓小朋友愛上主動學習。學而思課堂通過趣味教材和生動講解，再結合科技和遊戲互動元素，多方面激發孩子學習興趣，並通過對孩子自信心和成就感的培養，將學習興趣轉化成自主學習動力。',
        picture: require('assets/images/about-us/education-concept-1.jpg'),
      }, {
        tag: '培養能力',
        title: '培養能力，讓小朋友會學',
        desc: '當一個小朋友愛學習、想學習後，他就會自發去探索和獲取知識。而學而思的第二個教育理念，就是賦予小朋友能夠獲取和吸收知識的能力，學會正確的學習方法。教授知識只是一個外在的現象，我們更希望培養小朋友的能力，讓他們在未來道路上走得更遠、更好。',
        picture: require('assets/images/about-us/education-concept-2.jpg'),
      },
    ],
  },

  developmentHistory: {
    title: '學而思發展歷程',
    btnText: ['收起全部發展歷程', '查看全部發展歷程'],
    listData: [
      {
        time: '2021年',
        cases: ['5月成功舉辦首屆「學而思盃」全港奧數精英挑戰賽'],
      }, {
        time: '2020年',
        cases: ['9月-發佈「盃賽一條龍」服務平台，家長可24小時查詢各盃賽資訊', '3月-推出「公益直播課：21日訓練營」', '1月-學員於華夏盃決賽榮獲狀元'],
      }, {
        time: '2019年',
        cases: ['成功舉辦首屆「香港學而思筆記大賽」'],
      }, {
        time: '2018年',
        cases: ['成立學而思香港分校'],
      }, {
        time: '2017年',
        cases: ['品牌理念升級為「受益一生的能力」'],
      }, {
        time: '2013年',
        cases: ['體驗式教學反饋系統正式上線'],
      }, {
        time: '2012年',
        cases: ['發佈教育理念「激發興趣、培養習慣、塑造品格」'],
      }, {
        time: '2011年',
        cases: ['ICS2.0智能教學系統全面上線'],
      }, {
        time: '2010年',
        cases: ['在美國紐交所正式掛牌上市'],
      }, {
        time: '2009年',
        cases: ['推出小學奧數十二級課程體系'],
      }, {
        time: '2008年',
        cases: ['走出北京，開始在全國開設分校'],
      }, {
        time: '2007年',
        cases: ['「2007年家長心目中最具影響力的輔導機構」'],
      }, {
        time: '2003年',
        cases: ['學而思正式成立，主營小學奧數輔導'],
      },
    ],
  },

  aboutTal: {
    title: '關於好未來教育集團',
    desc: '好未來教育集團（TAL Education Group, NYSE：TAL）是一個以智慧教育和開放平臺為主體，以質素教育和課外輔導為副體，致力於全球各地的公共及私營教育，探索未來教育新模式的科技教育公司。旗下有學而思、學而思網校、家長幫、摩比思維、勵步英語、愛智康等品牌，以及Minerva大學、果殼網、寶寶樹、Knewton、作業盒子等投資項目。2010年，好未來教育集團在美國紐交所上市。',
  },
};
