import commonPagesConfig from 'config/pages/common'
import evaluation from 'common/evaluation.js'
import bus from 'common/bus'
import { oneApi } from 'config/api'

export default {
  props: {
    // 吸顶状态
    stickyStatus: {
      default: true,
      type: Boolean,
    },
  },
  data() {
    const userNavigationLinks = commonPagesConfig.navigation.getUserNavigationLinks(this)
    return {
      locale: this.$store.state.locale,
      langContext: this.$t('featureComponents.Header'),
      userNavigationLinks,
      siteShowNav: false, // 是否展示导航栏
      siteJumpUrl: '/', // 站点跳转链接
      tipsList: [],
    }
  },
  computed: {
    isLogin() {
      return this.$store.state.ucenter.isLogin;
    },
    userInfo() {
      return this.$store.state.ucenter.user
    },
    currentRoutePath() {
      return this.$route.path
    },
    headerType() {
      return this.$store.state.website.config.headerType
    },
    navigationLinks() {
      return this.$store.state.website.header.navigationLinks
    },
    // 主导航列表
    mainNavList() {
      return this.navigationLinks.filter((item) => (item.navigation && item.navigation === 1) || !item.navigation)
    },
    // 副导航列表
    subNavList() {
      return this.navigationLinks.filter((item) => item.navigation && item.navigation === 2)
    },
    logoUrl() {
      const { config } = this.$store.state.website || {}
      return process.env.clientType === 'web' ? (config.logo || this.$t('common.logo.normal')) : (config.whiteLogo || this.$t('common.logo.normalWhite'))
    },
    isWebClient() {
      return process.env.clientType === 'web'
    },
    isShowTipsBox() {
      const currentFullPath = this.$route.fullPath
      const hasShowTrue = this.tipsList.some((e) => e.show === true)
      return hasShowTrue && !currentFullPath.includes('recommended-courses')
    },
  },
  mounted() {
    this.querySiteNavShow()
    this.getsiteJumpUrl()
  },
  methods: {
    handleClickNav(name) {
      this.Sensors.track('component_click', {
        component_name: name,
      })
    },
    // 查询是否展示导航栏
    querySiteNavShow() {
      // 希望杯站点不展示导航栏
      const { isHopePath, sourceSite } = this.getHopeCupSiteInfo()
      if (isHopePath && !sourceSite) {
        window.sessionStorage.setItem('source_site', 'hopecup')
        window.sessionStorage.setItem('header_site', this.headerType)
      }
      this.siteShowNav = !isHopePath && !sourceSite
    },
    // 获取logo跳转链接
    getsiteJumpUrl() {
      const { isHopePath, sourceSite } = this.getHopeCupSiteInfo()
      this.siteJumpUrl = isHopePath || sourceSite ? '/hope-cup' : '/'
    },
    // 获取希望杯站点信息
    getHopeCupSiteInfo() {
      const isHopePath = this.currentRoutePath === '/hope-cup'
      const sessionSourceSite = window.sessionStorage.getItem('source_site') || ''
      return { isHopePath, sourceSite: sessionSourceSite === 'hopecup' }
    },
    goAppointment(item) {
      // 7诊断预约 9已诊断 11预约诊断报告咨询 13完成诊断报告咨询 27长期班待支付
      if (item.tag === 9 || item.tag === 13 || item.tag === 27) {
        const params = JSON.parse(item.param)
        const queryObj = {
          reportId: params.id,
        }
        if (item.tag === 9) {
          evaluation.toEvaluation(this.$store, '/report/contentPage', queryObj)
        }
        if (item.tag === 13 || item.tag === 27) {
          this.$router.push(`/test/recommended-courses?reportId=${params.id}&uid=${item.uid}`);
        }
      } else if (item.tag === 7 || item.tag === 11) {
        const params = encodeURIComponent(item.param)
        if (this.isWebClient) {
          bus.$emit('toShowDiagnosis', { type: 4, url: params })
        } else {
          const currentFullPath = this.$route.fullPath
          const backUrl = encodeURI(currentFullPath)
          this.$router.replace(`/appointmentDiagnosis?cancelUrl=${backUrl}&successUrl=${backUrl}&type=4&url=${params}`);
        }
      }
    },
    async getEvalutaionTips() {
      const studentsList = window.localStorage.getItem('studentsList');
      const uids = studentsList ? JSON.parse(studentsList).map((e) => e.uid) : []
      const res = await this.$axios.post(oneApi.getTips, {}, { rewritePostBody: true, uid: uids || [] })
      if (!this.isLogin) {
        return
      }
      this.tipsList = res.data.examTipList || []
      if (this.tipsList.length === 0) {
        return
      }
      this.tipsList.forEach((e, index) => {
        e.show = index === 0;
      });
    },
    close(item, index) {
      if (this.tipsList.length === index + 1) {
        item.show = false
        this.tipsList = JSON.parse(JSON.stringify(this.tipsList))
        const timestamp = new Date().getTime()
        window.localStorage.setItem('closeAllTipsTime', timestamp)
        window.localStorage.setItem('closeAllTipsStatus', true)
        return
      }
      item.show = false
      this.tipsList[index + 1].show = true
      this.tipsList = JSON.parse(JSON.stringify(this.tipsList))
    },
  },
}
