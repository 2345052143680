import evaluationUrl from 'config/evaluationUrl.js'

/**
 * 去诊断
 * @param {String} locale
 * @param {Number} examId
 * @param {Object} queryObj
 */

const toEvaluation = (store, path, queryObj, pageSource = 'other') => {
  const { locale } = store.state
  if (!locale || !path) return
  const origin = evaluationUrl.origin[process.env.runtime][locale];
  // const evaluationPath = `/evaluation/instruction/${examId}`
  const evaluationPath = path
  let link = origin + evaluationPath
  let referUrl = window.location.href

  let queryData = {}
  const utmSource = window.sessionStorage.getItem('utm_source');
  const utmMedium = window.sessionStorage.getItem('utm_medium');
  const utmCampaign = window.sessionStorage.getItem('utm_campaign');

  if (utmSource && utmMedium && utmCampaign) {
    if (!referUrl.includes('utm_source') || !referUrl.includes('utm_medium') || !referUrl.includes('utm_campaign')) {
      const symbol = referUrl.includes('?') ? '&' : '?'
      referUrl = `${referUrl}${symbol}utm_source=${utmSource}&utm_medium=${utmMedium}&utm_campaign=${utmCampaign}`
    }
  }

  const {
    eventType, eventId, sourceType, sourceId,
  } = store.state.attribution

  if (eventType) queryData.event_type = eventType
  if (eventId) queryData.event_id = eventId
  if (sourceType) queryData.source_type = sourceType
  if (sourceId) queryData.source_id = sourceId

  queryData.referUrl = encodeURIComponent(referUrl);
  queryData.source = pageSource
  queryData = Object.assign(queryData, queryObj)
  const keys = Object.keys(queryData)

  for (let i = keys.length - 1; i >= 0; i -= 1) {
    const symbol = link.includes('?') ? '&' : '?'
    const value = queryData[keys[i]]
    if (value) link += `${symbol}${keys[i]}=${queryData[keys[i]]}`
  }
  window.open(link, '_blank')
}

export default {
  toEvaluation,
}
