/* eslint-disable no-param-reassign */
/**
 * 检测设置分校标识插件
 */

import config from 'config/index';
import localeConfig from 'config/localeConfig'

export default function ({ req, app, store }) {
  // 客户端环境不执行
  if (process.client) return;

  // 国家域名语言环境映射关系
  const { domainsLocaleMap } = config;

  // 主机名
  const { hostname } = req.ctx;
  console.log('hostname123', hostname)
  // 默认语言环境
  let locale = config.fallbackLocale;

  // 根据主机名定位语言环境
  Object.keys(domainsLocaleMap).forEach((key) => {
    if (hostname.includes(key)) {
      locale = domainsLocaleMap[key];
    }
  })

  if (locale === 'hk' || locale === 'ai') {
    app.head.htmlAttrs.lang = 'zh-Hant'
  }

  if (hostname.includes('bettermeedu')) {
    store.commit('SET_BETTERME', 'bettermeedu');
  } else {
    store.commit('SET_BETTERME', 'none');
  }

  // 设置语言环境
  store.commit('SET_LOCALE', locale);

  // app.head.htmlAttrs.id = `style__${locale}`

  // 设置分校配置
  store.commit('SET_LOCALE_CONFIG', localeConfig[locale] || {});

  // 设置国家编码
  const countryCode = config.countryCodeMap[locale];
  store.commit('SET_COUNTRY_CODE', countryCode);

  // 设置分校编码
  const schoolCode = config.schoolCodeMap[locale];
  store.commit('SET_SCHOOL_CODE', schoolCode);
}
