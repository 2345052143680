/* eslint-disable */
/**
 * 课程store数据
 */
import { oneApi } from 'config/api'
import { getClientType } from 'common/utils'

export const state = () => ({
  defaultGrade: {value: '', name: ''},
  selectedGrade: '',
  selectedGradeStageIndex: 0,
  selectedGradeName: '',
  selectedChannelGroupIndex: 0,
  selectedSubject: '',
  selectedDifficulty:0,
  gradeList: [],
  channelList: [],
  subjectList: [],
  bannerObj: {},
  // 班次级别列表
  levelDegreeList: [],
  optionalLevelDegreeList:[{ value: 0, label: 'ALL' }],
  activeSubjectIndex: 0,
  // app选择年级
  appSelectedGrade: '',
  // app学期名字
  appSelectedTermName: '',
  // 课程类型下标
  selectedCourseTypeIndex: 0,
  // 课程类型
  selectedCourseType: 0,
  // 学期列表
  termList: [],
  // 课程类型
  coursesTypeList: [],
  
  // 要加载更多的栏目
  pagingLoadingItem: [1, 2],
  /**
   * 栏目id：
   * 1 - 长期班
   * 2 - 短期班
   */
  longTermId: 1,
  shortTermId: 2,
    // 国家列表
    countryList: [],
    queryFilter:{},
    selectedChannelGroup:'',
    selectedChannel:''
});

export const mutations = {
  /**
   * 改变banner资源
   * @param {State} state
   * @param {某年级的banner资源} banner
   */
  ADD_GRADE_BANNER(state, banner) {
    state.bannerObj[state.selectedChannel] = banner;
  },
  /**
   * 改变选择年级
   * @param {State} state
   * @param {年级选项} options
   */
  CHANGE_SELECTED_GRADE(state, options) {
    state.selectedGrade = options.gradeId;
    state.selectedGradeName = options.gradeName;
  },
  /**
   * 设置默认年级数据
   * @param {*} state 
   * @param {*} grade 
   */
    /**
   * 设置选中年级的学部
   * @param {state} state 
   * @param {gradeStageIndex} index 
   */
  SET_SELECT_GRADE_STAGE_INDEX(state, index) {
    state.selectedGradeStageIndex = index
  },
  SET_DEFAULT_GRADE(state, grade) {
    state.defaultGrade = Object.assign(state.defaultGrade, grade)
  },
  /**
   * 设置选中频道组
   * @param {state} state 
   * @param {selectedChannelGroupIndex} index 
   */
  SET_SELECT_CHANNEL_GROUP_INDEX(state, index) {
    state.selectedChannelGroupIndex = index
  },
  /**
   * 改变选择课程
   * @param {State} state
   * @param {课程vlaue} subject
   */
  CHANGE_SELECTED_SUBJECT(state, subject) {
    state.selectedSubject = subject;
  },
    /**
   * 改变选择难度
   * @param {State} state
   * @param {课程vlaue} level
   */
  CHANGE_SELECTED_LEVEL(state, level) {
    state.selectedDifficulty = level;
  },
  /**
   * 存储年级列表
   * @param {State} state
   * @param {年级列表} gradeList
   */
  SET_GRADE_LIST(state, gradeList) {
    state.gradeList = gradeList;
  },
  /**
   * 存储全量channel列表
   * @param {State} state
   * @param {channel列表} channelList
   */
   SET_CHANNEL_LIST(state, channelList) {
    state.channelList = channelList;
  },
  /**
   * 存储学科列表
   * @param {State} state
   * @param {学科列表} subjectList
   */
  SET_SUBJECT_LIST(state, subjectList) {
    state.subjectList = subjectList;
  },
  /**
   * SubjectName  选择年级名字
   */
  CHANGE_SELECTED_SUBJECT_NAME(state, subjectName) {
    state.SelectedSubjectName = subjectName;
  },
  /**
   * 改变选择课程
   * @param {State} state
   * @param {下标} index
   */
  ACTIVE_SUBJECT_INDEX(state, index) {
    state.activeSubjectIndex = index;
  },
  /**
   * index  课程类型下标
   */
  CHANGE_SELECTED_COURSE_TYPE_INDEX(state, index) {
    state.selectedCourseTypeIndex = index
  },
  /**
   * app grade设置
   */
  CHANGE_APP_SELECTED_GRADE(state, grade) {
    state.appSelectedGrade = grade;
  },
  /**
   * gradeName  选择年级名字
   */
  CHANGE_APP_SELECTED_SUBJECT_NAME(state, subjectName) {
    state.appSelectedSubjectName = subjectName;
  },
  /**
   * index  课程类型
   */
  CHANGE_SELECTED_COURSE_TYPE(state, type) {
    state.selectedCourseType = type
  },
  /**
   * id 为加载项
   */
  GET_PAGE_LOADING_ITEM(state, id) {
    state.pagingLoadingItem = id;
  },
  /**
   * list 学期列表
   */
  CHANGE_TERM_LIST(state, list) {
    state.termList = list;
  },
  /**
   * list 课程类型列表
   */
  CHANGE_COURSES_TYPE_LIST(state, list) {
    state.coursesTypeList = list;
  },
  /**
   * list 班次级别/难度列表
   */
  CHANGE_LEVEL_DEGREE_LIST(state, list) {
    state.levelDegreeList = list;
  },
    /**
   * list 可选班次级别/难度列表
   */
  CHANGE_OPTIONAL_LEVEL_DEGREE_LIST(state, list) {
    state.optionalLevelDegreeList = list;
  },
    /**
   * 国家 
   */
  SET_COUNTRY_LIST(state, list) {
    state.countryList = list;
  },

  CHANGE_FILTER_DATA(state, list) {
    state.queryFilter = list;
  },

   /**
   * 改变选择频道组
   * @param {State} state
   * @param {频道组} options
   */
  CHANGE_SELECTED_CHANNEL_GROUP(state, options) {
    state.selectedChannelGroup = options;
  },
     /**
   * 改变选择频道
   * @param {State} state
   * @param {频道} options
   */
  CHANGE_SELECTED_CHANNEL(state, options) {
    state.selectedChannel = options;
  },
}

export const actions = {
  /**
   * mock获取年级数据
   */
  async queryGradeList({ commit, state }, { $axios, callback }) {
    if (!state.gradeList.length) {
      const res = await $axios.post(oneApi.queryGrades, {}, { rewritePostBody: true })
      // 业务异常阻断
      if (res.code != 0) {
        if (process.env.clientType !== 'web') {
          this.$Toast(res.message);
        }
        return
      }
      const gradeList = res.data.list;
      gradeList.length && commit('CHANGE_SELECTED_GRADE', { gradeId: gradeList[0].list[0].value, gradeName: gradeList[0].list[0].name })
      gradeList.length && commit('SET_GRADE_LIST', gradeList)

      if(gradeList.length && 'list' in gradeList[0] && gradeList[0].list.length) {
        commit('SET_DEFAULT_GRADE', gradeList[0].list[0])
      }
      callback && callback()
    }
  },
  /**
   * 获取配置channel
   */
  async queryChannelList({ commit, state }, { $axios, callback }) {
    if (!state.channelList.length) {
      const res = await $axios.post(oneApi.getChannel, { hideGroup: true }, { rewritePostBody: true })
      // 业务异常阻断
      if (res.code !== 0) {
        return
      }
      const channelList = res.data.channels || [];
      if (channelList.length) {
        commit('SET_CHANNEL_LIST', channelList)
      }
      if (callback) callback()
    }
  },
  /**
   * mock获取subject数据
   */
  async querySubjectList({ commit, state }, { $axios, callback }) {
    if (!state.subjectList.length) {
      const res = await $axios.post(oneApi.querySubjects, {}, { rewritePostBody: true })
      // 业务异常阻断
      if (res.code != 0) {
        if (process.env.clientType !== 'web') {
          this.$Toast(res.message);
        }
        return
      }
      const subjectList = res.data.list

      if (process.env.clientType === 'web') {
        subjectList.unshift({ name: 'ALL', value: 0 });
        subjectList.length && commit('CHANGE_SELECTED_SUBJECT_NAME', subjectList[0].name)
      } else {
        subjectList.unshift({ name: 'Selected', value: 0 });
        subjectList.length && commit('CHANGE_APP_SELECTED_SUBJECT_NAME', subjectList[0].name)
      }
      subjectList.length && commit('CHANGE_SELECTED_SUBJECT', subjectList[0].value)
      subjectList.length && commit('SET_SUBJECT_LIST', subjectList)

      callback && callback()
    }
  },
  /**
   * 请求学期列表数据
   */
  async queryTermList({ commit, state }, { $axios, callback }) {
    if (!state.termList.length) {
      const res = await $axios.post(oneApi.queryTerms, {}, { rewritePostBody: true })
      // 业务异常阻断
      if (res.code != 0) {
        if (process.env.clientType !== 'web') {
          this.$Toast(res.message);
        }
      }
      const termList = res.data.list;
      termList.unshift({ text: 'ALL', value: 0 })
      termList.length && commit('CHANGE_TERM_LIST', termList)
      callback && callback()
    }
  },
  async queryCourseTypes({ commit, state }, { $axios, callback }) {
    if (!state.coursesTypeList.length) {
      const res = await $axios.post(oneApi.queryCourseTypes, {}, { rewritePostBody: true })
      // 业务异常阻断
      if (res.code != 0) {
        if (process.env.clientType !== 'web') {
          this.$Toast(res.message);
        }
      }
      const coursesTypeList = res.data.list;
      coursesTypeList.unshift({ name: 'ALL', value: 0 })
      coursesTypeList.length && commit('CHANGE_COURSES_TYPE_LIST', coursesTypeList)
      callback && callback()
    }
  },
  /**
   * 获取班次级别列表
   */
  async queryLevelDegreeList({ commit, state }, { $axios, callback }) {
    if (!state.levelDegreeList.length) {
      const res = await $axios.post(oneApi.queryLevelDegree, {}, { rewritePostBody: true })
      // 业务异常阻断
      if (res.code != 0) {
        if (process.env.clientType === 'web') {
          this.$ElMessage.error(res.message);
        } else {
          this.$Toast(res.message);
        }
        return
      }
      const levelDegreeList = res.data.list;
      levelDegreeList.length && commit('CHANGE_LEVEL_DEGREE_LIST', levelDegreeList)
      callback && callback()
    }
  },
  async queryCountryList({ commit, state }, { $axios, callback }) {
    if (!state.countryList.length) {
      const res = await $axios.post(oneApi.queryCountry, {}, { rewritePostBody: true })
      // 业务异常阻断
      if (Number(res.code) !== 0) {
        if (process.env.clientType === 'web') {
          this.$ElMessage.error(res.message);
        } else {
          this.$Toast(res.message);
        }
      }
      const countryList = res.data.list;
      if (countryList.length) commit('SET_COUNTRY_LIST', countryList)
      if (callback) callback()
    }
  },
  // 获取商城banner图
  async getCoursesBannerList({ commit, state }, { $axios, callback }) {
    const  channelIds= [];
    channelIds.push(state.selectedChannel)
    const data = [];
    const item = {};
    item.locationKey = `${getClientType()}-shop_home_screen`;
    item.channelIds = channelIds;
    data.push(item);
    const res = await $axios.post(oneApi.getCoursesBannerList, data, { rewritePostBody: true });
    // 业务异常阻断
    if (Number(res.code) !== 0) {
      return
    }
    if (Array.isArray(res.data) && res.data.length > 0 && 'resources' in res.data[0]) {
      commit('ADD_GRADE_BANNER', res.data[0].resources);
      // console.log('store________________bannerObj:',state.bannerObj);
    }
    callback && callback();
  },
  //获取筛选器
  async queryFilter({ commit }, { $axios, mallGroup ,callback }) {
      const res = await $axios.post(oneApi.getChannel, {mallGroup:mallGroup}, { rewritePostBody: true })
     // 业务异常阻断
      if (res.code != 0) { 
        return
      }  
        commit('CHANGE_FILTER_DATA', res.data) 
     
    callback && callback()
 
  },
}
