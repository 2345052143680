// 诊断页面配置
export default {
  understandingDialog: {
    title: 'Understanding the test result',
    items: [
      {
        title: 'What is this test result for?',
        desc: 'You can use the level test result to help choose the {0} course most suited to your child\'s {1} ability.',
      },
      {
        title: 'What are the different difficulty levels?',
        desc: 'Think Academy provides courses with the following difficulty levels for children of mixed abilities.',
      },
    ],
  },
};
