/* eslint-disable */
/**
 * recaptch 防刷参数
 */

export const state = () => ({
  // 令牌
  token: '',
  // 形式
  action: ''
});

export const mutations = {
  /**
   * @param {State} state 
   * @param {令牌} token 
   */
  SEND_GRC_TOKEN(state, token) {
    state.token = token;
  },
  SEND_GRC_ACTION(state, action) {
    state.action = action;
  },
}