/**
 * 时区设置中间件
 * Cookie: _official_timezone 为用户手动设置时区缓存
 * Cookie: _app_timezone 为App端传入的时区
 * 优先使用用户设置的时区
 * 此中间件放在basicData中间件之后, basicData会设置分校默认时区
 */
export default async function ({ store, req }) {
  if (process.client) return
  const { cookies } = req.ctx
  const { enableTimezone } = store.state
  const timezoneCache = cookies.get('_official_timezone') || cookies.get('_app_timezone') || ''
  if (timezoneCache) {
    if (enableTimezone) {
      // 分校开启时区功能则更新时区配置
      store.dispatch('updateTimezone', timezoneCache)
    } else {
      // 分校未开启时区功能则清除时区缓存
      cookies.set('_official_timezone', '')
    }
  }
}
