/**
 * 自定义页面请求导航栏数据中间件
 */

import { oneApi } from 'config/api'
import { clearUrlEndSlash } from 'common/utils'

export default async function ({
  req, route, store, app, error,
}) {
  const host = process.server ? req.ctx.hostname : window.location.hostname
  const { viewModel, previewSign } = route.query
  const path = clearUrlEndSlash(route.path)
  let apiUrl = oneApi.queryPageData

  const params = {
    host,
    path,
  }

  // 自定义页面预览
  if (viewModel === 'preview' && previewSign) {
    apiUrl = oneApi.queryPagePreviewData
    params.sign = previewSign
  }
  const res = await app.$axios.post(apiUrl, params, { rewritePostBody: true, sendToken: false })
  const { code, data = {} } = res || {}

  if (code === 4003) {
    error({ statusCode: 404 })
    return
  }

  if (code !== 0) {
    return
  }

  store.dispatch('customPage/updatePageData', data)
}
