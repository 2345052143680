/**
 * 获取alipay HK灰度用户
 * 将开关状态存储于store中
 */

export default async function ({ store, app }) {
  // 客户端环境不执行
  if (process.client) return;
  await store.dispatch('ucenter/getAlipayGrayUser', { $axios: app.$axios });
}
