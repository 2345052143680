export default {
  coupon: {
    selectCoupons: 'Sélectionner les coupons',
    tips: 'Recommandé <span class="orange-text">{x} coupon(s)</span> sélectionné, sauver <span class="orange-text">{y}</span>',
    couponTips: '<span class="orange-text">{x} coupon(s)</span> sélectionné, sauver <span class="orange-text">{y}</span>',
    noSelectedTips: '<span class="orange-text">{x} Coupon(s)</span> Disponible',
    changeCard: 'Changer de carte de paiement',
    automaticPay: 'Paiement automatique',
    authorized: 'Autorisé',
    unauthorized: 'Non autorisé',
  },
}
