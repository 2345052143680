<template>
  <div class="chat-input" :class="{'hide': isHide, 'has-input': hasInput}">
    <div v-if="maxTextLen" class="chat-input-tip">
      <img v-lazy-load data-src="../imgs/warning.png" alt="">
      <span class="max-text">* Maximum of 500 characters is allowed.</span>
    </div>
    <el-input
      ref="input"
      v-model="textarea"
      type="textarea"
      :autosize="{ maxRows: 8}"
      :maxlength="maxLen"
      placeholder="Write a reply…"
      @keyup.enter.native="handleKeyup"
      @keydown.enter.native="handleKeydown"
    />
    <i class="iconfont icon-send" @click="handlemessageSend" />
  </div>
</template>

<script>
import mixin from './mixin.js';

export default {
  mixins: [mixin],
  watch: {
    isHide(newVal) {
      if (!newVal) {
        this.$nextTick(() => {
          this.$refs.input.focus();
        });
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.chat-input {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #e6e6e6;

  .send-img {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  /deep/.el-textarea {
    padding: 17px 0 17px 20px;

    textarea::-webkit-scrollbar {
      width: 3px;
    }

    .el-textarea__inner {
      font-size: 14px;
      font-weight: 500;
      min-height: 22px;
      max-height: 166px;
      line-height: 22px;
      color: var(--txt-color-lv1);
      border: none;
      resize: none;
      padding: 0;
      padding-right: 98px;
    }
  }

  .chat-input-tip {
    position: absolute;
    top: -45px;
    left: 20px;
    display: flex;
    align-items: center;
    padding: 9px 10px;
    border-radius: 4px;
    background: rgba(255, 80, 63, 0.1);
    border: 1px solid rgba(255, 80, 63, 0.6);

    img {
      width: 14px;
      height: 14px;
      object-fit: contain;
    }

    .max-text {
      font-size: 12px;
      line-height: 14px;
      color: #ff503f;
    }
  }

  .icon-send {
    position: absolute;
    right: 20px;
    top: 17px;
    font-size: 24px;
    cursor: pointer;
  }
}

.hide {
  visibility: hidden;
}

.has-input {
  .icon-send {
    color: var(--txt-color-link);
  }
}
</style>
