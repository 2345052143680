export default {
  props: {
    messages: {
      type: Array,
      required: true,
    },
  },
  mounted() {
    this.$nextTick(this.scrollDown())
  },
  updated() {
    this.$nextTick(this.scrollDown())
  },
  methods: {
    scrollDown() {
      this.$refs.scrollList.scrollTop = this.$refs.scrollList.scrollHeight
    },
    handleRetry(contentId) {
      this.$emit('retry', contentId)
    },
  },
}
