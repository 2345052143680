<template>
  <div class="side-bar-right-wrapper">
    <!-- 侧边栏-宣传位 -->
    <div v-if="isShowAdvertising && isShowToolGroup && showRightTool" class="side-bar-advertising" :style="{'background-image':`url(${advertisingData.src})`}" @click="adClickHandler(advertisingData)" />
    <div v-if="isShowToolGroup && showRightTool && isShowTool" class="tools-group">
      <!-- <div class="tools-group"> -->
      <template v-for="(item, index) in tools">
        <div v-if="item.type === 'link'" :key="index" class="tool-item">
          <a :href="item.link" class="tool-item-link" :target="item.target">
            <div class="tool-item-icon">
              <i class="iconfont" :class="item.icon" />
            </div>
            <div class="tool-item-name">
              {{ item.name }}
            </div>
          </a>
        </div>
        <div v-else-if="item.type === 'qrcode'" :key="index" class="tool-item has-qrcode">
          <div class="tool-item-icon">
            <i class="iconfont" :class="item.icon" />
          </div>
          <div class="tool-item-name">
            {{ item.name }}
          </div>
          <!-- 两个二维码特殊处理 -->
          <div v-if="item.linkWechat" class="qrcode-wrapper hover-transition" :class="{'is-fr': item.linkWechat}">
            <div>
              <img v-lazy-load :data-src="item.link" :alt="$t('common.websiteName') + ' ' + item.name" class="qrcode-img" width="150" loading="lazy">
              <div v-if="item.config.tip" class="tip-text">
                {{ item.config.tip }}
              </div>
            </div>
            <div class="line" />
            <div v-if="item.linkWechat">
              <img v-lazy-load :data-src="item.linkWechat" :alt="$t('common.websiteName') + ' ' + item.name" class="qrcode-img" width="150" loading="lazy">
              <div v-if="item.config.tipTextWechat" class="tip-text">
                {{ item.config.tipTextWechat }}
              </div>
            </div>
          </div>
          <div v-else class="qrcode-wrapper hover-transition">
            <img v-lazy-load v-lazy-load :data-src="item.link" :alt="$t('common.websiteName') + ' ' + item.name" class="qrcode-img" width="150" loading="lazy">
            <div v-if="item.config && item.config.tip" class="tip-text">
              {{ item.config.tip }}
            </div>
          </div>
        </div>
        <div v-else-if="item.type === 'chat' && (locale === 'cn' || locale === 'us') " :key="index" class="tool-item" @click="handleInitDebounce">
          <div class="chat-robot">
            <div class="chat-robot-box">
              <i class="iconfont" :class="[item.icon]" />
            </div>
          </div>
          <div class="tool-item-name-chat">
            {{ item.name }}
          </div>
        </div>
      </template>
      <div class="tool-item icon-backtop-box">
        <div class="tool-item-icon" @click="backTop">
          <i class="iconfont icon-backtop-new" />
        </div>
      </div>
    </div>
    <ChatRobot ref="chatRobot" />
  </div>
</template>
<script>
import skrollTop from 'skrolltop';
import { oneApi } from 'config/api';
import { resourceLocationMap, resourceLocationSaMap } from 'common/map.js';
import { adShow, adClick } from 'common/sensors.js';
import ChatRobot from '@/components/global/FeatureComponents/ChatRobot/ChatRobotComp/web.vue'

const debounce = require('lodash/debounce')

export default {
  components: {
    ChatRobot,
  },
  data() {
    const { localeConfig } = this.$store.state
    return {
      isShowToolGroup: false,
      tools: this.$t('common.rightTools'),
      advertisingData: {
        src: '',
        url: '',
        target: true,
      },
      showRightTool: localeConfig.showRightTool,
    }
  },
  computed: {
    locale() {
      return this.$store.state.locale
    },
    isShowAdvertising() {
      return this.advertisingData.src !== ''
    },
    isShowTool() {
      const currentFullPath = this.$route.fullPath
      return !currentFullPath.includes('recommended-courses')
    },
  },
  mounted() {
    this.getResourceData()
    this.addScrollEvent();
    this.watchScroll();
  },
  destroyed() {
    this.removeScrollEvent();
  },
  methods: {
    // 防抖
    handleInitDebounce: debounce(function () {
      this.handleChat()
    }, 300),
    async handleChat() {
      if (this.$refs.chatRobot?.isInteracting || this.$refs.chatRobot?.webchatSid) {
        this.$refs.chatRobot.openChat()
        return
      }
      // 首次打开
      const res = await this.$axios.post(oneApi.startChatSession, {}, { rewritePostBody: true })
      if (res.code !== 0) {
        this.$ElMessage.error('该功能暂未开通，敬请期待');
        return
      }
      const resData = res.data || {}
      this.$refs.chatRobot.openChat(resData)
    },
    // 添加滚动事件
    addScrollEvent() {
      window.addEventListener('scroll', this.watchScroll, false);
    },
    // 移除滚动事件
    removeScrollEvent() {
      window.removeEventListener('scroll', this.watchScroll, false);
    },
    watchScroll() {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
      if (scrollTop > 580) {
        this.isShowToolGroup = true;
      } else {
        this.isShowToolGroup = false;
      }
    },
    backTop() {
      skrollTop.scrollTo({
        to: 0,
      });
    },
    async getResourceData() {
      const data = [{
        locationKey: resourceLocationMap.get('side-bar'),
      }]

      const res = await this.$axios.post(oneApi.queryResourceByLocation, data, { rewritePostBody: true })

      if (res.code !== 0) {
        return
      }

      const resData = res.data || []
      if (resData.length === 0) return

      const resourcesList = resData[0].resources || []

      if (resourcesList.length === 0) return

      // eslint-disable-next-line prefer-destructuring
      this.advertisingData = resourcesList[0]

      this.adShowHandler(this.advertisingData)
    },
    adShowHandler(currentAd) {
      adShow(this, resourceLocationSaMap.get('side-bar'), currentAd.resId)
    },
    adClickHandler(currentAd) {
      window.open(currentAd.url, currentAd.target ? '_blank' : '_self')
      adClick(this, resourceLocationSaMap.get('side-bar'), currentAd.resId)
    },
  },
}
</script>
<style lang="scss" scoped>
.side-bar-right-wrapper {
  position: fixed;
  z-index: 1001;
  // top: 200px;
  bottom: 70px;
  right: 20px;
}

.side-bar-advertising {
  margin-bottom: 20px;
  width: 70px;
  height: 70px;
  background-position: center;
  background-size: 100%;
  background-repeat: no-repeat;
  border-radius: 4px;
}

.tools-group {
  width: 70px;
  padding: 24px 0;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  font-size: 12px;
  text-align: center;
  color: #999;
}

.tool-item {
  position: relative;
  display: block;
  margin-bottom: 18px;
  font-size: 12px;
  color: #999;
  cursor: pointer;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    color: #666;
  }

  .tool-item-icon {
    margin: 0 auto;
    width: 32px;
    height: 32px;
  }

  .tool-item-link {
    display: block;
  }

  .tool-item-name {
    margin-top: 7px;
    color: #999;
  }

  .tool-item-name-chat {
    margin-top: 7px;
    color: #999;
  }

  .tool-item-back-to-top {
    &:hover {
      color: var(--txt-color-lv2);
    }
  }

  .iconfont {
    font-size: 32px;
  }

  .icon-wechat {
    color: #01e26e;
  }

  .icon-line2 {
    color: #04c300;
  }

  .icon-whatsapp {
    color: #07e677;
  }

  .icon-facebook {
    color: #1d76f5;
  }

  .icon-youtube,
  .icon-youtube-new {
    color: #ff0305;
  }

  .qrcode-wrapper {
    position: absolute;
    top: -24px;
    right: 73px;
    padding: 12px;
    width: 180px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    text-align: center;
    visibility: hidden;
    opacity: 0;

    .qrcode-img {
      display: block;
      margin: 0 auto;
      width: 150px;
      height: 150px;
      background: #f1f1f1;
    }
  }

  .is-fr {
    display: flex;
    width: auto;
    left: -340px;

    .line {
      margin-top: 26px;
      width: 1px;
      border: 1px solid #f1f1f1;
      height: 100px;
    }
  }

  .tip-text {
    margin-top: 11px;
    font-size: 12px;
    line-height: 14px;
    color: #999;
  }

  &.has-qrcode {
    &:hover .qrcode-wrapper {
      visibility: visible;
      opacity: 1;
    }
  }
}

.chat-robot {
  height: 35px;
  display: flex;
  align-items: center;

  .chat-robot-box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    margin: 0 auto;
    background: var(--txt-color-link);
  }

  .iconfont {
    font-size: 16px;
    color: #fff;
  }
}

.icon-backtop-box {
  height: 35px;
  display: flex;
  align-items: center;

  .icon-backtop-new {
    font-size: 28px;

    &:hover {
      color: var(--txt-color-lv1);
    }
  }
}
</style>
