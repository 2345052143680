<template>
  <div>
    <div v-if="isSend" class="verify-headline">
      <h3 class="login-headline font-semibold">
        {{ $t("login.login.loginForm.email.verifyMainTitle") }}
      </h3>
      <div v-if="locale !== 'jp'" class="login-subtitle">
        {{ $t("login.login.loginForm.phone.verifySubTitle") }}
      </div>
      <div class="login-tip" :class="{ 'login-exchange': locale === 'jp' }">
        {{ emailData.email }}
      </div>
      <div v-if="locale === 'jp'" class="login-exchange-end">
        {{ $t("login.login.loginForm.phone.verifySubTitle") }}
      </div>
    </div>
    <div v-else-if="isTop === 2" class="verify-headline">
      <h3 class="login-headline font-semibold">
        {{ $t("login.login.CreateANewAccount") }}
      </h3>
    </div>
    <el-form
      ref="emailForm"
      class="login-new-form font-medium"
      :model="emailData"
      :rules="loginRule"
    >
      <el-form-item v-show="!isSend" prop="email">
        <EmailInput
          v-model="emailData.email"
          :last-modified="lastModified"
          @input-focus="reset('email')"
        />
      </el-form-item>
      <el-form-item v-show="isSend" prop="captcha">
        <CodeInput
          ref="emailCode"
          v-model="emailData.captcha"
          :scene="scene"
          type="email"
          :is-top="isTop"
          :email="emailData.email"
          :cookie-name="cookieName"
          :before-send="codeBeforeSend"
          :send-success="codeSendSuccess"
          :send-error="codeSendError"
        />
      </el-form-item>
    </el-form>
    <div v-if="hasRepeat" class="has-repeat">
      {{ $t("login.login.ThisEmailAlready") }}
      <span @click="toSignUp(3)">{{ $t("login.login.SignInNow") }}</span>
    </div>
    <div v-if="hasRepeat2" class="has-repeat">
      {{ $t("login.login.ItSeemsHaven") }}
      <span @click="toSignUp(2)">{{ $t("login.login.SignUpNow") }}</span>
    </div>
    <div v-if="canSwitchType" style="padding-top: 6px">
      <div v-show="!isSend" class="update-button-container">
        <el-button
          type="primary"
          class="button login-button new-login-font"
          :loading="sendLoading"
          :disabled="!isEmailContinue"
          @click="goSendCode('emailCode')"
        >
          {{ $t("login.login.loginForm.email.continueBtn") }}
        </el-button>
        <div v-if="isTop === 3" style="margin-top: 16px" />
        <div v-else class="update-divider">
          <div class="update-dividet-text">
            {{ $t("login.login.loginForm.changeTipText") }}
          </div>
        </div>
        <el-button
          class="button button-outline switch-button new-login-font"
          :disabled="sendLoading"
          @click="switchType('phone')"
        >
          <i class="iconfont iconphone" />
          {{ $t("login.login.loginForm.email.switchBtn") }}
        </el-button>
      </div>
      <div v-show="isSend" class="update-button-container">
        <el-button
          type="primary"
          class="button login-button new-login-font"
          :loading="isLoging"
          :disabled="!isLoginSubmitAvalible"
          @click="loginSubmit"
        >
          {{ $t("login.login.loginForm.submitBtnText") }}
        </el-button>
      </div>
      <div v-if="isTop === 3 && !isSend" class="sign-up-n">
        {{ $t("login.login.DontHaveAccoun") }}
        <span @click="toSignUp(1)">{{ $t("login.login.SignUpNow") }}</span>
      </div>
    </div>
    <!-- 不可以切换 -->
    <div v-else style="padding-top: 6px" :class="{ isTop3: isTop === 3 }">
      <div v-show="!isSend" class="update-button-container">
        <div
          :class="['arrow-button', !isEmailContinue ? 'arrow-disabled' : '']"
          @click="goSendCode('emailCode')"
        >
          <div class="arrow-img" />
        </div>
      </div>
      <div v-show="isSend" class="update-button-container paddingTop8">
        <el-button
          type="primary"
          class="button new-login-font"
          :loading="isLoging"
          :disabled="!isLoginSubmitAvalible"
          @click="loginSubmit"
        >
          {{ $t("bindForm.submitBtn") }}
        </el-button>
      </div>
      <div class="tip font-medium">
        {{ $t("bindForm.tip") + contact }}
      </div>
      <div v-if="isTop === 3 && !isSend" class="sign-up-n">
        {{ $t("login.login.DontHaveAccoun") }}
        <span @click="toSignUp(1)">{{ $t("login.login.SignUpNow") }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import EmailInput from 'components/login/newLoginForm/newEmailInput.vue';
import CodeInput from 'components/login/newLoginForm/newCodeInput.vue';
import { oneApi } from 'config/api';

export default {
  components: {
    EmailInput,
    CodeInput,
  },
  props: {
    isLoging: {
      type: Boolean,
      default: false,
    },
    canSwitchType: {
      type: Boolean,
      default: true,
    },
    cookieName: {
      type: String,
      default: 'login-email-count',
    },
    scene: {
      type: Number,
      default: 1,
    },
    lastModified: {
      type: Object,
      default: () => ({}),
    },
    isTop: {
      type: Number,
      default: 2,
    },
  },
  data() {
    return {
      isChecking: false, // 检测中
      contact:
        this.$t('common.contactPhone') !== ''
          ? this.$t('common.contactPhone')
          : this.$t('common.contactEmail'),
      isSend: false, // 是否切换到code页面
      sendLoading: false, // 按钮加载中状态
      emailData: {
        email: '',
        captcha: '',
      },
      loginRule: {
        captcha: [
          {
            required: true,
            message: this.$t('login.login.loginForm.captcha.rules.required'),
            trigger: 'blur',
          },
          {
            pattern: /^\d{6}$/,
            message: this.$t('login.login.loginForm.captcha.rules.format'),
            trigger: 'blur',
          },
        ],
        email: [
          {
            required: true,
            message: this.$t('login.login.loginForm.email.rules.required'),
            trigger: 'blur',
          },
          {
            pattern: /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+.([A-Za-z]{2,8})$/,
            message: this.$t('login.login.loginForm.email.rules.format'),
            trigger: 'blur',
          },
        ],
      },
      hasRepeat: false,
      hasRepeat2: false,
    };
  },
  computed: {
    isLoginSubmitAvalible() {
      return /^\d{6}$/.test(this.emailData.captcha);
    },
    isEmailContinue() {
      const isContinue = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+.([A-Za-z]{2,8})$/.test(
        this.emailData.email,
      );
      return isContinue;
    },
    locale() {
      return this.$store.state.locale;
    },
  },
  watch: {
    lastModified() {
      if (
        Object.keys(this.lastModified).length
        && this.lastModified.type === 'email'
      ) {
        if (!this.lastModified.accountName) return;
        this.emailData.email = this.lastModified.accountName;
      }
    },
  },
  methods: {
    init() {
      if (
        Object.keys(this.lastModified).length
        && this.lastModified.type === 'email'
      ) {
        if (this.lastModified.accountName) {
          this.emailData.email = this.lastModified.accountName;
        }
      }
      this.$refs.emailCode.init();
      this.emailData.captcha = '';
      this.isSend = false;
    },
    // 触发发送验证码
    async goSendCode(codInputName) {
      if (this.sendLoading || !this.isEmailContinue) {
        return undefined;
      }
      // 加一个已注册的验证
      this.sendLoading = true;
      if (this.isTop === 2) {
        this.hasRepeat = false;
        this.$refs.emailForm.validateField('email', async (errMsg) => {
          if (errMsg !== '') {
            return;
          }
          const data = {
            type: 2,
            contactInfo: this.emailData.email,
          };
          console.log('校验手机号29');
          const res = await this.$axios.post(oneApi.contactVerify, data, {
            rewritePostBody: true,
          });
          this.sendLoading = false;
          if (Number(res.code) == 100100) {
            this.hasRepeat = true;
            return;
          } if (Number(res.code) !== 0) {
            this.$ElMessage({
              type: 'error',
              message: res.msg,
            });
            return;
          }
          return this.$refs[codInputName].sendCodeHanlder();
        });
      } else if (this.isTop === 3) {
        this.hasRepeat2 = false;
        this.$refs.emailForm.validateField('email', async (errMsg) => {
          if (errMsg !== '') {
            return;
          }
          const data = {
            type: 2,
            contactInfo: this.emailData.email,
          };
          const res = await this.$axios.post(oneApi.contactVerify, data, {
            rewritePostBody: true,
          });
          this.sendLoading = false;
          if (Number(res.code) === 0) {
            this.hasRepeat2 = true;
            return;
          } if (Number(res.code) !== 100100) {
            this.$ElMessage({
              type: 'error',
              message: res.msg,
            });
            return;
          }
          return this.$refs[codInputName].sendCodeHanlder();
        });
      }
    },
    // 切换表单
    switchType() {
      this.emailData.email = '';
      this.$refs.emailForm.clearValidate();
      this.$emit('switch');
    },
    // 验证码发送前
    codeBeforeSend(callback) {
      this.$refs.emailForm.validateField('email', async (errMsg) => {
        if (errMsg !== '') {
          return;
        }
        this.sendLoading = true;
        if (this.scene === 3 && !this.isChecking) {
          const data = {
            type: 2,
            contactInfo: this.emailData.email,
          };
          this.isChecking = true;
          const res = await this.$axios.post(oneApi.contactVerify, data, {
            rewritePostBody: true,
          });
          this.isChecking = false;
          if (Number(res.code) !== 0) {
            this.$ElMessage({
              type: 'error',
              message: res.msg,
            });
            this.sendLoading = false;
            return;
          }
        }
        callback();
      });
    },
    // 发送成功
    codeSendSuccess() {
      this.sendLoading = false;
      this.isSend = true;
      // 修改dialog文案使用
      const valueCode = this.emailData.email;
      this.$emit('titleChange', {
        isSend: this.isSend,
        valueCode,
      });
    },
    /**
     * 触发登录按钮
     */
    loginSubmit() {
      this.$refs.emailForm.validate((isValid) => {
        if (isValid) {
          this.$emit('submit', this.emailData);
        }
      });
    },
    codeSendError() {
      this.sendLoading = false;
    },
    reset(prop) {
      if (this.$refs.loginForm && this.$refs.loginForm.clearValidate) {
        this.$refs.loginForm.clearValidate(prop);
      }
    },
    toSignUp(num) {
      this.hasRepeat = false;
      this.hasRepeat2 = false;
      this.$emit('toSignIn', num);
    },
  },
};
</script>
<style lang="scss" scoped>
.arrow-button {
  width: 56px;
  height: 56px;
  background: var(--txt-color-link);
  border-radius: 28px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 16px;
  cursor: pointer;

  &:hover {
    background: var(--txt-color-link2);
  }

  .arrow-img {
    background: url("~assets/images/common/next-arrow.png") no-repeat;
    background-size: 100%;
    width: 24px;
    height: 24px;
  }
}

.arrow-disabled {
  opacity: 0.2;
}

.paddingTop8 {
  padding-top: 8px;
}

.tip {
  font-size: 12px;
  color: var(--txt-color-lv2);
  line-height: 14px;
  text-align: center;
  padding-bottom: 20px;
}
.has-repeat {
  font-size: 12px;
  font-family: Montserrat-Medium, Montserrat;
  font-weight: 500;
  color: #d44e4e;
  line-height: 15px;
  margin-top: -12px;
  margin-bottom: -3px;
  span {
    color: var(--txt-color-link);
    text-decoration: underline;
    cursor: pointer;
    &:hover {
      color: var(--txt-color-link2);
    }
  }
}
.sign-up-n {
  font-size: 12px;
  font-family: Montserrat-Medium, Montserrat;
  font-weight: 500;
  color: #7a7a7a;
  line-height: 18px;
  margin-top: 20px;
  position: absolute;
  bottom: 20px;
  left: 24px;
  cursor: pointer;
  span {
    color: var(--txt-color-link);
    text-decoration: underline;
    &:hover {
      color: var(--txt-color-link2);
    }
  }
}
</style>
