/* eslint-disable max-len */
export default {
  allOrders: 'Toutes les commandes',
  detail: {
    preClass: 'Préparation avant le cours',
    preClassInfo: 'Venez comprendre comment les enfants assistent aux cours et ce qu\'il faut préparer avant les cours.',
    prepare: {
      preClass: 'Préparation avant le cours',
      parents: 'Pour parent',
      students: 'Pour étudiant',
      tobetterInfo: 'Mieux comprendre la situation d\'apprentissage de votre enfant, calendrier des cours de suivi, avis d\'apprentissage des tuteurs, et les renouvellements de cours, veuillez télécharger l\'application pour les parents',
      benefitsInfo: 'Avantages du téléchargement de l\'application pour les parents',
      calendar: 'Calendrier',
      calendarInfo: 'Vous avez la possibilité de consulter l\'emploi du temps complet des cours de votre enfant, ce qui facilite la planification de ses activités quotidiennes.',
      preClassInfo: 'Avant le cours, vous pouvez avoir un aperçu du contenu du cours et des plans d\'enseignement de l\'enseignant en consultant le matériel de cours à l\'avance.',
      learningFeedback: 'Retour d\'apprentissage',
      learningInfo: 'Après les cours, vous avez la possibilité de consulter les rapports de fin de session et les appréciations des enseignants pour évaluer les performances de votre enfant en classe. Il est également facile de suivre l\'état d\'avancement des devoirs de votre enfant ainsi que les annotations et corrections apportées par l\'enseignant.',
      learningService: 'Notification du service d\'apprentissage',
      learningServiceInfo: "Vous recevrez immédiatement les mises à jour de l'apprentissage de votre enfant et les notifications de services importants.",
      quickOnline: 'Sélection rapide de cours en ligne',
      quickOnlineInfo: "Nos conseillers spécialisés vous recommanderont des cours, ce qui vous permettra de trouver ce qui convient le mieux à l'éducation de votre enfant.",
    },
    attend: {
      howtoAttend: 'Comment participer?',
      howtoAttendInfo: 'Ce cours sera enseigné en personne dans nos centres d\'apprentissage. Pour faciliter une meilleure organisation, nous demandons aux étudiants d\'arriver en classe 15 minutes avant le début du cours.',
      location: 'Emplacement du centre d\'apprentissage',
      classroom: 'Salle de classe',
      address: 'Adresse',
      contactNumber: 'Numéro de contact',
    },
    attendOnline: {
      howtoAttend: 'Comment participer?',
      attendInfo: 'Pour que votre enfant ait une meilleure expérience de la classe, veuillez suivre les étapes ci-dessous',
      stepOne: 'Première étape',
      stepOneInfo: 'Télécharger Think Academy Étudiant”',
      stepOneDetail: "Pour assurer à votre enfant une expérience de classe, veuillez utiliser <span class='orange-txt'> PC, iPad or tablette Android </span> pour télécharger l'application Étudiant: Think Academy Étudiant.",
      stepTwo: 'Deuxième étape',
      stepTwoInfo: 'Ouvrez "Think Academy Étudiant" et entrez dans la salle de classe',
      stepTwoDetailOnline: 'Veuillez utiliser votre compte Think Academy pour vous connecter à l\'APP des élèves et confirmer que vous avez sélectionné le bon enfant. Les enfants peuvent entrer dans la classe 15 minutes avant le début du cours.',
      stepTwoDetailRecord: 'Veuillez utiliser votre compte Think Academy pour vous connecter à l\'APP étudiant et confirmer que vous avez sélectionné le bon enfant.  Dans "Cours", passez à "Cours Enregistrés", puis cliquez sur "Entrer" pour commencer l\'apprentissage.',
    },
    viewVideo: 'Voir la vidéo',
  },
  studentAppName: 'Think Academy Étudiant',
  parentAppName: 'Think Academy Parent',
  all: 'Tous',
}
