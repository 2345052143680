export default {
  allOrders: 'All Orders',
  detail: {
    preClass: 'Pre-class Preparation',
    preClassInfo: 'Come and understand how children attend classes and what needs to be prepared before class.',
    prepare: {
      preClass: 'Pre-class Preparation',
      parents: 'For Parent',
      students: 'For Student',
      tobetterInfo: 'To better understand your child’s learning situation, follow-up course schedules, tutors’s learning notices, and course renewals, please download the parent app',
      benefitsInfo: 'Benefits of downloading the parent app',
      calendar: 'Calendar',
      calendarInfo: 'You can view all your child\'s class schedules, helping you plan their time effectively.',
      preClassInfo: 'Before class, you can preview the course content and teacher\'s teaching plans by reviewing the course materials in advance.',
      learningFeedback: 'Learning Feedback',
      learningInfo: 'After class, you can access post-class reports and teacher evaluations, understanding your child\'s classroom performance. You can also promptly check your child\'s homework completion status and the teacher\'s corrections.',
      learningService: 'Learning Service Notification',
      learningServiceInfo: "You will receive your child's learning updates and important service notifications immediately.",
      quickOnline: 'Quick Online Course Selection',
      quickOnlineInfo: "Our dedicated advisors will recommend courses for you, ensuring you find the perfect fit for your child's education.",
    },
    attend: {
      howtoAttend: 'How to attend?',
      howtoAttendInfo: 'This course will be taught in-person at our learning centers. To facilitate better organization, we kindly request students to arrive at the classroom 15 minutes before the class begins.',
      location: 'Learning Center location',
      classroom: 'Classroom',
      address: 'Address',
      contactNumber: 'Contact Number',
    },
    attendOnline: {
      howtoAttend: 'How to attend?',
      attendInfo: 'In order to give your children a better class experience, please follow the steps below',
      stepOne: 'Step One',
      stepOneInfo: 'Download “Think Academy Student”',
      stepOneDetail: "To ensure your child's class experience, please use a<span class='orange-txt'> PC, iPad or Android tablet</span> to download the student app: Think Academy Student.",
      stepTwo: 'Step Two',
      stepTwoInfo: 'Open “Think Academy Student” and enter the classroom',
      stepTwoDetailOnline: 'Please use your Think Academy account to log in to the student APP and confirm that you have selected the correct child. Children can enter the class 15 minutes before the class starts.',
      stepTwoDetailRecord: 'Please use your Think Academy account to log in to the student APP and confirm that you have selected the correct child.  In the "Courses", switch to "Recorded Courses", then click "Enter" to start learning.',
    },
    viewVideo: 'View Video',
  },
  studentAppName: 'Think Academy Student',
  parentAppName: 'Think Academy Parent',
  all: 'All',
}
