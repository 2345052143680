/**
 * 平台检测中间件
 */

export default function ({ req, store }) {
  // 客户端环境不执行
  if (process.client) return;

  const userAgent = req.headers['user-agent'];
  // const platform = (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent)) ? 'touch' : 'pc';
  const platform = (/Android(?=.*Mobile)|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent)) ? 'touch' : 'pc';

  store.commit('SET_PLATFORM', platform);
}
