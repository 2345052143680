/* eslint-disable max-len */
/**
 * course-material-fee-policy页面配置
 */

export default {
  title: 'Course Material Fee Policy',
  content: `
  <h2>1.Course Material Fee</h2>
  <p>The Course Material Fee (GST waived for now) is required for all Think Academy Courses. </p>
  
  <h2>2.Delivery Duration</h2>
  <p>Printed course materials are provided for selected courses*, for Singapore and China addresses only.</p>
  <p><b>Standard Delivery: </b>3-5 working days (excludes both countries' Public Holidays)</p>
  <p>For parents that would like an Express Delivery of 1-2 working days (excludes Public Holidays), please proceed to complete course registration on the website and <a href="https://api.whatsapp.com/send?phone=6582853984" target="_blank">WhatsApp 8285 3984</a> or inform your child's Guiding Teacher. An additional $10 cash payment will be required on delivery (i.e. paid directly to the delivery personnel).</p>
  <p><i>*Softcopy course materials will only be provided for Free trial and selected courses (indicated in the relevant course information page) and the students residing outside of Singapore and China.</i></p>
  
  <h2>3.Transfer Courses</h2>
  <ol>
  <li>If you request to transfer a course before the course material has been mailed out, we will deliver the new class' course material without additional charges.</li>
  <li>Otherwise, you need to return the original copy in sealed condition, and we will only arrange re-delivery of the new course material after receiving the proven record of return. Think Academy will not cover the cost of return mail. Please reach out to your child's Guiding Teacher or <a href="https://api.whatsapp.com/send?phone=6582853984" target="_blank">WhatsApp 8285 3984</a> for assistance. </li>
  <li>For all other cases, the course material fee is non-refundable.</li>
  </ol>

  <h2>4.Refund of Course</h2>
  <ol>
  <li> If you request a refund before the course starts and the textbook has not been mailed out, we will make a full refund (i.e. both course and course material fee will be refunded), which will be reflected in your relevant credit card or bank account statement within 14 working days.
  </li>
  <li>If your request a refund of course and the textbook is in the midst of being delivered, you may inform your child's Guiding Teacher or <a href="https://api.whatsapp.com/send?phone=6582853984" target="_blank">WhatsApp 8285 3984</a> to inform of your intent and arrange return of the original copy in sealed condition to Think Academy, Level 6, 30 Prinsep St Singapore 188647 (return mail cost to borne by the parent). Upon receipt of the course material in original sealed condition, Think Academy will arrange refund of the course material fee. In other cases, the course material fee will be deducted from the total payment.</li>
  <li>If you request a refund after your child's first scheduled live lesson, the course material fee is non-refundable, but the course fee will be fully refund to you.</li>
  <li>If you request a refund after a few lessons, the course material fee is non-refundable, and the prorated course fee for upcoming live lessons scheduled within the programme will be refunded.</li>
  </ol>
 
  <h2>5.If you have more than one child at the same grade level</h2>
  <p>Each register course includes one set of the course materials. If you would like extra sets, . kindly reach out to your child's Guiding Teacher or <a href="https://api.whatsapp.com/send?phone=6582853984" target="_blank">WhatsApp 8285 3984</a> for additional purchase.</p>

  <h2>6.If you are not residing in Singapore or China</h2>
  <ol>
  <li>The course material fee is still applicable. Due to the current uncertainty of international shipping, only a PDF version will be given.</li>
  <li>If you decide to transfer course after receiving the course material PDF file, the course material fee is non-refundable, and you will need to pay for the new course's course material fee.</li>
  <li>If you decide to refund after receiving the course material PDF file, the course material is non-refundable.</li>
  </ol>

  <h2>7.Information Accuracy</h2>
  <ol>
  <li>It is your responsibility to ensure that the address provided for mailing the textbook is correct.</li>
  <li>If you wish to edit or change your mailing address, kindly complete the update within 24 hours after successfully registering for the course via the <a href="/my-courses/list">Student Portal</a>.</li>
  <li>For failed delivery due to incorrect addresses, you will need to repurchase the course material at an additional fee.</li>
  </ol>

  <h2>8.Revision of the Policy</h2>
  <p>The company reserves the right to revise, modify any or all clauses of this policy depending upon demand of business.</p>
  `,
}
