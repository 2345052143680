<template>
  <div :class="[elfsightId]" />
</template>

<script>
import { loadScript } from 'common/utils'

export default {
  data() {
    return {
      elfsightId: this.$t('common.elfsightId'),
    }
  },
  mounted() {
    loadScript('https://apps.elfsight.com/p/platform.js', {
      async: true,
    })
  },
}
</script>
