export default {
  // 订阅列表
  subscriptionList: {
    title: 'My Subscription',
    subscriptionDetail: 'Subscription Detail',
    changeAddress: 'Change Address',
    productType: 'Product Type',
    subscriptionStatusList: [
      {
        value: '',
        label: 'All',
      },
    ],
    noOrders: 'No orders under this category. Browse more courses provided by Think Academy.',
    moreCourses: 'Browse More Courses',
    noSubscriptionText: 'You currently do not have any auto-renewal subscriptions',
    unSubscribeTipsTitle: 'Please contact your learning center to cancel the subscription',
    unSubscribeTipsContent: 'After cancelling the subscription, there will be no charges for the next month. Existing orders will not affect ongoing classes.',
    autoRenewal: 'Auto Renewal',
    openRecords: 'Activation Records',
    learnPlayTag: 'play and learn',
    every: 'Every',
    timeLimit: 'Period',
    month: 'months',
    phase: 'Phase',
    stage: 'Stage {num}',
    deductionDate: 'Deduction Date',
    deductionAmount: 'Deduction Amount',
    status: 'Status',
    statusText: {
      1: 'To be paid',
      2: 'Paid',
      3: 'Payment Failed',
    },
    unsubscribe: 'Unsubscribe',
    weekMap: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    weekDayList: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    monthsList: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ],
    curriculumSchedule: 'Curriculum schedule',
    today: 'today',
    classBegun: 'started',
    unClassBegun: 'not started',
    noClasses: 'No classes today',
    activedTip: 'Click the check box to activated',
  },
  subscriptionCard: {
    pay: 'Pay',
    unsuceess: 'Unsuceessful Deduction',
    viewOrder: 'View Order',
    start: 'Start Time',
    end: 'End Time',
    nextPay: 'Estimated Next Payment',
    expire: 'Expiration Time',
    expireDate: 'Expiry Date',
    orderTime: 'Order Time',
    statusText: {
      1: 'To be paid',
      2: 'Active',
      3: 'Active',
      4: 'Cancelled',
    },
  },
  subscriptionDetail: {
    cancel: 'Cancel Subscription',
    cancelReasons: 'Cancel Reasons',
    title: 'Subscription Detail',
  },
  // 支付失败结果页提示文案
  toBePaidResult: {
    title: 'Important Payment Reminder',
    content: 'If you have been deducted, but the order is not in the "paid" status, it may be because we have not received the banks deduction notification yet. Please check later. If you have any questions, please contact us at:',
    confirm: 'Got it!',
  },
  personalInfo: {
    childEdit: 'Child\'s Profile',
    phone: 'Mobile Number',
    emailAddress: 'Email Address',
    manage: 'Manage my children and personal information',
    manageChild: 'Manage my children',
    edit: 'Edit my profile',
  },
  childFilter: {
    child: 'Child',
    type: 'Type',
    kids: 'Kids',
    save: 'Save',
  },
  address: {
    noAddress: 'You haven\'t added an address yet',
  },
  coupon: {
    title: 'My Coupons',
    detail: 'Coupon Details',
    recommendClass: 'Recommended Class',
    testResult: 'Understanding the test result',
    on: 'On {feeName}',
    over: 'Over {symbol}{price}',
    off: '%Off',
    noAvailable: 'No available course can be applied to the chosen coupon.',
    redeemFailedTips: 'Promo code not found. Please try again',
  },
  courses: {
    title: 'Courses',
    availableClass: 'Available Classes',
    noCoursesTip: 'No courses under this category. Browse more courses provided by Think Academy.',
    moreCourses: 'Browse More Courses',
  },
  tips: {
    editSuccess: 'Student edited successfully',
    copyFiled: 'Copied Failed',
    copySuccess: 'Copied Successfully',
    success: 'Successfully',
    redeemSuccess: 'Redeem Successful',
    addSuccess: 'Student added successfully',
    login: 'Please Login again',
    loginTips: 'Your login session has expired. Please login again to continue',
    cancel: 'Cancel',
    loginAgain: 'Login Again',
    invalid: 'The input is required',
    removeWish: 'Removed from the wish list',
    loading: 'Loading...',
    deleteSuccess: 'Delete Successful',
    addSuccessful: 'Add Successful',
    updateSuccess: 'Update Successful',
    updateFailed: 'Update failed. Same with the previous address.',
    viewCourses: 'View Courses',
    emptyTips: 'Available courses coming soon! View currently available courses provided by Think Academy',
    before: 'Before',
    after: 'After',
  },
  coursesCardTag: {
    new: 'New',
    popular: 'Popular',
    full: 'Full',
    current: 'Current',
    completed: 'Completed',
    refunded: 'Refunded',
    playBack: 'Playback Only',
  },
  getNotifiedConfig: {
    title: 'Can\'t Find an Appropriate Course for Your Child?',
    description: 'That\'s ok! While you take some time to think, leave your email with us to receive admissions support for grammar and independent school entry, links to 11+ webinars and more practice materials.',
    form: {
      grade: 'Child\'s Year group',
      firstName: 'Child\'s first name',
      lastName: 'Last name',
      email: 'Your email',
      platform: 'How did you find out about us?',
    },
    notice: 'Sign up for our mailing list to receive the latest news, promotions and updates! ',
    errorNotice: 'Submission failed. Please try again later.',
    emailError: 'The Email address seems invalid.',
    phoneError: 'The phone number seems invalid.',
    submit: 'Submit',
    success: 'Thank you for your interest in Think Academy!',
  },
  // Cookies声明
  cookiesStatement: {
    description: 'We use cookies to provide you with better services. By clicking any link on this page, your consent has been given.',
  },
  // cookies policy
  cookiesAccept: {
    title: 'Our Use of Cookies',
    description: `<p>We use Cookies to personalise your user experience on our website.</p>
    <p>For more information please see our <a href="/cookies-policy" target="_blank">Cookie Policy</a></p>`,
    accept: 'Accept',
    reject: 'Reject',
  },
  unpaidCourses: {
    tips1: `
      <p class="dotted-para" style="margin-left: 10px; margin-bottom: 10px;">Due to high demand for classes, 
      there is a deadline for switching class. Please select the class you want between: <span style="color: #3e6eff;">{startTime} - {endTime}</span></p>`,
    tips2: `
      <p class="dotted-para" style="margin-left: 10px; margin-bottom: 10px;">Due to high demand for classes, 
      there is a deadline for switching class. Please select the class you want after: <span style="color: #3e6eff;">{startTime}</span></p>`,
    tips3: `
      <p class="dotted-para" style="margin-left: 10px; margin-bottom: 10px;">Due to high demand for classes, 
      there is a deadline for switching class. Please select the class you want before the deadline: <span style="color: #3e6eff;">{endTime}</span></p>`,
  },
}
