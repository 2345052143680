/**
 * 海外网络加载资源
 */

export default function ({
  req, route, app, store,
}) {
  // 客户端环境不执行
  if (process.client) return

  // const { userNetworkInfo } = store.state
  // const { isMainland } = userNetworkInfo

  // 大陆网络不执行
  // if (isMainland === true) {
  //   return
  // }

  // facebook渠道点击ID
  const fbclid = route.query.fbclid || req.ctx.cookies.get('_official_fbclid') || ''
  // tiktok渠道点击ID
  const ttclid = route.query.ttclid || req.ctx.cookies.get('_official_ttclid') || ''

  const { fbPixel, ttPixel } = store.state.localeConfig || {}

  const fbScriptFragment = fbPixel && fbclid ? `
    !function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)}(window,document,'script',
    'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', ${fbPixel});
    fbq('track', 'PageView');
  ` : ''

  const ttScriptFragment = ttPixel && ttclid ? `
    !function (w, d, t) {
      w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];
      ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],
      ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};
      for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);
      ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},
      ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";
      ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};
      var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;
      var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)};
      ttq.load('${ttPixel}');
      ttq.page();
    }(window, document, 'ttq');
  ` : ''

  if (fbScriptFragment) {
    app.head.script.push({
      innerHTML: fbScriptFragment,
      async: true,
    })
  }

  if (ttScriptFragment) {
    app.head.script.push({
      innerHTML: ttScriptFragment,
      async: true,
    })
  }
}
