/**
 * 路由拦截器中间件
 */

export default function ({
  req, store, redirect,
}) {
  // 客户端环境不执行
  if (process.client) return;

  const { locale } = store.state;
  const { url } = req.ctx.request;

  // 英国逻辑
  if (locale === 'uk') {
    // 访问时跳转至首页
    if (url === '/bm/free-online-lesson') {
      redirect('/');
    }
  }
}
