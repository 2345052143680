/* eslint-disable max-len */
/**
 * child-privacy 页面配置
 */

export default {
  title: '兒童個人信息保護規則',
  content: `
    <p>版本發布日期：2021年 03月 25日</p>
    <p>版本生效日期：2021年 03月 25日</p>
    <p>如果您是兒童： </p>
    <p>如果您是14周歲以下的兒童，請您務必通知您的監護⼈仔細閱讀本規則，並在徵得您的監護⼈同意後，使⽤學⽽思培優服務或向學⽽思培優提供信息。學⽽思培優會在App啟動後通過彈窗確認您的年齡段。 </p>
    <p>如果您是監護⼈： </p>
    <p>如您是兒童監護⼈，請您仔細閱讀和選擇是否同意本規則。請您與學⽽思培優共同保護兒童的個⼈信息，教育引導兒童增強個⼈信息保護意識和能⼒，指導、提醒和要求他們在未經您同意的情況下，不使⽤學⽽思培優服務或向學⽽思培優提供信息。學⽽思培優會在App啟動後通過彈窗確認⽤戶的年齡段。若已滿14周歲，可正常使⽤學⽽思服務或向學⽽思培優提供信息，若未滿14周歲，平台將要求該兒童獲得監護⼈的同意後再使⽤學⽽思服務或向學⽽思培優提供信息。本《規則》的閱讀者為監護⼈，應由兒童的監護⼈閱讀並同意本《規則》的內容。 </p>
    <p>學⽽思培優深知保護兒童個⼈信息安全和隱私的重要性。在《⽤戶個⼈信息保護政策》的基礎上，學⽽思培優希望通過《兒童個⼈信息保護規則》（以下簡稱「本規則」）向兒童監護⼈（以下簡稱「您」）說明學⽽思培優在收集和使⽤兒童個⼈信息時對應的處理規則等相關事宜。請在使⽤學⽽思培優，服務前務必仔細閱讀並透徹理解本規則，在確認充分理解並同意全部條款後再開始使⽤。除另有約定外，本聲明所⽤術語和縮略詞與學⽽思培優《⽤戶個⼈信息保護政策》中的術語和縮略詞具有相同的涵義。 </p>
    <p>請您在仔細閱讀、充分理解本規則後，選擇是否同意《⽤戶個⼈信息保護政策》和本規則。您點擊「同意」即表示您同意學⽽思培優按照《⽤戶個⼈信息保護政策》和本規則的約定收集、使⽤和處理您和您監護兒童的個⼈信息。如果您不同意《⽤戶個⼈信息保護政策》和本規則的內容，將可能導致無法正常使⽤學⽽思培優相關業務功能或服務。 </p>
    <p>為加強對兒童個⼈信息的保護，平台將遵循正當必要、知情同意、⽬的明確、安全保障、依法利⽤的原則，根據國家相關法律法規及本政策的規定保護兒童的個⼈信息，具體內容如下： </p>
    <p>1.我們如何收集和使⽤兒童的個⼈信息 </p>
    <p>學⽽思培優的《⽤戶個⼈信息保護政策》闡述了我們通常的個⼈信息收集做法。我們會出於以下⽬的，在徵得監護⼈的同意後，收集和使⽤以下類型的個⼈信息： </p>
    <p>1.1 為提供平台相應功能，平台需收集的信息包括： </p>
    <p>1.1.1 帳號信息：為成為學⽽思培優⽤戶，便於平台為您提供服務，兒童需要提供基本注冊或登錄信息，包括⼿機號碼、微信帳號，並創建兒童的帳號、⽤戶名和密碼。並且由於培優提供的是本地化服務，我們需要根據您的所在城市為您展示課程、並存儲您的學員數據。如果您不提供上述信息，您可以使⽤遊客模式瀏覽、搜索及查看學⽽思培優官⽅主頁及學⽽思培優開放平台基本服務及介紹，但不能註冊或登錄帳號。 </p>
    <p>1.1.2 個⼈資料和服務項⽬信息：在部分單項服務註冊及登錄過程中，如果兒童提供其他額外信息（您帳號所提供的城市、地區、在讀學校、在讀年級、⽬標學校、性別、出⽣⽇期、昵稱、頭像），將有助於平台給您提供個性化的推薦和更優的服務體驗，但如果您不提供這些信息，並不會影響您使⽤學⽽思培優的基本功能。 </p>
    <p>1.1.3 驗證信息：依照相關法律法規的要求或者特定功能及服務需要，兒童在使⽤特定功能及服務前，可能需要您提供其他信息（姓名、出⽣⽇期、性別、聯繫⼈⼿機號碼、收貨地址）。如果不提供上述信息，平台將不能提供相關的功能及服務。為避免歧義，如果兒童僅需使⽤瀏覽、搜索及查看學⽽思培優官⽅主頁及學⽽思培優開放平台基本服務及介紹，兒童不需要註冊成為平台的會員及提供上述信息。成為學⽽思培優⽤戶後，兒童可以對個⼈信息進⾏修改，如姓名、年級、地區、電話、頭像、性別、聯繫⼈⼿機號碼、收貨地址、在讀學校、⽬標學校。 </p>
    <p>1.2 為提供更契合您需求的服務，兒童會向平台提供信息並將這些信息進⾏關聯，這些功能和信息包括： </p>
    <p>1.2.1 交易相關的身份、地址和聯絡信息：兒童需要向學⽽思培優提供聯繫⼈信息（姓名、性別、電話號碼）、地址信息（如涉及到需要郵寄教材、圖書、教具、講義、商品服務時），以供學⽽思培優為您在具體產品提供長期班、短期班、精品課、講座、在線課、⼀對⼀的錄播課程、直播課程、⼩班⾯授、雙師課堂產品預訂/購買服務。如果兒童不提供上述信息，將不能完成預定/購買，但不會影響兒童使⽤學⽽思培優的其他功能。 </p>
    <p>當兒童在學⽽思培優旗下平台上使⽤配送/郵寄（教材、圖書、教具、講義、商品購買、商品兌換）服務時，兒童需要提供取\收貨⼈的姓名、性別（⽤於配送⼈員聯絡時稱呼您，如劉⼥⼠）、⼿機號碼、收貨地址、門牌號，以便於訂單能夠安全準確送達並完成。如果您不提供上述信息，您將不能收到郵寄的商品，但不會影響您使⽤學⽽思培優的其他功能。 </p>
    <p>1.2.2 訂單、⽀付信息：在學⽽思培優旗下平台上⽀付時，可以選擇學⽽思培優合作的第三⽅⽀付機構（如招商銀⾏⼀網通、微信⽀付、⽀付寶⽀付通道）所提供的⽀付服務。⽀付功能本身並不收集兒童的個⼈信息，但平台需要將兒童的學⽽思培優訂單信息及對賬信息與這些⽀付機構共享以確認兒童的⽀付指令並完成⽀付。如果不提供上述信息，將不能完成購買⽀付，但不會影響兒童使⽤學⽽思培優的其他功能。 </p>
    <p>1.2.3 為展示兒童帳號的訂單信息，平台會收集兒童在使⽤平台服務過程中產⽣的訂單信息⽤於展示及便於對訂單進⾏管理。兒童在學⽽思培優⽣成的訂單中，將可能包含身份信息、聯絡信息、地址信息、⽀付信息。如不提供上述信息，訂單將不能正常展示，並且由於無法查詢到訂單的⽀付信息，有關訂單的繼續⽀付、退款操作將可能無法完成，但不影響使⽤學⽽思培優的其他功能。身份信息、聯絡信息、地址信息、⽀付信息屬於敏感信息，請謹慎向他⼈展示或對外提供。如學⽽思培優需對外提供訂單信息時，將取得授權，並盡到合理商業注意義務對兒童的信息進⾏去標識化處理以在最⼤化保護兒童的個⼈信息同時實現信息可⽤性。 </p>
    <p>1.2.4 兒童在平台作出的評價和發布的內容：兒童在學⽽思培優旗下平台上主動對產品/服務進⾏評價或發布其他內容（如發表評論、回覆信息）時，平台將收集兒童發布的信息，並展示兒童的昵稱、頭像和發布內容。如兒童選擇以匿名⽅式發布評價信息時，平台將不展示昵稱、頭像。兒童還可以通過主動上傳圖⽚、視頻的⽅式授權訪問相機、照⽚和麥克風，以便於兒童通過拍照、拍視頻、上傳照⽚或上傳視頻發布內容。當需要關閉該功能時，⼤多數移動設備都會⽀持兒童的這項需求，具體⽅法請參考或聯繫移動設備的服務商或⽣產商。如兒童不提供需要發布的信息，評價或內容將不能正常提交並展示，但不會影響您使⽤學⽽思培優的其他功能。 </p>
    <p>1.2.5 客服服務相關的通信記錄：當與平台的客服取得聯繫時，平台的系統可能會記錄兒童與客服之間的通訊記錄，以及使⽤帳號信息以便核驗身份；當需要平台提供與訂單相關的客戶服務時，平台可能會查兒童您的相關訂單信息以便給予您適當的幫助和處理；當需要客服協助修改有關信息（如姓名、配送地址、聯繫⽅式）時，兒童可能還需要提供上述信息外的其他信息以便完成修改。如不提供上述信息，客服將可能無法很好地解答疑問或協助處理請求，但不會影響兒童使⽤學⽽思培優的其他功能。 </p>
    <p>1.2.6 ⼿機屏幕內容：當兒童向客服/顧問⽼師成功發起共享屏幕或聽⽼師講解功能期間，平台的系統會在APP處於前台的情況下，將⼿機屏幕內的所有內容（包含學⽽思培優APP頁⾯呈現的信息、通知欄消息）實時發送給顧問⽼師，以便客服/顧問⽼師更好地為兒童提供課程諮詢及操作協助服務。如希望停⽌共享⼿機屏幕中的內容，可以隨時點擊屏幕4中的結束共享按鈕終⽌共享屏幕。如不提供上述信息，兒童將無法使⽤屏幕共享功能，但不會影響兒童使⽤學⽽思培優的其他功能。 </p>
    <p>1.3 為了提供更契合需求的服務，平台會收集關於兒童使⽤產品、服務以及使⽤⽅式的信息並將這些信息進⾏關聯，這些信息包括：</p>
    <p>1.3.1 設備信息：平台會根據兒童在軟件安裝及使⽤中授予的具體權限，接收並記錄兒童所使⽤的設備相關信息（設備品牌、設備型號、操作系統版本、設備設置、唯⼀設備標識符）、設備所在位置相關信息（IP地址、GPS/北⽃位置信息以及能夠提供相關信息的Wi-Fi接⼊點、藍⽛和基站傳感器信息）。如不提供設備相關信息，當使⽤功能並遇到問題時，平台將無法及時排查到問題原因；如不提供位置相關信息，我們將無法展示當地的課程及服務中⼼，但不會影響兒童使⽤學⽽思培優的其他功能。 </p>
    <p>1.3.2 ⽇誌信息：當兒童使⽤平台的網站或客戶端提供的產品或服務時，平台會⾃動收集兒童對平台服務的詳細使⽤情況，作為有關網絡⽇誌保存。包含搜索查詢內容、IP地址、瀏覽器的類型、電信運營商、使⽤的語⾔、訪問⽇期和時間、兒童訪問的網頁記錄、通話狀態信息。如不提供上述信息，平台將無法展示兒童的搜索記錄；當使⽤功能並遇到問題時，平台將無法及時排查到問題原因；但不會影響兒童使⽤學⽽思培優的其他功能。 </p>
    <p>請注意，單獨的設備信息、⽇誌信息等是無法識別特定⾃然⼈身份的信息。如果平台將這類⾮個⼈信息與其他信息結合⽤於識別特定⾃然⼈身份，或者將其與個⼈信息結合使⽤，則在結合使⽤期間，這類⾮個⼈信息將被視為個⼈信息，除取得監護⼈的授權或法律法規另有規定外，平台會將該類個⼈信息做匿名化處理。</p>
    <p>如學⽽思培優需要超出上述收集範圍收集和使⽤您或您監護的兒童的個⼈信息，學⽽思培優將再次告知您，並徵得您的同意。</p>
    <p>2. 我們如何存儲這些信息為加強對兒童個⼈信息的保護，學⽽思培優就所收集的兒童個⼈信息和監護⼈個⼈信息分別存儲，學⽽思培優承諾將採取不低於同⾏業⽔平的加密措施存儲兒童信息，確保兒童信息安全。 </p>
    <p>學⽽思培優存儲兒童個⼈信息，不會超過實現其收集、使⽤⽬的所必需的期限。 </p>
    <p>學⽽思培優將指定專⼈負責兒童信息保護，任何⼯作⼈員訪問兒童信息的，必須經兒童信息保護⼈員的書⾯審批（包括電⼦郵件、短信、微信等電⼦信息交互⽅式），並形成訪問情況的書⾯記錄；同時，學⽽思培優承諾將採取不低於同⾏業⽔平的技術措施，避免違法複製、下載兒童信息。 </p>
    <p>3. 我們如何共享、轉移、披露兒童個⼈信息 </p>
    <p>為加強對兒童個⼈信息的保護，學⽽思培優將遵照法律法規的規定，嚴格限制共享、轉移、披露兒童個⼈信息的情形。 </p>
    <p>3.1 共享</p>
    <p> 除以下情形外，未經監護⼈的同意，我們不會與學⽽思培優之外的任何第三⽅共享兒童的個⼈信息： </p>
    <p>1) 向兒童提供我們的服務。我們可能向合作伙伴及其他第三⽅分享未成年⼈的信息，以實現我們產品與/或服務的功能，讓未成年⼈正常使⽤需要的服務，例如：提供⽀付服務的⽀付機構、 提供數據服務（包括網絡廣告監測、數據統計、數據分析）的合作伙伴、第三⽅物流公司和其他服務提供商； </p>
    <p>2) 與關聯⽅的分享。為便於我們基於統⼀的帳號體系向兒童提供⼀致化服務以及便於其進⾏統⼀管理、保障系統和帳號安全等兒童的個⼈信息可能會在我們和我們的關聯⽅之間進⾏必要共享 </p>
    <p>3) 實現本規則第⼀條「我們如何收集和使⽤未成年⼈個⼈信息」部分所述⽬的； </p>
    <p>4) 履⾏我們在本規則或我們與兒童、監護⼈達成的其他協議中的義務和⾏使我們的權利；</p>
    <p>5) 在法律法規允許的範圍內，為了遵守法律、維護我們及我們的關聯⽅或合作伙伴、兒童、監護⼈或其他⽤戶或社會公眾利益、財產或安全免遭損害，⽐如為防⽌欺詐等違法活動和減少信⽤風險，我們可能與其他公司和組織交換信息。不過，這並不包括違反本規則中所作的承諾⽽為獲利⽬的出售、出租、共享或以其它⽅式披露的信息； </p>
    <p>6) 應兒童合法需求或經監護⼈的授權同意； </p>
    <p>7) 應監護⼈合法要求； </p>
    <p>8) 基於符合法律法規的社會公共利益⽽提供。 </p>
    <p>我們僅會出於合法、正當、必要、特定、明確的⽬的共享兒童個⼈信息。對我們與之共享個⼈信息的公司、組織和個⼈，我們會進⾏安全評估，要求他們嚴格保護兒童個⼈信息，按照我們的說明、本規則以及其他任何相關的保密和安全措施來處理信息。</p>
    <p>3.2 轉讓</p>
    <p>我們不會將兒童和您的個⼈信息轉讓給任何公司、組織和個⼈，但以下情況除外： </p>
    <p>1）在獲取明確同意的情況下轉讓：獲得監護⼈的明確同意後，我們會向其他⽅轉讓兒童個⼈信息。 </p>
    <p>2）在學⽽思培優服務提供者發⽣合併、收購或破產清算情形，或其他涉及合併、收購或破產清算情形時，如涉及到個⼈信息轉讓，平台會要求新的持有兒童和您個⼈信息的公司、組織繼續受本政策的約束，否則平台將要求該公司、組織和個⼈重新向監護⼈徵求授權同意。 </p>
    <p>3.3 披露</p>
    <p>⼀般情況下，學⽽思培優禁⽌對外披露兒童個⼈信息，但可能基於以下⽬的披露兒童的個⼈信息： </p>
    <p>3.3.1 遵守適⽤的法律法規等有關規定； </p>
    <p>3.3.2 遵守法院判決、裁定或其他法律程序的規定；</p>
    <p>3.3.3 遵守相關政府機關或其他法定授權組織的要求； </p>
    <p>3.3.4 其他合法且必要的情形。 </p>
    <p>4. 我們如何保障您管理兒童個⼈信息 </p>
    <p>4.1 您和您監護的兒童可以通過《⽤戶個⼈信息保護政策》第四部分「您的權利」⼀節提供的操作設置來查看、訪問學⽽思培優收集的關於兒童的個⼈信息，要求修改、刪除或拒絕允許進⼀步收集或使⽤這些信息，但刪除這些信息的請求可能會限制兒童訪問所有或部分功能、服務。 </p>
    <p>4.2 您也可以通過《⽤戶個⼈信息保護政策》第⼋部分提供的聯繫⽅式，來反饋和諮詢相關信息。學⽽思培優會在核實您反饋的問題後及時與您聯繫。 </p>
    <p>4.3 如您認為學⽽思培優未經您同意⽽收集、使⽤兒童信息的，請盡快通知學⽽思培優，學⽽思培優在接到的通知後會在合理期限內盡快刪除相關信息。 </p>
    <p>5. 本規則的適⽤ </p>
    <p>本規則僅適⽤於學⽽思培優專門⾯向14周歲以下的兒童的業務功能或服務，針對其他的功能或服務，除法律法規另有明確規定的以外，僅適⽤《⽤戶個⼈信息保護政策》。 </p>
    <p>請您理解，在您同意本規則的同時，也即同意您以及您所管理的兒童提供的個⼈信息的準確性，根據該等信息可以確認您所管理的兒童已年滿14 周歲的，⾃年滿之⽇起，不再適⽤本規則。 </p>
    <p>請您理解，如本規則未特別規定之處，則適⽤《⽤戶個⼈信息保護政策》。 </p>
    <p>在適⽤本規則的情況下，如本規則與《⽤戶個⼈信息保護政策》的條款規定存在不⼀致的，以本規則為準。</p>
  `,
}
