/**
 * 浏览器Web API兼容处理
 */
export default function () {
  if (!process.client) return

  if (!window.scrollTo) {
    window.scrollTo = (x, y) => {
      window.pageXOffset = x;
      window.pageYOffset = y;
    };
  }

  if (!document.body.scrollTo) {
    Element.prototype.scrollTo = (x, y) => {
      this.scrollLeft = x;
      this.scrollTop = y;
    };
  }
}
