export default {
  title: '連絡先を追加',
  Email: 'メールアドレス',
  Phone: '電話番号',
  email: 'メールアドレス',
  phone: '電話番号',
  WeChat: 'ウィチャット',
  WhatsApp: 'Whatsアプリ',
  Line: 'ライン',
  save: '保存',
  cancel: 'キャンセル',
  editWeChat: 'WeChatを入力してください',
  editWhatsApp: 'WhatsAppを入力してください',
  editLine: 'Lineを入力してください',
  editPhone: '電話番号を編集する',
  editEmail: 'メールを編集する',
  contactTip1: 'ウィチャット / Whatsアプリ / ラインは、教師が生徒について保護者と連絡を取る際に利用されます',
  contactTip2: '学習',
  contactTip3: '正しい連絡先をご記入ください。ご記入なさっていない場合、担当先生はタイムリーにお子さんの学習状況をお知らせできかねます',
  contactTip4: '',
  minChoose: '保存する前に、少なくとも1つの連絡先情報を入力してください',
  headline: '需要な連絡先を改善する',
  describe: '連絡先が記入されていない場合、先生が生徒の学習状況を保護者にタイムリーに連絡することができなくなります',
  invalidPhone: '有効な携帯電話番号を入力してください。',
  invalidEmail: '有効なメールアドレスを入力してください。',
  invalidWechat: '有効なウィチャットを入力してください。',
  invalidWhatsApp: '有効なwhatsアプリを入力してください。',
  saveSuccess: '保存に成功',
  saveFail: '少なくとも1つの連絡先情報を入力してください',
  contactTip5: '電話番号とメールアドレスは、お子様の学習に関する重要なお知らせをお送りするために使用しますので、正確にご記入ください',
};
