export default {
  coupon: {
    selectCoupons: 'Select Coupons',
    tips: 'Recommended <span class="orange-text">{x} coupon(s)</span> selected, saving <span class="orange-text">{y}</span>',
    couponTips: '<span class="orange-text">{x} coupon(s)</span> selected, saving <span class="orange-text">{y}</span>',
    noSelectedTips: '<span class="orange-text">{x} Coupon(s)</span> Available',
    changeCard: 'Change Payment Card',
    automaticPay: 'Automatic Pay',
    authorized: 'Authorized',
    unauthorized: 'Unauthorized',
  },
}
