/**
 * goods页面配置
 */
export default {
  recordedCourse: 'Cours enregistré',
  courseTypeList: [{
    value: 'En direct',
    label: 'Cours en direct',
  },
  {
    value: 'enregistré',
    label: 'Cours enregistrés',
  },
  ],
  // 录播课
  recordingDetail: {
    exceptiontip: 'Ce cours n\'est plus ouvert à l\'inscription. Voir d\'autres cours dispensés par Think Academy',
    exceptiontip1: 'Désolé, cette leçon n\'est pas disponible pour le moment. Voir d\'autres cours dispensés par Think Academy',
    free: 'Gratuit',
    courseDetails: 'Détails du cours',
    courseDetail: 'Détail du cours',
    overview: 'Vue d\'ensemble',
    syllabus: 'Syllabus',
    teacher: 'Enseignant',
    validFor: 'Valable pour',
    day: 'jour',
    days: 'jours',
    viewMore: 'Voir tous',
    lesson: 'leçon',
    lessons: 'leçons',
    readMore: 'En savoir plus',
    hr: 'hr',
    min: 'min',
    courseValidUntil: 'Date d\'expiration: ',
    btnStatus: {
      forSale: 'Bientôt disponible',
      buyNow: 'Achetez maintenant',
      soldOut: 'Épuisé',
      registered: 'Inscription réussie',
      subscription: 'Achat',
    },
    expired: 'Expiré',
    expiredTip: 'Ce cours est expiré',
    permanentTip: 'Cours valable en permanence',
    start: 'Commencer',
  },
  goodsDetail: {
    year: 'Annuel',
    month: 'Mensuel',
    discounts: '-20%',
  },
  subscriptionPeriod: {
    2: 'jour',
    3: 'mois',
    4: 'année',
  },
  subDate: {
    2: 'Jour',
    3: 'Mois',
    4: 'année',
  },
  howToAttendClass: {
    title: 'Comment assister aux cours',
    firstStepLeft: 'Télécharger',
    firstStepRight: 'App',
    secondStep: 'Se connecter à l\'application, vous pouvez trouver le cours dans "Classe"',
    thirdStep: 'Trouvez la leçon que vous souhaitez étudier, cliquez sur "Entrée" pour commencer l\'apprentissage',
  },
  orderResult: {
    viewMoreCourse: 'Voir plus de cours',
    viewOrder: 'Voir la commande',
    viewCourse: 'Voir le cours',
    cancel: 'Annuler',
    howToAttendClass: 'Comment assister aux cours',
  },
  recordedVideoTips: {
    0: 'Jouer',
    '0-1': 'Pause',
    1: 'Reculer de 10s',
    2: 'Avancer de 10s',
    9: 'Capture d\'écran',
    11: 'Plein écran',
    '11-1': 'Quitter le plein écran',
  },
  recordedVideo: {
    reload: 'Recharge',
    loadFailed: 'Échec du chargement',
    loading: 'Chargement',
    saveShotScreen: 'La capture d\'écran est enregistrée',
    switchChannel: 'Changer de chaîne',
  },
}
