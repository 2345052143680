<template>
  <div>
    <el-dialog
      v-if="show"
      :visible.sync="show"
      :show-close="false"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      width="512px"
      custom-class="normal-dialog"
    >
      <template slot="title">
        <div class="dialog-header bind-email-dialog-header">
          <div>
            <div class="operate-dialog-icon close-icon" @click="closeHandler">
              <i class="el-icon-close" />
            </div>
          </div>
          <div class="logo" :class="`logo-${$store.state.locale}`">
            <img v-lazy-load :data-src="$t('common.logo.normal')">
          </div>
        </div>
      </template>
      <div class="dialog-body login-dialog-body">
        <h3 class="login-guide-text font-semibold">
          Enter your Email address
        </h3>
        <div class="dialog-label font-medium">
          We will use your Email to send you course information and learning resources.
        </div>
        <EmailInput :trigger-src="triggerSrc" :success-url="successUrl" @close-dialog="closeHandler" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import EmailInput from 'components/login/EmailInput/index.vue';

export default {
  components: { EmailInput },
  props: {
    show: {
      default: false,
      type: Boolean,
    },
    successUrl: {
      default: '',
      type: String,
    },
    triggerSrc: {
      default: '',
      type: String,
    },
  },
  data() {
    return {
    }
  },
  methods: {
    closeHandler() {
      // 通知父级更新弹出层显示状态
      this.$emit('update:show', false);
    },
  },
}
</script>

<style lang="scss">
@import "../login.scss";
</style>
