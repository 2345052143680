/**
 * 导航时区标识
 */
export default {
  // 自动定位时区按钮名称
  autoTimezoneButton: {
    cancelName: 'Pas maintenant',
    confirmName: 'Passer à votre fuseau horaire',
  },
  // 网站时区提示
  websiteTimezoneNotice: 'Fuseau horaire affiché sur le site web:',
  // 当前时区提示
  currentTimezoneNotice: 'Votre fuseau horaire:',
  // 当前时区按钮名称
  currentTimezoneButton: {
    confirmName: 'J\'ai compris',
  },
  // 切换定位刷新页面提示
  refreshNotice: 'Changer de fuseau horaire pour vous',
}
