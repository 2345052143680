export default {
  allOrders: '所有訂單',
  detail: {
    preClass: '課前準備',
    preClassInfo: '快来了解孩子如何上课，课前需要准备什么吧',
    prepare: {
      preClass: '課前準備',
      parents: '家長',
      students: '學生',
      tobetterInfo: '為了能更好的瞭解孩子學習情况、後續課程安排、老師的學習通知、課程的續報，請您下載家長端APP',
      benefitsInfo: '下載家長端的好處',
      calendar: '日历课表',
      calendarInfo: '您可以瞭解孩子的所有課次安排，合理安排時間',
      preClassInfo: '在課前您可以通過查看課件提前瞭解本節課課程內容',
      learningFeedback: '学情反馈',
      learningInfo: '在課後您可以查看課後報告及老師評估，瞭解孩子課堂學習表現； 並且可以及時瞭解孩子工作完成情况及老師批改情况',
      learningService: '学习服务通知',
      learningServiceInfo: '您可以第一時間收到孩子的學習動態和重要的服務通知',
      quickOnline: '線上快捷選課',
      quickOnlineInfo: '專屬顧問為您推薦體系化課程',
    },
    attend: {
      howtoAttend: '如何上課',
      howtoAttendInfo: '此課程我們將線上下進行授課，為了老師更好的安排學生，請孩子在開課前15分鐘到達教室',
      location: '教學點',
      classroom: '教室',
      address: '地址',
      contactNumber: '聯繫電話',
    },
    attendOnline: {
      howtoAttend: '如何上課',
      attendInfo: '為了讓孩子有一個更好的上課體驗，請您按以下步驟操作',
      stepOne: '第一步',
      stepOneInfo: '下載“Think Academy Student”',
      stepOneDetail: '為了保證孩子的上課體驗，請您用PC、iPad或者Android平板來下載學生端：Think Academy Student',
      stepTwo: '第二步',
      stepTwoInfo: '打開“Think Academy Student”並進入課堂',
      stepTwoDetailOnline: '請用您的Think Academy帳號登入學生端APP，確認您選擇了正確的孩子。 孩子可以在課次開始前15分鐘，進入課堂',
      stepTwoDetailRecord: '請用您的Think Academy帳號登入學生端APP，確認您選擇了正確的孩子。 在學習中心課程類型中，切換至“錄播課”，點擊“上課”開始學習',
    },
    viewVideo: '觀看視頻',
  },
  studentAppName: 'Think Academy Student',
  parentAppName: 'Think Academy Parent',
}
