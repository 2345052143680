import { classHotTagType } from 'common/map.js';

export default {
  methods: {
    // 获取班级热度标签类型
    getClassHotTagType(store, sellStore, remainSellTime) {
      return classHotTagType(store, sellStore, remainSellTime)
    },
  },
}
