/**
 * 神策sdk接入
 */
import Vue from 'vue';
import sensors from 'sa-sdk-javascript';

const hash = require('object-hash');

const serverUrlLocal = 'http://shence-datasink.thethinkacademy.com/sa?project=default&token=sa3ff799b4'
const serverUrlBeta = 'https://shence-datasink.thethinkacademy.com/sa?project=default&token=sa3ff799b4'
const serverUrlProd = 'https://shence-datasink.thethinkacademy.com/sa?project=production&token=sa89427894'

const getServerUrl = (env) => {
  if (env === 'prod') {
    return serverUrlProd
  }
  if (env === 'beta' || env === 'preprod') {
    return serverUrlBeta
  }
  return serverUrlLocal
}

// 浏览器事件类型
const browserEvent = [
  'click',
]

// 自定义事件类型
const customEvent = [
  'visually',
]

// 曝光事件触发配置项
const options = {
  root: null,
  // 元素露出超过50%触发回调
  threshold: 0.5,
}

const timer = {};
const visuallyList = [];
const visuallyDataStore = {};
// 回调
const visuallyCallback = (entries, observer) => {
  entries.forEach((entry) => {
    let visuallyData = null;
    let visuallyHashId = '';
    try {
      visuallyHashId = entry.target.getAttribute('data-sa-track-visually-hashid');
      visuallyData = visuallyDataStore[visuallyHashId]
    } catch (e) {
      visuallyData = null;
      visuallyHashId = '';
      console.error('埋点数据格式异常', e)
    }

    // 没有埋点数据取消上报
    if (!visuallyData || !visuallyHashId) {
      observer.unobserve(entry.target)
      return
    }

    if (entry.isIntersecting) {
      timer[visuallyHashId] = setTimeout(() => {
        // 上报埋点信息
        let eventName = ''
        let eventData = {};
        try {
          eventName = visuallyData.event
          eventData = Object.assign(visuallyData.data, eventData)
        } catch (e) {
          eventName = ''
          eventData = null
          console.log('埋点事件上报异常', e)
        }

        console.log('埋点事件上报', eventName, eventData)
        sensors.track(eventName, eventData)
        // 上报成功后取消监听
        observer.unobserve(entry.target)
        visuallyList.push(visuallyHashId)

        timer[visuallyHashId] = null
        observer.unobserve(entry.target)
      }, 500)
    } else if (timer[visuallyHashId]) {
      clearTimeout(timer[visuallyHashId]);
      timer[visuallyHashId] = null;
    }
  });
}

export default function (ctx) {
  const { env, store, app, route } = ctx
  sensors.init({
    name: 'sensors',
    server_url: getServerUrl(env.runtime),
    is_track_single_page: false, // 单页面配置，默认开启，若页面中有锚点设计，需要将该配置删除，否则触发锚点会多触发 $pageview 事件
    use_client_time: false,
    send_type: 'ajax',
    heatmap: {
      // 是否开启点击图，default 表示开启，自动采集 $WebClick 事件，可以设置 'not_collect' 表示关闭。
      clickmap: 'default',
      // 是否开启触达图，not_collect 表示关闭，不会自动采集 $WebStay 事件，可以设置 'default' 表示开启。
      scroll_notice_map: 'default',
    },
    // 数据批量发送
    batch_send: {
      datasend_timeout: 6000, // 一次请求超过多少毫秒的话自动取消，防止请求无响应。
      send_interval: 6000, // 间隔多少毫秒发一次数据。
      one_send_max_length: 6, // 一次请求最大发送几条数据，防止数据太大
    },
    show_log: env.runtime !== 'prod',
  });

  sensors.use('PageLeave') // 开启页面浏览时长统计
  sensors.use('PageLoad') // 开启页面加载时长统计

  const getUserId = () => `${store.state.ucenter.user.uid}`
  const getIsLogin = () => store.state.ucenter.isLogin
  const userInfo = store.state.ucenter.user;

  const commonProperty = {
    hw_user_id: getUserId,
    is_login: getIsLogin,
    event_belong: `海外官网${process.env.clientType}`,
    school_code: store.state.schoolCode,
    website_version: 'v2', // 官网版本
  }
  // 用户属性
  const userAttributes = {
    hw_user_id: getUserId(),
    card: `${userInfo.card}`,
    nick_name: userInfo.nickName,
    grade: userInfo.gradeName,
  }

  // 注册公共属性
  sensors.registerPage(commonProperty);

  if (getIsLogin() && userInfo.uid) {
    // 设置用户属性
    sensors.setProfile(userAttributes)
    sensors.login(`${getUserId()}`)
  }

  // 手动上报浏览事件
  app.router.afterEach(() => {
    Vue.nextTick(() => {
      sensors.quick('autoTrackSinglePage')
    })
  })

  Vue.prototype.Sensors = {
    track(eventName, eventParam = {}, callback = () => {}) {
      const sourceId = route.query.source_id || window.sessionStorage.getItem('source_id') || ''
      eventParam.sourceId = sourceId;
      const params = {
        ...eventParam,
      }
      // 如果有计时对象，则自动传入time_on_page
      if (this.memoryEvent[eventName]) {
        params.time_on_page = parseInt(new Date().getTime() - this.memoryEvent[eventName], 10);
        Reflect.deleteProperty(this.memoryEvent, eventName)
      }
      setTimeout(() => {
        sensors.track(eventName, params, callback)
      }, 500)
    },
    login(uid) {
      setTimeout(() => {
        sensors.login(`${uid}`)
      }, 500)
    },
    keepTime(eventName) {
      this.memoryEvent[eventName] = new Date().getTime()
    },
    memoryEvent: {},
  }

  // 埋点事件上报
  const sensorsTrack = (data) => {
    const trackData = data || null
    // 埋点事件名&埋点数据
    let eventName = '';
    let eventData = {};

    try {
      eventName = trackData.event
    } catch (e) {
      eventName = ''
      console.log('埋点事件名参数异常', e)
    }

    try {
      eventData = Object.assign(trackData.data, {})
    } catch (e) {
      eventData = {}
    }

    if (!eventName) return

    setTimeout(() => {
      sensors.track(eventName, eventData)
    }, 500)
  }

  // 曝光埋点相关定义
  const observer = new IntersectionObserver(visuallyCallback, options);

  const addVisuallyListenner = (ele, bindData) => {
    const hashId = hash(bindData)
    visuallyDataStore[hashId] = bindData
    ele.setAttribute('data-sa-track-visually-hashid', hashId)
    if (visuallyList.indexOf(hashId) !== -1) return;
    try {
      observer.observe(ele);
    } catch (error) {
      console.log(error)
    }
  }

  const removeVisuallyListenner = (ele) => {
    try {
      observer.unobserve(ele);
    } catch (error) {
      console.log(error)
    }
  }

  const addCustomEventListenner = (eventType, ele, bindData) => {
    switch (eventType) {
      case 'visually':
        addVisuallyListenner(ele, bindData)
        break;
      default: break
    }
  }

  const removeCustomEventListenner = (eventType, ele) => {
    switch (eventType) {
      case 'visually':
        removeVisuallyListenner(ele)
        break;
      default: break
    }
  }

  // 指令绑定
  const bindHandler = (ele, binding) => {
    const bindingData = binding.value
    // 未绑定数据
    if (!bindingData) return
    if (Array.isArray(bindingData)) {
      bindingData.forEach((bindingItem) => {
        const eventType = bindingItem.type;
        // 浏览器原生事件
        if (browserEvent.indexOf(eventType) >= 0) {
          ele.addEventListener(eventType, () => { sensorsTrack(bindingItem) }, false)
        }

        // 自定义事件
        if (customEvent.indexOf(eventType) >= 0) {
          addCustomEventListenner(eventType, ele, bindingItem)
        }
      })
    }
  }

  // 指令解绑
  const unbindHandler = (ele, binding) => {
    const bindingData = binding.value
    // 未绑定数据
    if (!bindingData) return
    if (Array.isArray(bindingData)) {
      bindingData.forEach((bindingItem) => {
        const eventType = bindingItem.type;
        // 浏览器原生事件
        if (browserEvent.indexOf(eventType) >= 0) {
          ele.removeEventListener(eventType, () => { }, false)
        }

        // 自定义事件
        if (customEvent.indexOf(eventType) >= 0) {
          removeCustomEventListenner(eventType, ele)
        }
      })
    }
  }

  // 浏览器原生事件触发埋点
  Vue.directive('sa-track', {
    bind: bindHandler,
    unbind: unbindHandler,
  })

  ctx.$sensors = Vue.prototype.Sensors
}
