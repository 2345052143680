export default {
  register: {
    formPlaceholder: 'Please complete the registration information before checkout',
    formTitle: 'Register Info',
    submitName: 'Submit',
    requiredError: 'The input is required.',
    selectPlaceholder: 'Please select',
    inputPlaceholder: 'Please input',
  },
  priceInfo: {
    deduction: 'Automatic deduction after 48 hours',
  },
  orderCreate: {
    subscription: 'Purchase',
  },
}
