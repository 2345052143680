<template>
  <div>
    <CommonDialog :dialog-title="dialogTitle" :show-left-icon="showLeftIcon" :show-close-icon="showCloseIcon" :show.sync="isShow" @left-icon-handler="changeStepStatus('SWITCH_STUDENT')">
      <template v-slot:body>
        <SwtichStudentTemplate v-show="stepStatus === 'SWITCH_STUDENT'" :is-appli="isAppli"
                               :switch-stu-tip-text="switchStuTipText" refs="SwitchStudentTemplate"
                               :student-info="studentInfo"
                               :is-add-cart="isAddCart"
                               @switch-success="switchSuccessHandler" @add-student="changeStepStatus('ADD_STUDENT')"
        />
        <AddStudentTemplate v-if="stepStatus === 'ADD_STUDENT'" ref="AddStudentTemplate" :student-info="studentInfo" @close="changeStepStatus('SWITCH_STUDENT')" @add-success="changeStepStatus('SWITCH_STUDENT')" />
      </template>
    </CommonDialog>
  </div>
</template>

<script>
import CommonDialog from 'components/common/CommonDialog';
import SwtichStudentTemplate from './SwtichStudentTemplate';
import AddStudentTemplate from './AddStudentTemplate';

export default {
  components: { CommonDialog, SwtichStudentTemplate, AddStudentTemplate },
  props: {
    isAddCart: {
      default: false,
      type: Boolean,
    },
    switchStuTipText: {
      default: '',
      type: String,
    },
    isAppli: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      isShow: false,
      /**
       * stepStatus
       * SWITCH_STUDENT - 切换学员
       * ADD_STUDENT - 新增学员
       */
      stepStatus: 'SWITCH_STUDENT',
      studentInfo: [],
    }
  },
  computed: {
    locale() {
      return this.$store.state.locale
    },
    dialogTitle() {
      return this.stepStatus === 'SWITCH_STUDENT' ? this.$t('login.switchStudent.choose') : this.$t('login.switchStudent.add');
    },
    showLeftIcon() {
      return this.stepStatus === 'ADD_STUDENT';
    },
    showCloseIcon() {
      return this.stepStatus === 'SWITCH_STUDENT'
    },
  },
  methods: {
    openDialog(data) {
      this.stepStatus = 'SWITCH_STUDENT';
      this.isShow = true;
      if (data) {
        this.studentInfo = data
      }
    },
    closeDialog() {
      this.isShow = false;
    },
    changeStepStatus(newStepStatus) {
      this.stepStatus = newStepStatus;
    },
    switchSuccessHandler(isSwitch, studentInfo) {
      this.closeDialog();
      this.$emit('switch-success', isSwitch, studentInfo)
    },
  },
}
</script>

<style lang="scss" scoped>
/deep/.el-dialog.common-dialog .el-dialog__body .dialog-body {
  padding-top: 0;

  .coupon-tip-container {
    padding: 13px 0 0;
    margin: 0 0 -21px;
  }
}

.switch-student-dialog-body {
  padding: 0 0 20px;
  width: 512px;
  max-height: 564px;
  overflow: auto;

  &.scrolling-style {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 30px;
      border-radius: 10px 10px 0 0;
      background: linear-gradient(180deg, rgba(222, 226, 231, 0.6) 0%, rgba(222, 226, 231, 0) 100%);
    }
  }

  .students-list-title {
    font-size: 16px;
    line-height: 19px;
    color: var(--txt-color-lv2);
    word-break: keep-all;
  }

  .guide-to-add-student {
    margin: 20px 0 0;

    .btn-add-student {
      color: var(--txt-color-link);
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .students-list-wrapper {
    padding: 0 66px;
    max-height: 434px;
    overflow: auto;

    .students-list-title {
      margin: 34px 0 20px;
    }

    .students-list {
      min-height: 86px;

      /deep/.loading-wrapper {
        height: 86px;
      }
    }

    /deep/.student-item-card-wrapper {
      margin: 2px auto;
      border-radius: 0;

      &:first-child {
        border-radius: 10px 10px 0 0;
      }

      &:last-child {
        border-radius: 0 0 10px 10px;
      }

      &:only-child {
        border-radius: 10px;
      }
    }
  }

  .button-continue {
    margin: 24px auto 0;
    width: 220px;
  }
}
</style>
