// 诊断页面配置
export default {
  understandingDialog: {
    title: '了解測試結果',
    items: [
      {
        title: '測試結果可以做什麽？',
        desc: '您可以使用這個測試結果幫助選擇最適合您孩子{1}能力的{0}課程。',
      },
      {
        title: '什麽是不同的難度等級？',
        desc: '學而思為不同的兒童提供以下難度等級的課程。',
      },
    ],
  },
};
