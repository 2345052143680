/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
export const state = () => ({
  path: '',
  webchatSid: '',
  data: {},
});

export const mutations = {
  UPDATE_DATA(state, data) {
    state.data = data;
  },
  UPDATE_ID(state, id) {
    state.webchatSid = id
  },
  UPDAE_PATH(state, path) {
    state.path = path
  },
}
export const actions = {
  updateChatData({ commit }, data) {
    commit('UPDATE_DATA', data)
    commit('UPDATE_DATA', data.webchatSid)
  },
  updatePath({ commit }, path) {
    commit('UPDAE_PATH', path)
  },
}
