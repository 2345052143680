<template>
  <div v-if="isShow && !isOrgSchool" class="cookies-statement">
    <div class="wrapper">
      <div class="description">
        {{ description }}
      </div>
      <div class="button btn-primary" @click="handleAccept('Accept')">
        {{ $t('featureComponents.page.cookiesAccept.accept') }}
      </div>
    </div>
  </div>
</template>

<script>
// import commonPagesConfig from 'config/pages/common.js';

export default {
  data() {
    return {
      isShow: false,
      description: this.$t('featureComponents.page.cookiesStatement.description'),
    }
  },
  computed: {
    isOrgSchool() {
      const { locale } = this.$store.state;
      return locale === 'am' || locale === 'ae'
    },
  },
  mounted() {
    const cookiesAccept = window.localStorage.getItem('cookiesAccept', 1);
    if (!cookiesAccept) {
      this.isShow = true;
    }
  },
  methods: {
    handleAccept(btnName) {
      this.isShow = false;
      this.cookiesIsAcceptSa(btnName)
      window.localStorage.setItem('cookiesAccept', 1);
    },
    cookiesIsAcceptSa(btnName) {
      this.Sensors.track('bottomsheets_click', {
        button_content: btnName,
      })
    },
  },
};
</script>

<style lang="scss">
.cookies-statement {
  position: fixed;
  bottom: 0;
  z-index: 1000;
  width: 100%;
  height: 64px;
  background: #f1f1f1;

  .wrapper {
    position: relative;
  }

  .description {
    line-height: 64px;
    color: var(--txt-color-lv1);
    font-size: 16px;
  }

  .button {
    position: absolute;
    right: 0;
    top: 12px;
    width: 120px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border-radius: 20px;
    cursor: pointer;
  }
}
</style>
