/**
 * 登录通用方法
 */

/* eslint-disable import/prefer-default-export */
const getLoginTriggerSource = (source) => {
  switch (source) {
    case 'header':
      return '主页sign in'
    case 'courses-detail-registration':
      return '课程详情页-购买'
    case 'group-buy-detail-registration':
      return '参团详情页-购买'
    case 'courses-detail-wish':
      return '课程详情页-收藏'
    case 'personal-page':
      return '个人中心'
    case 'examination':
      return '模拟考试站'
    default:
      return '主页sign in'
  }
}

const getLoginAccountType = (accountType) => {
  switch (accountType) {
    case 0:
      return '手机号'
    case 3:
      return '邮箱'
    default: return '手机号'
  }
}

const getLoginMethod = (loginMethod) => {
  switch (loginMethod) {
    default: return '验证码'
  }
}

export {
  getLoginTriggerSource,
  getLoginAccountType,
  getLoginMethod,
}
