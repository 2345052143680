/**
 * APP内嵌页面中间件
 */

export default function ({ store, error }) {
  // 客户端环境不执行
  if (process.client) return;

  // 设置路由路转前缀
  store.commit('SET_ROUTER_PATH_PREFIX', '/app');

  // 只允许移动端APP访问，否则返回404页
  if (!store.state.appDevice && store.state.appDevice.toLowerCase() !== 'ios' && store.state.appDevice.toLowerCase() !== 'android') {
    error({ statusCode: 404 });
  }
}
