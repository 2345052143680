<template>
  <div v-if="isShow" class="cookies-accecpt">
    <div class="cookies-accecpt-img" />
    <div class="cookies-accecpt-title">
      {{ title }}
    </div>
    <div class="cookies-accecpt-description" v-html="description" />
    <div class="cookies-accecpt-button">
      <div class="button button-normal button-bg-transparent" @click="handleReject('Reject')">
        {{ $t('featureComponents.page.cookiesAccept.reject') }}
      </div>
      <div class="button button-normal button-primary-white" @click="handleAccept('Accept')">
        {{ $t('featureComponents.page.cookiesAccept.accept') }}
      </div>
    </div>
  </div>
</template>

<script>
// import commonPagesConfig from 'config/pages/common.js';

export default {
  data() {
    return {
      isShow: false,
      title: this.$t('featureComponents.page.cookiesAccept.title'),
      description: this.$t('featureComponents.page.cookiesAccept.description'),
    }
  },
  mounted() {
    const cookiesAccept = window.localStorage.getItem('cookiesAccept', 1);
    if (!cookiesAccept) {
      this.isShow = true;
    }
  },
  methods: {
    handleAccept(btnName) {
      this.isShow = false;
      this.cookiesIsAcceptSa(btnName)
      window.localStorage.setItem('cookiesAccept', 1);
    },
    handleReject(btnName) {
      this.isShow = false;
      this.cookiesIsAcceptSa(btnName)
      window.localStorage.setItem('cookiesAccept', 0);
    },
    // 神策埋点
    cookiesIsAcceptSa(btnName) {
      this.Sensors.track('bottomsheets_click', {
        button_content: btnName,
      })
    },
  },
};
</script>

<style lang="scss">
.cookies-accecpt {
  position: sticky;
  bottom: 0;
  z-index: 1000;
  padding: 34px 0 0 280px;
  height: 160px;
  background: linear-gradient(162deg, #ff870a 0%, #ffbc0a 100%);

  .cookies-accecpt-img {
    position: absolute;
    left: 100px;
    bottom: 24px;
    height: 160px;
    width: 160px;
    background-image: url('~assets/images/common/cookies-policy-img.png');
    background-size: 160px 160px;
  }

  .cookies-accecpt-title {
    height: 38px;
    font-size: 32px;
    font-weight: 600;
    color: #fff;
    line-height: 38px;
  }

  .cookies-accecpt-description {
    margin-top: 8px;

    >p {
      height: 23px;
      font-size: 18px;
      font-weight: 500;
      color: #fff;
      line-height: 23px;

      >a {
        color: #fff;
        text-decoration: underline;
      }
    }
  }

  .cookies-accecpt-button {
    .button-bg-transparent {
      position: absolute;
      right: 244px;
      bottom: 34px;
      height: 48px;
      width: 124px;
    }

    .button-primary-white {
      position: absolute;
      right: 100px;
      bottom: 34px;
      width: 124px;
    }
  }
}
</style>
