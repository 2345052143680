/**
 * 移动端适配中间件
 */

export default function ({
  req, store, redirect, route,
}) {
  // 客户端环境不执行
  if (process.client) return;

  // 短链接服务路由阻止touch端跳转
  if (route.name === 'j-id') {
    return
  }
  const userAgent = req.headers['user-agent'];
  // const platform = (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent)) ? 'touch' : 'pc';
  const platform = (/Android(?=.*Mobile)|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent)) ? 'touch' : 'pc';
  // 移动端访问跳转
  if (platform === 'touch') {
    const requestUrl = req.ctx.request.url;
    const touchDomain = process.env.touchDomains[store.state.locale];
    const touchUrl = `${touchDomain}${requestUrl}`;
    redirect(touchUrl);
  }
}
