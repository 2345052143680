export default {
  ImproveUserInfo: {
    title: '連絡先を追加',
    WeChat: 'ウィチャット',
    WhatsApp: 'WhatsApp',
    Line: 'Line',
    save: '保存',
    cancel: 'キャンセル',
    editWeChat: 'WeChatを入力してください',
    editWhatsApp: 'WhatsAppを入力してください',
    editLine: 'Lineを入力してください',
    contactTip1: 'ウィチャット / Whatsアプリ / ラインは、教師が生徒について保護者と連絡を取るために使用される',
    contactTip2: '学習.',
    contactTip3: '正しい連絡先をご記入ください。ご記入なさっていない場合、担当先生はタイムリーにお子さんの学習状況をお知らせできかねます',
    contactTip4: '',
    minChoose: '保存する前に、少なくとも1つの連絡先情報を入力してください',
  },
  recordingCard: {
    recordingCourse: 'ビデオレッスン',
    viewOrder: '注文を見る',
    howToAttendClass: 'クラスの参加方法',
    cancel: 'キャンセル',
    viewCourse: 'コースを見る',
    viewMoreCourse: '他のコースを見る',
  },
  // 支付失败结果页提示文案
  toBePaidResult: {
    title: '重要な支払いに関する注意事項',
    content: '引き落としが完了していても、注文が「支払い済み」になっていない場合は、銀行からの引き落とし通知がまだ届いていない可能性があります。後ほどご確認ください。ご不明な点がございましたら、下記までお問い合わせください',
    confirm: 'OK',
  },
  courseDetail: {
    rescheduled: '予定変更',
  },
  guidePopup: {
    // 7诊断预约 9已诊断 11预约诊断报告咨询 13完成诊断报告咨询 27长期班待支付
    7: ' to view appointment records.',
    9: ' to view diagnostic reports.',
    11: ' to view appointment records.',
    13: ' to view recommended courses.',
    27: ' to view recommended courses.',
  },
  scoreQuery: {
    legalCheck: '続行する前に、<a class="link-text" href="/terms-of-use" target="_blank">利用規約</a>および<a class="link-text" href="/privacy-policy" target="_blank">プライバシーポリシー</a>を読んで同意したことをご確認ください。',
    searchError: '句読点、スペース、その他の特殊文字を含まない内容を入力してください。',
    notFoundTip: '一致するレコードが見つかりませんでした。入力が正しいかどうかご確認ください。',
    queryResult: '結果を確認する',
    share: 'ダウンロードして共有',
    saveImage: '写真を保存する',
    cancel: 'キャンセル',
  },
}
