export default {
  title: 'Add important contact information',
  Email: 'Email Address',
  Phone: 'Phone number',
  email: 'Email Address',
  phone: 'Phone number',
  WeChat: 'WeChat',
  WhatsApp: 'WhatsApp',
  Line: 'Line',
  save: 'Save',
  cancel: 'Cancel',
  editWeChat: 'Edit WeChat',
  editWhatsApp: 'Edit WhatsApp',
  editLine: 'Edit Line',
  editPhone: 'Edit Phone',
  editEmail: 'Edit Email',
  contactTip1: 'WeChat / WhatsApp / Line will be used for teachers to communicate with parents about students',
  contactTip2: 'learning.',
  contactTip3: 'Please fill in the contact information truthfully. If the contact information is missing, it will affect the teacher to synchronize the students',
  contactTip4: 'learning situation with the parents in time',
  minChoose: 'Fill in at least one contact information before saving',
  headline: 'Improve important contacts',
  describe: 'Please fill in the contact information truthfully, if the contact information is missing, it will affect the teacher to synchronize the learning situation of students to parents in a timely manner',
  invalidPhone: 'Please enter a valid mobile number',
  invalidEmail: 'Please enter a valid e-mail',
  invalidWechat: 'Please enter a valid wechat',
  invalidWhatsApp: 'Please enter a valid whatsapp',
  saveSuccess: 'Saved successfully',
  saveFail: 'Please enter at least one contact',
};
