/**
 * App h5
 */

export default function ({ app, store }) {
  if (process.server) return;
  const isApp = !!window.JsInjectionActivity;
  store.commit('SET_IS_APP', isApp);
  if (isApp) {
    app.head.meta.push({
      name: 'viewport',
      content: 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no',
    });
  }
}
