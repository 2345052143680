<template>
  <div>
    <!-- 手机号表单 -->
    <el-form ref="loginForm" class="login-form font-medium" :model="formData" :rules="loginRule">
      <el-form-item v-show="!isSend" prop="phone">
        <PhoneInput v-model="formData.phone" @areaChange="areaChange" />
      </el-form-item>
      <el-form-item v-show="isSend" prop="captcha">
        <CodeInput ref="phoneCode" v-model="formData.captcha" :scene="scene" type="phone" :phone="formData.phone" :area-code="formData.phoneAreaCode.value" :cookie-name="cookieName"
                   :before-send="codeBeforeSend" :send-success="codeSendSuccess" :send-error="codeSendError"
        />
      </el-form-item>
    </el-form>
    <!-- 可以切换 -->
    <div v-if="canSwitchType">
      <div v-show="!isSend" class="button-container">
        <el-button type="primary" class="button button-normal login-button-size marginBtm21" :loading="sendLoading" :disabled="!isPhoneContinue" @click="goSendCode('phoneCode')">
          {{ $t('login.login.loginForm.continueBtn') }}
        </el-button>
        <div class="divider marginBtm19">
          <div class="dividet-text">
            or
          </div>
        </div>
        <el-button class="button button-outline login-button-size fs14 marginBtm30" :disabled="sendLoading" @click="switchType('email')">
          {{ $t('login.login.loginForm.switchEmail') }}
        </el-button>
      </div>
      <div v-show="isSend" class="button-container">
        <el-button type="primary" class="button button-normal login-button-size marginBtm21" :loading="isLoging" :disabled="!isLoginSubmitAvalible" @click="loginSubmit">
          {{ $t('login.login.loginForm.submitBtnText') }}
        </el-button>
      </div>
    </div>
    <!-- 不可以切换 -->
    <div v-else>
      <div v-show="!isSend" class="button-container">
        <div :class="['arrow-button', !isPhoneContinue ? 'arrow-disabled': '']" @click="goSendCode('phoneCode')">
          <div class="arrow-img" />
        </div>
      </div>
      <div v-show="isSend" class="button-container paddingTop8">
        <el-button type="primary" class="button button-normal login-button-size marginBtm16" :loading="isLoging" :disabled="!isLoginSubmitAvalible" @click="loginSubmit">
          {{ $t('bindForm.submitBtn') }}
        </el-button>
      </div>
      <!-- <div class="tip font-medium">
        {{ $t('bindForm.tip')+contact }}
      </div> -->
    </div>
  </div>
</template>
<script>
import PhoneInput from 'components/login/newLoginForm/phoneInput.vue';
import CodeInput from 'components/login/newLoginForm/codeInput.vue';
import { oneApi } from '~/config/api';

export default {
  components: {
    PhoneInput, CodeInput,
  },
  props: {
    isLoging: { // 登陆按钮加载中
      type: Boolean,
      default: false,
    },
    canSwitchType: {
      type: Boolean,
      default: true,
    },
    cookieName: {
      type: String,
      default: 'login-phone-count',
    },
    scene: {
      type: Number,
      default: 1,
    },
    execute: {
      type: Boolean,
      default: false,
    },
    guide: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isChecking: false, // 检测中
      contact: this.$t('common.contactPhone') !== '' ? this.$t('common.contactPhone') : this.$t('common.contactEmail'),
      isSend: false, // 是否切换到code页面
      sendLoading: false, // 按钮加载中状态
      formData: {
        phone: '',
        captcha: '',
        phoneAreaCode: {
          value: '',
        },
      },
      loginRule: {
        phone: [{ required: true, message: this.$t('login.login.loginForm.phone.rules.required'), trigger: 'blur' }],
        captcha: [{ required: true, message: this.$t('login.login.loginForm.captcha.rules.required'), trigger: 'blur' },
          { pattern: /^\d{6}$/, message: this.$t('login.login.loginForm.captcha.rules.format'), trigger: 'blur' }],
      },
    }
  },
  computed: {
    isLoginSubmitAvalible() {
      return /^\d{6}$/.test(this.formData.captcha);
    },
    isPhoneContinue() {
      if (this.formData.phoneAreaCode.reg) {
        return this.formData.phoneAreaCode.reg.test(this.formData.phone)
      }
      return this.formData.phone !== ''
    },
  },
  methods: {
    init() {
      this.$refs.phoneCode.init();
      this.formData.captcha = '';
      this.isSend = false
    },
    /**
     * 触发登录按钮
     */
    loginSubmit() {
      this.$refs.loginForm.validate((isValid) => {
        if (isValid) {
          this.$emit('submit', this.formData)
        }
      });
    },
    // 切换表单
    switchType() {
      this.formData.phone = '';
      this.$refs.loginForm.clearValidate();
      this.$emit('switch')
    },
    // 触发发送验证码
    goSendCode(codInputName) {
      if (this.canSwitchType) {
        // 登陆弹窗触发该方法，加入埋点
        this.$emit('clickContinue')
      }
      if (this.sendLoading || !this.isPhoneContinue) {
        return undefined
      }
      if (this.execute) {
        return this.guide(this.formData)
      }
      return this.$refs[codInputName].sendCodeHanlder()
    },
    // 手机区号change
    areaChange(obj) {
      this.formData.phoneAreaCode = obj;
      this.setPhonePattern();
      if (this.formData.phone === '') return;
      this.$refs.loginForm.validateField('phone');
    },
    /**
     * 设置手机号校验正则
     */
    setPhonePattern() {
      const { reg } = this.formData.phoneAreaCode;
      this.loginRule.phone[1] = {
        pattern: reg,
        message: this.$t('login.login.loginForm.phone.rules.format'),
        trigger: 'blur',
      }
    },
    // 验证码发送前
    codeBeforeSend(callback) {
      console.log('校验手机号2')
      this.$refs.loginForm.validateField('phone', async (errMsg) => {
        if (errMsg !== '') { return }
        this.sendLoading = true;
        // 绑定联系方式时需要验证联系方式是否重复
        if (this.scene === 3 && !this.isChecking) {
          const data = {
            type: 0,
            countryCallingCode: this.formData.phoneAreaCode.value,
            contactInfo: this.formData.phone,
          }
          this.isChecking = true;
          console.log('校验手机号31');
          const res = await this.$axios.post(oneApi.contactVerify, data, { rewritePostBody: true })
          this.isChecking = false;
          if (Number(res.code) !== 0) {
            this.$ElMessage({
              type: 'error',
              message: res.msg,
            });
            this.sendLoading = false;
            return;
          }
        }
        callback();
      });
    },
    // 发送成功
    codeSendSuccess() {
      this.sendLoading = false;
      this.isSend = true;
      let valueCode = ''
      // 修改dialog文案使用
      valueCode = `+${this.formData.phoneAreaCode.value} ${this.formData.phone}`
      this.$emit('titleChange', {
        isSend: this.isSend,
        valueCode,
      })
    },
    codeSendError() {
      this.sendLoading = false;
    },
  },
}
</script>
<style lang="scss" scoped>
.button-container {
  text-align: center;
  padding-top: 16px;

  .marginBtm21 {
    margin-bottom: 21px;
  }

  .marginBtm19 {
    margin-bottom: 19px;
  }

  .marginBtm30 {
    margin-bottom: 30px;
  }

  .marginBtm16 {
    margin-bottom: 16px;
  }
}

.login-form-container {
  height: 269px;
  position: relative;

  .login-policy-checkbox {
    width: 456px;
    white-space: normal;

    .legal-statement-content {
      width: 434px;
      font-size: 16px;
      line-height: 19px;
      color: var(--txt-color-lv2);

      > a {
        display: inline-block;
        color: var(--txt-color-link);
        text-decoration: underline;

        &:hover {
          color: var(--txt-color-link);
        }
      }
    }
  }
}

.divider {
  display: block;
  height: 1px;
  width: 130px;
  margin: 0 auto;
  background-color: var(--txt-color-link3);
  position: relative;

  .dividet-text {
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    position: absolute;
    background-color: #fff;
    padding: 0 8px;
    font-weight: 500;
    color: var(--txt-color-link);
    font-size: 16px;
  }
}

.arrow-button {
  width: 56px;
  height: 56px;
  background: var(--txt-color-link);
  border-radius: 28px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 16px;
  cursor: pointer;

  &:hover {
    background: var(--txt-color-link2);
  }

  .arrow-img {
    background: url('~assets/images/common/next-arrow.png') no-repeat;
    background-size: 100%;
    width: 24px;
    height: 24px;
  }
}

.arrow-disabled {
  opacity: 0.2;
}

.paddingTop8 {
  padding-top: 8px;
}

.tip {
  font-size: 12px;
  color: var(--txt-color-lv2);
  line-height: 14px;
  padding-bottom: 20px;
  text-align: center;
}

.fs14 {
  font-size: 14px;
  font-weight: 600;
}
</style>
