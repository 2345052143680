<template>
  <el-dialog
    v-if="visible"
    :visible.sync="visible"
    :show-close="false"
    :close-on-click-modal="false"
    :destroy-on-close="true"
    width="480px"
    custom-class="normal-dialog"
    center
  >
    <template slot="title">
      <div class="dialog-header login-dialog-header">
        <div v-if="isSend" class="back-icon" @click="backFunc" />
        <div class="operate-dialog-icon close-icon" @click="closeHandler">
          <i class="iconfont icon-close-new" />
        </div>
      </div>
    </template>
    <div v-if="type=='phone'">
      <div v-if="!isSend" class="dialog-title font-semibold">
        {{ $t('bindForm.phone.title') }}
      </div>
      <div v-if="!isSend" class="subTitle font-medium">
        {{ $t('bindForm.phone.subTitle') }}
      </div>

      <div v-if="isSend" class="dialog-title font-semibold">
        {{ $t('login.login.confirmTitleForPhone') }}
      </div>
      <div v-if="isSend && locale !== 'jp'" class="subTitle font-medium marginReset" v-html="$t('login.login.confirmSubTitleForPhone')" />
      <div v-if="isSend" class="code-style font-medium">
        {{ valueCode }}
      </div>
      <div v-if="isSend && locale === 'jp'" class="subTitle font-medium marginReset" v-html="$t('login.login.confirmSubTitleForPhone')" />
    </div>
    <div v-if="type=='email'">
      <div v-if="!isSend" class="dialog-title font-semibold">
        {{ $t('bindForm.email.title') }}
      </div>
      <div v-if="!isSend" class="subTitle font-medium">
        {{ $t('bindForm.email.subTitle') }}
      </div>

      <div v-if="isSend" class="dialog-title font-semibold">
        {{ $t('login.login.confirmTitleForEmail') }}
      </div>
      <div v-if="isSend" class="subTitle font-medium marginReset" v-html="$t('login.login.confirmSubTitleForEmail')" />
      <div v-if="isSend" class="code-style font-medium">
        {{ valueCode }}
      </div>
    </div>

    <!-- 手机号表单 -->
    <PhoneForm v-if="type=='phone'" ref="phoneForm" :scene="3" :execute="execute" :guide="guide" class="form-width" cookie-name="bind-phone-count" :can-switch-type="false" :is-loging="isLoging" @submit="submitHandle" @titleChange="titleChange" />
    <!-- 邮箱表单 -->
    <EmailForm v-if="type=='email'" ref="emailForm" :scene="3" :execute="execute" :guide="guide" class="form-width" cookie-name="bind-email-count" :can-switch-type="false" :is-loging="isLoging" @submit="submitHandle" @titleChange="titleChange" />
  </el-dialog>
</template>
<script>
import PhoneForm from 'components/login/newLoginForm/phoneForm.vue'
import EmailForm from 'components/login/newLoginForm/emailForm.vue';
import bus from 'common/bus.js';
import { oneApi } from 'config/api'
import { getLoginAccountType } from 'common/login.js';

export default {
  components: {
    PhoneForm, EmailForm,
  },
  data() {
    const { studentId } = this.$route.query;
    return {
      studentId,
      isSend: false,
      isLoging: false,
      valueCode: '',
    }
  },
  computed: {
    visible() {
      return this.$store.state.bindDialog.visible
    },
    type() {
      return this.$store.state.bindDialog.type
    },
    guide() {
      return this.$store.state.bindDialog.guide
    },
    execute() {
      return this.$store.state.bindDialog.execute
    },
    locale() {
      return this.$store.state.locale
    },
  },
  watch: {
    visible(val) {
      if (val) {
        // 展示时埋点
        if (!this.isSend) {
          this.eventTrack('associate_pop_pv')
        } else {
          this.eventTrack('associate_confirm_pop_pv')
        }
      } else {
        this.$store.dispatch('bindDialog/close')
      }
    },
  },
  methods: {
    eventTrack(eventName) {
      this.Sensors.track(eventName, {
        associate_type: getLoginAccountType(this.type === 'phone' ? 0 : 3),
      })
    },
    closeHandler() {
      this.isSend = false;
      this.isLoging = false;
      this.valueCode = '';
      this.$store.dispatch('bindDialog/close')
    },
    backFunc() {
      if (this.type === 'phone') {
        this.$refs.phoneForm.init();
      } else {
        this.$refs.emailForm.init();
      }
      this.isSend = false;
    },
    titleChange(obj) {
      this.eventTrack('associate_confirm_pop_pv')
      this.isSend = obj.isSend
      this.valueCode = obj.valueCode
    },
    async submitHandle(obj) {
      console.log('到这没')
      this.eventTrack('associate_confirm_pop_click')
      let data = {}
      let res;
      if (this.type === 'phone') {
        data = {
          phone: obj.phone,
          verificationCode: obj.captcha,
          countryCallingCode: obj.phoneAreaCode.value,
        }
        res = await this.$axios.post(oneApi.bindPhone, data, { rewritePostBody: true, uid: this.studentId })
      } else {
        data = {
          email: obj.email,
          verificationCode: obj.captcha,
        }
        res = await this.$axios.post(oneApi.bindEmail, data, { rewritePostBody: true, uid: this.studentId })
      }
      // 触发后续购课流程
      if (Number(res.code) !== 0) {
        this.$ElMessage({
          type: 'error',
          message: res.msg,
        });
        return;
      }
      this.$store.dispatch('ucenter/queryUserBasicInfo', { $axios: this.$axios, userData: { fillInAttention: false } });
      this.closeHandler();
      if (this.$route.name === 'my-unpaid-courses-list') {
        bus.$emit('unpaid.list.registrationCourses', { uid: this.studentId })
      }
      bus.$emit('courses.detail.registrationCourses', false)
    },
  },
}
</script>
<style lang="scss" scoped>
@import "../../login/login.scss";

.dialog-title {
  font-size: 20px;
  color: var(--txt-color-lv1);
  line-height: 24px;
  margin-bottom: 20px;
  text-align: center;
}

.subTitle {
  font-size: 16px;
  color: var(--txt-color-lv2);
  line-height: 19px;
  margin-bottom: 32px;
  text-align: center;
}

.login-dialog-header {
  padding-top: 58px;
}

.login-form {
  .el-form-item {
    width: 312px;
    margin: 0 auto;
  }
}

/deep/ .normal-dialog {
  border-radius: 16px;

  .form-width {
    margin: 0 auto;
    width: 312px;
  }

  .code-style {
    font-size: 16px;
    color: var(--txt-color-lv1);
    line-height: 19px;
    text-align: center;
    margin-bottom: 32px;
  }

  .marginReset {
    margin: 0 auto 16px;
    width: 100%;
  }
}
</style>
