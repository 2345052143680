<template>
  <div class="bind-email-container">
    <div class="bind-email-form">
      <el-form ref="bindEmailForm" class="new-round-form" :model="formData" :rules="rules">
        <el-form-item prop="email">
          <el-input ref="bindEmailInput" v-model="formData.email" placeholder="Email" :clearable="true" />
        </el-form-item>
      </el-form>
      <div class="bind-email-form-bottom-container">
        <div class="bind-email-btn-container">
          <el-button class="button button-large button-bind-email" :loading="isSubmitBtnLoading" :disabled="!isEmailSubmitBtnAvailable" @click="onSubmit()">
            <i v-if="!isSubmitBtnLoading" class="el-icon-right" />
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bus from 'common/bus';
import { oneApi } from 'config/api';

export default {
  props: {
    successUrl: {
      default: '',
      type: String,
    },
    triggerSrc: {
      default: '',
      type: String,
    },
  },
  data() {
    return {
      isSubmitBtnLoading: false,
      formData: {
        email: '',
      },
      rules: {
        email: [{ required: true, message: this.$t('featureComponents.page.tips.invalid'), trigger: 'blur' },
          { pattern: /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,8})$/, message: 'Please enter a valid Email address.', trigger: 'blur' }],
      },
    }
  },
  computed: {
    isEmailSubmitBtnAvailable() {
      return this.formData.email !== '' && this.formData.email.indexOf('@') > 0;
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      // 输入框自动聚焦
      this.$refs.bindEmailInput.focus();
    },
    onSubmit() {
      this.$refs.bindEmailForm.validate((isValid) => {
        if (isValid) {
          this.bindEmail();
        }
      });
    },
    /**
     * 绑定邮箱
     */
    async bindEmail() {
      this.isSubmitBtnLoading = true;
      const data = {
        email: this.formData.email,
      }

      const res = await this.$axios.post(oneApi.bindEmail, data, { rewritePostBody: true });

      if (Number(res.code) !== 0) {
        this.isSubmitBtnLoading = false;
        this.$ElMessage.error(res.msg);
        if (Number(res.code) === 9) {
          this.$emit('close-dialog');
          bus.$emit('login');
        }
        return;
      }
      this.isSubmitBtnLoading = false;
      this.bindEmailSuccessHandler();
    },
    bindEmailSuccessHandler() {
      this.$store.commit('ucenter/SET_USER_EMAIL', this.formData.email);

      // 关闭弹窗
      this.$emit('close-dialog');

      // 成功后跳转路径
      if (this.triggerSrc) {
        if (this.triggerSrc === 'REGISTRATION_COURSE') {
          bus.$emit('courses.detail.registrationCourses')
        }
        return;
      }
      // 购课按钮触发绑定邮箱 跳转至确认订单页面
      if (this.successUrl) {
        this.$router.push(this.successUrl);
      }
    },

  },
}
</script>

<style lang="scss" scoped>
.bind-email-container {
  .bind-email-btn-container {
    margin: 0 auto;
    width: 56px;
  }

  .button-bind-email {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    width: 56px;
    height: 56px;

    .el-icon-right {
      font-weight: bold;
    }
  }
}
</style>
