/**
 * 获取页面配置
 */

import { oneApi } from 'config/api'
import { clearUrlEndSlash } from 'common/utils'

export default async function ({
  req, store, app, route,
}) {
  const host = process.server ? req.ctx.hostname : window.location.hostname
  const path = clearUrlEndSlash(route.path)
  const params = {
    host,
    path,
  }

  // 客户端路由切换使用异步请求, 防止页面切换时调用接口阻塞页面跳转
  if (process.client) {
    app.$axios.post(oneApi.queryPageConfig, params, { rewritePostBody: true, sendToken: false }).then((res) => {
      const { code, data = {} } = res || {}
      if (code !== 0) {
        return
      }
      const { config = {}, isDefault } = data
      store.dispatch('website/updatePageData', {
        ...config,
        isDefault,
      })
    })
    return
  }

  const res = await app.$axios.post(oneApi.queryPageConfig, params, { rewritePostBody: true, sendToken: false })
  const { code, data = {} } = res || {}
  if (code !== 0) {
    return
  }
  const { config = {}, isDefault } = data
  store.dispatch('website/updatePageData', {
    ...config,
    isDefault,
  })
}
