export default {
  computed: {
    pageTitle() {
      return this.$store.state.website.page.title
    },
    pageKeywords() {
      return this.$store.state.website.page.keywords
    },
    pageDescription() {
      return this.$store.state.website.page.description
    },
  },
  head() {
    const defaultTitle = this.$t('common.websiteName')
    return {
      title: this.pageTitle || defaultTitle,
      meta: [{
        name: 'keywords',
        content: this.pageKeywords,
      }, {
        name: 'description',
        content: this.pageDescription,
      }],
    }
  },
}
