export default {
  pageTitle: 'Think Academyアプリをダウンロードする',
  studentApp: '生徒用アプリ',
  studentInfo: '興味をそそるための没入型と対話型オンライン授業',
  parentApp: '保護者用アプリ',
  parentInfo: 'お子さまの学習進捗状況をリアルタイムで確認できる便利なアシスタント',
  deviceTitle: 'デバイス要件',
  windowsRequirement: {
    title: 'Windows要件',
    info1: 'オペレーティングシステムはWindows 7以上が必要になります',
    info2: '最小構成 CPU 4コア、4GB RAM',
    info3: 'デバイス ChromebookおよびSurface Bookはサポート対象外です。',
  },
  macRequirement: {
    title: 'Mac要件',
    info1: 'オペレーティングシステムはMacOS 10.12以上が必要になります',
    info2: '最小構成CPU 4 コア, 4GB RAM',
  },
  ipadRequirement: {
    title: 'iPad要件',
    info1: 'オペレーティングシステムは iOS 10.0以上が必要になります',
    info2: 'デバイス:iPad 5、iPad Air 3、 iPad mini 5、およびすべての新機種',
  },
  iphoneRequirement: {
    title: 'iPhone要件',
    info1: 'オペレーティングシステムはiOS 10.0以上が必要になります',
    info2: 'デバイス iPhone7/Plus およびすべての新モデル',
  },
  androidRequirement: {
    title: 'Android要件',
    info1: 'オペレーティングシステムはAndroid 6.0以上が必要になります',
    info2: '最小構成(タブレット): 2.0 GHzプロセッサ速度, 4GB RAM',
  },
  parentThinkAcademy: 'Think Academy Parent',
  download: 'ダウンロード',
}
