export default {
  coupon: {
    selectCoupons: '選擇優惠券',
    tips: '最佳優惠推薦使用<span class="orange-text">{x}張優惠券</span>，共减免<span class="orange-text">{y}</span>',
    couponTips: '已選擇<span class="orange-text">{x}張優惠券</span>，共减免<span class="orange-text">{y}</span>',
    noSelectedTips: '<span class="orange-text">{x}張優惠券</span>可用',
    changeCard: '更換支付卡',
    automaticPay: '授权状态',
    authorized: '已授權',
    unauthorized: '未授權',
    selectedTips: "已選擇<span class='orange-text'>{x}張優惠券</span>，共减免<span class='orange-text'>{y}</span>",
  },
}
