<template>
  <div class="student-info-form-wrapper">
    <el-form ref="studentInfoForm" :model="stuFormData" inline :rules="rules" label-width="160px" class="student-info-form" label-position="left">
      <div class="student-info">
        <el-form-item :label="pageConfig.displayName" prop="nickName" class="normal-pure-white-form-item name" placeholder="">
          <el-input v-show="focusFieldName==='nickName'" v-model="stuFormData.nickName" :class="focusFieldName=='nickName'?'active-input':''"
                    maxlength="65" @blur="focusFieldName=''" @change="changeHandler"
          >
            <i v-show="focusFieldName=='nickName'&&stuFormData.nickName" slot="suffix" class="icon-close-content" @mousedown.prevent="clearContent('nickName')" />
          </el-input>
          <p v-show="focusFieldName!=='nickName'" :class="!stuFormData.nickName?'empty-text':'not-empty-text'" class="text-overflow-ellipsis" @click="autoFocus('nickName')">
            {{ !stuFormData.nickName ? pageConfig.enter + pageConfig.displayName : stuFormData.nickName }}
          </p>
        </el-form-item>
        <p class="name-rule">
          {{ pageConfig.nameTip }}, {{ pageConfig.maximun10 }}
        </p>
        <div class="student-information">
          <div class="student-information-form">
            <el-form-item v-if="locale === 'hk'" :label="pageConfig.lastName" prop="lastName" class="normal-pure-white-form-item info-wrapper" placeholder="Enter last name">
              <el-input v-show="focusFieldName==='lastName'" v-model="stuFormData.lastName" class="form-input" :class="focusFieldName=='lastName'?'active-input':''"
                        maxlength="32" @blur="focusFieldName=''" @change="changeHandler"
              >
                <i v-show="focusFieldName=='lastName'&&stuFormData.nickName" slot="suffix" class="icon-close-content" @mousedown.prevent="clearContent('lastName')" />
              </el-input>
              <p v-show="focusFieldName!=='lastName'" :class="!stuFormData.lastName?'empty-text':'not-empty-text'" class="text-overflow-ellipsis" @click="autoFocus('lastName')">
                {{ !stuFormData.lastName ? pageConfig.enter + pageConfig.language + pageConfig.lastName : stuFormData.lastName }}
              </p>
            </el-form-item>
            <el-form-item :label="pageConfig.firstName" prop="firstName" class="normal-pure-white-form-item info-wrapper" placeholder="Enter first name">
              <el-input v-show="focusFieldName==='firstName'" v-model="stuFormData.firstName" class="form-input" :class="focusFieldName=='firstName'?'active-input':''"
                        maxlength="32" @blur="focusFieldName=''" @change="changeHandler"
              >
                <i v-show="focusFieldName=='firstName'&&stuFormData.nickName" slot="suffix" class="icon-close-content" @mousedown.prevent="clearContent('firstName')" />
              </el-input>
              <p v-show="focusFieldName!=='firstName'" :class="!stuFormData.firstName?'empty-text':'not-empty-text'" class="text-overflow-ellipsis" @click="autoFocus('firstName')">
                {{ !stuFormData.firstName ? `${pageConfig.enter}${pageConfig.language || ''}${ pageConfig.firstName}` : stuFormData.firstName }}
              </p>
            </el-form-item>
            <el-form-item v-if="locale !== 'hk'" :label="pageConfig.lastName" prop="lastName" class="normal-pure-white-form-item info-wrapper" placeholder="Enter last name">
              <el-input v-show="focusFieldName==='lastName'" v-model="stuFormData.lastName" class="form-input" :class="focusFieldName=='lastName'?'active-input':''"
                        maxlength="32" @blur="focusFieldName=''" @change="changeHandler"
              >
                <i v-show="focusFieldName=='lastName'&&stuFormData.nickName" slot="suffix" class="icon-close-content" @mousedown.prevent="clearContent('lastName')" />
              </el-input>
              <p v-show="focusFieldName!=='lastName'" :class="!stuFormData.lastName?'empty-text':'not-empty-text'" class="text-overflow-ellipsis" @click="autoFocus('lastName')">
                {{ !stuFormData.lastName ? pageConfig.enter + pageConfig.lastName : stuFormData.lastName }}
              </p>
            </el-form-item>
            <el-form-item :label="$t('page.common.grade')" prop="gradeId" class="normal-pure-white-form-item info-wrapper">
              <el-select v-model="stuFormData.gradeId" class="form-input select-grade" :placeholder="pageConfig.pleaseSelect" popper-class="normal-grace-dropdown" :disabled="!isEditing" @change="changeHandler">
                <el-option
                  v-for="item in gradeList"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </div>
        </div>
      </div>
    </el-form>
    <button :disabled="isDisabled" class="button button-normal button-save" @click="submitForm">
      {{ submitBtnText }}
    </button>
  </div>
</template>

<script>
import { oneApi } from 'config/api';

export default {
  props: {
    studentData: {
      default: () => ({
        nickName: '',
        firstName: '',
        lastName: '',
        gradeId: '',
        // email: '',
      }),
      type: Object,
    },
    tipTextTopOfBtn: {
      default: '',
      type: String,
    },
    submitBtnText: {
      default: 'Save',
      type: String,
    },
  },
  data() {
    const pageConfig = this.$t('page.personalInfo')
    return {
      pageConfig,
      isLoading: false,
      isEditing: true,
      stuFormData: this.studentData,
      gradeList: [],
      emailChanged: false,
      rules: {
        nickName: [
          { pattern: pageConfig.pattern.nickname, message: pageConfig.maximun },
          { required: true, message: pageConfig.invalid },
        ],
        firstName: [
          { pattern: pageConfig.pattern.name, message: pageConfig.maximun32 },
          { required: true, message: pageConfig.invalid },
        ],
        lastName: [
          { pattern: pageConfig.pattern.name, message: pageConfig.maximun32 },
          { required: true, message: pageConfig.invalid },
        ],
        gradeId: [
          { required: true, message: pageConfig.invalid },
        ],
        email: [
          { required: true, message: pageConfig.invalid },
        ],
      },
      focusFieldName: '',
      isDisabled: false,
      locale: this.$store.state.locale,
    }
  },
  watch: {
    emailChanged(newVal) {
      if (newVal) {
        this.rules.email.push({ pattern: /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,8})$/, message: this.pageConfig.invalid })
      }
    },
  },
  mounted() {
    this.getGradeList();
  },
  methods: {
    submitForm() {
      this.$refs.studentInfoForm.validate((valid) => {
        if (valid) {
          this.isDisabled = true;
          this.$emit('save', this.stuFormData)
        } else {
          this.$ElMessage.error({ message: this.pageConfig.invalid, customClass: 'el-message-toast' });
          return false;
        }
        return ''
      });
    },
    async getGradeList() {
      this.isLoading = false;
      const res = await this.$axios.post(oneApi.queryGradesAll, {}, { rewritePostBody: true })
      if (Number(res.code) !== 0) {
        this.$ElMessage.error(res.msg);
        this.isLoading = false;
        return;
      }

      const resData = res.data || {};

      const gradeList = resData.list;

      let gradeMap = [];
      gradeList.forEach((item) => {
        gradeMap = gradeMap.concat(item.list)
      });

      this.gradeList = gradeMap;
      this.isLoading = false;
    },
    changeHandler(key) {
      if (key === 'email') {
        this.emailChanged = true
      }
      this.$emit('update:studentData', this.stuFormData)
    },
    autoFocus(name) {
      if (!this.isEditing) {
        return false;
      }
      this.focusFieldName = name
      setTimeout(() => {
        const activeInput = document.getElementsByClassName('active-input')[0].getElementsByClassName('el-input__inner')[0]
        activeInput.focus()
      }, 100)
      return ''
    },
    clearContent(name) {
      this.focusFieldName = name;
      this.stuFormData[name] = ''
    },
  },
}
</script>
<style lang="scss" scoped>
.student-info-form-wrapper {
  /deep/.normal-pure-white-form-item {
    padding: 0 16px;
    width: 100%;
    border-radius: 4px;

    .el-form-item__content {
      width: 220px;
    }
  }

  /deep/.normal-pure-white-form-item.name {
    margin-top: 0;
  }

  .student-information-form {
    border-radius: 10px;
    overflow: hidden;

    /deep/.normal-pure-white-form-item {
      margin: 0 auto;
      border-radius: 0;
    }
  }

  .name-rule {
    margin: -12px auto 20px;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: var(--txt-color-lv2);
    word-break: break-word;
  }

  .tip-text {
    font-size: 12px;
    line-height: 14px;
    // word-break: keep-all;
    color: var(--txt-color-lv2);
  }

  .button-save {
    margin: 20px auto 0;
    width: fit-content;
  }
}

/deep/.select-grade {
  display: block;
}
</style>
