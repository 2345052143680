/* eslint-disable max-len */
export default {
  title: 'Ajouter des informations de contact importantes',
  Email: 'E-mail',
  Phone: 'Numéro',
  email: 'E-mail',
  phone: 'Numéro',
  WeChat: 'WeChat',
  WhatsApp: 'WhatsApp',
  Line: 'Line',
  save: 'Enregistrer',
  cancel: 'Annuler',
  editWeChat: 'Modifier WeChat',
  editWhatsApp: 'Modifier WhatsApp',
  editLine: 'Modifier Line',
  editPhone: 'Modifier Phone',
  editEmail: 'Modifier E-mail',
  contactTip1: 'WeChat / WhatsApp / sera utilisée par les enseignants pour communiquer avec les parents au sujet des élèves',
  contactTip2: 'Apprentissage.',
  contactTip3: 'Veuillez remplir les informations de contact de manière véridique. Si les informations de contact sont manquantes, l\'enseignant ne pourra pas synchroniser les étudiants',
  contactTip4: 'Communiquer la situation d\'apprentissage aux parents en temps réel',
  minChoose: 'Remplir au moins une information de contact avant de sauvegarder',
  headline: 'Améliorer les contacts importants',
  describe: 'Veuillez remplir les informations de contact de manière véridique, si les informations de contact sont manquantes, cela affectera la capacité de l\'enseignant à synchroniser la situation d\'apprentissage des élèves avec les parents en temps opportun',
  invalidPhone: 'Veuillez saisir un numéro de téléphone portable valide',
  invalidEmail: 'Veuillez saisir un e-mail valide',
  invalidWechat: 'Veuillez saisir un Wechat valide',
  invalidWhatsApp: 'Veuillez saisir un Whatsapp valide',
  saveSuccess: 'Sauvegardé avec succès',
  saveFail: 'Veuillez indiquer au moins un contact',
  contactTip5: 'Le numéro et l\'E-mail seront utilisés pour envoyer des notifications importantes relatives à l\'apprentissage de votre enfant.',
};
