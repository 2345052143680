export default {
  pageTitle: 'Download Think Academy Apps',
  studentApp: 'Student App',
  studentInfo: 'Immersive and interactive Online classes for stimulating interest',
  parentApp: 'Parent App',
  parentInfo: 'Your handy assistant for real-time updates on your child’s learning progress',
  deviceTitle: 'Device Requirements',
  windowsRequirement: {
    title: 'Windows Requirements',
    info1: 'Operating System: Windows 7 and above',
    info2: 'Minimum configurations: CPU 4 Cores, 4GB RAM',
    info3: 'Device: Chromebook and Surface Book are NOT SUPPORTED',
  },
  macRequirement: {
    title: 'Mac Requirements',
    info1: 'Operating System: MacOS 10.12 and above',
    info2: 'Minimum configurations: CPU 4 Cores, 4GB RAM',
  },
  ipadRequirement: {
    title: 'iPad Requirements',
    info1: 'Operating System: iOS 10.0 and above',
    info2: 'Device: iPad 5, iPad Air 3, iPad mini 5, and all newer models',
  },
  iphoneRequirement: {
    title: 'iPhone Requirements',
    info1: 'Operating System: iOS 10.0 and above',
    info2: 'Device: iPhone7/Plus and all newer models',
  },
  androidRequirement: {
    title: 'Android Requirements',
    info1: 'Operating System: Android 6.0 and above',
    info2: 'Minimum configurations (Tablet): 2.0 GHz processor speed, 4GB RAM',
  },
  parentThinkAcademy: 'Think Academy Parent',
  download: 'Download',
}
