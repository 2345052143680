export default {
  methods: {
    // 神策埋点-班级卡片&班级详情曝光来源
    getClassFrom(fromSourceName) {
      switch (fromSourceName) {
        case 'courses':
          return 'courses页';
        case 'courses-list':
          return '班级列表页';
        case 'courses-available-classes-courseId':
          return '班级列表页';
        case 'free-trial-class':
          return 'free trial页面';
        case 'teachers-detail-teacherId':
          return 'teacher detail页面';
        case 'my-wish-list':
          return '收藏夹';
        case 'my-unpaid-courses-list':
          return '待缴费清单';
        case 'my-coupons-detail-couponId':
          return '优惠券可用班级列表';
        case 'courses-detail-coursesId':
          return '班级详情页';
        case 'courses-detail':
          return '年级聚合页';
        case 'test-recommended-classes-redeem':
          return '诊断课程推荐页';
        default: return fromSourceName;
      }
    },
    // 神策埋点-班级卡片通用参数
    getClassSaData(source, classData, coursesMode) {
      const classSepc = classData.spec || {}
      // 主讲老师
      const teacherList = classSepc.teacherList || []
      let teacherNames = ''
      teacherList.forEach((teacher) => {
        teacherNames += teacher.sysName
      });
      // 辅导老师
      const tutorList = classSepc.tutorList || []
      let tutorNames = ''
      tutorList.forEach((teacher) => {
        tutorNames += teacher.sysName
      });
      const teacherIds = Array.isArray(classSepc.teacherIds) ? classSepc.teacherIds.join() : '';
      const tutorIds = Array.isArray(classSepc.tutorIds) ? classSepc.tutorIds.join() : '';
      const saData = {
        course_name: classData.title,
        course_id: classSepc.courseId,
        class_id: Number(classSepc.clazzId || 0),
        year: classSepc.year || '',
        semester: Number(classSepc.term || 0),
        subject: classSepc.subject || '',
        grade: classSepc.gradeId || classSepc.grade || 0,
        grade_name: classSepc.gradeName || '',
        course_level: classSepc.levelDegreeName || '',
        course_level_id: classSepc.levelDegree || 0,
        course_capacity: classData.store || 0,
        course_category: classSepc.courseType || '',
        course_type: classSepc.platformType || '',
        content_teacher: teacherNames,
        content_teacher_ids_str: teacherIds,
        local_teacher: tutorNames,
        local_teacher_ids_str: tutorIds,
        mode: coursesMode || 'none',
      }
      if (source) saData.previous_source = source
      return saData
    },
  },
}
