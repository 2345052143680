/* eslint-disable */
import Vue from 'vue';
import VueI18n from 'vue-i18n';
// import { conforms, defaultsDeep } from 'lodash';
import defaultsDeep from 'lodash/defaultsDeep'
import config from 'config/index';
import moment from 'moment-timezone'

// 全局英文配置
import globalEnConfig from '~/locales/global/en'
// 全局繁体配置
import globalZhTWConfig from '~/locales/global/zh-TW'
// 全局简体中文配置
import globalZhConfig from '~/locales/global/chs'
// 全局日文配置
import globalJpConfig from '~/locales/global/jp'
// 全局韩文配置
import globalKoConfig from '~/locales/global/ko'
// 全局法文配置
import globalFrConfig from '~/locales/global/fr'
// 基础英文配置
import baseEnConfig from 'locales/base/en';
// 基础中文繁体配置
import baseTcConfig from 'locales/base/zh-TW'
// 基础简体中文配置
import baseZhConfig from 'locales/base/chs'

// VueI18n注册
Vue.use(VueI18n);

export default async ({ app, store }) => {
  // 合并全局配置的基础配置
  const _baseEnConfig = defaultsDeep(baseEnConfig, globalEnConfig)
  const _basetcConfig = defaultsDeep(baseTcConfig, globalZhTWConfig)
  const _baseZhConfig = defaultsDeep(baseZhConfig, globalZhConfig)
  const _baseJpConfig = defaultsDeep({}, globalJpConfig)
  const _baseKoConfig = defaultsDeep({}, globalKoConfig)
  const _baseFrConfig = defaultsDeep({}, globalFrConfig)

  const { locale, localeConfig: localeConf } = store.state
  let localeConfig = {}
  // 按分校懒加载配置包
  switch (locale) {
    case 'uk':
      localeConfig = await import('locales/uk')
      break;
    case 'sg':
      localeConfig = await import('locales/sg')
      break;
    case 'us':
      localeConfig = await import('locales/us')
      break;
    case 'hk':
      localeConfig = await import('locales/hk')
      moment.locale('zh-hk')
      break;
    case 'tmc':
      localeConfig = await import('locales/tmc')
      break;
    case 'au':
      localeConfig = await import('locales/au')
      break;
    case 'my':
      localeConfig = await import('locales/my')
      break;
    case 'ca':
      localeConfig = await import('locales/ca')
      break;
    case 'tc':
      localeConfig = await import('locales/tc')
      break;
    case 'ai':
      localeConfig = await import('locales/ai')
      break;
    case 'am':
      localeConfig = await import('locales/am')
      break;
    case 'ae':
      localeConfig = await import('locales/ae')
      break;
    case 'bmsg':
      localeConfig = await import('locales/bmsg')
      break;
    case 'bmus':
      localeConfig = await import('locales/bmus')
      break;
    case 'jp':
      localeConfig = await import('locales/jp')
      moment.locale('ja')
      break;
    case 'kr':
      localeConfig = await import('locales/kr')
      moment.locale('ko')
      break;
    case 'fr':
      localeConfig = await import('locales/fr')
      moment.locale('fr')
      break;
    default:
      localeConfig = await import('locales/sg')
      break;
  }

  // 自适应分校、两科技部演示分校 主体使用新加坡
  if (localeConf.useSgMain) {
    let localTmConfig
    if (locale === 'tm') localTmConfig = await import('locales/tm')
    localeConfig.default = defaultsDeep(localTmConfig.default, localeConfig.default)
  }
  // 香港配置包无需合并基础配置
  let defaultConfig
  if ((locale === 'hk' || locale === 'ai')) {
    defaultConfig = defaultsDeep(localeConfig.default, _basetcConfig)
  } else if ((locale === 'am' || locale === 'ae')) {
    defaultConfig = defaultsDeep(localeConfig.default, _baseZhConfig)
  } else if (locale === 'jp') {
    defaultConfig = defaultsDeep(localeConfig.default, _baseJpConfig)
  } else if (locale === 'kr') {
    defaultConfig = defaultsDeep(localeConfig.default, _baseKoConfig)
  } else if (locale === 'fr') {
    defaultConfig = defaultsDeep(localeConfig.default, _baseFrConfig)
  } else {
    defaultConfig = defaultsDeep(localeConfig.default, _baseEnConfig)
  }

  const messages = {
    [locale]: defaultConfig
  }

  // 实例化VueI18n
  app.i18n = new VueI18n({
    // 国家环境
    locale,
    // 预设的国家环境
    fallbackLocale: config.fallbackLocale,
    silentTranslationWarn: true,
    messages,
  });
}
