import skrollTop from 'skrolltop';
import { oneApi } from 'config/api';
import { createWay } from 'config/order.js';

export default {
  data() {
    return {
      // 拼团腰封dom id
      activityGroupEleId: 'courses-detail-activity-group',
      // 详情页活动数据
      activityInfo: {
        activityId: '',
        activityType: 0,
        sellPrice: 0,
        showPrice: 0,
        activityParam: '',
      },
    }
  },
  computed: {
    goodInActivity() {
      return this.activityInfo.activityType === 6
    },
    groupData() {
      if (this.activityInfo.activityType === 6) {
        return JSON.parse(this.activityInfo.activityParam)
      }
      if (process.env.clientType === 'web') return null
      return {}
    },
    groupParam() {
      const groupData = JSON.parse(this.activityInfo.activityParam) || {}
      return groupData.groupParam || {}
    },
  },
  methods: {
    joinInGroupHandler(group) {
      this.registrationHandler({
        way: 'ACTIVITY_GROUP_JOIN',
        groupId: group.groupId || null,
        activityId: this.activityInfo.activityId,
        activityType: this.activityInfo.activityType,
        skuId: group.skuId ? group.skuId : null,
      })
    },
    // 活动参与资格校验
    async activityRegistrationCheck() {
      const { orderCreateWay } = this.$store.state.order
      if (orderCreateWay === createWay.ACTIVITY_GROUP_NEW) {
        const result = await this.groupCheckHandler()
        return result
      }
      if (orderCreateWay === createWay.ACTIVITY_GROUP_JOIN) {
        const query = this.$store.state.order.createOrderQuery
        const groupId = query.groupId || ''
        if (!groupId) return false;
        const result = await this.groupCheckHandler(groupId)
        return result
      }
      return true
    },
    // 更新拼团数据
    async updateGroupData(skuId) {
      const data = {
        skuId,
      }
      const res = await this.$axios.post(oneApi.queryCourseDetail, data, { rewritePostBody: true });
      if (res.code !== 0) {
        return
      }
      const resData = res.data || {}
      this.activityInfo = Object.assign(this.activityInfo, resData.activityInfo || {})
    },
    // 拼团资格校验
    async groupCheckHandler(groupId) {
      const orderSkuId = this.$store.state.order.skuId
      const data = {
        activityId: this.activityInfo.activityId,
        skuId: orderSkuId || this.skuId,
        courseId: this.detailData.spec.courseId || null,
        curPackageId: this.detailData.spec.curriculumPackageId || null,
        startTime: this.detailData.spec.startDate || null,
        groupId,
      }
      const res = await this.$axios.post(oneApi.checkGroupBuy, data, { rewritePostBody: true })
      if (Number(res.code) !== 0) {
        return false
      }

      const resData = res.data || {}
      const result = resData.result || 0

      if (Number(result) !== 0) {
        this.groupCheckFailHandler(result)
        return false
      }

      if (resData.recommendedSkuId) {
        this.$store.commit('order/SET_ORDER_SKU_ID', resData.recommendedSkuId)
        this.$store.commit('order/SET_ORDER_CREATE_PATH', `/order/create/${resData.recommendedSkuId}`)
      }
      return true
    },
    // 拼团校验失败提示
    groupCheckFailHandler(failCode) {
      switch (failCode) {
        case 6000:
          // 活动已结束
          this.setConfirmDialog({
            show: true,
            title: 'Event has ended',
            content: 'Oopsie, group buy event has ended. Thank you for your interest!',
            mainBtnText: 'Okay, got it!',
            mainBtnClick: () => {
              // 弹窗关闭&页面数据更新
              this.init()
            },
            subBtnText: '',
          })
          break;
        case 6001:
          // 不满足开团条件
          this.setConfirmDialog({
            show: true,
            title: 'Did not meet requirements launch a group~',
            content: `Group launch features are only available to certain users, click "join group" to participate in the group buy~
            `,
            mainBtnText: 'Join a group',
            mainBtnClick: () => {
              // 弹窗关闭 & 页面滚动至拼团腰封模块
              this.scrollToGroupSection()
            },
            subBtnText: 'Cancel',
          })
          break;
        case 6006:
          // 不满足参团条件
          this.setConfirmDialog({
            show: true,
            title: 'Did not meet requirements to join group~',
            content: `Only new users that have yet to purchase courses can join existing groups, 
            what about launching your own group?`,
            mainBtnText: 'Launch a group',
            mainBtnClick: () => {
              // 弹窗关闭 & 触发开团按钮点击事件
              this.registrationHandler({ way: 'ACTIVITY_GROUP_NEW' })
            },
            subBtnText: 'Cancel',
          })
          break;
        case 6007:
        //  重复参团、开团
          this.setConfirmDialog({
            show: true,
            title: 'Unable to participate',
            content: 'You have participated in the same group buy activity. Unable to participate again.',
            mainBtnText: 'Okay, got it!',
            mainBtnClick: () => {},
            subBtnText: '',
          })
          break;
        default:
          if (this.$store.state.order.orderCreateWay === createWay.ACTIVITY_GROUP_NEW) {
            this.setConfirmDialog({
              show: true,
              title: 'Oh no, unable to launch new group',
              content: 'Oopsie, unable to launch new group. Join an existing group now!',
              mainBtnText: 'Okay, got it!',
              mainBtnClick: () => {},
              subBtnText: '',
            })
          } else if (this.$store.state.order.orderCreateWay === createWay.ACTIVITY_GROUP_JOIN) {
            this.setConfirmDialog({
              show: true,
              title: 'Unable to join group buy',
              content: 'Oopsie, the group that you chose is full. Launch your own group now!',
              mainBtnText: 'Launch a group',
              mainBtnClick: () => {
                // 弹窗关闭 & 触发开团按钮点击事件
                this.registrationHandler({ way: 'ACTIVITY_GROUP_NEW' })
              },
              subBtnText: 'Cancel',
            })
          }
          break;
      }
    },
    // 页面滚动至拼团信息模块
    scrollToGroupSection() {
      const eleId = this.activityGroupEleId
      skrollTop.scrollTo({
        to: document.getElementById(eleId).offsetTop,
      });
    },

  },
}
