/**
 * 时区切换窗口
 */
export default {
  // 标题
  title: 'Choose time zone',
  // 当前时区描述
  describe: 'Your current time zone is ',
  // 无搜索结果提示
  noResultNotice: 'No Result',
  // 切换定位刷新页面提示
  refreshNotice: 'Switching time zone for you',
  // 搜索输入框提示文本
  searchInputPlaceholder: 'Search time zone',
}
