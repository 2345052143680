/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
/**
 * 公共store数据
 */

import config from 'config/index';
import { oneApi } from 'config/api';
import { defaultPhoneAreaCode, defaultCountryShortCode } from 'common/map.js';

export const state = () => ({
  // 系统时间
  // 服务端渲染服务器时间
  systemTimestamp: new Date().getTime(),

  // 网页标题模板
  titleTemplate: '%s',

  // 用户网络环境信息
  userNetworkInfo: {
    // 是否大陆网络
    isMainland: null,
    // 是否中国网络
    isChina: null,
  },

  // 平台参数 (pc|touch)
  platform: process.env.clientType === 'web' ? 'pc' : 'touch',
  // 设备标识id
  deviceId: '',
  // 布局名称 (default|touchLayout)
  layoutName: 'default',

  // APP名称
  appName: '',
  // APP客户端名
  appDevice: '',
  // 是否在APP内
  isApp: false,
  // APP客户端版本号
  appVersion: '',
  // APP客户端操作系统版本号
  appPlatformVersion: '',
  // 路由跳转前缀
  // 区分APP与Touch端
  routerPathPrefix: '',

  // 当前语言环境
  locale: config.fallbackLocale,
  // 当前分校配置
  localeConfig: {},
  // 官网渠道编码
  channelCode: config.channelCode,
  // 分校编码 (4401 | 6501 | 415)
  schoolCode: '',
  // 国家编码 (uk | sg | us)
  countryCode: '',
  // 手机区号列表
  phoneCodeList: [],
  // 默认手机区号
  defaultPhoneCode: null,
  // 默认国家两字母缩写(大写)
  defaultCountryTwoLetterCode: '',

  // 以下字段从basicData中通过schoolCode匹配出的
  // 当前货币信息
  currency: {},
  // 当前时区信息
  timezone: '',
  // 当前分校信息, 包含countryId、schoolCode、shortName
  schoolInfo: {},
  // 当前学科信息
  subject: {},
  // 当前教师名称
  teacher: {},
  // 是否启用时区切换
  enableTimezone: false,
  // 首选登录方式
  loginPreferred: '',
  // 首选联系方式
  contactsPreferred: {},
  // App是否审核中
  review: null,
});

export const mutations = {
  /**
   * 设置网页标题模板
   * @param {State} state
   * @param {String} titleTemplate
   */
  SET_TITLE_TEMPLATE(state, titleTemplate) {
    state.titleTemplate = titleTemplate;
  },

  /**
   * 设置用户网络信息
   * @param {State} state
   * @param {Object} data
   */
  SET_USER_NETWORK_INFO(state, data) {
    state.userNetworkInfo.isMainland = data.isMainland
    state.userNetworkInfo.isChina = data.isChina
  },

  /**
   * 设置平台参数
   * @param {State} state
   * @param {平台} platform
   */
  SET_PLATFORM(state, platform) {
    state.platform = platform;
  },

  /**
   * 设置设备标识id
   * @param {state} state
   * @param {string} deviceId
   */
  SET_DEVICE_ID(state, deviceId) {
    state.deviceId = deviceId
  },

  /**
   * 设置布局名称参数
   * @param {State} state
   * @param {布局名称} layoutName
   */
  SET_LAYOUT_NAME(state, layoutName) {
    state.layoutName = layoutName;
  },

  /**
   * 设置APP设备名称
   * @param {State} state
   * @param {设备}} appDevice
   */
  SET_APP_DEVICE(state, appDevice) {
    if (!appDevice || appDevice === 'undefined' || appDevice === 'null') {
      state.appDevice = '';
      return;
    }
    state.appDevice = appDevice;
  },
  /**
   * 设置是否是APP
   * @param {State} state
   * @param {设备}} appDevice
   */
  SET_IS_APP(state, isApp) {
    state.isApp = isApp;
  },
  /**
   * 设置APP版本号
   * @param {State} state
   * @param {版本} appVersion
   */
  SET_APP_VERSION(state, appVersion) {
    if (!appVersion || appVersion === 'undefined' || appVersion === 'null') {
      state.appVersion = '';
      return;
    }
    state.appVersion = appVersion;
  },
  /**
   * 设置APP应用名称
   * @param {State} state
   * @param {应用名称}} appName
   */
  SET_APP_NAME(state, appName) {
    if (!appName || appName === 'undefined' || appName === 'null') {
      state.appName = '';
      return;
    }
    state.appName = appName;
  },
  /**
   * 设置APP操作系统版本号
   * @param {State} state
   * @param {操作系统版本} appPlatformVersion
   */
  SET_APP_PLATFORM_VERSION(state, appPlatformVersion) {
    if (!appPlatformVersion || appPlatformVersion === 'undefined' || appPlatformVersion === 'null') {
      state.appPlatformVersion = '';
      return;
    }
    state.appPlatformVersion = appPlatformVersion;
  },

  /**
   * 设置语言环境
   * @param {State}} state
   * @param {语言环境} locale
   */
  SET_LOCALE(state, locale) {
    state.locale = locale;
  },

  /**
   * 设置better分校值
   * @param {State}} state
   * @param {语言环境} locale
   */
  SET_BETTERME(state, locale) {
    state.betterme = locale;
  },

  /**
   * 设置分校配置
   * @param {Object} state state
   * @param {Object} localeConfig 分校配置
   */
  SET_LOCALE_CONFIG(state, localeConfig) {
    state.localeConfig = localeConfig
  },

  /**
   * 设置国家编码
   * @param {State} state
   * @param {国家编码} countryCode
   */
  SET_COUNTRY_CODE(state, countryCode) {
    state.countryCode = countryCode;
  },

  /**
   * 设置分校编码
   * @param {State} state
   * @param {分校编码} schoolCode
   */
  SET_SCHOOL_CODE(state, schoolCode) {
    state.schoolCode = schoolCode;
  },

  /**
   * 设置路由跳转前缀
   * @param {State} state
   * @param {前缀} prefix
   */
  SET_ROUTER_PATH_PREFIX(state, prefix) {
    state.routerPathPrefix = prefix;
  },

  /**
   * 设置手机区号
   * @param {State} state
   * @param {区号列表} list
   */
  SET_PHONE_CODE_LIST(state, list) {
    state.phoneCodeList = list;
  },

  /**
   * 设置默认手机区号
   */
  SET_DEFAULT_PHONE_CODE(state, list) {
    let defaultVal = list[0];
    const defaultPhoneAreaCodeVal = defaultPhoneAreaCode(state.schoolCode)

    if (defaultPhoneAreaCodeVal) {
      for (let i = 0; i < list.length; i += 1) {
        if (list[i].value === defaultPhoneAreaCodeVal.value && list[i].countryCode === defaultPhoneAreaCodeVal.countryCode) {
          defaultVal = list[i];
          break;
        }
      }
    }

    state.defaultPhoneCode = defaultVal
  },

  /**
   * 默认国家
   */
  SET_DEFAULT_COUNTRY_SHORT_CODE(state) {
    state.defaultCountryTwoLetterCode = defaultCountryShortCode(state.schoolCode)
  },

  /**
   * 重设基础信息
   * @param {*} state
   * @param {*} data
   */
  SET_BASIC_INFO(state, data) {
    state.review = data.review || null
    state.currency = data.currency || {}
    state.timezone = data.timezone || ''
    state.schoolInfo = data.schoolInfo || {}
    state.subject = data.subject || {}
    state.teacher = data.teacher || {}
    state.loginPreferred = data.loginPreferred || ''
    state.contactsPreferred = data.contactsPreferred || {}
  },

  /**
   * 设置是否启用时区切换
   * @param {Object} state
   * @param {Boolean} enableTimezone
   */
  SET_ENABLE_TIMEZONE(state, enableTimezone) {
    state.enableTimezone = enableTimezone
  },

  /**
   * 更新时区
   * @param {Object} state
   * @param {String} timezone 时区
   */
  UPDATE_TIMEZONE(state, timezone) {
    if (!timezone) return
    state.timezone = timezone
  },
}

export const actions = {
  async requeryBasicData({ commit, state }, $axios) {
    const data = {
      platform: 'H5',
    }

    const res = await $axios.post(oneApi.requeryBasicData, data, { rewritePostBody: true, sendToken: false });
    // 业务异常阻断
    if (Number(res.code) !== 0) {
      return
    }
    const resData = res.data || {};

    if (Object.keys(resData).length === 0) return

    const {
      international = [],
      currencyV2 = {},
      timezoneV2 = {},
      schoolsV2 = {},
      subjectV2 = {},
      teacherV2 = {},
      timeZoneSwitchSchool = [],
      loginPreferred = '',
      contactsPreferred = {},
      review = null,
    } = resData

    // 设置分校基础信息
    commit('SET_BASIC_INFO', {
      currency: currencyV2[state.schoolCode],
      timezone: timezoneV2[state.schoolCode],
      schoolInfo: schoolsV2[state.schoolCode],
      subject: subjectV2[state.schoolCode],
      teacher: teacherV2[state.schoolCode],
      loginPreferred: loginPreferred[state.schoolCode],
      contactsPreferred: contactsPreferred[state.schoolCode],
      review,
    })

    // 设置分校短名称
    commit('SET_DEFAULT_COUNTRY_SHORT_CODE')

    // 设置是否启用时区切换
    const enableTimezone = timeZoneSwitchSchool.includes(state.schoolCode)
    commit('SET_ENABLE_TIMEZONE', enableTimezone)

    // 处理手机区号列表
    if (international.length) {
      const phoneCodeList = international.map((item) => ({
        countryName: item.countryName,
        countryCode: item.countryShortCode,
        value: item.countryCallingCode,
        reg: new RegExp(`^\\d{${item.phoneMinLength},${item.phoneMaxLength}}$`),
      }));
      commit('SET_PHONE_CODE_LIST', phoneCodeList);
      commit('SET_DEFAULT_PHONE_CODE', phoneCodeList)
    }
  },

  /**
   * 设置模板标题
   * @param {String} titleTemplate 标题模板
   */
  setTitleTemplate({ commit }, titleTemplate) {
    commit('SET_TITLE_TEMPLATE', titleTemplate)
  },

  /**
   * 设置用户网络信息
   * @param {Object} data 网络信息
   */
  setUserNetworkInfo({ commit }, data) {
    commit('SET_USER_NETWORK_INFO', data)
  },

  /**
   * 更新时区
   * @param {String} timezone 时区
   */
  updateTimezone({ commit }, timezone) {
    commit('UPDATE_TIMEZONE', timezone)
  },
}
